<template>
  <div style="height: 800px">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: "soil",
    data() {
      return {
      }
    },
    methods: {

    }
  }
</script>

<style scoped>

</style>
