<template>
  <div style="text-align: center; width:100%;">
    <h1>{{this.year}}长势（数量）情况表</h1>
    <div id="count" :style="{width: '100%',height: '490px',}">
      <h1>图表加载失败</h1>
    </div>
  </div>
</template>

<script>
    export default {
        name: "grow-count",
        props:{//接受传入的参数
            'year': {},
            'showPromptMessage': {},
            'showWarningLine': {},
            collapsed:{},
        },
        data () {
            return {
              optionsYear: [],
              msg:{
                emergenceSeveral:{  //出苗数
                  value:0,
                  upperLimit:0,
                  lowerLimit:0,
                },
                emergenceTime:{  //50%出苗时间
                  value:0,
                  upperLimit:0,
                  lowerLimit:0,
                },
                bladesNumber :{ //叶片数
                  value:0,
                  upperLimit:0,
                  lowerLimit:0,
                },
                stolonCount:{ //匍匐茎数量
                  value:0,
                  upperLimit:0,
                  lowerLimit:0,
                },
                plantMainStemNumber:{  //单株主茎数量
                  value:0,
                  upperLimit:0,
                  lowerLimit:0,
                },
                perBudNumber:{  //单株芽数
                  value:0,
                  upperLimit:0,
                  lowerLimit:0,
                }
              }
            };
        },
        methods: {
          getMsg(val){
              this.$axios.post("/indicator/count",val).then((res)=>{
                let emergenceSeveral={name:"出苗数",num:0};
                let emergenceTime={name:"50%出苗时间",num:0};
                let bladesNumber={name:"叶片数",num:0};
                let stolonCount={name:"匍匐茎数量",num:0};
                let plantMainStemNumber={name:"单株主茎数量",num:0};
                let perBudNumber={name:"单株芽数",num:0};
                //出苗数 穴327  50%出苗时间毫秒 378 叶片数个 305   匍匐茎数量个 10056  单株主茎数量个 10057  单株芽数个  10058
                for(let i=0;i<res.data.title.length;i++){
                  if(res.data.list327[i]<this.msg.emergenceSeveral.lowerLimit){
                      emergenceSeveral.num++;
                    }
                  if(res.data.list378[i]<this.msg.emergenceTime.lowerLimit){
                    emergenceTime.num++;
                  }
                  if(res.data.list305[i]<this.msg.bladesNumber.lowerLimit){
                    bladesNumber.num++;
                  }
                  if(res.data.list10056[i]<this.msg.stolonCount.lowerLimit){
                    stolonCount.num++;
                  }
                  if(res.data.list10057[i]<this.msg.plantMainStemNumber.lowerLimit){
                    plantMainStemNumber.num++;
                  }
                  if(res.data.list10058[i]<this.msg.perBudNumber.lowerLimit){
                    perBudNumber.num++;
                  }
                }
                let a=[{name:"长势（数量）",num:3}]
                a.push(emergenceSeveral,emergenceTime,bladesNumber,stolonCount,plantMainStemNumber,perBudNumber);
                this.$emit("change",a)
                console.log(res.data)
                let myCharts = this.$echarts.init(document.getElementById("count"));
                myCharts.setOption( {
                  title:{
                    text:this.year+'年长势(数量)情况',
                    left:'center',
                    textStyle:{
                      fontWeight:'bolder',
                      fontSize:22,
                      color:"black",
                    },
                  },
                  xAxis: [{
                    nameLocation:'end', //坐标轴名称显示的位置
                    silent:true,  //是否静态无法交互
                    type: 'category',
                    data: res.data.title,
                    axisLine:{
                      show:true,
                    },},],
                  legend:{
                    textStyle:{
                      color:"#444",
                      fontSize:14,
                      fontWeight: 'bold',
                    },
                    // formatter:'{name}'+(this.showPromptMessage===true?"*":' '),
                    data: [
                      {name:'出苗数（穴）', tooltip:
                            {
                              name:'出苗数（穴）',
                              trigger:'出苗数（穴）',
                              show:this.showPromptMessage,
                              formatter:this.msg.emergenceSeveral.value,
                              position:'top',
                            }
                      },{name:'50%出苗时间（毫秒）', tooltip:
                            {
                              name:'50%出苗时间（毫秒）',
                              trigger:'50%出苗时间（毫秒）',
                              show:this.showPromptMessage,
                              formatter:this.msg.emergenceTime.value,
                              position:'top',
                            }
                      },{name:'叶片数（个）', tooltip:
                            {
                              name:'叶片数（个）',
                              trigger:'叶片数（个）',
                              show:this.showPromptMessage,
                              formatter:this.msg.bladesNumber.value,
                              position:'top',
                            }
                      },{name:'匍匐茎数量（个）', tooltip:
                            {
                              name:'匍匐茎数量（个）',
                              trigger:'匍匐茎数量（个）',
                              show:this.showPromptMessage,
                              formatter:this.msg.stolonCount.value,
                              position:'top',
                            }
                      },{name:'单株主茎数量（个）', tooltip:
                            {
                              name:'单株主茎数量（个）',
                              trigger:'单株主茎数量（个）',
                              show:this.showPromptMessage,
                              formatter:this.msg.plantMainStemNumber.value,
                              position:'top',
                            }
                      },
                      {name:'单株芽数（个）', tooltip:
                            {
                              name:'单株芽数（个）',
                              trigger:'单株芽数（个）',
                              show:this.showPromptMessage,
                              formatter:this.msg.perBudNumber.value,
                              position:'top',
                            }
                      },
                    ]
                  },
                  series: [
                    {
                      markLine:{
                        label:{
                          show:this.showWarningLine,
                          // formatter:'10cmEC警戒指标',
                        },
                        data : [
                          {
                            lineStyle:{ //警戒线的样式 ，虚实 颜色
                              width: this.showWarningLine===true?1:0   //宽度
                            },
                            yAxis: this.msg.emergenceSeveral.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                          }]
                      },
                      name:'出苗数（穴）',
                      data: res.data.list327,
                      symbolSize:10
                    },{
                      markLine:{
                        label:{
                          show:this.showWarningLine,
                          // formatter:'10cmEC警戒指标',
                        },
                        data : [
                          {
                            lineStyle:{ //警戒线的样式 ，虚实 颜色
                              width: this.showWarningLine===true?1:0   //宽度
                            },
                            yAxis: this.msg.emergenceTime.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                          }]
                      },
                      name:'50%出苗时间（毫秒）',
                      data:res.data.list378,
                      symbolSize:10
                    },{
                      markLine:{
                        label:{
                          show:this.showWarningLine,
                          // formatter:'10cmEC警戒指标',
                        },
                        data : [
                          {
                            lineStyle:{ //警戒线的样式 ，虚实 颜色
                              width: this.showWarningLine===true?1:0   //宽度
                            },
                            yAxis: this.msg.perBudNumber.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                          }]
                      },
                      name:'叶片数（个）',
                      data:res.data.list305,
                      symbolSize:10
                    },
                    {
                      markLine:{
                        label:{
                          show:this.showWarningLine,
                          // formatter:'10cmEC警戒指标',
                        },
                        data : [
                          {
                            lineStyle:{ //警戒线的样式 ，虚实 颜色
                              width: this.showWarningLine===true?1:0   //宽度
                            },
                            yAxis: this.msg.stolonCount.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                          }]
                      },
                      name:'匍匐茎数量（个）',
                      data:res.data.list10056,
                      symbolSize:10
                    },
                    {
                      markLine:{
                        label:{
                          show:this.showWarningLine,
                          // formatter:'10cmEC警戒指标',
                        },
                        data : [
                          {
                            lineStyle:{ //警戒线的样式 ，虚实 颜色
                              width: this.showWarningLine===true?1:0   //宽度
                            },
                            yAxis: this.msg.bladesNumber.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                          }]
                      },
                      name:'单株芽数（个）',
                      data:res.data.list10058,
                      symbolSize:10
                    },
                    {
                      markLine:{
                        label:{
                          show:this.showWarningLine,
                          // formatter:'10cmEC警戒指标',
                        },
                        data : [
                          {
                            lineStyle:{ //警戒线的样式 ，虚实 颜色
                              width: this.showWarningLine===true?1:0   //宽度
                            },
                            yAxis: this.msg.plantMainStemNumber.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                          }]
                      },
                      name:'单株主茎数量（个）',
                      data:res.data.list10057,
                      symbolSize:10
                    },

                  ]
                });
              })

            },
          changeChart(){
                this.getMsg(this.year)
            },
          handleWindowResize() {
            let myCharts = this.$echarts.init(document.getElementById('count'));
            myCharts && myCharts.resize();
          },
          changeChartSize(){
            setTimeout(()=>{
              this.handleWindowResize()
              // console.log("在刷新了")
            },500)
          }
        },
        mounted() {
          //出苗数 穴327  50%出苗时间毫秒 378 叶片数个 305   匍匐茎数量个 10056  单株主茎数量个 10057  单株芽数个  10058
          this.$axios.get("/indicators/getDetails/327",).then((rest)=>{
            this.msg.emergenceSeveral.value=rest.data.indexDetails
            this.msg.emergenceSeveral.lowerLimit=rest.data.lowerLimit
            this.msg.emergenceSeveral.upperLimit=rest.data.upperLimit
          })
          this.$axios.get("/indicators/getDetails/378",).then((rest)=>{
            this.msg.emergenceTime.value=rest.data.indexDetails
            this.msg.emergenceTime.lowerLimit=rest.data.lowerLimit
            this.msg.emergenceTime.upperLimit=rest.data.upperLimit
          })
          this.$axios.get("/indicators/getDetails/305",).then((rest)=>{
            this.msg.bladesNumber.value=rest.data.indexDetails
            this.msg.bladesNumber.lowerLimit=rest.data.lowerLimit
            this.msg.bladesNumber.upperLimit=rest.data.upperLimit
          })
          this.$axios.get("/indicators/getDetails/10056",).then((rest)=>{
            this.msg.stolonCount.value=rest.data.indexDetails
            this.msg.stolonCount.lowerLimit=rest.data.lowerLimit
            this.msg.stolonCount.upperLimit=rest.data.upperLimit
          })
          this.$axios.get("/indicators/getDetails/10057",).then((rest)=>{
            this.msg.plantMainStemNumber.value=rest.data.indexDetails
            this.msg.plantMainStemNumber.lowerLimit=rest.data.lowerLimit
            this.msg.plantMainStemNumber.upperLimit=rest.data.upperLimit
          })
          this.$axios.get("/indicators/getDetails/10058",).then((rest)=>{
            this.msg.perBudNumber.value=rest.data.indexDetails
            this.msg.perBudNumber.lowerLimit=rest.data.lowerLimit
            this.msg.perBudNumber.upperLimit=rest.data.upperLimit
          })
            //初始化echarts
            let myCharts = this.$echarts.init(document.getElementById("count"));
          setTimeout(()=>{
            this.handleWindowResize()
            // console.log("在刷新了")
          },100)
            window.addEventListener('resize',this.handleWindowResize)
            myCharts.setOption( {
                title: {
                    show:false,
                    text: '长势（数量）情况表',
                },
                xAxis: [{
                    name:'日期',  //坐标轴的名称
                    nameLocation:'end', //坐标轴名称显示的位置
                    silent:false,  //是否静态无法交互
                    type: 'category',
                    data: [],
                    axisLine:{
                        show:true,
                    },
                }],
                yAxis: {
                    name:'',  //坐标轴的名称
                    type: 'value',
                    scale:true,
                    // splitNumber:20,
                    start:30,
                    axisLine:{
                        show:true,
                    },
                },
                toolbox: {
                    show:true,
                    feature: {
                        saveAsImage: {},
                    }
                },
                        //出苗数 穴327  50%出苗时间毫秒 378 叶片数个 305   匍匐茎数量个 10056  单株主茎数量个 10057  单株芽数个  10058
                    // data: ['出苗数（穴）', '50%出苗时间（毫秒）', '叶片数（个）','匍匐茎数量（个）','单株主茎数量（个）','单株芽数（个）']
                legend:{
                        top:0,
                        // formatter:'{name}'+(this.showPromptMessage===true?"*":' '),
                        data: [
                            {name:'出苗数（穴）', tooltip:
                                    {
                                        name:'出苗数（穴）',
                                        trigger:'出苗数（穴）',
                                        show:this.showPromptMessage,
                                        formatter:this.msg.emergenceSeveral,
                                        position:'top',
                                    }
                            },{name:'50%出苗时间（毫秒）', tooltip:
                                    {
                                        name:'50%出苗时间（毫秒）',
                                        trigger:'50%出苗时间（毫秒）',
                                        show:this.showPromptMessage,
                                        formatter:this.msg.emergenceTime,
                                        position:'top',
                                    }
                            },{name:'叶片数（个）', tooltip:
                                    {
                                        name:'叶片数（个）',
                                        trigger:'叶片数（个）',
                                        show:this.showPromptMessage,
                                        formatter:this.msg.bladesNumber.value,
                                        position:'top',
                                    }
                            },{name:'匍匐茎数量（个）', tooltip:
                                    {
                                        name:'匍匐茎数量（个）',
                                        trigger:'匍匐茎数量（个）',
                                        show:this.showPromptMessage,
                                        formatter:this.msg.stolonCount.value,
                                        position:'top',
                                    }
                            },{name:'单株主茎数量（个）', tooltip:
                                    {
                                        name:'单株主茎数量（个）',
                                        trigger:'单株主茎数量（个）',
                                        show:this.showPromptMessage,
                                        formatter:this.msg.plantMainStemNumber.value,
                                        position:'top',
                                    }
                            },
                            {name:'单株芽数（个）', tooltip:
                                    {
                                        name:'单株芽数（个）',
                                        trigger:'单株芽数（个）',
                                        show:this.showPromptMessage,
                                        formatter:this.msg.perBudNumber.value,
                                        position:'top',
                                    }
                            },
                        ]
                    },
              grid: {
                left: '12px',
                right: '50px', ///   调整大小
                bottom: '1%',
                top:'7%',
                // show:true, //  边框是否显示
                containLabel: true
              },
                tooltip:{
                    appendToBody:'true',
                    backgroundColor:'rgba(255,255,255,0.96)',   //背景的颜色
                    textStyle:{
                        fontWeight:'normal',
                        indent:'50px',
                        color:"#555",
                    },
                    extraCssText:'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;white-space:pre-wrap;max-width:300px;',
                    show:true,
                    trigger:'axis',
                },
                series: [
                    {
                        name:"出苗数（穴）",
                        data: [],
                        type: 'line',
                        connectNulls:true,  //将断点连起来
                    },
                    {
                        name:"50%出苗时间（毫秒）",
                        data: [],
                        type: 'line',
                        connectNulls:true,
                    },
                    {
                        connectNulls:true,
                        name:"叶片数（个）",
                        data: [],
                        type:'line',
                    },
                    {
                        connectNulls:true,
                        name:"匍匐茎数量（个）",
                        data: [],
                        type:'line',
                    },
                    {
                        connectNulls:true,
                        name:"单株芽数（个）",
                        data: [],
                        type:'line',
                    },
                    {
                        connectNulls:true,
                        name:"单株主茎数量（个）",
                        data: [],
                        type:'line',
                    },

                ],

            });
        },
      watch:{
        year:'changeChart',
        showPromptMessage:'changeChart',
        showWarningLine:'changeChart',
        collapsed:"changeChartSize",
      },
    }
</script>

<style scoped>
h1{
  display: inline-block;
  font-weight: bolder;
  font-size: 24px;
}
</style>