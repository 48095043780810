<template>
    <el-form style="width: 60%" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

        <el-form-item label="模板名称" prop="name">
            <!--readOnly：只读，不可修改(或者disable)-->
            <el-input v-model="ruleForm.name" ></el-input>
        </el-form-item>

        <el-form-item label="创建日期" prop="createTime">
            <el-input v-model="ruleForm.createTime" readOnly></el-input>
        </el-form-item>

        <el-form-item label="使用情况" prop="situation">
            <el-input v-model="ruleForm.situation" readOnly></el-input>
        </el-form-item>

<!--        <el-form-item>-->
<!--            <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>-->
<!--        </el-form-item>-->

    </el-form>
</template>

<script>
    export default {
        data() {
            return {
                ruleForm: {
                    id: '',
                    name: '',
                    createTime: '',
                    situation:''
                },
                rules: {
                    name: [
                        { required: true, message: '计划名称不能为空', trigger: 'blur' }
                    ],
                }
            };
        },
        methods: {
            showEditDate(row){
                this.ruleForm=row;
            },
            updata()
            {
                if(this.ruleForm.name=="")
                {
                    this.$alert("请输入模板名称")
                    window.location.reload()
                }
                else
                {
                    this.$axios.post("/templateBasicinfo/updata",this.ruleForm).then(res=>{
                    })
                    // window.location.reload()
                }

            },
            submitForm(formName) {
                this.$alert(this.ruleForm)
            },
        },

    }
</script>