<template>
  <div style="background-color: white;height: 100vh;">


    <a-radio-group v-model="switch2D" style="position: relative;top: 40px;left: 40px;">
      <a-radio  :value="1">上传遥感图像</a-radio>
      <br>
      <a-radio  :value="0">上传点云模型</a-radio>
    </a-radio-group>

    <a-form v-if="switch2D==1" style="background-color: white;padding: 20px;padding-top: 50px;"
      id="components-form-demo-validate-other" :form="form" v-bind="formItemLayout" @submit="handleSubmit">


      <a-form-model-item label="地图名称" >
        <a-input v-decorator="[
          'name',
          { rules: [{ required: true, message: '地图名称' }] },
        ]"  placeholder="地图名称" style="width: 100%;" />
      </a-form-model-item>

      <a-form-model-item label="创建时间" >
        <a-date-picker v-decorator="[
          'formatTime',
          { rules: [{ required: true, message: '创建时间' }] },
        ]" show-time type="date" placeholder="选择日期" style="width: 100%;" />
      </a-form-model-item>



      <a-form-model-item label="是否具有NVDI数据">
        <a-radio-group v-decorator="[
          'isNvdi',
          { rules: [{ required: true, message: '是否具有NVDI数据' }] },
        ]">
          <a-radio value="1">是</a-radio>
          <a-radio value="0">否</a-radio>
        </a-radio-group>
      </a-form-model-item>


      <a-form-model-item label="是否切片" prop="isTiles">
      <a-radio-group v-decorator="[
          'isTiles',
          { rules: [{ required: true, message: '是否切片' }] },
        ]">
        <a-radio value="1">是</a-radio>
        <a-radio value="0">否</a-radio>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item label="是否上传" prop="isUpload">
      <a-radio-group v-decorator="[
          'isUpload',
          { rules: [{ required: true, message: '是否上传' }] },
        ]" >
        <a-radio value="1">是</a-radio>
        <a-radio value="0">否</a-radio>
      </a-radio-group>
    </a-form-model-item>


      <!-- TODO 需要后端给出可选地图 -->
      <a-form-item label="所属地图">
        <a-select v-decorator="[
          'map',
          { rules: [{ required: true, message: '请选择所属地图' }] },
        ]" placeholder="所属地图">
          <a-select-option v-for="(item, index) in plotData" :value="item.id">
            {{ item.affiliation }} ：{{ item.name }}
          </a-select-option>

        </a-select>
      </a-form-item>

      <a-form-item label="是否需要切分">
        <a-switch v-decorator="['switch', { valuePropName: 'checked' }]" />
      </a-form-item>


      <a-form-item label="地图类型" has-feedback>
        <a-select v-decorator="[
          'type',
          { rules: [{ required: true, message: '请选择地图类型' }] },
        ]" placeholder="地图类型">
          <a-select-option value="rgb">
            rgb
          </a-select-option>
          <a-select-option value="red">
            red
          </a-select-option>
          <a-select-option value="green">
            green
          </a-select-option>
          <a-select-option value="red_edge">
            red_edge
          </a-select-option>
          <a-select-option value="nir">
            nir
          </a-select-option>
        </a-select>
      </a-form-item>



      <a-form-item label="上传地块文件">
        <div class="dropbox">
          <a-upload-dragger :customRequest="customRequest" v-decorator="[
            'dragger',
            {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
            },
          ]">
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">
              点击区域上传 / 拖拽到本区域上传
            </p>
            <p class="ant-upload-hint">
              支持单个或多个上传
            </p>
          </a-upload-dragger>
        </div>
      </a-form-item>

      <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
        <a-button style="margin-top: 20px;" type="primary" html-type="submit">
          确认
        </a-button>
      </a-form-item>
    </a-form>

    <Upload3D v-if="switch2D==0"></Upload3D>

  </div>
</template>
  
<script>
import COS from 'cos-js-sdk-v5';
import axios from 'axios'
import Upload3D from './upload3d'
export default {
  components: {
    Upload3D
  },
  data: () => ({
    switch2D:1,
    formItemLayout: {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    },
    mapShowData: [],//已经创建的地图
    plotData: [],//已经创建的地块
    cosUrl: "",
    belong: "",
    needSlices: "",
    taskId: "",
    show2D: true,

  }),
  beforeCreate() {
    const _this = this;
    this.form = this.$form.createForm(this, { name: 'validate_other' });


    _this.$axios.get('http://43.143.170.49:8468/cropsSumMap/listMap').then(res => {
      _this.mapShowData = res.data.data;
      console.log(_this.mapShowData);
    })

    _this.$axios.get('/cropsSumMap/getListPage', {
      params: {
        pageNum: 1,
        plotName: '',
        plotAffiliation: '',
        plotYears: ''
      }
    }).then(res => {

      this.plotData = res.data.data.data
      console.log("dataSource is:", this.plotData)
    });


  },
  methods: {

    customRequest({
      // action,
      // data,
      file,
      // filename,
      headers,
      // onError,
      onProgress,//这里作为参数传进来，让axios的回调进行使用
      onSuccess,
      withCredentials,
    }) {
      const _this = this;
      const cos = new COS({
        SecretId: 'AKIDd6IDsHQKzPZUWKs98rJUR6Udcy6xWnmX',
        SecretKey: 'Zty1xa5NSLF5g2A5qMcfU3NRIuHQQBiz',
      });
      // console.log("action is"+action)
      console.log("headers is" + headers)
      console.log("withCredentials is " + withCredentials)
      cos.uploadFile({
        Bucket: 'scanner3d-1316598877', /* 填写自己的 bucket，必须字段 */
        Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
        Key: file.name,              /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
        Body: file, // 上传文件对象
        SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
        onProgress: function (progressData) {
          //想办法让下面的东西控制进度条
          //自己输出，为了不破坏antd的onProgress内部构造，目前还不知道自己重写antd的onProgress如何显示进度条，那么就不改动他的了
          console.log((progressData.loaded / progressData.total * 100).toFixed(2) + "%")//打开控制台可以看到更加精确的数值，下面的那个使用的整数（这个antd要求的）
          onProgress({ percent: Math.round((progressData.loaded / progressData.total) * 100) }, file);//这个是antd给我们传入的

        }
      }, function (err, cosData) {
        if (err) {
          console.log(err);
          // onError(err)//调用外部的onError方法，让配置的antd的处理函数处理，下面同理
        } else {
          console.log(cosData)
          onSuccess(cosData, file)//手动调用onSuccess告诉antd完成上传了
          // const formData = new FormData()


          // console.log(data.taskInfo.taskId)
          // console.log(cosData.Location)
          /**cosData格式
           * {
                "statusCode": 200,
                "headers": {
                    "content-length": "0",
                    "etag": "\"a016eb8decf5a22bcc2344358878b6f0\"",
                    "x-cos-request-id": "NjRhNzY5MjBfMmE2ZWFhMDlfMTA5MTNfMmE0ZTBkMg=="
                },
                "Location": "scanner3d-1316598877.cos.ap-nanjing.myqcloud.com/wallhaven-85dwdk.jpg",
                "ETag": "\"a016eb8decf5a22bcc2344358878b6f0\"",
                "RequestId": "NjRhNzY5MjBfMmE2ZWFhMDlfMTA5MTNfMmE0ZTBkMg=="
            }
           */
          _this.cosUrl = cosData.Location;//给this的data赋值
        }
      });

      return {
        abort() {
          console.log('upload progress is aborted.');
        },
      };
    },



    handleSubmit(e) {

      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('接收到的表单信息是: ', values);
          const addMapForm= {
            cropsRotationPlotId: values.map,              //plot_id和plot_name应该从当前位置取得，看record的发挥了
          cropsRotationPlotName: values.name,
          //TODO: 这个字段应该是从上传的文件中统计获得，而不是现在手动填写
            dataFormat: values.type,
            isNvdi: values.isNvdi,
            isTiles: values.isTiles,
            isUpload: values.isUpload,
            formatTime: values.formatTime,
          }
          //先新增地块，然后给地块添加地图
          this.$axios.request({
            method: "POST",
            url: '/cropsSumMap/addmap',
            data: addMapForm
          }).then(res => {
            console.log(res)
            console.log(res.data.data.id)
            // this.getList()

            const formData = new FormData();
            //这里判断data是否有值，如果没有则不能提交
            formData.append("cosurl", this.cosUrl)
            console.log("cosUrl is ", this.cosUrl)
            formData.append("belong", res.data.data.id)
            formData.append("type", values.type)
            if (values.switch == true) {
              formData.append("needSlices", "1")
            } else {
              formData.append("needSlices", "0")
            }
            formData.append("taskId", values.taskId)
            axios.post("http://43.143.170.49:8468/map/upload", formData).then(res => {
              console.log(res)
            })

          });

        }
      });
    },

    normFile(e) {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },
  },
}
</script>
<style>
#components-form-demo-validate-other .dropbox {
  height: 180px;
  line-height: 1.5;
}

.switchClass {
  top: 50px;
  left: 50px;
  /* transform: rotate(90deg); */
}
</style>