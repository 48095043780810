<template>
    <div>

        

      <div class="header" style="background-color: #fff;margin-bottom: 20px;padding: 20px;">
        <a-dropdown :trigger="['click']">
          <a @click="[e => e.preventDefault()] [selectTemplate=!selectTemplate ]" style="color: black;">
            <div style="font-weight: bolder">
              <h2 style="display: inline">选择计划： </h2>
              <a-icon :type="selectTemplate===true?'caret-down':'caret-up'" style="color:#888" />
            </div>
          </a>
          <a-menu slot="overlay" @click="getTemplate"  >
            <a-menu-item
                v-for="item in optionsTemplate"
                :key="item">
              <span style="font-size: 20px; margin-right: 20px; margin-left: 20px;">{{item}}</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <h1>{{template}}</h1>
      </div>

        <a-table style="background-color: #fff" :columns="columns" :data-source="data"  class="components-table-demo-nested">
           <div slot="operation" slot-scope="text,record">

               <a style="margin-right: 5px" @click="updata1(record)"><a-icon type="edit" /></a>

               <a-modal v-model="visible1" title="信息修改" @ok="handleOk1">
                   <edit1 ref="editPage1" />
               </a-modal>


               <a @click="deleted1(record)"><a-icon type="delete" /></a>
           </div>




            <a-table
                    slot="expandedRowRender"
                    slot-scope="record"
                    :columns="innerColumns"
                    size="small"
                    :data-source="record.innerData"

                    :pagination="false">
                
                <span slot="operation" slot-scope="text,record" class="table-operation">
                    
                    <a @click="getAllPeroid(record)" style="margin-right: 5px"><a-icon type="edit" /></a>

                    <a-modal v-model="visible2" title="详情追踪" >

                      <edit3 ref="editPage2" />
                      
                    </a-modal>

                    <a @click="deleted2(record)"><a-icon type="delete" /></a>

                </span>
            </a-table>
            
        </a-table>

       

            <el-dialog title="指标采集详情" :visible.sync="detailDialogVisible" size="big" append-to-body>
                <div>
                    <el-row>
                      <el-col v-for="(item, index) in AllPeroid" :key="index" :span="4">
                        <el-button type="text" :disabled="false" @click="butClick(item.periodId)">
                          <i :class="'el-icon-user'"
                            :style="{ background: (false ? '#46b2bf' : (index == butIndex ? '#46b2bf' : '#83d4dc')), color: ('#ffffff'), padding: ('12px'), 'border-radius': ('8px') }" />
                          <span
                            :style="{ color: (false? '#46b2bf' : (index == butIndex ? '#46b2bf' : '#83d4dc')), 'margin-left': ('5px') }">{{ item.periodName }}</span>
                        </el-button>
                      </el-col>
                    </el-row>
                  </div>
     
                <div class="card-timeline" v-for="item1 in TrackDetal">
                    <div class="card-item" :data-year="item.id"  v-for="item in item1" :key="item.id">
                    <div class="card-item-title">{{item.time}}</div>
                    <div class="card-item-title">{{item.title}}</div>
                    <div class="card-item-title">采集人：{{item.name}}</div>
                    <div class="card-item-desc">{{item.description}}</div>
                    
                    </div>
                </div>
            </el-dialog>

    </div>
</template>
<script>
    var columns = [
        { title: '指标分组', dataIndex: 'indicatorGroup', key: 'indicatorGroup' },
        { title: '指标个数', dataIndex: 'indicatorNum', key: 'indicatorNum' },
        { title: '指标完成度(%)', dataIndex: 'indicatorFinish', key: 'indicatorFinish' },
        { title: '采集方', dataIndex: 'collector', key: 'collector' },
        { title: 'Action', key: 'operation', scopedSlots: { customRender: 'operation'}},
    ];
    var phase='';
    var data = [];
    var innerColumns = [
        { title: '指标名称', dataIndex: 'indicatorName', key: 'indicatorName' ,
        },
        { title: '播种期',float:'right',id:1,
            children: [{title:'',dataIndex: 'boZhong', key: 'boZhong',}]
        },
        { title: '发芽期',id:2,
            children: [{title:'',dataIndex:'faYa', key: 'faYa'}]
        },
        { title: '幼苗期',id:3,
            children:[{title:'',dataIndex: 'youMiao', key: 'youMiao' ,}]
        },
        { title: '发棵期',id:4,
            children:[{title:'',dataIndex: 'faKe', key: 'faKe' ,}]
        },
        { title: '结薯期',id:5,
            children:[{title:'',dataIndex: 'jieShu', key: 'jieShu' ,}]
        },
        { title: '成熟期',id:6,
            children:[{title:'',dataIndex: 'chengShu', key: 'chengShu',}]
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            key: 'operation',
            scopedSlots: { customRender: 'operation' },
        },
    ];
    var innerData = [];
    var innerArray=[];
    import edit1 from "./edit/Edit1"
    import edit3 from "./edit/Edit3"
    export default {
        components:{edit1,edit3},
        data() {
            return {

            butList: [{
                    icon: 'el-icon-user',
                    text: '按钮01',
                    type: true
                },
                {
                    icon: 'el-icon-phone',
                    text: '按钮02',
                    type: true
                },
                {
                    icon: 'el-icon-goods',
                    text: '按钮03',
                    type: true
                },
                {
                    icon: 'el-icon-s-goods',
                    text: '按钮04',
                    type: true
                },
                {
                    icon: 'el-icon-remove',
                    text: '按钮05',
                    type: true
                },
                {
                    icon: 'el-icon-remove',
                    text: '按钮05',
                    type: true
                }
                ],
                butIndex: '-1', // 用来判断选中的按钮
                TrackDetal:[],
                detailDialogVisible:false,
                data,
                columns,
                innerColumns,
                // innerData,
                visible1: false,
                visible2 : false,
                dataSource:null,
                year:'',
                template:'',
                selectTemplate:true,
                optionsTemplate:['模板1','模板2'],
                phase,
                AllPeroid:[],
                Peroid:0,
                indicatorName:""
            };
        },
        methods:{
            butClick(index) {
                this.butIndex = index
                this.showDetailed(index,this.indicatorName)
            },

            groupHandleChange(value) {
                console.log(`selected ${value}`);
                let _this = this
                _this.Peroid = value
                console.log(_this.Peroid);
                // _this.selectData()
            },
            //获取所有指标不为0的阶段
            getAllPeroid(param) {
                this.detailDialogVisible = true,
                console.log("进入阶段获取阶段")
                this.indicatorName = param.indicatorName
                console.log(this.indicatorName)
                let _this = this
                _this.$axios.get('/templateDetailinfo1/get_all_by_type1',{
                    params: {
                        IndicatorName: param.indicatorName,
                    }
                }).then(res => {

                    console.log(res.data)
                    _this.AllPeroid = res.data;
                    console.log(_this.AllPeroid)
                })

            },
                

            showDetailed(param1,param2){
                console.log(param1)
                console.log(param2)
                let _this = this
                console.log("进行最终数据获取显示")
                this.detailDialogVisible = true;
                console.log("阶段"+param2)
                console.log("指标"+param2)
                
                this.$axios.get('/templateDetailinfo1/get_all_by_type',{
                    params: {
                        peroid: param1,
                        IndicatorName: param2
                    }
                }).then(res => {
                    console.log(res)
                    let _date = res.data.data;
                    _this.TrackDetal = res.data.data;
                    console.log(_this.TrackDetal)

			})


            },
            updata1(record)
            {
                this.visible1 = true;
                // this.$alert(row)
                this.$nextTick( () => {
                    //通过ref向子组件传递信息
                    this.$refs.editPage1.showEditDate(record)
                })
            },
            handleOk1(e) {//点击弹窗中的“确定”按钮触发的事件

                this.$nextTick( () => {
                    //通过ref向子组件传递信息
                    this.$refs.editPage1.updata()
                    this.visible1=false
                })
            },
            deleted1(record)
            {
                this.$axios.post("/templateDetailinfo1/deleted1",record).then(res=>{
                    this.$alert("删除成功",{
                        confirmButtonText: '确定',
                        callback: action => {//删除成功后重新加载一下当前页面
                            window.location.reload()
                        }
                    })
                })
            },
            updata2(record)
            {
                this.visible2 = true;
                // this.$alert(row)
                this.$nextTick( () => {
                    //通过ref向子组件传递信息
                    this.$refs.editPage2.showEditDate(record)
                })
            },
            handleOk2(e) {//点击弹窗中的“确定”按钮触发的事件

                this.$nextTick( () => {
                    //通过ref向子组件传递信息
                    this.$refs.editPage2.updata()
                    this.visible2=false
                })
            },
            deleted2(record)
            {
                this.$axios.post("/templateDetailinfo1/deleted2",record).then(res=>{
                    this.$alert("删除成功",{
                        confirmButtonText: '确定',
                        callback: action => {//删除成功后重新加载一下当前页面
                            window.location.reload()
                        }
                    })
                })
            },
            getTemplate(val)
            {
                data.splice(0,data.length)
                this.template=val.key
                //向父组件返回选择的模板名称
                this.$emit("getName",this.template)

                //根据模板名称获取阶段表的年份
                this.$axios.get("/templateDetailinfo2/findYear/"+this.template).then(res=>{
                    this.year=res.data
                  if(innerColumns[1].children[0].title==="")
                    {
                        this.$axios.get("/collectionPhase/getByYear?year="+this.year).then(res=>{
                            this.phase=res.data;
                            console.log("+++++++++++")
                            console.log(res)
                            innerColumns[1].title=innerColumns[1].title+'-'+this.phase.data[0].weeks+'周'
                            innerColumns[1].children[0].title=this.phase.data[0].startTime+'-'+this.phase.data[0].endTime

                            innerColumns[2].title=innerColumns[2].title+'-'+this.phase.data[1].weeks+'周'
                            innerColumns[2].children[0].title=this.phase.data[1].startTime+'-'+this.phase.data[1].endTime

                            innerColumns[3].title=innerColumns[3].title+'-'+this.phase.data[2].weeks+'周'
                            innerColumns[3].children[0].title=this.phase.data[2].startTime+'-'+this.phase.data[2].endTime

                            innerColumns[4].title=innerColumns[4].title+'-'+this.phase.data[3].weeks+'周'
                            innerColumns[4].children[0].title=this.phase.data[3].startTime+'-'+this.phase.data[3].endTime

                            innerColumns[5].title=innerColumns[5].title+'-'+this.phase.data[4].weeks+'周'
                            innerColumns[5].children[0].title=this.phase.data[4].startTime+'-'+this.phase.data[4].endTime

                            innerColumns[6].title=innerColumns[6].title+'-'+this.phase.data[5].weeks+'周'
                            innerColumns[6].children[0].title=this.phase.data[5].startTime+'-'+this.phase.data[5].endTime
                        })
                    }
                })

                this.$axios.get("/templateDetailinfo2/findName/"+this.template).then(res=>{
                
                    for(let i=0;i<res.data.length;i++)
                    {
                        console.log(res.data[i])
                        data.push(res.data[i])
                    }
                })
            }
        },
        mounted() {

            this.data.splice(0,this.data.length)

            // 查找所有模板的名称进行前台展示
            this.$axios.get("/templateBasicinfo/findAllNames").then(res=>{
                this.optionsTemplate=res.data
                this.template=res.data[0]
                this.$emit("getName",this.template)

                //根据模板名称获取阶段表的年份
                this.$axios.get("/templateDetailinfo2/findYear/"+this.optionsTemplate[0]).then(res=>{
                    console.log("wwwwwwwwwwww"+res.data)
                    this.year=res.data
                    if(innerColumns[1].children[0].title=="")
                    {
                        this.$axios.get("/collectionPhase/getByYear?year="+this.year).then(res=>{
                            this.phase=res.data;
                            console.log("+++++++++++")
                            console.log(res)
                            innerColumns[1].title=innerColumns[1].title+'-'+this.phase.data[0].weeks+'周'
                            innerColumns[1].children[0].title=this.phase.data[0].startTime+'-'+this.phase.data[0].endTime

                            innerColumns[2].title=innerColumns[2].title+'-'+this.phase.data[1].weeks+'周'
                            innerColumns[2].children[0].title=this.phase.data[1].startTime+'-'+this.phase.data[1].endTime

                            innerColumns[3].title=innerColumns[3].title+'-'+this.phase.data[2].weeks+'周'
                            innerColumns[3].children[0].title=this.phase.data[2].startTime+'-'+this.phase.data[2].endTime

                            innerColumns[4].title=innerColumns[4].title+'-'+this.phase.data[3].weeks+'周'
                            innerColumns[4].children[0].title=this.phase.data[3].startTime+'-'+this.phase.data[3].endTime

                            innerColumns[5].title=innerColumns[5].title+'-'+this.phase.data[4].weeks+'周'
                            innerColumns[5].children[0].title=this.phase.data[4].startTime+'-'+this.phase.data[4].endTime

                            innerColumns[6].title=innerColumns[6].title+'-'+this.phase.data[5].weeks+'周'
                            innerColumns[6].children[0].title=this.phase.data[5].startTime+'-'+this.phase.data[5].endTime
                        })
                    }


                })


                // 根据名称获取父表与子表内容
                this.$axios.get("/templateDetailinfo2/findName/"+this.optionsTemplate[0]).then(res=>{
                    for(let i=0;i<res.data.length;i++)
                    {

                        // res.data.innerData = 
                        this.data.push(res.data[i])
                    }
                })
            })
        }
    };
</script>
<style>

.el-select-dropdown.el-popper.detailDialog_select-poper{
    z-index: 10001 !important;
}

.headers .header:first-child {
  border-width:0;
}

.header h1{
  display:inline-block;
  font-size:30px;
  line-height: 50px;
  /*font-weight: bold;*/
  margin-top: 4px;
}
.header h2{
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
}
.header h3{
  margin: 0;
  color: #666666;
}

.header span{

}
.headers .header {
  margin-top: 20px;
  display: inline-block;
  text-align: left;
  /*background-color: #00ff00;*/
  vertical-align: middle;
  padding-left: 20px;
  width: 25%;
  min-height: 160px;
  border-left: 1px solid #ddd;
}

.headers .set div h2{
  margin-top: 20px;
  width: 30%;
  line-height: 30px;
}

@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500|Jost:400,500,600&display=swap");


.card-box {
	font-family: "Jost", sans-serif;
	display: flex;
	flex-direction: column;
	background-color: rgba(16, 18, 27, 0.1);
	align-items: center;
	overflow: auto;
	width: 100%;
	
}

.card {
	width: 100%;
	background-color: rgba(255, 255, 255, 1);
	display: flex;
	flex-direction: column;
	transition: 0.5s;
	border-radius: 10px;
	box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
	margin: 10px 0;
}

.card-header {
	position: relative;
	display: flex;
	height: 200px;
	flex-shrink: 0;
	width: 100%;
	transition: 0.5s;
	
	*{
		transition: 0.5s;
	}
	.card-avatar {
		width: 100px;
		height: 100px;
		box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
		border-radius: 50%;
		object-position: center;
		object-fit: cover;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%) translateY(-64px);
	}
	.card-fullname {
		position: absolute;
		bottom: 0;
		font-size: 22px;
		font-weight: 700;
		text-align: center;
		white-space: nowrap;
		transform: translateY(-10px) translateX(-50%);
		left: 50%;
	}
	
	.card-jobtitle {
		position: absolute;
		bottom: 0;
		font-size: 11px;
		white-space: nowrap;
		font-weight: 500;
		opacity: 0.7;
		text-transform: uppercase;
		letter-spacing: 1.5px;
		margin: 0;
		left: 50%;
		transform: translateX(-50%) translateY(-7px);
	}
}

.card.is-active {
	.card-header {
		height: 80px;
	}
	
	.card-avatar {
		transform: none;
		left: 20px;
		width: 50px;
		height: 50px;
		bottom: 10px;
	}
	
	.card-fullname,
	.card-jobtitle {
		left: 86px;
		transform: none;
	}
	
	.card-fullname {
		bottom: 18px;
		font-size: 19px;
	}
	
	.card-jobtitle {
		bottom: 16px;
		letter-spacing: 1px;
		font-size: 10px;
	}
}

.card-timeline {
    margin-top: 30px;
    position: relative;
    
    &:after {
        background: linear-gradient(
                to top,
                rgba(134, 214, 243, 0) 0%,
                rgba(81, 106, 204, 1) 100%
        );
        content: "";
        left: 42px;
        width: 2px;
        top: 0;
        height: 100%;
        position: absolute;
    }
    
    .card-item {
        position: relative;
        padding-left: 60px;
        padding-right: 20px;
        padding-bottom: 30px;
        z-index: 1;
        
        &:last-child {
            padding-bottom: 50px;
        }
        
        &:after {
            content: attr(data-year);
            width: 10px;
            position: absolute;
            top: 0;
            left: 37px;
            width: 8px;
            height: 8px;
            line-height: 0.6;
            border: 2px solid #fff;
            font-size: 11px;
            text-indent: -35px;
            border-radius: 50%;
            color: rgba(#868686, 0.7);
            background: linear-gradient(
                    to bottom,
                    lighten(#516acc, 20%) 0%,
                    #516acc 100%
            );
        }
        
        .card-item-title {
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 5px;
        }
        .card-item-desc {
            font-size: 13px;
            color: #6f6f7b;
            line-height: 1.5;
            font-family: "DM Sans", sans-serif;
        }
    }
    
}

.card-main {
	position: relative;
	flex: 1;
	display: flex;
	align-items: center;
	padding-top: 10px;
	flex-direction: column;
	
	.card-section {
		display: none;
		
		&.is-active {
			display: block;
			animation: fadeIn 0.6s both;
		}
		
		.card-content {
			padding: 20px;
			
			.card-subtitle {
				font-weight: 700;
				font-size: 13px;
				margin-bottom: 8px;
			}
			
			.card-desc {
				line-height: 1.6;
				color: #636b6f;
				font-size: 14px;
				margin: 0;
				font-weight: 400;
				font-family: "DM Sans", sans-serif;
			}
			
			
			
			.card-contact-wrapper {
				margin-top: 20px;
				
				.card-contact {
					display: flex;
					align-items: center;
					font-size: 13px;
					color: #6f6f7b;
					font-family: "DM Sans", sans-serif;
					line-height: 1.6;
					cursor: pointer;
					margin-bottom: 10px;
					
					& + & {
						margin-top: 16px;
					}
					
					img {
						flex-shrink: 0;
						width: 25px;
						height: 25px;
						transition: 0.3s;
						margin-right: 10px;
					}
					span{
						border-left: 1px solid #dfe2ec;
						padding-left: 12px;
					}
				}
				.contact-me {
					border: 0;
					outline: none;
					background: linear-gradient(
							to right,
							rgba(83, 200, 239, 0.8) 0%,
							rgba(81, 106, 204, 0.8) 96%
					);
					box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
					color: #fff;
					padding: 12px 16px;
					width: 100%;
					border-radius: 5px;
					margin-top: 25px;
					cursor: pointer;
					font-size: 14px;
					font-weight: 500;
					font-family: "Jost", sans-serif;
					transition: 0.5s;
				}
			}
			
		}
		
		.card-social {
			display: flex;
			align-items: center;
			padding: 0 20px;
			margin-bottom: 30px;
			
			svg {
				fill: rgb(165, 181, 206);
				width: 22px;
				display: block;
				transition: 0.5s;
			}
			
			a {
				color: #8797a1;
				height: 32px;
				width: 32px;
				border-radius: 50%;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				transition: 0.5s;
				background-color: rgba(93, 133, 193, 0.05);
				margin-right: 10px;
				
				&:hover {
					svg {
						fill: darken(rgb(165, 181, 206), 20%);
					}
				}
				
				&:last-child {
					margin-right: 0;
				}
			}
			
			
		}
	}
	
	.card-buttons {
		display: flex;
		background-color: #fff;
		margin-top: auto;
		width: 98%;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		
		button {
			flex: 1 1 auto;
			user-select: none;
			background: 0;
			font-size: 13px;
			border: 0;
			padding: 15px 5px;
			cursor: pointer;
			color: #5c5c6d;
			transition: 0.5s;
			font-family: "Jost", sans-serif;
			font-weight: 500;
			outline: 0;
			border-bottom: 3px solid transparent;
			
			&.is-active,
			&:hover {
				color: #2b2c48;
				border-bottom: 3px solid #8a84ff;
				background: linear-gradient(
						to bottom,
						rgba(127, 199, 231, 0) 0%,
						rgba(207, 204, 255, 0.2) 44%,
						rgba(211, 226, 255, 0.4) 100%
				);
			}
		}
	}
}

</style>
