<template>
  <div style="height: 100%">
    <div>
      <div id="rightMenu" type="primary" @click="showDrawer">
        <a-icon type="left" style="font-size: 16px"/>
      </div>
      <a-drawer
          title="地图编辑"
          placement="right"
          :mask="false"
          :keyboard="true"
          :closable="false"
          :visible="visible"
          :width="400"
          :after-visible-change="afterVisibleChange"
          @close="onClose"
      >
        <SideBar ref="SideBar" id="side-bar" :hide-menu="onClose" :show-bottom="showBottom" :set-center="setCenter" :set-lock="setLock"></SideBar>
      </a-drawer>

<!--      <a-drawer-->
<!--          title="Basic Drawer"-->
<!--          placement="bottom"-->
<!--          :closable="false"-->
<!--          :visible="bottom"-->
<!--          :mask="false"-->
<!--          headerStyle="display: none"-->
<!--          @close="closeBottom"-->
<!--      >-->
<!--        <p>Some contents...</p>-->
<!--        <p>Some contents...</p>-->
<!--        <p>Some contents...</p>-->
<!--      </a-drawer>-->

    </div>

    <div id="allBmap" style="height: 118%;"></div>

  </div>

</template>

<script>
import SideBar from './side/side-editor'
export default {
  name: "map-editor",
  components: {
    SideBar
  },
  data() {
    return {
      bottom: false,
      visible: true,
      center: {
        lng: 114.9455555555,
        lat: 41.422
      },
      dateValue: new Date(),
      zoom: 17,
      BMap: null,
      mapObj: null, // 地图
      positionMaker: {
        name: '地块一',
        soilPosition: {
          lng: 114.9455555555, lat: 41.422
        }
      },
      styleOptions: {
        strokeColor: '#5E87DB',   // 边线颜色
        fillColor: '#FFFFFF',     // 填充颜色。当参数为空时，圆形没有填充颜色
        strokeWeight: 2,          // 边线宽度，以像素为单位
        strokeOpacity: 1,         // 边线透明度，取值范围0-1
        // fillOpacity: 0         // 填充透明度，取值范围0-1
      },
      labelOptions: {
        borderRadius: '2px',
        background: '#FFFBCC',
        border: '1px solid #E1E1E1',
        color: '#703A04',
        fontSize: '12px',
        letterSpacing: '0',
        padding: '5px'
      },
      clickOptions: {
        borderRadius: '2px',
        background: '#FFFFFF',
        border: '1px solid #E1E1E1',
        color: '#703A04',
        fontSize: '12px',
        letterSpacing: '0',
        padding: '5px'
      },
      allMap: false,
      drawingManager:null, //鼠标绘图工具
      show:false,
      lock: 0,
      select: 0,
      devType: null
    }
  },
  watch: {
    '$route': 'routerChange'
  },
  created() {
    // this.loadBMapScript();
    // this.routerChange()
  },
  mounted() {
    this.initMap();
    // this.showDraw()
    //初始化加载绘图工具
    window.setTimeout(()=>{
      this.showDraw()
      // this.getOverlays()
    }, 100);

    this.getMarkers()
    this.getPolyline()
    this.getPolygon()


  },
  methods: {
    getOverlays() {
      var overlays = this.mapObj.getOverlays();

      var markers = []
      for (var i = 0; i < overlays.length; i++) {
        // console.log(i, overlays[i].isVisible())

        if (overlays[i].isVisible() && overlays[i].point !== undefined) {
            this.openInfo('', overlays[i], overlays[i])
          // markers.push(overlays[i].point)
        }
      }
      // console.log(overlays)
      // console.log(markers)
    },
    setCenter(id, point, introduce) {
      if(point == null) {
        // console.log("point is null")
      } else {
        let location = point[0] + ',' + point[1]
        // let introduce = '123123123'
        this.$refs.SideBar.setValue(id, location, introduce)

        this.mapObj.setZoom(17)
        this.mapObj.setCenter(new BMap.Point(point[0], point[1]))
      }
    },
    afterVisibleChange(val) {
      // console.log('visible', val);
    },
    showBottom() {
      this.bottom = true;
    },
    closeBottom() {
      this.bottom = false;
    },
    showDrawer() {
      this.visible = true;
      this.setLock()
    },
    onClose() {
      this.visible = false;
      this.setLock()
    },
    routerChange (to, from) {
      // console.log(to.path)
      // console.log(from.path)
    },
    //加载在线文件
    loadBMapScript () {
      let script = document.createElement('script');
      script.src = 'http://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js';
      // script.src = 'http://mapopen.cdn.bcebos.com/github/BMapGLLib/DrawingManager/src/DrawingManager.min.js';
      document.body.appendChild(script);

      let link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'http://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.css';
      // link.href = 'http://mapopen.cdn.bcebos.com/github/BMapGLLib/DrawingManager/src/DrawingManager.min.css';
      document.body.appendChild(link);
    },
    initMap() {
      let _this = this
      let map = null

      map = new BMap.Map("allBmap", {
        minZoom: 8,
        maxZoom: 17
      })

      // 初始化地图中心和Zoom层级
      map.centerAndZoom(new BMap.Point(114.9455555555, 41.422), _this.zoom)

      // 启动滚轮放大缩小
      map.enableScrollWheelZoom(true)

      // 启动连续缩放效果
      map.enableContinuousZoom(true)

      // 设置地图类型
      map.setMapType(BMAP_HYBRID_MAP)

      // 鱼骨图
      map.addControl(new BMap.NavigationControl())

      // 设置地图空间
      map.addControl(new BMap.MapTypeControl({
        mapTypes: [
          BMAP_NORMAL_MAP,
          BMAP_HYBRID_MAP,
          // BMAP_EARTH_MAP
        ]
      }))

      // 标注点
      // var point = new BMap.Point(_this.positionMaker.soilPosition.lng, _this.positionMaker.soilPosition.lat);
      // var marker = new BMap.Marker(point);        // 创建标注

      // map.addOverlay(marker);                     // 将标注添加到地图中


      // var menu = new BMap.ContextMenu();
      // var txtMenuItem = [
      //   {
      //     text:'放大',
      //     callback:function(){map.zoomIn()}
      //   },
      //   {
      //     text:'缩小',
      //     callback:function(){map.zoomOut()}
      //   }
      // ];
      // for(var i=0; i < txtMenuItem.length; i++){
      //   menu.addItem(new BMap.MenuItem(txtMenuItem[i].text,txtMenuItem[i].callback,100));
      // }
      // map.addContextMenu(menu);
      // console.log(map)

      _this.mapObj = map
    },
    showDraw() {
      let _this = this

      var arr =[]
      arr.push()
      // 实例化鼠标绘制工具
      _this.drawingManager = new BMapLib.DrawingManager(_this.mapObj, {
        isOpen: false, // 是否开启绘制模式
        enableDrawingTool: true, // 是否显示工具栏
        drawingToolOptions: {
          // anchor: BMAP_ANCHOR_TOP_LEFT,    // 位置
          // offset: new BMap.Size(1000, 5),     // 偏移
          scale: 0.8,                       // 工具栏缩放比例
          drawingModes: [
            BMAP_DRAWING_MARKER,    // 点
            BMAP_DRAWING_POLYGON,   // 多边形
            BMAP_DRAWING_POLYLINE,  // 线
            // BMAP_DRAWING_RECTANGLE,
            // BMAP_DRAWING_CIRCLE,
          ]
        },
        polylineOptions: this.styleOptions,
        polygonOptions: this.styleOptions
      })
      //监听画图方法
      _this.drawingManager.addEventListener('overlaycomplete', _this.overlaycomplete);
    },
    showPhoto() {
      this.$router.push('/soil/map-soil')
    },
    showAllMap() {
      if (this.allMap) {
        document.getElementById('side_bar').style.width = 0 + '%'
        this.allMap = !this.allMap
      } else {
        document.getElementById('side_bar').style.width = 30 + '%'
        this.allMap = !this.allMap
      }
    },
    getMarkers() {
      let _this = this

      _this.$axios.request({
        method: 'POST',
        url: '/soil/getMarkers',
        params: {
          type: 'Marker',
          isBaidu: 1
        }
      }).then(res => {
        // console.log(res)

        var markers = res.data.data
        // 标注点
        for (var i = 0; i< markers.length; i++)
        {
          var location = JSON.parse(markers[i].location)

          var lng = location[0][0]
          var lat = location[0][1]

          var devType = markers[i].devType
          var markerId = markers[i].id

          var point = new BMap.Point(lng, lat);
          // var myIcon = new BMap.Icon("weather.png", new BMap.Size(52, 52));
          var marker = new BMap.Marker(point);        // 创建标注

          // 添加标注信息
          var content = '名称: ' + markers[i].soilName  + '<br/>介绍: ' + markers[i].soilContent
          _this.addClickHandler(content, 'Marker', marker, markerId, devType)      // 添加左键点击事件
          _this.RightClickHandler(content, marker, markerId);//右键单击marker出现右键菜单事件

          // console.log(marker)
          _this.mapObj.addOverlay(marker);            // 将标注添加到地图中
        }
      })
    },
    getPolyline() {
      let _this = this

      _this.$axios.request({
        method: 'POST',
        url: '/soil/getMarkers',
        params: {
          type: 'Polyline',
          isBaidu: 1
        }
      }).then(res => {
        // console.log(res)

        var markers = res.data.data
        for(var i = 0; i < markers.length; i++) {
          var markarArr = []

          let location = JSON.parse(markers[i].location)

          let devType = markers[i].devType
          let markerId = markers[i].id

          for(var j = 0; j < location.length; j++) {
            let lng = location[j][0]
            let lat = location[j][1]

            var point = new BMap.Point(lng, lat)
            markarArr.push(point)
          }

          var polyline = new BMap.Polyline(markarArr)
          polyline.enableEditing()

          // 添加标注信息
          var content = 'polyline'
          _this.addClickHandler(content, 'Polyline', polyline, markerId, devType)      // 添加左键点击事件
          _this.RightClickHandler(content, polyline, markerId);//右键单击marker出现右键菜单事件

          _this.mapObj.addOverlay(polyline)
        }
      })
    },
    getPolygon() {
      let _this = this

      _this.$axios.request({
        method: 'POST',
        url: '/soil/getMarkers',
        params: {
          type: 'Polygon',
          isBaidu: 1
        }
      }).then(res => {

        // console.log(res)

        var markers = res.data.data
        for(var i = 0; i < markers.length; i++) {
          var markarArr = []

          let location = JSON.parse(markers[i].location)

          let devType = markers[i].devType
          let markerId = markers[i].id

          for(var j = 0; j < location.length; j++) {
            let lng = location[j][0]
            let lat = location[j][1]

            var point = new BMap.Point(lng, lat)
            markarArr.push(point)
          }

          // console.log(markarArr)

          var polygon = new BMap.Polygon(markarArr, {fillColor: "#FFFFFF", strokeColor:"blue", strokeWeight:2, strokeOpacity:0.5})

          polygon.enableEditing()
          // 添加标注信息
          var content = 'polygon'
          _this.addClickHandler(content, 'Polygon', polygon, markerId, devType)      // 添加左键点击事件
          _this.RightClickHandler(content, polygon, markerId);//右键单击marker出现右键菜单事件

          _this.mapObj.addOverlay(polygon)
        }
      })
    },
    openInfo(content, marker, e) {
      // console.log(marker)

      var _this = this

      var point = new BMap.Point(e.point.lng, e.point.lat)

      // 创建信息窗口对象
      var infoWindow = new BMap.InfoWindow(content, _this.opts);

      infoWindow.addEventListener("close", function () {
        _this.$refs.SideBar.clearValue()
        marker.setFillColor('#FFFFFF')
      })
      //开启信息窗口
      _this.mapObj.openInfoWindow(infoWindow, point)
    },
    setContent(type, temperture, humidity) {
      if (type === 0) {
        return '<p>空气温度：' +
            temperture +
            '</p>' +
            '<p>空气湿度：' +
            humidity +
            '</p>'
      } else if (type === 1) {
        return '<p>土壤温度：' +
            temperture +
            '</p>' +
            '<p>土壤湿度：' +
            humidity +
            '</p>'
      }
    },
    // 添加点击事件,可在循环数据时调用
    addClickHandler(content, type, marker, markerId, devType) {
      var _this = this
      let dev = devType

      marker.addEventListener("click", function (e) {
        // console.log(e)

        var location = '['

        if (type.toLowerCase() === 'Marker'.toLowerCase()) {
          var lng = marker.point.lng
          var lat = marker.point.lat
          location += ('[' + lng + ',' + lat + ']]')

          // var content = '<p>温度：</p>' +
          //     '<p>湿度：</p>'

          var introduce = undefined

          if (markerId === undefined) {
            // console.log(location)
            _this.openInfo('',marker, e)
            _this.$refs.SideBar.setValue(markerId, dev, location, introduce, type)
          } else {
            // console.log(markerId)
            _this.$axios.request({
              method: 'GET',
              url: '/equipment/listEquipmentByMarkerId',
              params: {
                id: markerId
              }
            }).then( res => {
              // console.log(res)
              var content
              var item = res.data.listEquipmentByMarkerId[0]


              if (item.equipmentConditionVO === null) {
                content = _this.setContent(item.equipmentType, '无', '无')
              } else {
                content = _this.setContent(
                    item.equipmentType,
                    item.equipmentConditionVO.temperatureValue / 100,
                    item.equipmentConditionVO.humidityValue / 100)
              }

              _this.openInfo(content, marker, e)
              _this.$refs.SideBar.setValue(markerId, dev, location, introduce, type)
            })
          }

        } else {
          var markers = marker.Bo

          // console.log(marker)
          // var content = '<p>地块说明：</p>'

          for (var i = 0; i < markers.length; i++) {
            var lng = markers[i].lng
            var lat = markers[i].lat
            location += ('[' + lng + ',' + lat + '],')
          }
          location = location.substr(0, location.length-1) + ']'

          var devType = undefined
          var introduce = undefined

          if (marker.z.fillColor.toUpperCase() === "#FFFFFF".toUpperCase()){
            marker.setFillColor('#FFFBCC')

            // console.log(markerId)
            if(markerId === undefined) {
              // console.log(dev)
              _this.openInfo('', marker, e)
              _this.$refs.SideBar.setValue(markerId, dev, location, introduce, type)
            } else {
              var content = ''
              _this.$axios.request({
                method: 'POST',
                url: '/soil/getMarkerById',
                params: {
                  id: markerId
                }
              }).then(res => {
                // console.log(res)
                content = '<p>地块名称：' +
                    res.data.data.soilName +
                    '</p>' +
                    '<p>地块描述：' +
                    res.data.data.soilContent +
                    '</p>'

                if(dev == -1) {
                  dev = 2
                }
                _this.openInfo(content, marker, e)
                _this.$refs.SideBar.setValue(markerId, dev, location, introduce, type)
              })
              // _this.$axios.request({
              //   method: 'GET',
              //   url: '/equipment/listEquipmentByMarkerId',
              //   params: {
              //     id: markerId
              //   }
              // }).then( res => {
              //   // console.log("----------------")
              //   // console.log(res)
              //   // console.log(location)
              //
              //   // var content = '<p>提供方：' +
              //   //     res.data.listEquipmentByMarkerId[0].equipmentProvider +
              //   //     '</p>' +
              //   //     '<p>使用方：' +
              //   //     res.data.listEquipmentByMarkerId[0].equipmentUser +
              //   //     '</p>'
              //
              //
              //   // _this.openInfo(content, marker, e)
              //   // devType = res.data.listEquipmentByMarkerId[0].equipmentType
              //
              // }).catch(error => {
              //   // console.log(error)
              //
              //   var content = ''
              //   _this.$axios.request({
              //     method: 'POST',
              //     url: '/soil/getMarkerById',
              //     params: {
              //       id: markerId
              //     }
              //   }).then(res => {
              //     // console.log(res)
              //     content = '<p>地块名称：' +
              //         res.data.data.soilName +
              //         '</p>' +
              //         '<p>地块描述：' +
              //         res.data.data.soilContent +
              //         '</p>'
              //
              //     _this.openInfo(content, marker, e)
              //
              //     // console.log(type, dev, devType)
              //     if(dev == -1) {
              //       dev = 2
              //     }
              //     // console.log(dev)
              //     _this.$refs.SideBar.setValue(markerId, dev, location, introduce, type)
              //   })
              // })

            }
          } else {
            // marker.setFillColor('#FFFFFF')
          }
        }

        // let location = e.point.lng + ',' + e.point.lat
      });
    },
    setLock() {
      // console.log(this.lock)
      if(this.lock === 1) {
        this.lock = 0
      } else {
        this.lock = 1
      }
    },
    RightClickHandler(content, overlay, markerId) {
      var _this = this

      //给覆盖物添加右键菜单
      var addMarker = function (e, ee, marker) {
        // console.log(marker)

        var location = '['

        if (marker.VQ === 'Marker') {
          var lng = marker.point.lng
          var lat = marker.point.lat
          location += ('[' + lng + ',' + lat + ']]')
        } else {
          var markers = marker.ha

          for (var i = 0; i < markers.length; i++) {
            var lng = markers[i].lng
            var lat = markers[i].lat
            location += ('[' + lng + ',' + lat + '],')
          }
          location = location.substr(0, location.length-1) + ']'
        }

        if(markerId == null) {
          _this.$confirm({
            title: '确认保存',
            content: h => <div>您确定保存此标注信息吗？</div>,
            onOk() {
              // console.log('OK');
              _this.$axios.request({
                method: 'POST',
                url: '/soil/addMarker',
                data: {
                  id: null,
                  devType: null,
                  devId: null,
                  type: marker.VQ,
                  isBaidu: 1,
                  location: location,
                  soilId: null,
                  soilName: null,
                  soilContent: null
                }
              }).then(res => {
                // console.log(res)
              })
            },
            onCancel()
            {
              // console.log('Cancel');
            }
          })
        } else {
          _this.$message.warning('此标注信息已经保存');
        }
      }
      var delMarker = function(e,ee,marker) {
        // console.log(markerId)
        _this.$confirm({
          title: '确认删除',
          content: h => <div style="color:red;">您确定删除此标注信息吗？</div>,
          onOk() {
            // console.log('OK');
            _this.$axios.request({
              method: 'DELETE',
              url: 'soil/delMarker',
              params: {
                id: markerId
              }
            }).then(res => {
              // console.log(res)
            })
            _this.mapObj.removeOverlay(marker);
          },
          onCancel()
          {
            // console.log('Cancel');
          }
        })
      }
      var toMapSoil = function (e, ee, marker) {
        _this.$router.push({
          name: 'map-soil',
          params: {
            tProjection: '',
            tUrl: 'https://gis-1300769337.cos.ap-beijing.myqcloud.com/gengyunMap/G:/tif_section/20/rgb/哈7_20200729/地块0729/_alllayers/',
            tRes: [4.2333418000169338,2.1166709000084669,1.0583354500042335,0.52916772500211673,0.26458386250105836,0.13229193125052918,0.066145965625264591,0.033072982812632296],
            tOrigin: [-5120900,9998100],
            tExtent: [326902.77013000002,4587784.7006200012,327754.41438000003,4588650.6783700008],
            tCode: 32650,
            tPro_extent: [166021.44,0.00,534994.66,9329005.18],
            tProje_defs: '+proj=utm +zone=50 +datum=WGS84 +units=m +no_defs',
          }
        })
      }


      //创建右键菜单
      var markerMenu=new BMap.ContextMenu();
      // markerMenu.addItem(new BMap.MenuItem('跳转',toMapSoil.bind(overlay)))
      // markerMenu.addItem(new BMap.MenuItem('保存', addMarker.bind(overlay)))
      markerMenu.addItem(new BMap.MenuItem('删除',delMarker.bind(overlay)))

      // 标注点
      _this.mapObj.addOverlay(overlay);                     // 将标注添加到地图中
      overlay.addContextMenu(markerMenu);
    },
    overlaycomplete(e) {
      let _this = this
      // console.log(e.overlay)
      _this.mapObj.removeOverlay(e.overlay)

      // console.log(e)
      var type = e.drawingMode
      var overlay = e.overlay
      var content = ''

      // console.log(type)
      _this.addClickHandler(content, type, overlay, undefined, undefined)
      // _this.addClickHandler(content, type, overlay)      // 添加左键点击事件
      _this.RightClickHandler(content, overlay)   // 添加右键点击事件

      e.overlay.enableEditing()
      _this.mapObj.addOverlay(e.overlay)
    }
  }
}
</script>

<style>
.BMapLib_Drawing {
  margin-left: 40% !important;
}
</style>
<!--3	3	3	polyline	1	[[116.433015,39.909843],[114.905222,40.760343],[115.487075,38.856858],[115.523579,38.878724]]	3	河北	河北-->