<template>
    <div>
        <div style="margin-left: 5px;float: left">
            <strong style="float: left">选择数据对比年份：</strong>
            <br />
            <a-checkbox-group v-model="checkedList" :options="this.plainOptions" @change="getM" />
        </div>
        <div id="t" style="margin-top: 20px;width: 100%;height: 400px">
        </div>
    </div>
</template>
<script>
    var optionsYear;
    var plainOptions = [];
    var defaultCheckedList = [];

    export default {
        data() {
            return {
                checkedList: defaultCheckedList,
                indeterminate: true,
                checkAll: false,
                plainOptions:[],
                optionsYear:[]
            };
        },
        beforeMount() {
            //加载年份
            this.$axios.post("/indicator/getYear").then((res)=>{
                this.optionsYear =res.data
                this.plainOptions=this.optionsYear
                this.checkedList=[this.plainOptions[0],this.plainOptions[1]]
                this.getM(this.checkedList);
            })


        },
        methods: {
            getM(checkedList)
            {
                let _this = this
                // let res = [];
                let se=[];
                this.$axios.post("/indicator/getBar22", checkedList).then((res) => {
                    let myChart = _this.$echarts.init(document.getElementById('t'))
                    myChart.clear()
                    for (var i = 0; i < res.data.length; i++) {
                        se[i] = {
                            name: checkedList[i],
                            data: res.data[i].ss,
                            type: 'bar',
                            backgroundStyle: {
                                color: 'rgba(180, 180, 180, 0.2)'
                            },
                            emphasis: {
                                focus: 'self'
                            },
                        };

                    }
                    myChart.setOption({
                        title: {
                            text: '结薯重量不同年份对比',
                            left: 'center'
                        },
                        xAxis: {
                            axisLabel: {
                                interval:0,
                                rotate : 40},
                            type: 'category',
                            data: ['2两以下', '2-3两', '3两以上', '单株大薯块', '单株中薯块', '单株小薯块', '单株特小薯块', '结薯平均']
                        },
                        yAxis: {
                            type: 'value'
                        },
                        tooltip: {},
                        legend: {
                            data: checkedList,
                            top:25
                        },
                        series: se
                    })

                });
                this.indeterminate = !!checkedList.length && checkedList.length < plainOptions.length;
                this.checkAll = checkedList.length === plainOptions.length;
            },
            changeChart(){
                this.getM(this.checkedList)
            },
            onChange: function (checkedList) {
                let _this = this
                // let res = [];
                let se=[];
                this.$axios.post("/indicator/test", checkedList).then((res) => {
                    console.log(checkedList + "*******")
                    let myChart = _this.$echarts.init(document.getElementById('tu'))
                    for (var i = 0; i < res.data.length; i++) {
                        console.log(res.data[i].ss)
                        se[i] = {
                            name: checkedList[i],
                            data: res.data[i].ss,
                            type: 'bar',
                            backgroundStyle: {
                                color: 'rgba(180, 180, 180, 0.2)'
                            },
                            emphasis: {
                                focus: 'self'
                            },
                        };

                    }
                    myChart.setOption({
                        title: {
                            text: '不同年份对比'
                        },
                        xAxis: {
                            type: 'category',
                            data: ['2两以下', '2-3两', '3两以上', '单株大薯块', '单株中薯块', '单株小薯块', '单株特小薯块', '结薯平均']
                        },
                        yAxis: {
                            type: 'value'
                        },
                        tooltip: {},
                        legend: {
                            data: checkedList
                        },
                        series: se
                    })

                });
                this.indeterminate = !!checkedList.length && checkedList.length < plainOptions.length;
                this.checkAll = checkedList.length === plainOptions.length;
            },
            onCheckAllChange(e) {
                Object.assign(this, {
                    checkedList: e.target.checked ? plainOptions : [],
                    indeterminate: false,
                    checkAll: e.target.checked,
                });
            },
        },
    };
</script>
