<template>
<div>
  <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
  >
    <a-form-model-item ref="name" label="采集计划名称" prop="name">
      <a-input
          v-model="form.name"
          @blur="() => {$refs.name.onFieldBlur();}"/>
    </a-form-model-item>

    <a-form-model-item label="计划采集年份" prop="region">
      <a-select v-model="form.region"  defaultActiveFirstOption  @mousemove="onChange">
        <a-select-option v-for="item in value" :key="item-1+new Date().getFullYear()" :value="item-1+new Date().getFullYear()"  >{{ item-1+new Date().getFullYear() }}</a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item style="text-align: center;" :wrapper-col="{ span: 14, offset: 4 }">
      <a-button style="margin-right: auto;margin-left: auto;" type="primary" @click="onSubmit">
        创建
      </a-button>
<!--      <a-button style="margin-left: 10px;" @click="resetForm">-->
<!--        清空-->
<!--      </a-button>-->
    </a-form-model-item>
  </a-form-model>
</div>
</template>

<script>
export default {
  name: "make-plan",
  data() {
    return {
      value: 10,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: '',
      form: {
        name: '',
        region: new Date().getFullYear(),
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入采集计划名称', trigger: 'blur' },
          { min: 0, max: 20, message: '名称在20个字以内', trigger: 'blur' },
        ],
        region: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
      },
    };
  },
  methods: {
    onChange() {
      // console.log('changed', value);
      // console.log(value)
      this.value++;
    },
    onSubmit() {
      //获得年份
      if(this.form.name!==''){
        this.$emit("getYear",[this.form.name,this.form.region])
      }
      else{
        this.$message.info("请输入采集计划名称");
      }
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
  },
}
</script>

<style scoped>

</style>