<template>
  <div style="text-align: center; width:100%;">
    <h1 style="display: inline-block">{{this.year}}年土壤EC</h1>
    <h1 style="display: inline-block;">值</h1>
    <div id="indicator-EC-charts" :style="{height: '500px',width:'100%'}">
      <h1>图表加载失败</h1>
    </div>
  </div>
</template>

<script >

// noinspection JSUnresolvedVariable
export default {
  name: 'Indicator-EC-charts',
  props:{
    'year': {},
    'showPromptMessage': {},
    'showWarningLine': {},
    collapsed:{},
  },
  //初值和变量
  data () {
    return {
      optionsYear: [],
      msg:{
        EC10cm:{
          value:'',
          upperLimit:'',
          lowerLimit:'',
        },
        EC20cm:{
          value:'',
          upperLimit:'',
          lowerLimit:'',
        },
        EC30cm:{
          value:'',
          upperLimit:'',
          lowerLimit:'',
        }
      }
    };
  },
  //方法
  methods: {
    getMsg(val){
      this.$axios.post("/indicator/ecall",val).then((res)=>{
        let eC10cm={name:"EC10cm",num:0};
        let eC20cm={name:"EC20cm",num:0};
        let eC30cm={name:"EC30cm",num:0};
        for(let i=0;i<res.data.title.length;i++){
          if(res.data.list274[i]<this.msg.EC10cm.lowerLimit){
            eC10cm.num++;
          }
          if(res.data.list10035[i]<this.msg.EC20cm.lowerLimit){
            eC20cm.num++;
          }
          if(res.data.list10036[i]<this.msg.EC30cm.lowerLimit){
            eC30cm.num++;
          }
        }
        let a=[{name:"土壤EC值",num:2}];
        a.push(eC10cm,eC20cm,eC30cm);
        this.$emit("change",a)

        let myCharts = this.$echarts.init(document.getElementById("indicator-EC-charts"));
        // noinspection JSUnresolvedVariable
        myCharts.setOption( {
          title:{
            text:this.year+'年土壤EC表'
          },
          xAxis: [{
            nameLocation:'end', //坐标轴名称显示的位置
            silent:true,  //是否静态无法交互
            type: 'category',
            data: res.data.title,
            axisLine:{
              show:true,
            },},],
          legend: {
            // formatter: function (name) {
            //   return echarts.format.truncateText(name, 40, '14px Microsoft Yahei', '…');
            // },
            itemGap:30, //图例每项的间隔
            textStyle:{
              color:"#444",
              fontSize:14,
              fontWeight: 'bold',
            },
            tooltip: {
              show: true
            },
            data: [{
              tooltip:{
                show:this.showPromptMessage,
                formatter:this.msg.EC10cm.value,
              },
              name:'10cmEC'
            }, {
              name:'20cmEC',
              tooltip:{
                show:this.showPromptMessage,
                formatter: this.msg.EC20cm.value,
              }
            }, {
              name:'30cmEC',
              tooltip: {
                show:this.showPromptMessage,
                formatter: this.msg.EC30cm.value,
              }
            }]
          },
          series: [
            {
              markLine:{
                label:{
                  show:this.showWarningLine,
                },
                data : [{
                  silent:false, //鼠标悬停事件 true没有，false有
                  lineStyle:{ //警戒线的样式 ，虚实 颜色
                    width: this.showWarningLine===true?1:0   //宽度
                  },
                  yAxis: this.msg.EC10cm.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                }]
              },
              name:'10cmEC',
              data: res.data.list274,
            },{
              markLine:{
                label:{
                  show:this.showWarningLine,
                },
                data : [{
                  silent:false, //鼠标悬停事件 true没有，false有
                  lineStyle:{ //警戒线的样式 ，虚实 颜色
                    width: this.showWarningLine===true?1:0   //宽度
                  },
                  yAxis: this.msg.EC20cm.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                }]
              },
              name:'20cmEC',
              data:res.data.list10035,
            },{
              markLine:{
                label:{
                  show:this.showWarningLine,
                },
                data : [{
                  silent:false, //鼠标悬停事件 true没有，false有
                  lineStyle:{ //警戒线的样式 ，虚实 颜色
                    width: this.showWarningLine===true?1:0   //宽度
                  },
                  yAxis: this.msg.EC30cm.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                }]
              },
              name:'30cmEC',
              data:res.data.list10036,
            }
          ]
        });
      })
    },
    changeChart(){
      this.getMsg(this.year)
    },
    handleWindowResize() {
      let myCharts = this.$echarts.init(document.getElementById('indicator-EC-charts'));
      myCharts && myCharts.resize();
    },
    changeChartSize(){
      //延迟渲染，
      setTimeout(()=>{
        this.handleWindowResize()
      },200)
    }
  },
  //在页面加载前执行
  mounted() {
    this.$axios.get('/indicators/getDetails/274').then((res)=>{
      // console.log(res)
      // noinspection JSUnresolvedVariable
      this.msg.EC10cm.value=res.data.indexDetails;
      this.msg.EC10cm.upperLimit=res.data.upperLimit;
      this.msg.EC10cm.lowerLimit=res.data.lowerLimit;
    })
    this.$axios.get('/indicators/getDetails/10035').then((res)=>{
      // console.log(res)
      // noinspection JSUnresolvedVariable
      this.msg.EC20cm.value=res.data.indexDetails;
      this.msg.EC20cm.upperLimit=res.data.upperLimit;
      this.msg.EC20cm.lowerLimit=res.data.lowerLimit;
    })
    this.$axios.get('/indicators/getDetails/10036').then((res)=>{
      // console.log(res)
      // noinspection JSUnresolvedVariable
      this.msg.EC30cm.value=res.data.indexDetails;
      this.msg.EC30cm.upperLimit=res.data.upperLimit;
      this.msg.EC30cm.lowerLimit=res.data.lowerLimit;
    })
    //初始化echarts
    let myCharts = this.$echarts.init(document.getElementById("indicator-EC-charts"));
    //延迟渲染，
    setTimeout(()=>{
      this.handleWindowResize()
    },100)
    window.addEventListener('resize',this.handleWindowResize)
    myCharts.setOption( {

      title: {
        show:false,
        text: '土壤EC表',
      },
      xAxis: [{
        name:'日期',  //坐标轴的名称
        nameLocation:'end', //坐标轴名称显示的位置
        silent:false,  //是否静态无法交互
        type: 'category',
        data: [],
        axisLine:{
          show:true,
        },
      }],
      yAxis: {
        name:'毫秒/厘米',  //坐标轴的名称
        type: 'value',
        scale:true,
        // splitNumber:20,
        start:30,
        axisLine:{
          show:true,
        },
      },
      toolbox: {
        show:true,
        feature: {
          saveAsImage: {},
        }
      },
      // dataZoom: [
      //   {
      //     height:15,
      //     type:'slider',
      //     // show: true,     //显示缩放的滑条
      //     dataBackground:{
      //       showDataShadow:'false',
      //       lineStyle:{
      //         opacity:0,
      //       },
      //       areaStyle:{
      //         opacity:0,
      //       },
      //     }
      //   },
      //   {
      //     type: 'inside', //鼠标滚轮控制缩放
      //     height:15,
      //   }
      // ],
      grid: {
        left: '27px',
        right: '50px', ///   调整大小
        bottom: '1%',
        top:'7%',
        // show:true, //  边框是否显示
        containLabel: true
      },

      legend: {
        // formatter: function (name) {
        //   return echarts.format.truncateText(name, 40, '14px Microsoft Yahei', '…');
        // },
        itemGap:30, //图例每项的间隔
        textStyle:{
          color:"#444",
          fontSize:14,
          fontWeight: 'bold',
        },
        tooltip: {
          show: true
        },
        data: [{
          tooltip:{
            show:this.showPromptMessage,
            formatter:this.msgEC,
          },
          name:'10cmEC'
        }, '20cmEC', '30cmEC']
      },
      tooltip:{
        appendToBody:'true',
        backgroundColor:'rgba(255,255,255,0.96)',   //背景的颜色
        textStyle:{
          fontWeight:'normal',
          indent:'50px',
          color:"#555",
        },
        extraCssText:'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;white-space:pre-wrap;max-width:300px;',
        show:true,
        trigger:'axis',
      },
      series: [
        {
          markLine:{
            symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
            label:{
              position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
              // formatter:'10cmEC警戒指标',
            },
            data : [{
              silent:false, //鼠标悬停事件 true没有，false有
              lineStyle:{ //警戒线的样式 ，虚实 颜色
                type:"dotted", //样式  ‘solid’和'dotted'
                // color:"#FA3934",
                width: 1   //宽度
              },
              yAxis: 3 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
            }]
          },
          name:"10cmEC",
          data: [],
          type: 'line',
          connectNulls:true,  //将断点连起来
        },
        {
          markLine:{
            symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
            label:{
              position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
              // formatter:'20cmEC警戒指标',
            },
            data : [{
              silent:false, //鼠标悬停事件 true没有，false有
              lineStyle:{ //警戒线的样式 ，虚实 颜色
                type:"dotted", //样式  ‘solid’和'dotted'
                // color:"#FA3934",
                width: 1   //宽度
              },
              yAxis: 1.6 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
            }]
          },
          name:"20cmEC",
          data: [],
          type: 'line',
          connectNulls:true,
        },
        {
          markLine:{
            symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
            label:{
              position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
              // formatter:'30cmEC警戒指标',
            },
            data : [{
              silent:false, //鼠标悬停事件 true没有，false有
              lineStyle:{ //警戒线的样式 ，虚实 颜色
                type:"dotted", //样式  ‘solid’和'dotted'
                // color:"#FA3934",
                width: 1   //宽度
              },
              yAxis: 1.1250 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
            }]
          },
          connectNulls:true,
          name:"30cmEC",
          data: [],
          type:'line',
        },
      ],

    });
  },
  watch:{
    year:'changeChart',
    showPromptMessage:'changeChart',
    showWarningLine:'changeChart',
    collapsed:"changeChartSize"
  }



}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
h1{
  display: inline-block;
  font-weight: bolder;
  font-size: 24px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
