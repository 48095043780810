<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <SideBar :collapsed="collapsed"></SideBar>
    <a-layout>
      <a-layout-content
          :style="{ margin: '16px 16px', overflow: 'initial' }">
        <div :style="{ background: '#f0f2f5', minHeight: '800px' }">
          <router-view :collapsed="collapsed"></router-view>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import Table from '../components/Table'
import SideBar from '../components/side/side-bar'
export default {
  name: "Home",
  props: {

  },
  components: {
    Table,
    SideBar
  },
  data() {
    return {
      collapsed: false
    }
  },
  methods: {}
}
</script>

<style>
#components-layout-demo-custom-trigger {
  height: 100%;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>
