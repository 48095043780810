<template>
<div>
  <div>
<!--    步骤条     -->
    <div>
      <a-steps :current="current" style="width:80%; margin-left:  auto; margin-right: auto;">
        <a-step v-for="item in steps" :key="item.title" :title="item.title" />
      </a-steps>
    </div>
<!--    标签页，随步骤条动-->
    <div style="margin-top: 20px;">
      <a-tabs :activeKey="current" :tabBarStyle="{display:'none'}">
        <a-tab-pane :key="0" :tab="0" force-render style="margin-top: 20px; padding: 20px; background-color: #fff;">
          <makePlan @getYear="getYear" style="padding-top: 40px;"/>
        </a-tab-pane>
        <a-tab-pane :key="1" :tab="1" force-render >
          <detailed-information @getName="getName"/>
        </a-tab-pane>
        <a-tab-pane :key="2" :tab="2" force-render>

        </a-tab-pane>
      </a-tabs>

    </div>
<!--    按钮    -->
    <div style="padding: 20px; background-color: #fff;">
      <a-button v-if="current>0 && current < steps.length-2" type="primary" @click="next">
        下一步
      </a-button>
      <a-button v-if="current===1" type="primary" @click="bind" >绑定</a-button>
      <a-button
          v-if="current === steps.length - 1"
          type="primary"
          @click="$message.success('已执行采集计划')"
      >
        执行采集计划
      </a-button>
      <a-button v-if="current > 0&&current<steps.length-1" style="margin-left: 8px" @click="prev">
        上一步
      </a-button>
      <a-button v-if="current===steps.length-1" style="margin-left: 8px" @click="unbind">
        解除绑定
      </a-button>
    </div>
  </div>


</div>
</template>
 
<script>
import makePlan from "@/components/collect/edit/make-plan";
import detailedInformation from "@/components/collect/Detailed-information";
export default {
  name: "collection-plan-make", 
  components: {makePlan,detailedInformation},
  data: function () {
    return {
      selectTemplate: true,
      optionsTemplate: [],
      template: '',
      data:[],
      current: 0,
      steps: [{title: '创建',}, {title: '填写',}, {title: '执行',},],
      planName:0, //采集计划模板的名字
      planYear:[], //计划采集年份
    }
  },
  methods:{
    bind(){
      console.log(this.planYear)
      this.$axios.post("/templateBasicinfo/createPlan/?name="+this.planYear[0]+"&citeName="+this.planName+"&year="+this.planYear[1]).then((val)=>{
        console.log(val)
        if(val.data.code===200){
          this.$message.success(val.data.msg);
          this.next();
        }else{
          this.$message.warning(val.data.msg);
        }
      })
    },
    unbind(){
      let c=""
      this.$axios.post("/templateBasicinfo/relievePlan/?name="+this.planYear[0]).then((val)=>{
        console.log(val)
        if(val.data.code===200){
          this.$message.success(val.data.msg);
        }
      })
      this.prev();
    },
    next() {
      this.current++;
    },
    prev() {
      this.current--;
    },
    getName(val){
      this.planName=val
    },
    getYear(val){
      this.planYear=val
      this.next()
    },
    getTemplate(val)
    {
      this.data.splice(0,this.data.length)
      this.template=val.key
      this.$axios.get("/templateDetailinfo2/findName/"+this.template).then(res=>{
        for(let i=0;i<res.data.length;i++)
        {
          this.data.push(res.data[i])
        }
      })
    }
  },
  mounted() {
    this.$axios.get("/templateBasicinfo/findAllNames").then(res=>{
      this.optionsTemplate=res.data
      this.template=res.data[0]
      console.log(this.optionsTemplate[0])
      // 获取父表与子表内容
      this.$axios.get("/templateDetailinfo2/findName/"+this.optionsTemplate[0]).then(res=>{
        for(let i=0;i<res.data.length;i++)
        {
          this.data.push(res.data[i])
        }
      })
    })
  }
}
</script>

<style scoped>

.headers{
  user-select:none;
  background-color: #FFF;
  min-height: 200px;
  box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.13) 0 0 1px 1px;
}
/* headers下的第一个header没有边框 */
.headers .header:first-child {
  border-width:0;
}

.header h1{
  display:inline-block;
  font-size:30px;
  line-height: 50px;
  /*font-weight: bold;*/
  margin-top: 4px;
}
.header h2{
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
}
.header h3{
  margin: 0;
  color: #666666;
}

.header span{

}
.headers .header {
  margin-top: 20px;
  display: inline-block;
  text-align: left;
  /*background-color: #00ff00;*/
  vertical-align: middle;
  padding-left: 20px;
  width: 25%;
  min-height: 160px;
  border-left: 1px solid #ddd;
}

.headers .set div h2{
  margin-top: 20px;
  width: 30%;
  line-height: 30px;
}
</style>