<template>
<div>
  <div class="headers">
    <div class="header" >
      <a-dropdown :trigger="['click']">
        <a @click="[e => e.preventDefault()];" style="color: black;">
          <div style="font-weight: bolder">
            <h2>历史采集计划 </h2>
            <a-icon type="caret-down" style="color:#888" />
          </div>
        </a>
        <a-menu slot="overlay" @click="getPlan"  >
          <a-menu-item
              v-for="(item,index) in historyPlan"
              :key="index">
            <span>{{item.name}}</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <h1>{{plan.name}}</h1>
      <h3>执行年份: {{ plan.year }}</h3>
    </div>
  </div>
  <div style="margin-top: 20px;">

  </div>
  <fill-plan :template-name="plan.name"></fill-plan>
</div>
</template>

<script>
import fillPlan from "@/components/collect/edit/fill-plan";
export default {
  name: "collection-plan-history",
  components:{fillPlan},
  data(){
    return{
      historyPlan:[],
      allPlan:[],
      plan:'',
    }
  },
  mounted() {
    this.$axios.post("/templateBasicinfo/getHistoryPlan/").then((val)=>{
      this.historyPlan=val.data;
      this.plan=this.historyPlan[0];
    })
  },
  methods:{
    getPlan(val){
      this.plan=this.historyPlan[val.key];
    }
  }

}
</script>

<style scoped>

.headers{
  user-select:none;
  background-color: #FFF;
  min-height: 200px;
  box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.13) 0 0 1px 1px;
}
/* headers下的第一个header没有边框 */
.headers .header:first-child {
  border-width:0;
}

.header h1{
  display:inline-block;
  font-size:30px;
  line-height: 50px;
  /*font-weight: bold;*/
  margin-top: 4px;
}
.header h2{
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
}
.header h3{
  margin: 0;
  color: #666666;
}

.header span{

}
.headers .header {
  margin-top: 20px;
  display: inline-block;
  text-align: left;
  /*background-color: #00ff00;*/
  vertical-align: middle;
  padding-left: 20px;
  width: 25%;
  min-height: 160px;
  border-left: 1px solid #ddd;
}

.headers .set div h2{
  margin-top: 20px;
  width: 30%;
  line-height: 30px;
}
</style>