import Vue from 'vue'
import VueRouter from 'vue-router' 

import Home from '@/views/Home'
import Test from '../components/Test'
import Table from "../components/Table";

import Login from '../components/login/index'
import Soil from '../components/soil/soil'
import MapHome from '../components/soil/map-home'
import MapSoil from '../components/soil/map-soil'
import MapEditor from '../components/soil/map-editor'
import MapMessage from "../components/soil/map-message"
import MapDetection from '../components/soil/map-detection'

import Grow from '../components/grow/grow'
import ShowSoilWater from '../components/grow/show-soil-water'
import ShowNutritionGrowth from '../components/grow/show-nutrition-growth'
import ShowProductQuality from "../components/grow/show-product-quality"
import ShowTemperatureHumidity from '../components/grow/show-temperature-humidity'
import ShowTemperatureHumidity1 from '../components/grow/show-temperature-humidity1'

import Message from '../components/message/message'
import ManagerIndicator from '../components/message/manager-indicator'
import managerIndicator1 from '../components/message/manager-indicator1'
import ManagerCollect from '../components/message/manager-collect'
import ManagerOrgan from '../components/message/manager-organ'

import Devices from '../components/devices/devices'
import DeviceRunningStatus from "../components/devices/device-running-status"
import DeviceMessage from '../components/devices/device-message'
import singular from "../components/charts/singular";
import multiple from "../components/charts/multiple";

import Setting from '../components/setting/setting'
import ManagerUser from '../components/setting/manager-user'
import ManagerRole from '../components/setting/manager-role'
import ManagerPermission from '../components/setting/manager-permission'
import plan_make from '../components/collect/collection-plan-make'
import collect from '../components/collect/collect'
import show from '../components/collect/collect-show'
import plan_track from '../components/collect/collection-plan-track'
import plan_history from '../components/collect/collection-plan-history'
import Plot from "../components/plot/plot"
import PlotUpload from '../components/plot/upload'
import PlotTask from '../components/plot/task'
import IndicatorCharts from '../components/charts/Indicator-sprout-charts'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        
        children: [
            {
                name: 'collect',
                path: '/collect',
                component: collect,
                redirect: '/collect/collect-show',
                children: [
                    {
                        name: 'show',
                        path: 'collect-show',
                        component: show,
                    },
                    {
                        name: 'plan_make',
                        path: '/collect/collection-data-get',
                        component: plan_make,
                    },
                    {
                        name: 'plan_track',
                        path: 'collection-plan-track',
                        component: plan_track
                    },
                    {
                        name: 'plan_history',
                        path: 'collection-plan-history',
                        component: plan_history,
                    },
                ]
            },
            {
                path: 'test',
                name: 'Test',
                component: Test
            },
            {
                // path: '/message/message-askldjalk'
                path: '/soil',
                name: 'soil',
                component: Soil,
                children: [
                    {
                        path: 'map-home',
                        name: 'map-home',
                        component: MapHome
                    },
                    {
                        path: 'map-message',
                        name: 'map-message',
                        component: MapMessage
                    },
                    {
                        path: 'map-editor',
                        name: 'map-editor',
                        component: MapEditor
                    },
                    {
                        path: 'map-soil',
                        name: 'map-soil',
                        component: MapSoil
                    }, {
                        path: 'map-detection',
                        name: 'map-detection',
                        component: MapDetection,
                        children: [
                            {
                                path: 'charts',
                                name: 'IndicatorCharts',
                                component: IndicatorCharts
                            },
                        ]
                    }
                ]
            },
            {
                path: '/devices',
                name: 'devices',
                component: Devices,
                children: [
                    {
                        path: 'device-running-status',
                        name: 'device-running-status',
                        component: DeviceRunningStatus
                    }, {
                        path: 'device-message',
                        name: 'device-message',
                        component: DeviceMessage
                    },
                ]
            },
            {
                path: '/message',
                name: 'message',
                component: Message,
                children: [
                    {
                        path: 'manager-indicator',
                        name: 'manager-indicator',
                        component: ManagerIndicator
                    },
                    {
                        path: 'manager-collect',
                        name: 'manager-collect',
                        component: ManagerCollect
                    },
                    {
                        path: 'manager-organ',
                        name: 'manager-organ',
                        component: ManagerOrgan
                    }
                ]
            },
            {
                path: 'grow',
                name: 'grow',
                component: Grow,
                children: [
                    {
                        path: 'show-soil-water',
                        name: 'show-soil-water',
                        component: ShowSoilWater
                    },
                    {
                        path: 'show-nutrition-growth',
                        name: 'show-nutrition-growth',
                        component: ShowNutritionGrowth
                    },
                    {
                        path: 'show-product-quality',
                        name: 'show-product-quality',
                        component: ShowProductQuality,
                        redirect: '/grow/show-product-quality/singular',
                        children: [
                            {
                                path: 'singular',
                                name: 'singular',
                                component: singular,
                            },
                            {
                                path: 'multiple',
                                name: 'multiple',
                                component: multiple,
                            }


                        ]
                    },
                    {
                        path: 'show-temperature-humidity',
                        name: 'show-temperature-humidity',
                        component: ShowTemperatureHumidity
                    },
                    {
                        path: 'show-temperature-humidity1',
                        name: 'show-temperature-humidity1',
                        component: ShowTemperatureHumidity1
                    },
                    {
                        name: 'manager_indicator',
                        path: '/collect/collection-data-get1',
                        component: managerIndicator1,//最新添加的页面
                    },
                ],
            },
            {
                path: 'setting',
                name: 'setting',
                component: Setting,
                children: [
                    {
                        path: 'manager-user',
                        name: 'manager-user',
                        component: ManagerUser
                    },
                    {
                        path: 'manager-role',
                        name: 'manager-role',
                        component: ManagerRole
                    },
                    {
                        path: 'manager-permission',
                        name: 'manager-permission',
                        component: ManagerPermission
                    }
                ]
            },{
                path:"three",
                name:"three",
                component:()=>import  ("../components/three/three"),
                children:[
                    {
                        path:"three-show",
                        name:"three-show",
                        component:()=>import ("../components/three/three-show"),
                    }
                ]

            },{
                path: 'plot',
                name:'plot',
                component:Plot,
                children: [
                    {
                        path: 'upload',
                        name: 'upload',
                        component: PlotUpload
                    },{
                        path:'task',
                        name:'task',
                        component: PlotTask
                    },{
                        path:'process',
                        name:'process',
                        component: () => import("../components/plot/process")
                    },{
                        path:'upload3d',
                        name:'upload3d',
                        component:()=>import('../components/plot/upload3d')
                    },{
                        path:'test',
                        name:'test',
                        component:()=>import("../components/plot/test")
                    }
                    
                ]
            },{
                path:"pool",
                name:"pool",
                component:()=>import("../components/pool/pool"),
                children:[
                    {
                        path:'model-pool',
                        name:"model-pool",
                        component:()=>import("../components/pool/model-pool")
                    },
                    {
                        path:"model-desc",
                        name:"model-desc",
                        component:()=>import("../components/pool/model-desc")
                    },
                    {
                        path:"model-info",
                        name:"model-info",
                        component:()=>import("../components/pool/model-info")
                    }
                ]
            }
        ]
    },
    {
        path: '/ER',
        component: () => import("../components/charts/Indicator-leaf-charts")
    }
]

const router = new VueRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    routes
})

export default router
