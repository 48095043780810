<template>
  <a-form-model ref="ruleForm" :model="form" :rules="rules"  :label-col="labelCol" :wrapper-col="wrapperCol">
    <a-form-model-item label="地块名" ref="name" prop="name">
      <a-input v-model="form.name"
        @blur="
          () => {
            $refs.name.onFieldBlur();
          }
        "
      />
    </a-form-model-item>
    <a-form-model-item label="地块归属" prop="affiliation">
      <a-select v-model="form.affiliation" placeholder="选择归属方">
        <a-select-option  v-for="affiliation in allAffiliations"  :value="affiliation">
          {{affiliation}}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="建立日期" required prop="date">
      <a-date-picker
          v-model="form.date"
          show-time
          type="date"
          placeholder="选择日期"
          style="width: 100%;"
      />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
export default {
  name: 'Form',
  props:{
    getList: Function
  },
  data() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
      allAffiliations: ['无'],
      form: {
        id: '',
        name: '',
        affiliation: undefined,
        amount: 0,                    //默认值
        date: undefined,
      },
      rules:{
        name: [
          { required: true, message: '请输入正确格式的地块名', trigger: 'blur' },
        ],
        affiliation: [
          { required: true, message: '请选择一个地块归属', trigger: 'change' }
        ],
        date: [
          { required: true, message: '请选择一个建立日期', trigger: 'change' }
        ]
      }
    };
  },
  created() {
    this.getAllAffiliations()
  },
  methods: {
    onSubmit() {
      let _this = this

      //输入检查  本来想把检查和后面的提交写到同一级，然后中间用return跳出函数运行，结果这里的return貌似和C不一样，不能借助跳出，那只能调整层级关系了
      _this.$refs.ruleForm.validate(valid => {
        if (valid) {

          let plot = _this.form;
          _this.$axios.request({
            method: "POST",
            url: '/cropsSumMap/addplot',
            data: plot
          }).then(res => {
            console.log("地块信息传输完成!")
            console.log(res)
            _this.getList()       //这个是外面传的一个参数，自身是函数，应该是在调用组件时绑定的，我看绑定为了ShowData
                                  //其实我在那个调用这个的位置最后也有一个调用showData，感觉功能可能差不多，可能是怕这里忘写吧

            _this.form = {         //回归最初状态
              id: '',
              name: '',
              affiliation: undefined,
              amount: 0,
              date: undefined,
            }
          });
        } else {
          alert('错误的输入');      //但是这还不够，其实提交失败就不应该关闭窗口了...
        }
      });
    },
    showUpdate(e){            //修改时将获取到的数据加入表中作初始化内容操作
      let _this = this
      console.log("showUpdate")
      this.$nextTick( () => {
        _this.form = e
      })
    },
    onUpdate(e){        //将修改提交到后台的操作
      let _this = this
      let plot = _this.form

      console.log("在这停顿!!!")
      console.log(plot)
      _this.$axios.request({
        method: "POST",
        url: '/cropsSumMap/updateplot',        //待修改
        data: plot           //要传的数据
      }).then(res => {
        console.log("地块信息修改完成!")
        _this.getList()
      })
    },

    //获取所有归属方
    getAllAffiliations(){
      let _this = this
      _this.$axios.get('/organization/getAllOrgan') .then(res => {
        console.log(res)
        _this.allAffiliations = res.data
        // _this.plotYears = res.data.data[0]        //用第一个年份做初始化-因为让plotYears处于undefined状态很麻烦
      })
    },
  },

};
</script>
