<template>
  <div style="margin:20px 0;margin-top: 50px">


    <a-form :form="form" :model="formState" name="validate_other" v-bind="formItemLayout" @submit="handleSubmit">
      <a-form-item name="name" label="任务名称" has-feedback>
        <a-input v-decorator="[
          'taskName',
          {
            rules: [
              {
                required: true,
                message: '请输入任务名称！',
              },
            ],
          },
        ]" placeholder="任务名称"></a-input>
      </a-form-item>

      <a-form-item name="type" label="任务类型" has-feedback>
        <a-select v-decorator="[
          'type',
          {
            rules: [
              { required: true, message: '请输入任务类型！' },
            ],
          },
        ]" ref="select" style="width: 100%">
          <a-select-option value="fenge">分割任务</a-select-option>
          <a-select-option value="shibie">图像识别</a-select-option>
          <a-select-option value="ScheduledTasks">定时任务</a-select-option>
        </a-select>
      </a-form-item>


      <a-form-item name="aiModelName" label="AI模型" has-feedback>
        <a-select v-decorator="[
          'aiModelName',
          {
            rules: [
              { required: true, message: '请选择AI模型' },
            ],
          },
        ]" ref="select" style="width: 100%">
          <a-select-option value="" select="select">不绑定</a-select-option>
          <a-select-option v-for="(item, index) in aiModels" :key="index" :value="item.modelName">
            {{ item.modelName }}
          </a-select-option>

        </a-select>
      </a-form-item>



      <a-form-item name="modelName" label="地块地图" has-feedback>
        <!-- <a-select 
        v-decorator="[
          'map',
          {
            rules: [
              { required: true, message: '请选择地块地图！' },
            ],
          },
        ]"
        ref="select" style="width: 100%">
          <a-select-option value="" select="select">不绑定</a-select-option>
          <a-select-option v-for="(item, index) in maps" :key="index" :value="item.id">
            {{ item.id }} : {{ item.mapType }}
          </a-select-option>

        </a-select> -->

        <a-tree-select 
        v-decorator="[
          'map',
          {
            rules: [
              { required: true, message: '请选择地块地图！' },
            ],
          },
        ]"
        v-model="value" show-search style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择地图" allow-clear
          tree-default-expand-all>

          <a-tree-select-node v-for="(item,index) in maps " :key="item.id" :selectable="false" :value="item.name" :title="item.name">


            <a-tree-select-node v-for="(innerItem,index) in item.innerData" :key="innerItem.id" :value="innerItem.showDataId" :title="innerItem.cropsRotationPlotName+'——————————'+innerItem.formatTime">
            </a-tree-select-node>

          </a-tree-select-node>


        </a-tree-select>

      </a-form-item>


      <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
        <a-button type="primary" html-type="submit">提交</a-button>
      </a-form-item>
    </a-form>



  </div>
</template>
<script>
import axios from "axios";
import { message } from 'ant-design-vue';


export default ({
  components: {

  },
  data() {
    return {
      treeExpandedKeys: [],
      value: undefined,
      maps: [],
      options: [],

      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 14,
        },
      },
      aiModels: [],
      time: null,
      formState: {
        'modelName': "",
        'aiModelName': "",
      },
      time: null,

    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'validate_other' });
  },
  mounted() {

    //动态获取AI模型名称
    axios.get("http://43.143.170.49:8466/api/aimodel/list").then(res => {
      console.log(res);
      this.aiModels = res.data.data;
    })
    //动态获取地图信息
    axios.get("http://43.143.170.49:8468/cropsSumMap/twoLevelMap").then(res => {
      this.maps = res.data.data;
      console.log(res)
    })
  },


  methods: {

    handleSubmit(e) {//表单提交
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values is ", values);
          let modelId = "";
          for (let i = 0; i < this.aiModels.length; i++) {//获取模型id,比较模型名称获取模型id
            if (this.aiModels[i].modelName == values.aiModelName) {
              modelId = this.aiModels[i].id;
            }
          }
          console.log("modelId is", modelId)

          const formData = new FormData();
          formData.append("taskName", values.taskName)
          formData.append("model", modelId)
          formData.append("map", values.map)
          axios.post("http://43.143.170.49:8468/map/createTask", formData).then(res => {
            if (res.data.code == 120) {
              message.success("添加任务成功((￣︶￣*))", 10)
            } else {
              message.error("添加任务失败＞︿＜：" + res.data.msg);
            }
          }).catch(err => {
            console.log(err);
          })
          console.log('验证成功Success:', values);
        }
      });
    },


    normFile(e) {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },

    onChange: (value, dateString) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
    },
    onOk: value => {
      console.log('onOk: ', value);
    },
    onRangeChange: (value, dateString) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
    },
    onRangeOk: value => {
      console.log('onOk: ', value);
    },



  }
});
</script>
