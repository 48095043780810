<template>
    <div id="pie2"  style="box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px; margin: auto" :style="{width: '100%',height: '360px',}">
        weight
    </div>
</template>

<script>
    export default {
        name: "quantity-weight",
        props:{//接受传入的参数
            'year': {},
            'showPromptMessage': {},
            'showWarningLine': {},
        },
        data () {
            return {
                optionsYear: [],
                a1:'',a2:'',a3:'',
            };
        },
        methods:{
            getMsg(val){
                let _this = this
                this.$axios.post('/indicator/pie2', val).then(function (response) {
                    // console.log(response.data[0])
                    let myChart = _this.$echarts.init(document.getElementById('pie2'))
                    myChart.setOption({
                        title: {
                            text: _this.year+'年结薯重量饼状图',
                            // subtext: 'Fake Data',
                            left: 'center'
                        },
                        //控制x轴y轴位置
                        grid: {
                            left: '1%',
                            right: '15%', ///   调整大小
                            bottom: '1%',
                            top:'59%',
                            // show:true, //  边框是否显示
                            containLabel: true
                        },
                        legend: {
                            top:40,
                            textStyle:{
                                color:"#444",
                                fontSize:14,
                                fontWeight: 'bold',
                            },
                            data:[
                                {name:'2两以下重量（g） : '+response.data[0],
                                    tooltip:
                                        {
                                            formatter:_this.a1,
                                            show:_this.showPromptMessage,
                                            position:'top',
                                        }
                                },{name:'2-3两重量（g）: '+response.data[1],
                                    tooltip:
                                        {
                                            formatter:_this.a2,
                                            show:_this.showPromptMessage,
                                            position:'top',
                                        }
                                },{name:'3两以上重量（g）: '+response.data[2],
                                    tooltip:
                                        {
                                            formatter:_this.a3,
                                            show:_this.showPromptMessage,
                                            position:'top',
                                        }
                            }
                                ]
                            // orient: 'vertical',
                            // left: 'left'
                        },
                        tooltip:{
                            appendToBody:'true',
                            backgroundColor:'rgba(255,255,255,0.96)',   //背景的颜色
                            textStyle:{
                                fontWeight:'normal',
                                indent:'50px',
                                color:"#555",
                            },
                            extraCssText:'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;white-space:pre-wrap;max-width:300px;',
                            show:true,
                            trigger:'axis',
                        },
                        series: [
                            {
                                name: '结薯分布',
                                type: 'pie',
                                radius: '50%',
                                data: [
                                    {value: response.data[0], name: '2两以下重量（g） : '+response.data[0]},
                                    {value: response.data[1], name: '2-3两重量（g）: '+response.data[1]},
                                    {value: response.data[2], name: '3两以上重量（g）: '+response.data[2]},
                                    // {value: 484, name: 'Union Ads'},
                                    // {value: 300, name: 'Video Ads'}
                                ],
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    })
                })

            },
            changeChart(){
                this.getMsg(this.year)
            }
        },
        beforeMount() {
            this.getMsg(new Date().getFullYear())
            this.$axios.get("/indicators/getDetails/336",).then((rest)=>{
                this.a1=rest.data.indexDetails
            })
            this.$axios.get("/indicators/getDetails/337",).then((rest)=>{
                this.a2=rest.data.indexDetails
            })
            this.$axios.get("/indicators/getDetails/338",).then((rest)=>{
                this.a3=rest.data.indexDetails
            })
        },
        watch:{
            year:'changeChart'
        },
    }
</script>

<style scoped>

</style>