import Vue from 'vue'
import router from './router'
import store from './store'
import axios from 'axios'
import App from './App';

//import message from 'element-ui';
import ElementUI from 'element-ui' //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'//element-ui的css
Vue.use(ElementUI) //使用elementUI



import VueResource from 'vue-resource';
Vue.use(VueResource)

import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd)

import './axios'
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.prototype.$axios = axios


new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App),
  data:function(){
    return{
        backgroundSelectid:-1,
        backgroundSelect: '1',   // 定义的变量
        backgroundId:-1,
        backgroundTaskId:-1,
        backgroundTaskResName:[
          "自定义地块一","自定义地块二","自定义地块三","自定义地块四"
      ],
        backgroundTaskRes:[ 
                            "180638",
                            "207371",
                            "227689",
                            "300876",
                            "330825"
                          ],
        backgroundTaskAllRes:[
          [
              

              340,
              399,
              339,
              402,
              442,
              459,
              492,
              497,
              502,
              520,
              // 562,
              // 603,
          ],
          [
              670,
              705,
              715,
              752,
              785,
              799,
              819,
              829,
              902,
              960,
              // 980,
              // 1003,
          ],
          [
              340,
              320,
              334,
              335,
              390,
              445,
              469,
              470,
              474,
              589,
              // 592,
              // 630
           ],
           [
            220,
            230,
            234,
            306,
            370,
            415,
            469,
            471,
            474,
            590,
            // 592,
            // 640
          ]
        ],
        backgroundTaskAllRes1:[
          [
              

              350,
              369,
              379,
              412,
              422,
              439,
              472,
              487,
              512,
              540,
              // 572,
              // 683,
          ],
          [
              670,
              705,
              715,
              752,
              785,
              799,
              819,
              829,
              902,
              960,
              // 980,
              // 1003,
          ],
          [
              240,
              310,
              326,
              315,
              360,
              415,
              459,
              470,
              494,
              539,
              // 582,
              // 690
           ],
           [
            190,
            210,
            274,
            316,
            360,
            415,
            489,
            491,
            494,
            600,
            // 692,
            // 740
          ]
        ],
        backgroundTaskResTime:[
          "2023-05-18",
          "2023-05-22",
          "2023-06-02",
          // "2023-06-05",
          "2023-06-13",
          "2023-06-25",
          "2023-07-03",
          // "2023-07-10",
          "2023-07-24",
          "2023-08-01",
          "2023-08-07",
          "2023-08-14"
        ],
        backgroundTaskResTime1:[
          "2022-05-18",
          "2022-05-22",
          // "2022-06-02",
          "2022-06-05",
          "2022-06-13",
          "2022-06-25",
          "2022-07-03",
          // "2022-07-10",
          "2022-07-24",
          "2022-08-01",
          "2022-08-07",
          "2022-08-14"
        ],
        backgroundTaskLen:-1,
        backgroundJSON:0

    }
  },
}).$mount('#app')

