var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"white","height":"100vh","padding":"20px","padding-top":"50px"}},[_c('div',[_c('a-form',_vm._b({attrs:{"name":"validate_other","form":_vm.form},on:{"finishFailed":_vm.onFinishFailed,"submit":_vm.handleSubmit}},'a-form',_vm.formItemLayout,false),[_c('a-form-item',{attrs:{"label":"模型名称","has-feedback":"","accept":".ply"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'name',
                    { rules: [{ required: true, message: '请填写模型名称' }] },
                ]),expression:"[\n                    'name',\n                    { rules: [{ required: true, message: '请填写模型名称' }] },\n                ]"}],attrs:{"placeholder":"模型名称"}})],1),_c('a-form-model-item',{attrs:{"label":"创建时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'formatTime',
                    { rules: [{ required: true, message: '创建时间' }] },
                ]),expression:"[\n                    'formatTime',\n                    { rules: [{ required: true, message: '创建时间' }] },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"show-time":"","type":"date","placeholder":"选择日期"}})],1),_c('a-form-item',{attrs:{"label":"所属地图"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'map',
                    { rules: [{ required: true, message: '请选择所属地图' }] },
                ]),expression:"[\n                    'map',\n                    { rules: [{ required: true, message: '请选择所属地图' }] },\n                ]"}],attrs:{"placeholder":"所属地图"}},_vm._l((_vm.plotData),function(item,index){return _c('a-select-option',{attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.affiliation)+" ："+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"name":"location","label":"地区","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'location',
                    { rules: [{ required: true, message: '请选择地区' }] },
                ]),expression:"[\n                    'location',\n                    { rules: [{ required: true, message: '请选择地区' }] },\n                ]"}],attrs:{"placeholder":"雄安新区"}})],1),_c('a-form-item',{attrs:{"name":"plyfile","label":"上传点云文件(.ply)"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'plyfile',
                    { rules: [{ required: true, message: '请上传点云文件ply' }] },
                ]),expression:"[\n                    'plyfile',\n                    { rules: [{ required: true, message: '请上传点云文件ply' }] },\n                ]"}],attrs:{"accept":".ply","action":"","list-type":"text"}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 点击上传 ")],1)],1)],1),_c('a-form-item',{attrs:{"name":"jsonfile","label":"上传标注文件(.json)"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'jsonfile',
                    { rules: [{ required: true, message: '请上传标注文件json' }] },
                ]),expression:"[\n                    'jsonfile',\n                    { rules: [{ required: true, message: '请上传标注文件json' }] },\n                ]"}],attrs:{"accept":".json","name":"jsonfile","action":"","list-type":"text"}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 点击上传 ")],1)],1)],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 6 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("提交")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }