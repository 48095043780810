<template>
    <div id="count2" style="margin:auto;width: 100%;height: 300px">
        count2
    </div>
</template>

<script>


    export default {
        name: "quantity-count2",
        props:{//接受传入的参数
            'year': {},
        },
        data () {
            return {
                optionsYear: [],
            };
        },
        methods:{
            getMsg(){
                let _this = this
                this.$axios.post('/indicator/getBar2').then(function (response){
                    console.log(response.data)
                    let myChart = _this.$echarts.init(document.getElementById('count2'))
                    myChart.setOption({
                        title:{
                            text:'不同年份对比'
                        },
                        xAxis: {
                            type: 'category',
                            data: ['2两以下', '2-3两', '3两以上', '单株大薯块', '单株中薯块', '单株小薯块', '单株特小薯块','结薯平均']
                        },
                        yAxis: {
                            type: 'value'
                        },
                        tooltip: {},
                        legend: {
                            data:['2021年','2020年']
                        },
                        series: [
                            {
                                name:'2021年',
                                data: response.data.first,
                                type: 'bar',
                                // showBackground: true,
                                backgroundStyle: {
                                    color: 'rgba(180, 180, 180, 0.2)'
                                },
                                emphasis: {
                                    focus: 'self'
                                },
                            },
                            {
                                name:'2020年',
                                data: response.data.second,
                                type: 'bar',
                                // showBackground: true,
                                backgroundStyle: {
                                    color: 'rgba(150, 150, 150, 0.2)'
                                },
                                emphasis: {
                                    focus: 'self'
                                },
                            }
                        ]
                    })
                })


            },
            changeChart(){
                this.getMsg()
            }
        },
        beforeMount() {
            this.getMsg()
        },
        watch:{
            year:'changeChart'
        },
    }
</script>

<style scoped>

</style>