<template>
    <div id="components-form-demo-advanced-search">
        <a-card>
            <a-form layout="inline">
                <a-form-model-item>

                    <a-form-item style="margin-left: 20px" label="组织方名称">
                        <a-input v-model="selectName"
                                 placeholder="请输入指标名称"/>
                    </a-form-item>

                </a-form-model-item>
                <a-form-item>
                    <a-button type="primary" html-type="submit" @click="selectData">
                        搜索
                    </a-button>

                </a-form-item>
                <a-form-item>
                    <a-button type="primary"  html-type="submit" @click="showAdd">
                        新增
                    </a-button>
                </a-form-item>
            </a-form>
            <a-modal v-model="add" title="新增" @ok="onAdd">
                <Edit ref="addEdit" :get-list="showData"></Edit>
            </a-modal>
        </a-card>

        <a-config-provider :locale="locale">
            <a-table
                    :dataSource="dataSource"
                    :columns="columns"
                    @change="changePage"
                    :pagination="pagination"
            >
                <p slot="expandedRowRender" slot-scope="record" style="margin: 0;white-space: pre-wrap;">
                    {{record.details}}
                </p>

                <template slot="operation" slot-scope="text, record">
                <span style="float:left" >
                    <a-button  type="link" style="margin-left:-15px" @click="showUpdate(record)">
                        <a-icon type="edit" />
                    </a-button>
                    <a-button type="link" style="margin-left:-15px" @click="onDelete(record)">
                        <a-icon type="delete" />
                    </a-button>
                </span>
                </template>
            </a-table>
        </a-config-provider>

        <a-modal v-model="update" title="修改" @ok="onUpdate">
            <!--          添加一个ref 组件-->
            <Edit ref="updateEdit" :get-list="showData"></Edit>
        </a-modal>
    </div>
</template>

<script>
    import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import Edit from "./edit/Edit-organ";
    export default {
        name: "manager-organ",
        components: {Edit},
        data() {
            return {
                locale: zhCN,
                selectName: '',
                count: 2,
                add: false,
                update: false,
                pagination: {
                    size: "default",
                    position: "bottom",
                    pageSize: 10,
                    current: 1,
                    total: 6,
                    showSizeChanger: false,
                    showQuickJumper: true,
                    // pageSizeOptions: ['2','10']
                },
                dataSource: null,
                columns: [
                    {
                        title: '序号',
                        dataIndex: 'id',
                        // scopedSlots: {customRender: 'name'},
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                        // scopedSlots: {customRender: 'name'},
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        // width: '10%',
                        scopedSlots: {customRender: 'operation'},
                        // align: 'center'
                    },
                ],
            }
        },
        created() {
            this.showData()
        },
        methods: {
            showData() {
                let _this = this
                console.log('Ok')
                let current = _this.pagination.current
                console.log(current)
                _this.$axios.get('/organization/getListPage',{
                    params: {
                        pageNum: current
                    }
                }).then(res => {
                    console.log(res)
                    _this.dataSource = res.data.data.data.records
                    _this.pagination.total = res.data.data.total
                    // _this.pagination = res.data.pagination
                })

            },
            changePage(page, filters, sorter){
                let _this = this
                _this.pagination.current = page.current
                let current =  _this.pagination.current
                console.log(current)
                _this.showData()
            },
            //查询
            selectData() {
                let _this = this
                console.log('select')
                console.log(_this.selectName)
                _this.$axios.get('/organization/selectByName', {
                    params: {
                        name: _this.selectName
                    }
                }).then(res => {
                    console.log("1")
                    console.log(res)
                    _this.dataSource = res.data.data
                    _this.pagination.total = res.data.data.length
                    console.log("select end")
                })

            },
            // 新增
            showAdd() {
                let _this = this
                _this.add = true;
            },
            onAdd(e) {
                let _this = this
                _this.add = false;

                _this.$refs.addEdit.onSubmit()
                _this.showData()
            },
            // 修改
            showUpdate(e) {
                let _this = this
                _this.update = true
                console.log("showUpdate")
                console.log(e)
                this.$nextTick( () => {
                    _this.$refs.updateEdit.showUpdateDate(e)
                })
            },
            onUpdate(e) {
                console.log(e)
                console.log('manager-->Update')

                let _this = this
                _this.update = false

                _this.$nextTick( () => {
                    _this.$refs.updateEdit.onUpdate()
                })


            },
            // 删除
            onDelete(e) {
                let _this = this
                let _id = e.id
                console.log(e.id)
                console.log('OK');
                _this.$confirm({
                    title: '确认删除',
                    content: h => <div style="color:red;">您确定删除采集数据吗？</div>,
                onOk() {
                    if(e.isUsed != 0) {
                        alert("使用中！不可删除！");
                    }else{
                        console.log('OK');
                        _this.$axios.get('/organization/delete', {
                            params: {
                                id: _id
                            }
                        }).then(res => {
                            //
                            console.log('deleteOK');
                            // _this.pagination.current = 1;
                            let current = _this.pagination.current
                            console.log(current)
                            _this.showData()
                            console.log("已删除")
                        })
                    }

                },
                onCancel()
                {
                    console.log('Cancel');
                }
            })
            }
        }
    }
</script>

<style scoped>
    /deep/ .ant-table-tbody > tr > td {
        background: #fff;
        padding: 5px 15px !important;
        background: #fff;
    }
</style>
