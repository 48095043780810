<template>
    <el-form style="width: 80%" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

        <el-form-item label="指标名称" prop="indicatorName">
            <!--readOnly：只读，不可修改(或者disable)-->
            <el-input v-model="ruleForm.indicatorName"  readOnly></el-input>
        </el-form-item>

        <el-form-item label="播种期" prop="boZhong">
            <el-input v-model="ruleForm.boZhong" class="input1" ></el-input> 次/周 
            已采集<el-input v-model="ruleForm.boZhong1" class="input1" ></el-input> 次
        </el-form-item>

        <el-form-item label="发芽期" prop="faYa">
            <el-input v-model="ruleForm.faYa" class="input1"></el-input> 次/周 
            <!-- 已采集<el-input v-model="ruleForm.faYa1" class="input1"></el-input> 次 -->
        </el-form-item>
        <el-form-item label="幼苗期" prop="youMiao">
            <el-input v-model="ruleForm.youMiao" class="input1"></el-input> 次/周 
            <!-- 已采集<el-input v-model="ruleForm.youMiao1" class="input1"></el-input> 次 -->
        </el-form-item>
        <el-form-item label="发棵期" prop="faKe">
            <el-input v-model="ruleForm.faKe" class="input1"></el-input> 次/周 
            <!-- 已采集<el-input v-model="ruleForm.faKe1" class="input1"></el-input> 次 -->
        </el-form-item>
        <el-form-item label="结薯期" prop="jieShu">
            <el-input v-model="ruleForm.jieShu" class="input1"></el-input> 次/周 
            <!-- 已采集<el-input v-model="ruleForm.jieShu1" class="input1"></el-input> 次 -->
        </el-form-item>
        <el-form-item label="成熟期" prop="chengShu">
            <el-input v-model="ruleForm.chengShu" class="input1"></el-input> 次/周 
            <!-- 已采集<el-input v-model="ruleForm.chengShu1" class="input1"></el-input> 次 -->
        </el-form-item>

        <!--        <el-form-item>-->
        <!--            <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>-->
        <!--        </el-form-item>-->

    </el-form>
</template>

<script>
    export default {
        name: "Edit3",
        data() {
            return {
                ruleForm: {
                    indicatorName: '',
                    boZhong: '',boZhong1: '',
                    faYa: '',faYa1:'',
                    youMiao: '',youMiao1:'',
                    faKe: '',faKe1 :'',
                    jieShu: '',jieShu1: '',
                    chengShu: '',chengShu1: ''
                },
                rules: {
                    collector: [
                        {required: true, message: '采集方不能为空', trigger: 'blur'}
                    ],
                }
            }
        },
        methods:{
            showEditDate(row){
                console.log(row.boZhong+"   "+row.boZhong1)
            }
        }
    }
</script>

<style scoped>

    .input1{
        width :22%
    }

</style>