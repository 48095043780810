<template>
  <div id="components-form-demo-advanced-search">
    <a-card>
      <a-form layout="inline">
        <a-form-item label="name">
          <a-input placeholder="input placeholder"/>
        </a-form-item>
        <a-form-item label="age">
          <a-input placeholder="input placeholder"/>
        </a-form-item>
        <a-form-item label="address">
          <a-input placeholder="input placeholder"/>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit">
            搜索
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="primary"  html-type="submit" @click="showAdd">
            新增
          </a-button>
        </a-form-item>
      </a-form>
      <a-modal v-model="add" title="Add" @ok="onAdd">
        <Edit ref="tableEdit" :get-list="showData"></Edit>
      </a-modal>
    </a-card>

    <a-table bordered :data-source="dataSource" :columns="columns" @change="changePage" :pagination="pagination" size="middle">
      <template slot="operation" slot-scope="text, record">
        <a href="javascript:;">
          <a-button type="link" style="margin: 0 10px;" @click="showUpdate">修改</a-button>
        </a>
        <a href="javascript:;">
          <a-button type="link" @click="onDelete">删除</a-button>
        </a>
      </template>
    </a-table>
    <a-modal v-model="update" title="Update" @ok="onUpdate">
      <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </a-modal>
  </div>
</template>

<script>
import Edit from "./edits/Edit";
export default {
  name: "Table",
  components: {Edit},
  data() {
    return {
      count: 2,
      add: false,
      update: false,
      pagination: {
        size: "default",
        position: "bottom",
        pageSize: 2,
        current: 1,
        total: 6,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['2', '5', '10', '20', '30']
      },
      dataSource: null,
      columns: [
        {
          title: 'name',
          dataIndex: 'name',
          // width: '30%'
          // scopedSlots: {customRender: 'name'},
        },
        {
          title: 'age',
          dataIndex: 'age',
          align: 'left'
        },
        {
          title: 'address',
          dataIndex: 'address',
          align: 'right'
        },
        {
          title: 'operation',
          dataIndex: 'operation',
          width: '16%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        },
      ],
    }
  },
  created() {
    this.showData()
  },
  methods: {
    showData() {
      let _this = this
      console.log('Ok')
      _this.$axios.get('http://localhost:8080/static/datatable.json').then(res => {
        console.log(res)
        _this.dataSource = res.data.data
        // _this.pagination = res.data.pagination
      })
    },
    changePage(page, filters, sorter){
      let _this = this
      _this.pagination.current = page.current
    },
    // 新增
    showAdd() {
      let _this = this
      _this.add = true;
    },
    onAdd(e) {
      let _this = this
      _this.add = false;

      _this.$refs.tableEdit.onSubmit()
      _this.showData()
    },
    // 修改
    showUpdate() {
      let _this = this
      _this.update = true
    },
    onUpdate(e) {
      console.log(e)
      console.log('Update')

      let _this = this
      _this.update = false

      _this.$axios.get('http://localhost:8080/static/datatable.json').then(res => {
        console.log(res)
      })
    },
    // 删除
    onDelete() {
      let _this = this
      _this.$confirm({
        title: '确认删除',
        content: h => <div style="color:red;">您确定删除采集数据吗？</div>,
        onOk() {
          console.log('OK');
          _this.$axios.get('http://localhost:8080/static/datatable.json').then(res => {
            console.log("已删除")
          })
        },
        onCancel()
      {
          console.log('Cancel');
        }
      })
    }
  }
}
</script>

<style scoped>
/deep/ .ant-table-tbody > tr > td {
  padding: 5px 15px !important;
}

</style>
