<template>
  <div>
    <div class="headers">
      <div class="header" >
        <a-dropdown :trigger="['click']">
          <a @click="[e => e.preventDefault()];" style="color: black;">
            <div style="font-weight: bolder">
              <h2>采集计划队列</h2>
              <a-icon type="caret-down" style="color:#888" />
            </div>
          </a>
          <a-menu slot="overlay" @click="getPlan"  >
            <a-menu-item
                v-for="(item,index) in allPlan"
                :key="index">
              <span>{{item.name}}</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <h1>{{plans.name}}</h1>
        <h3>执行年份: {{ plans.year }}</h3>
      </div>
      <div class="header">
        <h2>执行中的采集计划</h2>
        <h1 style="font-size: 30px; display: block; font-weight: bold;">{{ actioningPlan }}</h1>
      </div>
      <div class="header">
        <h2>采集阶段</h2>
        <h1 style="font-size: 30px; display: block; font-weight: bold;">{{ nowPhase }}</h1>
        <h3>开始时间：{{ startTime }}</h3>
        <h3>结束时间：{{ endTime }}</h3>
      </div>
      <div class="header">
        <h2>当前时间</h2>
        <h1 style="font-size: 20px; display: block; font-weight: bold;">{{new Date()}}</h1>
      </div>
    </div>

    <div style="margin-top: 20px;">
      <fillPlan :template-name="plans.name" :year="new Date().getFullYear()"></fillPlan>
    </div>

  </div>
</template>

<script>
import fillPlan from "@/components/collect/edit/fill-plan";
export default {
  components:{fillPlan,},
  name: "collection-plan-track",
  data(){
    return{
      actioningPlan:'123456',
      templatePlan:'',
      nowPhase:'',
      startTime:'',
      endTime:'',
      allPlan:[],
      plan:'',
      plans:{
        year:0,
        name:'',
      },
    }
  },
  methods:{
    getPlan(val){
      console.log("++++++")
      console.log(val)
      this.plans=this.allPlan[val.key];
    },
  },
  mounted() {
    this.$axios.post("/templateBasicinfo/actioningPlan/").then((val)=>{
        this.actioningPlan=val.data.name;
        this.templatePlan=val.data.situation;
        this.plan=val.data.name;
        this.plans=val.data;
    })
    this.$axios.post("/collectionPhase/getNowPhase/").then((val)=>{
        this.nowPhase=val.data.name;
        this.startTime=val.data.startTime.replace('/','年').replace('/','月')+"日";
        this.endTime=val.data.endTime.replace('/','年').replace('/','月')+"日";
    })
    // this.$axios.post("/templateBasicinfo/getWillPlan/").then((val)=>{//需要进行替换的接口
    //   console.log(val.data)
    //   this.allPlan=val.data;
    // })
    this.$axios.get("/templateBasicinfo/findAll").then(res=>{//目标接口
                // this.tableData=res.data;
                console.log(this.tableData)
                this.allPlan=res.data;
            })
  }
}
</script>

<style scoped>

.headers{
  user-select:none;
  background-color: #FFF;
  min-height: 200px;
  box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.13) 0 0 1px 1px;
}
/* headers下的第一个header没有边框 */
.headers .header:first-child {
  border-width:0;
}

.header h1{
  display:inline-block;
  font-size:50px;
  line-height: 50px;
  /*font-weight: bold;*/
  margin-top: 4px;
}
.header h2{
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
}
.header h3{
  margin: 0;
  color: #666666;
}

.header span{

}
.headers .header {
  margin-top: 20px;
  display: inline-block;
  text-align: left;
  /*background-color: #00ff00;*/
  vertical-align: middle;
  padding-left: 20px;
  width: 25%;
  min-height: 160px;
  border-left: 1px solid #ddd;
}

.headers .set div h2{
  margin-top: 20px;
  width: 30%;
  line-height: 30px;
}
</style>