<template>
    <div id="components-form-demo-advanced-search" >
        <a-card>
            <a-form layout="inline">
                <a-form-model-item label="指标分组" >
                    <a-select
                            default-value=""
                            style="width: 200px"
                            id="group"
                            @change="groupHandleChange">
                        <a-select-option value="全部分组" >
                            全部分组
                        </a-select-option>
                        <a-select-option
                                v-for="group in this.allGroup" :key="group">
                            {{group}}
                        </a-select-option>
                    </a-select>

                    <a-form-item style="margin-left: 20px" label="指标名称">
                        <a-input v-model="indictorsName"
                                 placeholder="请输入指标名称"/>
                    </a-form-item>

                </a-form-model-item>
                <a-form-item>
                    <a-button type="primary" html-type="submit" @click="selectData">
                        搜索
                    </a-button>

                </a-form-item>
                <a-form-item>
                    <a-button type="primary"  html-type="submit" @click="showAdd">
                        新增
                    </a-button>
                </a-form-item>
            </a-form>
            <a-modal v-model="add" title="新增" @ok="onAdd">
                <Edit ref="addEdit" :get-list="showData"></Edit>
            </a-modal>
        </a-card>

        <!--    每次只遍历第一条        ?????????????????????-->
      <a-config-provider :locale="locale">
        <a-table
            :dataSource="dataSource"
            :columns="columns"
            @change="changePage"
            :pagination="pagination"


        >
            <template
                    slot="expandedRowRender"
                    style="margin: 0;white-space: pre-wrap;"
                    slot-scope="text, record"
            >

                <ol >
                    <li v-for="d in details[record]">
                        {{d}}
                    </li>
                </ol>

            </template>

          <template slot="operation" slot-scope="text, record">
                <span style="float:left" >
                    <a-button  type="link" style="margin-left:-15px" @click="showUpdate(record)">
                        <a-icon type="edit" />
                    </a-button>
                    <a-button type="link" style="margin-left:-15px" @click="onDelete(record)">
                        <a-icon type="delete" />
                    </a-button>
                </span>
          </template>
        </a-table>
      </a-config-provider>

     <a-modal v-model="update" title="修改" @ok="onUpdate">
<!--          添加一个ref 组件-->
          <Edit ref="updateEdit" :get-list="showData"></Edit>
      </a-modal>
    </div>
</template>

<script>
    import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import Edit from "./edit/Edit";
    export default {
        name: "manager-indicator",
        components: {Edit},
        data() {
            return {
                locale: zhCN,
                indictorsName: '',
                indictorsGroup: '',
                allGroup: [],
                details:[],
                count: 2,
                add: false,
                update: false,
                pagination: {
                    size: "default",
                    position: "bottom",
                    pageSize: 10,
                    current: 1,
                    total: 6,
                    showSizeChanger: false,
                    showQuickJumper: true,
                    // pageSizeOptions: ['2','10']
                },
                dataSource: [],
                columns: [
                    {
                        title: '序号',
                        dataIndex: 'id',
                        width: '6%'
                        // scopedSlots: {customRender: 'name'},
                    },

                    {
                        title: '指标分组',
                        dataIndex: 'indicatorGroup',
                        width: '16%'
                        // scopedSlots: {customRender: 'name'},
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                        width: '13%'
                        // scopedSlots: {customRender: 'name'},
                    },
                    {
                        title: '单位',
                        dataIndex: 'unit',
                        // align: 'left'
                    },
                    {
                        title: '采集方',
                        dataIndex: 'gatherer',
                        width: '14%'
                        // align: 'left'
                    },

                    // {
                    //     title: '说明',
                    //     dataIndex: 'indexDetails',
                    //     // width: '16%',
                    //     scopedSlots: {customRender: 'indexDetails'},
                    //     // align: 'left'
                    // },
                    // {
                    //     title: '预计采集次数',
                    //     dataIndex: 'numOfCollections',
                    //     // width: '6%'
                    //     // align: 'left'
                    // },
                    {
                        title: '警戒上限',
                        dataIndex: 'upperLimit',
                        // align: 'left'
                    },
                    {
                        title: '警戒下限',
                        dataIndex: 'lowerLimit',
                        // align: 'left'
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        // width: '10%',
                        scopedSlots: {customRender: 'operation'},
                        // align: 'center'
                    },
                ],
            }
        },
        created() {
            this.showData()
            this.getAllgroup()
        },
        methods: {
            getAllgroup() {
                console.log("getAllgroup")
                let _this = this
                _this.$axios.get('/IndicatorsGroups/getAllGroup') .then(res => {
                    console.log(res)
                    _this.allGroup = res.data
                })

            },
            groupHandleChange(value) {
                console.log(`selected ${value}`);
                let _this = this
                _this.indictorsGroup = value
                _this.selectData()


            },
            showData() {
                let _this = this
                console.log('Ok')
                let current = _this.pagination.current
                console.log(current)
                _this.$axios.get('/indicators/getListPage',{
                    params: {
                        pageNum: current
                    }
                }).then(res => {
                    console.log(res)
                    let _date = res.data.data.data;
                    console.log(_date.length)
                    _this.dataSource = [];
                    for(let i = 0; i < _date.length; ++i) {
                        _this.dataSource.push(_date[i].record)
                        _this.details.push(_date[i].details);
                    }
                    console.log(_this.dataSource);
                    // _this.dataSource = res.data.data.data
                    _this.pagination.total = res.data.data.total
                    console.log(_this.pagination.total)
                    // _this.pagination = res.data.pagination
                })

            },
            changePage(page, filters, sorter){
                let _this = this
                _this.pagination.current = page.current
                let current =  _this.pagination.current
                console.log(current)
                _this.showData()
            },
            //查询
            selectData() {
                let _this = this
                console.log('select')
                console.log(_this.indictorsName)
                console.log(_this.indictorsGroup)
                let indictorname = this.indictorsName
                let indictorsGroup = this.indictorsGroup
                if(indictorsGroup === "全部分组") {
                    indictorsGroup = ''
                }
                if(typeof indictorname != 'undefined'
                    && typeof indictorsGroup != 'undefined'
                    && !(indictorsGroup === '')) {

                    _this.$axios.get('/indicators/selectByName', {
                        params: {
                            name: indictorname,
                            group: indictorsGroup
                        }
                    }).then(res => {
                        console.log("1")
                        console.log(res)
                        _this.dataSource = res.data.data.data
                        _this.pagination.total = res.data.data.total
                        console.log("select end")
                    })
                }else {
                    _this.$axios.get('/indicators/selectByName', {
                        params: {
                            name: indictorname,
                            group: indictorsGroup
                        }
                    }).then(res => {
                        console.log(res.data.data.data)
                        if (indictorname==='' && indictorsGroup===''){
                            
                            _this.dataSource = [];
                            let _date = res.data.data.data;
                            console.log("indictorname==='' && indictorsGroup===''"+_date)
                            for(let i = 0; i < _date.length; ++i) {
                                _this.dataSource.push(_date[i].record)
                                _this.details.push(_date[i].details);
                            }
                        }else {
                            console.log("else ---")
                            _this.dataSource = res.data.data.data
                        }

                        _this.pagination.total = res.data.data.total
                        console.log("select end")
                    })

                }


            },
            // 新增
            showAdd() {
                let _this = this
                _this.add = true;
            },
            onAdd(e) {
                let _this = this
                _this.add = false;

                _this.$refs.addEdit.onSubmit()
                _this.showData()
            },
            // 修改
            showUpdate(e) {
                let _this = this
                _this.update = true
                console.log("showUpdate")
                console.log(e)
                this.$nextTick( () => {
                    _this.$refs.updateEdit.showUpdateDate(e)
                })
            },
            onUpdate(e) {
                console.log(e)
                console.log('manager-->Update')

                let _this = this
                _this.update = false

                _this.$nextTick( () => {
                    _this.$refs.updateEdit.onUpdate()
                })


            },
            // 删除
            onDelete(e) {
                let _this = this
                let _id = e.id
                console.log(e.id)
                console.log('OK');
                _this.$confirm({
                    title: '确认删除',
                    content: h => <div style="color:red;">您确定删除采集数据吗？</div>,
                onOk() {
                    if(e.isUsed != 0) {
                        alert("使用中！不可删除！");
                    }else{
                        console.log('OK');
                        _this.$axios.get('/indicators/delete', {
                            params: {
                                id: _id
                            }
                        }).then(res => {
                            //
                            console.log('deleteOK');
                            // _this.pagination.current = 1;
                            let current = _this.pagination.current
                            console.log(current)
                            _this.showData()
                            console.log("已删除")
                        })
                    }
                },
                onCancel()
                {
                    console.log('Cancel');
                }
            })
            }
        }
    }
</script>

<style scoped>
    .ant-table-tbody > tr > td {
        background: #fff;
        padding: 5px 15px !important;
        background: #fff;
    }

</style>
