<template>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="名称">
            <a-input v-model="form.name"/>
        </a-form-model-item>

        <a-form-model-item label="说明">
            <!--      <a-input type="textarea" />-->
            <a-textarea
                    v-model="form.details"
                    placeholder="Basic usage" :rows="5"/>
        </a-form-model-item>

    </a-form-model>
</template>

<script>
    export default {
        name: "Edit-organ",
        props:{
            getList: Function
        },
        data() {
            return {
                allGroup: [],
                allGatherer: [],
                value: ['china'],
                labelCol: { span: 6 },
                wrapperCol: { span: 15 },
                form: {
                    name: '',
                    details: undefined,
                    id: ''

                },
            };
        },
        created() {

        },

        watch: {
            value(val) {
                console.log(`selected:`, val);
            },
        },
        methods: {
            showUpdateDate(e) {
                console.log("showUpdateDate")
                console.log(e)
                console.log(this)
                let _this = this

                console.log("e.name:"+e.name)

                this.$nextTick( () => {
                    console.log(_this.form)
                    _this.form = e
                })

            },
            handleChange(value) {
                console.log(`selected ${value}`);
                // let _this = this
                // _this.indictorsGroup = value
            },

            onUpdate() {
                console.log("edit-->onUpdate")
                let _this = this
                let organization = _this.form;
                console.log(organization);
                _this.$axios.request({
                    method: 'POST',
                    url: '/organization/update',
                    data: organization
                }).then(res => {
                    console.log("res")
                    console.log(res)
                    _this.getList()
                })


                // _this.$axios.post('/indicators/update',
                //         {indicator}, //对象
                //         {params:{
                //           //属性
                //           }})
            },

            onAdd() {
                let _this = this
                let organization = _this.form;
                console.log(organization);
                _this.$axios.request({
                    method: 'POST',
                    url: '/organization/add',
                    data: organization
                }).then(res => {
                    console.log("res")
                    console.log(res)
                    _this.getList()
                })
            },

            onSubmit() {
                let _this = this
                console.log('submit!', this.form);
                _this.onAdd()

            },
        }
    };
</script>

<style scoped>

</style>
