<template>
  <div id="width" style="margin: 0 auto">
    <!-- 头部 -->
    <div class="headers">
      <div class="header">
        <div style="font-weight: bolder">
          <h2>气象站个数</h2>
        </div>
        <h1>{{ airnum }}</h1>
        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;个</h2>
      </div>

      <div class="header">
        <div style="font-weight: bolder">
          <h2>土壤仪个数</h2>
        </div>
        <h1>{{ soilnum }}</h1>
        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;个</h2>
      </div>
    </div>

    <a-tabs default-active-key="1">
      <a-tab-pane
        key="1"
        tab="气象站"
        style="background-color: #fff"
        forceRender
      >
        <div>
          <a-card>
            <a-form layout="inline">
              <a-form-item label="设备名称">
                <a-input
                  placeholder="输入设备名称"
                  v-model="searchFrom.name"
                  v-on:blur="showData"
                />
              </a-form-item>
              <a-form-item label="设备提供方">
                <a-input
                  placeholder="输入设备提供方"
                  v-model="searchFrom.provider"
                  v-on:blur="showData"
                />
              </a-form-item>
              <a-form-item label="设备使用方">
                <a-input
                  placeholder="输入设备使用方"
                  v-model="searchFrom.user"
                  v-on:blur="showData"
                />
              </a-form-item>
              <a-form-item>
                <a-button type="primary" html-type="submit" @click="showData">
                  搜索
                </a-button>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" @click="addDialogVisible = true"
                  >新增</a-button
                >
              </a-form-item>
            </a-form>
          </a-card>

          <!--展示数据的表格，使用了Antd-Data Display-Table-嵌套子表格组件-->
          <a-table
            :columns="columns"
            :data-source="dataSource"
            class="components-table-demo-nested"
            @change="changePage"
            :pagination="pagination"
          >
            <p slot="operation" slot-scope="text, record">
              <a-button type="link" @click="showAddAirDeviceDialog(record)"
                ><a-icon type="plus"
              /></a-button>
              <a-button type="link" @click="showmodDialog(record)"
                ><a-icon type="edit"
              /></a-button>
              <a-button type="link" @click="remove(record.id)"
                ><a-icon type="delete"
              /></a-button>
            </p>

            <a-table
              slot="expandedRowRender"
              slot-scope="record"
              :columns="innerColumns"
              :data-source="record.details"
              :pagination="false"
            >
              <p slot="operation" slot-scope="text, record">
                <a-button type="link" @click="showModDetailsDialog(record)"
                  ><a-icon type="edit"
                /></a-button>
                <a-button type="link" @click="removeDe(record.id)"
                  ><a-icon type="delete"
                /></a-button>
              </p>
            </a-table>
          </a-table>
        </div>
      </a-tab-pane>

      <a-tab-pane
        key="2"
        tab="土壤仪"
        style="background-color: #fff"
        forceRender
      >
        <div>
          <a-card>
            <a-form layout="inline">
              <a-form-item label="设备名称">
                <a-input
                  placeholder="输入设备名称"
                  v-model="searchFrom1.name"
                  v-on:blur="showData"
                />
              </a-form-item>
              <a-form-item label="设备提供方">
                <a-input
                  placeholder="输入设备提供方"
                  v-model="searchFrom1.provider"
                  v-on:blur="showData"
                />
              </a-form-item>
              <a-form-item label="设备使用方">
                <a-input
                  placeholder="输入设备使用方"
                  v-model="searchFrom1.user"
                  v-on:blur="showData"
                />
              </a-form-item>
              <a-form-item>
                <a-button type="primary" html-type="submit" @click="showData">
                  搜索
                </a-button>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" @click="addSoilDialogVisible = true"
                  >新增</a-button
                >
              </a-form-item>
            </a-form>
          </a-card>
          <!--展示数据的表格，使用了Antd-Data Display-Table-嵌套子表格组件-->
          <a-table
            :columns="columns1"
            :data-source="dataSource1"
            class="components-table-demo-nested"
            @change="changePage1"
            :pagination="pagination1"
          >
            <p slot="operation" slot-scope="text, record">
              <a-button type="link" @click="showAddSoilDeviceDialog(record)"
                ><a-icon type="plus"
              /></a-button>
              <a-button type="link" @click="showmodSoilDialog(record)"
                ><a-icon type="edit"
              /></a-button>
              <a-button type="link" @click="removeSoil(record.id)"
                ><a-icon type="delete"
              /></a-button>
            </p>

            <a-table
              slot="expandedRowRender"
              slot-scope="record"
              :columns="innerColumns1"
              :data-source="record.details"
              :pagination="false"
            >
              <p slot="operation" slot-scope="text, record">
                <a-button type="link" @click="showsoilEtails(record)"
                  ><a-icon type="edit"
                /></a-button>
                <a-button type="link" @click="removesoilEtails(record.id)"
                  ><a-icon type="delete"
                /></a-button>
              </p>
            </a-table>
          </a-table>
        </div>
      </a-tab-pane>
    </a-tabs>

    <!-- 添加对话框 -->
    <el-dialog
      title="添加气象站设备"
      :visible.sync="addDialogVisible"
      width="30%"
    >
      <!-- 内容主体区域 -->
      <el-form label-width="110px" :model="addForm" ref="addFormRef">
        <!-- <el-form-item label="设备ID：" prop="deviceID">
          <el-input v-model="addForm.equipmentId"></el-input>
        </el-form-item> -->
        <el-form-item label="设备名称：" prop="devicename">
          <el-input v-model="addForm.equipmentName"></el-input>
        </el-form-item>
        <el-form-item label="设备提供方：" prop="deviceprovider">
          <el-input v-model="addForm.equipmentProvider"></el-input>
        </el-form-item>
        <el-form-item label="设备使用方：" prop="deviceuser">
          <el-input v-model="addForm.equipmentUser"></el-input>
        </el-form-item>
        <el-form-item label="设备入库时间：" prop="date">
          <el-date-picker
            v-model="addForm.equipmentPutInStorage"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDevice">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加土壤仪对话框 -->
    <el-dialog
      title="添加土壤仪设备"
      :visible.sync="addSoilDialogVisible"
      width="30%"
    >
      <!-- 内容主体区域 -->
      <el-form label-width="110px" :model="addSoilForm" ref="addFormRef">
        <!-- <el-form-item label="设备ID：" prop="deviceID">
          <el-input v-model="addSoilForm.equipmentId"></el-input>
        </el-form-item> -->
        <el-form-item label="设备名称：" prop="devicename">
          <el-input v-model="addSoilForm.equipmentName"></el-input>
        </el-form-item>
        <el-form-item label="设备提供方：" prop="deviceprovider">
          <el-input v-model="addSoilForm.equipmentProvider"></el-input>
        </el-form-item>
        <el-form-item label="设备使用方：" prop="deviceuser">
          <el-input v-model="addSoilForm.equipmentUser"></el-input>
        </el-form-item>
        <el-form-item label="设备入库时间：" prop="date">
          <el-date-picker
            v-model="addSoilForm.equipmentPutInStorage"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addSoilDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSoilDevice()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑对话框 -->
    <el-dialog
      title="修改气象站设备信息"
      :visible.sync="modDialogVisible"
      width="30%"
    >
      <!-- 内容主体区域 -->
      <el-form label-width="110px" :model="updateForm" ref="modFormRef">
        <!-- <el-form-item label="设备ID：" prop="deviceid">
          <el-input
            v-model="updateForm.equipmentId"
            :disabled="true"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="设备名称：" prop="deviceprovider">
          <el-input v-model="updateForm.equipmentName"></el-input>
        </el-form-item>
        <el-form-item label="设备提供方：" prop="deviceuser">
          <el-input v-model="updateForm.equipmentProvider"></el-input>
        </el-form-item>
        <el-form-item label="设备使用方：" prop="deviceuser">
          <el-input v-model="updateForm.equipmentUser"></el-input>
        </el-form-item>
        <el-form-item label="设备入库时间：" prop="date">
          <el-date-picker
            v-model="updateForm.equipmentPutInStorage"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="modDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateEquipment">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑土壤仪对话框 -->
    <el-dialog
      title="修改土壤仪设备信息"
      :visible.sync="modSoilDialogVisible"
      width="30%"
    >
      <!-- 内容主体区域 -->
      <el-form label-width="110px" :model="updateForm1" ref="modFormRef">
        <!-- <el-form-item label="设备ID：" prop="deviceid">
          <el-input
            v-model="updateForm1.equipmentId"
            :disabled="true"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="设备名称：" prop="deviceprovider">
          <el-input v-model="updateForm1.equipmentName"></el-input>
        </el-form-item>
        <el-form-item label="设备提供方：" prop="deviceuser">
          <el-input v-model="updateForm1.equipmentProvider"></el-input>
        </el-form-item>
        <el-form-item label="设备使用方：" prop="deviceuser">
          <el-input v-model="updateForm1.equipmentUser"></el-input>
        </el-form-item>
        <el-form-item label="设备入库时间：" prop="date">
          <el-date-picker
            v-model="updateForm1.equipmentPutInStorage"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="modSoilDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateInstrument">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 气象站详情添加对话框 -->
    <el-dialog
      title="添加气象站详情"
      :visible.sync="addDetailsDialogVisible"
      width="30%"
    >
      <!-- 内容主体区域 -->
      <el-form label-width="110px" :model="addetails" ref="addFormRef">
        <!-- <el-form-item label="年份：" prop="devicename">
          <el-date-picker
            type="year"
            v-model="addetails.usageDetailsYears"
            placeholder="选择年"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item label="使用开始时间：" prop="deviceprovider">
          <el-date-picker
            v-model="addetails.usageDetailsBeginTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="使用结束时间：" prop="deviceuser">
          <el-date-picker
            v-model="addetails.usageDetailsEndTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="故障次数：" prop="date">
          <el-input v-model="addetails.usageDetailsBreakdown"></el-input>
        </el-form-item>
        <el-form-item label="所属地块：" prop="date">
          <el-select v-model="addetails.usageDetailsPlotId" placeholder="地块">
            <el-option
              v-for="item in cropsSumMapList"
              :key="item.id"
              :label="item.cropsRotationPlotName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="所属地块：" prop="date">
          <el-input v-model="addetails.usageDetailsPlot"></el-input>
        </el-form-item> -->
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDetailsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addAirDevice()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 土壤仪详情添加对话框 -->
    <el-dialog
      title="添加土壤仪详情"
      :visible.sync="addSoilDetailsDialogVisible"
      width="30%"
    >
      <!-- 内容主体区域 -->
      <el-form label-width="110px" :model="addsoiletails" ref="addFormRef">
        <el-form-item label="使用开始时间：" prop="deviceprovider">
          <el-date-picker
            v-model="addsoiletails.usageDetailsBeginTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="使用结束时间：" prop="deviceuser">
          <el-date-picker
            v-model="addsoiletails.usageDetailsEndTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="故障次数：" prop="date">
          <el-input v-model="addsoiletails.usageDetailsBreakdown"></el-input>
        </el-form-item>
        <el-form-item label="所属地块：" prop="date">
          <el-select
            v-model="addsoiletails.usageDetailsPlotId"
            placeholder="地块"
          >
            <el-option
              v-for="item in cropsSumMapList"
              :key="item.id"
              :label="item.cropsRotationPlotName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addSoilDetailsDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="addSoilDetailedDevice()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 详情修改对话框 -->
    <el-dialog
      title="修改气象站详情"
      :visible.sync="modDetailsDialogVisible"
      width="30%"
    >
      <!-- 内容主体区域 -->
      <el-form label-width="110px" :model="updateEtails" ref="addFormRef">
        <!-- <el-form-item label="年份：" prop="devicename">
          <el-date-picker
            type="year"
            v-model="updateEtails.usageDetailsYears"
            placeholder="选择年"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item label="使用开始时间：" prop="deviceprovider">
          <el-date-picker
            v-model="updateEtails.usageDetailsBeginTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="使用结束时间" prop="deviceuser">
          <el-date-picker
            v-model="updateEtails.usageDetailsEndTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="故障次数：" prop="date">
          <el-input v-model="updateEtails.usageDetailsBreakdown"></el-input>
        </el-form-item>
        <el-form-item label="所属地块：" prop="date">
          <el-select
            v-model="updateEtails.usageDetailsPlotId"
            placeholder="地块"
          >
            <el-option
              v-for="item in cropsSumMapList"
              :key="item.id"
              :label="item.cropsRotationPlotName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="modDetailsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateModDetails(updateEtails)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 土壤仪详情修改对话框 -->
    <el-dialog
      title="修改土壤仪详情"
      :visible.sync="modSoilDetailsDialogVisible"
      width="30%"
    >
      <!-- 内容主体区域 -->
      <el-form label-width="110px" :model="updatesoilEtails" ref="addFormRef">
        <el-form-item label="使用开始时间：" prop="deviceprovider">
          <el-date-picker
            v-model="updatesoilEtails.usageDetailsBeginTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="使用结束时间" prop="deviceuser">
          <el-date-picker
            v-model="updatesoilEtails.usageDetailsEndTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="故障次数：" prop="date">
          <el-input v-model="updatesoilEtails.usageDetailsBreakdown"></el-input>
        </el-form-item>
        <el-form-item label="所属地块：" prop="date">
          <el-select
            v-model="updatesoilEtails.usageDetailsPlotId"
            placeholder="地块"
          >
            <el-option
              v-for="item in cropsSumMapList"
              :key="item.id"
              :label="item.cropsRotationPlotName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="modSoilDetailsDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="updateModDetails(updatesoilEtails)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Editplot from "../edits/Editplot";
import Editmap from "../edits/Editmap";

const rowSelection = {
  //行选择？3个方法
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};

export default {
  //这个到底相当于什么，一个对于上面整体<template>的Vue实例吗？ 怎么感觉它什么都能放
  name: "map-message", //
  components: { Editplot, Editmap }, //这个寓意什么?
  data() {
    return {
      //添加设备的表单数据
      addForm: {
        // equipmentId: ",
        equipmentName: "",
        equipmentProvider: "",
        equipmentUser: "",
        equipmentPutInStorage: "",
        equipmentType: 0,
      },
      updateForm: {
        equipmentId: "",
        equipmentName: "",
        equipmentProvider: "",
        equipmentUser: "",
        equipmentPutInStorage: "",
        equipmentType: 0,
      },
      updateForm1: {
        equipmentId: "",
        equipmentName: "",
        equipmentProvider: "",
        equipmentUser: "",
        equipmentPutInStorage: "",
        equipmentType: 1,
      },
      addSoilForm: {
        //equipmentId: "",
        equipmentName: "",
        equipmentProvider: "",
        equipmentUser: "",
        equipmentPutInStorage: "",
        equipmentType: 1,
      },
      //搜索
      searchFrom: {
        name: "",
        provider: "",
        user: "",
      },
      searchFrom1: {
        name: "",
        provider: "",
        user: "",
      },
      addetails: {
        usageDetailsYears: "",
        usageDetailsBeginTime: "",
        usageDetailsEndTime: "",
        usageDetailsBreakdown: "",
        usageDetailsPlotId: "",
      },
      updateEtails: {
        usageDetailsYears: "",
        usageDetailsBeginTime: "",
        usageDetailsEndTime: "",
        usageDetailsBreakdown: "",
        usageDetailsPlotId: "",
      },
      addsoiletails: {
        usageDetailsYears: "",
        usageDetailsBeginTime: "",
        usageDetailsEndTime: "",
        usageDetailsBreakdown: "",
        usageDetailsPlotId: "",
      },
      updatesoilEtails: {
        usageDetailsYears: "",
        usageDetailsBeginTime: "",
        usageDetailsEndTime: "",
        usageDetailsBreakdown: "",
        usageDetailsPlotId: "",
      },
      year: "",
      airnum: "",
      soilnum: "",
      addDialogVisible: false, //增加对话框
      modDialogVisible: false, //修改对话框
      addSoilDialogVisible: false, //增加土壤对话框
      modSoilDialogVisible: false, //修改土壤对话框
      modDetailsDialogVisible: false, //修改气象站详情
      modSoilDetailsDialogVisible: false,
      addDetailsDialogVisible: false, //气象站详情添加
      addSoilDetailsDialogVisible: false, //土壤仪详情添加
      cropsSumMapList: [],
      //data()中写的是一个数据模板，是前后端交互承接数据用的
      count: 2, //条目数量
      addplot: false, //控制新增地块页面显示
      addmap: false, //控制新增地图页面显示
      update: false,
      rowSelection,
      expandedRowKeys: [],
      pagination: {
        //对Navigation-Pagination分页组件传参用
        size: "default", //？
        position: "bottom", //页码显示所在位置   --- 我要用这些参数控制前端显示
        pageSize: 10, //每页条目数量      --- 原来如此，这里不只用于承接前端要显示的数据
        current: 1, //当前页码         --- 还要对前端一些需要参数的组件提供参数
        total: 3, //总计条目数，应该是根据获取数据来变化的，它变了，分页分了多少页才有根据
        showSizeChanger: false, //每页条目数量可选择下拉框
        showQuickJumper: true, //快速访问框
        // pageSizeOptions: ['2', '5', '10', '20', '30']    //下拉框可选量
      },
      pagination1: {
        //对Navigation-Pagination分页组件传参用
        size: "default", //？
        position: "bottom", //页码显示所在位置   --- 我要用这些参数控制前端显示
        pageSize: 10, //每页条目数量      --- 原来如此，这里不只用于承接前端要显示的数据
        current: 1, //当前页码         --- 还要对前端一些需要参数的组件提供参数
        total: 3, //总计条目数，应该是根据获取数据来变化的，它变了，分页分了多少页才有根据
        showSizeChanger: false, //每页条目数量可选择下拉框
        showQuickJumper: true, //快速访问框
        // pageSizeOptions: ['2', '5', '10', '20', '30']    //下拉框可选量
      },
      dataSource: null, //数据源对象，后台传给前台的JSON被get后的response的data部分
      dataSource1: null,
      loading: false,
      iconLoading: false,

      rotation_plot_id: 12, //用于子表找到其父表信息
      rotation_plot_name: "ztf",

      // data,
      columns: [
        //title是我要显示的名字,dataIndex是后台使用时指明属性含义,key必不可少，与dataIndex保持一致就好
        // {
        //   title: "设备ID",
        //   dataIndex: "id",
        //   key: "id",
        // },
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          align: "center",

          width: 100,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "设备名称",
          dataIndex: "name",
          key: "name",
          // align:'center',
          // width: '30%'
        },
        {
          title: "设备提供方",
          dataIndex: "provide",
          key: "provide",
          // align: 'center'
        },
        {
          title: "设备使用方",
          dataIndex: "use",
          key: "use",
        },
        {
          title: "设备入库时间",
          dataIndex: "endTime",
          key: "endTime",
          // align: 'center',
        },
        {
          title: "操作",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      columns1: [
        //title是我要显示的名字,dataIndex是后台使用时指明属性含义,key必不可少，与dataIndex保持一致就好
        // {
        //   title: "设备ID",
        //   dataIndex: "id",
        //   key: "id",
        // },
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          align: "center",

          width: 100,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "设备名称",
          dataIndex: "name",
          key: "name",
          // align:'center',
          // width: '30%'
        },
        {
          title: "设备提供方",
          dataIndex: "provide",
          key: "provide",
          // align: 'center'
        },
        {
          title: "设备使用方",
          dataIndex: "use",
          key: "use",
        },
        {
          title: "设备入库时间",
          dataIndex: "endTime",
          key: "endTime",
          // align: 'center',
        },
        {
          title: "操作",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],

      innerColumns: [
        { title: "年份", dataIndex: "year", key: "year" },
        {
          title: "使用开始时间",
          dataIndex: "startTime",
          key: "startTime",
        },
        { title: "使用结束时间", dataIndex: "endTime", key: "endTime" },
        { title: "故障次数", dataIndex: "breakdown", key: "breakdown" },
        { title: "所属地块", dataIndex: "plot", key: "plot" },

        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      innerColumns1: [
        { title: "年份", dataIndex: "year", key: "year" },
        {
          title: "使用开始时间",
          dataIndex: "startTime",
          key: "startTime",
        },
        { title: "使用结束时间", dataIndex: "endTime", key: "endTime" },
        { title: "故障次数", dataIndex: "breakdown", key: "breakdown" },
        { title: "所属地块", dataIndex: "plot", key: "plot" },

        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
    };
  },
  created() {
    this.showData();
    this.getPlotList();
  },

  methods: {
    //展示页面数据
    showData() {
      let _this = this;
      _this.dataSource = [];
      _this.pagination.total = 0;
      _this.dataSource1 = [];
      _this.pagination1.total = 0;
      _this.$axios
        .get("/equipment/listEquipment", {
          params: {
            name: _this.searchFrom.name,
            provider: _this.searchFrom.provider,
            user: _this.searchFrom.user,
            page: _this.pagination.current - 1,
            offset: _this.pagination.pageSize,
            tag: 0,
          },
        })
        .then((res) => {
          let result = res.data.listEquipment.list;
          for (let i = 0; i < result.length; i++) {
            result[i].endTime = this.timeFormart(result[i].endTime);

            for (let j = 0; j < result[i].details.length; j++) {
              result[i].details[j].startTime = this.timeFormart(
                result[i].details[j].startTime
              );
              result[i].details[j].endTime = this.timeFormart(
                result[i].details[j].endTime
              );
            }
          }
          _this.airnum = res.data.listEquipment.count;

          _this.dataSource = result;
          _this.pagination.total = res.data.listEquipment.count;
        });
      _this.$axios
        .get("/equipment/listEquipment", {
          params: {
            name: _this.searchFrom1.name,
            provider: _this.searchFrom1.provider,
            user: _this.searchFrom1.user,
            page: _this.pagination1.current - 1,
            offset: _this.pagination1.pageSize,
            tag: 1,
          },
        })
        .then((res) => {
          let result = res.data.listEquipment.list;
          for (let i = 0; i < result.length; i++) {
            result[i].endTime = this.timeFormart(result[i].endTime);
            for (let j = 0; j < result[i].details.length; j++) {
              result[i].details[j].startTime = this.timeFormart(
                result[i].details[j].startTime
              );
              result[i].details[j].endTime = this.timeFormart(
                result[i].details[j].endTime
              );
            }
          }
          _this.soilnum = res.data.listEquipment.count;
          _this.dataSource1 = res.data.listEquipment.list;
          _this.pagination1.total = res.data.listEquipment.count;
        });
    },

    //使用右下角点击切换当前页
    changePage(page, filters, sorter) {
      let _this = this;
      _this.pagination.current = page.current; //我理解应该是这个分页组件封装了这种行为，当我点击其他也，就会返回这样一个page对象
      let current = _this.pagination.current;
      console.log(current);
      _this.$axios
        .get("/equipment/listEquipment", {
          params: {
            page: _this.pagination.current - 1,
            offset: _this.pagination.pageSize,
            tag: 0,
          },
        })
        .then((res) => {
          _this.dataSource = res.data.listEquipment.list;
          _this.pagination.total = res.data.listEquipment.count;
        });
    },
    changePage1(page, filters, sorter) {
      let _this = this;
      _this.pagination1.current = page.current; //我理解应该是这个分页组件封装了这种行为，当我点击其他也，就会返回这样一个page对象
      let current = _this.pagination1.current;
      console.log(current);
      _this.$axios
        .get("/equipment/listEquipment", {
          params: {
            page: _this.pagination1.current - 1,
            offset: _this.pagination1.pageSize,
            tag: 1,
          },
        })
        .then((res) => {
          _this.dataSource1 = res.data.listEquipment.list;
          _this.pagination1.total = res.data.listEquipment.count;
        });
    },

    //添加设备
    addDevice() {
      this.$axios.post("/equipment/addEquipment", this.addForm).then((res) => {
        this.addDialogVisible = false;
        this.showData();
      });
    },
    //删除气象站设备信息
    async remove(id) {
      const res = await this.$alert(
        "此操作将永久删除该设备, 是否继续?",
        "提示",
        {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        }
      ).catch((err) => {
        return;
      });
      if (res) {
        this.$axios
          .post("/equipment/delEquipment?equipmentId=" + id)
          .then((resp) => {
            this.showData();
          });
      }

      console.log(res);
    },
    //展示修改对话框
    showmodDialog(item) {
      this.modDialogVisible = true;
      this.updateForm.equipmentId = item.id;
      this.updateForm.equipmentName = item.name;
      this.updateForm.equipmentProvider = item.provide;
      this.updateForm.equipmentUser = item.use;
      this.updateForm.equipmentPutInStorage = new Date(item.endTime);
    },
    //更新气象站信息
    updateEquipment() {
      this.updateForm.id = this.updateForm.equipmentId;
      this.$axios
        .post("/equipment/updateEquipment", this.updateForm)
        .then((resp) => {
          this.modDialogVisible = false;

          this.showData();
        });
    },

    //添加土壤设备
    addSoilDevice() {
      this.$axios
        .post("/equipment/addEquipment", this.addSoilForm)
        .then((res) => {
          this.addSoilDialogVisible = false;
          this.showData();
        });
    },
    //删除土壤设备
    async removeSoil(id) {
      const res = await this.$alert(
        "此操作将永久删除该设备, 是否继续?",
        "提示",
        {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        }
      ).catch((err) => {
        return;
      });
      if (res) {
        this.$axios
          .post("/equipment/delEquipment?equipmentId=" + id)
          .then((resp) => {
            this.showData();
          });
      }

      console.log(res);
    },
    //展示修改土壤对话框
    showmodSoilDialog(item) {
      this.modSoilDialogVisible = true;
      this.updateForm1.equipmentId = item.id;
      this.updateForm1.equipmentName = item.name;
      this.updateForm1.equipmentProvider = item.provide;
      this.updateForm1.equipmentUser = item.use;
      this.updateForm1.equipmentPutInStorage = new Date(item.endTime);
    },
    //更新土壤仪信息
    updateInstrument() {
      this.updateForm1.id = this.updateForm1.equipmentId;

      this.$axios
        .post("/equipment/updateEquipment", this.updateForm1)
        .then((resp) => {
          this.modSoilDialogVisible = false;
          this.showData();
        });
    },
    /**
     * 展示添加信息
     */
    showAddAirDeviceDialog(item) {
      this.addetails.equipmentId = item.id;
      this.addDetailsDialogVisible = true;
    },
    //添加气象站详细信息
    addAirDevice() {
      this.$axios
        .post("/equipment/addEquipmentInfo", this.addetails)
        .then((res) => {
          this.addDetailsDialogVisible = false;
          this.showData();
        });
    },
    //展示修改详情对话框
    showModDetailsDialog(item) {
      this.modDetailsDialogVisible = true;
      this.updateEtails.id = item.id;
      this.updateEtails.usageDetailsYears = item.year;
      this.updateEtails.usageDetailsBeginTime = item.startTime;
      this.updateEtails.usageDetailsEndTime = item.endTime;
      this.updateEtails.usageDetailsBreakdown = item.breakdown;
      this.updateEtails.usageDetailsPlotId = item.plotId;
      console.log(item);
    },
    //删除气象站详情
    async removeDe(id) {
      const res = await this.$alert(
        "此操作将永久删除该设备, 是否继续?",
        "提示",
        {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        }
      ).catch((err) => {
        return;
      });
      if (res) {
        this.$axios
          .post("/equipment/delEquipmentDetails?detailId=" + id)
          .then((resp) => {
            this.showData();
          });
      }

      console.log(res);
    },
    //更新气象站详细信息
    updateModDetails(item) {
      this.$axios.post("/equipment/updateDetail", item).then((resp) => {
        this.modDetailsDialogVisible = false;
        this.showData();
      });
    },
    /**
     * 展示土壤添加信息
     */
    showAddSoilDeviceDialog(item) {
      this.addsoiletails.equipmentId = item.id;
      this.addSoilDetailsDialogVisible = true;
    },
    //添加土壤详细信息
    addSoilDetailedDevice() {
      this.$axios
        .post("/equipment/addEquipmentInfo", this.addsoiletails)
        .then((res) => {
          this.addSoilDetailsDialogVisible = false;
          this.showData();
        });
    },
    //土壤仪详情修改
    showsoilEtails(item) {
      this.modSoilDetailsDialogVisible = true;
      this.updatesoilEtails.usageDetailsYears = item.year;
      this.updatesoilEtails.id = item.id;
      this.updatesoilEtails.usageDetailsBeginTime = item.startTime;
      this.updatesoilEtails.usageDetailsEndTime = item.endTime;
      this.updatesoilEtails.usageDetailsBreakdown = item.breakdown;
      this.updatesoilEtails.usageDetailsPlotId = item.plotId;
    },
    //更新
    updateSoilEtails() {
      this.updateForm1.id = this.updateForm1.equipmentId;

      this.$axios
        .post("/equipment/updateEquipment", this.updateForm1)
        .then((resp) => {
          this.modSoilDialogVisible = false;

          this.showData();
        });
    },
    //删除土壤仪详
    async removesoilEtails(id) {
      const res = await this.$alert(
        "此操作将永久删除该设备, 是否继续?",
        "提示",
        {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        }
      ).catch((err) => {
        return;
      });
      if (res) {
        this.$axios
          .post("/equipment/delEquipmentDetails?detailId=" + id)
          .then((resp) => {
            this.showData();
          });
      }
    },
    getPlotList() {
      this.$axios.get("/equipment/listPlot").then((resp) => {
        this.cropsSumMapList = resp.data.listEquipmentsPlot;
      });
    },
    timeFormart(date) {
      date = new Date(date);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      return (
        year +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day)
      );
    },
    clearSearchForm() {
      console.log(1);
    },
  },
};
</script>



<style scoped>
.headers {
  user-select: none;
  background-color: #fff;
  height: 200px;
  box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.13) 0 0 1px 1px;
}
/* headers下的第一个header没有边框 */
.headers .header:first-child {
  border-width: 0;
}

.header h1 {
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
  /*font-weight: bold;*/
  margin-top: 4px;
}
.header h2 {
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
}
.header h3 {
  margin: 0;
  color: #666666;
}

.header span {
}
.headers .header {
  margin-top: 20px;
  display: inline-block;
  text-align: left;
  /*background-color: #00ff00;*/
  vertical-align: middle;
  padding-left: 20px;
  width: 50%;
  min-height: 160px;
  border-left: 1px solid #ddd;
}

.headers .set div h2 {
  margin-top: 20px;
  width: 30%;
  line-height: 30px;
}
</style>
