
//小程序对接指标录入模块
<template>
    <div id="components-form-demo-advanced-search" >
        <a-card>
            <a-form layout="inline">
                <a-form-model-item label="" >
                    <a-form-item style="margin-left: 20px" label="采集人">
                        <a-input v-model="indictorsName"
                        placeholder="请输入采集人名称"/>
                    </a-form-item>
                </a-form-model-item>
                <a-form-item>
                    <a-button type="primary" html-type="submit" @click="selectData">
                        搜索
                    </a-button>
                </a-form-item>


                <a-form-model-item label="指标分组" >
                    <a-select
                            default-value=""
                            style="width: 200px"
                            id="group"
                            @change="groupHandleChange">
                        <a-select-option value="全部分组" >
                            全部分组
                        </a-select-option>
                        <a-select-option v-for="group in this.allGroup" :key="group.tenant_id">
                            {{group.tenant_name}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-item>
                    <a-button type="primary" html-type="submit" @click="selectData1()">
                        搜索
                    </a-button>
                </a-form-item>

            </a-form>

        </a-card>
      <a-config-provider :locale="locale">
        <a-table
            :dataSource="dataSource"
            :columns="columns"
            @change="changePage"
            :pagination="pagination"
        >
            <template
                    slot="expandedRowRender"
                    style="margin: 0;white-space: pre-wrap;"
                    slot-scope="text, record">
                <ol>
                    <li v-bind="details[record]">
                        采集图像为：   
                            <img :src= "details[record]"  width="100px" height="100px"/>
                    </li>
                </ol>

            </template>

          <template slot="operation" slot-scope="text, record">
                <span style="float:left" >
                    <a-button  type="link" style="margin-left:-15px" @click="showUpdate(record)">
                        <a-icon type="edit" />
                    </a-button>
                    <a-button type="link" style="margin-left:-15px" @click="onDelete(record)">
                        <a-icon type="delete" />
                    </a-button>
                </span>
          </template>
        </a-table>
      </a-config-provider>

     <a-modal v-model="update" title="修改" @ok="onUpdate">
<!--          添加一个ref 组件-->
          <Edit ref="updateEdit" :get-list="showData"></Edit>
      </a-modal>
    </div>
</template>

<script>
    import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import Edit from "./edit/Edit1";
    export default {
        name: "manager-indicator1",
        components: {Edit},
        data() {
            return {
                locale: zhCN,
                indictorsName: '',
                indictorsGroup: '',
                allGroup: [],
                details:[],
                count: 2,
                add: false,
                update: false,
                pagination: {
                    size: "default",
                    position: "bottom",
                    pageSize: 10,
                    current: 1,
                    total: 6,
                    showSizeChanger: false,
                    showQuickJumper: true,
                    // pageSizeOptions: ['2','10']
                },
                dataSource: [],
                columns: [
                    {
                        title: '序号',
                        dataIndex: 'id',
                        width: '6%'
                        // scopedSlots: {customRender: 'name'},
                    },

                    {
                        title: '时间',
                        dataIndex: 'formatTime',
                        width: '16%'
                        // scopedSlots: {customRender: 'name'},
                    },
                    {
                        title: '地块编号',
                        dataIndex: 'cropsRotationPlotId',
                        width: '13%'
                        // scopedSlots: {customRender: 'name'},
                    },
                    {
                        title: '指标名称',
                        dataIndex: 'indicatorId',
                        // align: 'left'
                    },
                    {
                        title: '指标数值',
                        dataIndex: 'digitValue',
                        width: '14%'
                        // align: 'left'
                    },

                    {
                        title: '采集人',
                        dataIndex: 'valueDeterminationType',
                        // width: '16%',
                        width: '14%'
                        // align: 'left'
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        // width: '10%',
                        scopedSlots: {customRender: 'operation'},
                        // align: 'center'
                    },
                ],
            }
        },
        created() {
            this.showData()
            this.getAllland()
        },
        methods: {
            getAllland() {
                console.log("getAllland")
                let _this = this
                _this.$axios.post('/soil/getLand') .then(res => {
                    console.log(res)
                    _this.allGroup = res.data.data;
                })
            },
            groupHandleChange(value) {
                console.log(`selected ${value}`);
                let _this = this
                _this.indictorsGroup = value
                console.log(_this.indictorsGroup);
                // _this.selectData()


            },
            showData() {
                let _this = this
                console.log('Ok')
                let current = _this.pagination.current
                console.log(current)
                _this.$axios.post('/indicator/get_all',{
                    // params: {
                    //     pageNum: current
                    // }
                }).then(res => {
                    console.log(res)
                    let _date = res.data.data;
                    console.log(_date)
                    _this.dataSource = [];


                    _date.forEach(function (b) {

                        _this.dataSource.push(b)
                        _this.details.push(b.textValue)

                    })
                    _this.pagination.total = res.data.data.total
                    console.log(_this.pagination.total)
                    // _this.pagination = res.data.pagination
                })

            },
            changePage(page, filters, sorter){
                let _this = this
                _this.pagination.current = page.current
                let current =  _this.pagination.current
                console.log(current)
                _this.showData()
            },
            //查询
            selectData() {
                let _this = this
                console.log('select')
                console.log(_this.indictorsName)
                console.log(_this.indictorsGroup)
                let indictorname = this.indictorsName
                let indictorsGroup = this.indictorsGroup
                if(indictorsGroup === "全部分组") {
                    indictorsGroup = ''
                }
                
                    
                _this.$axios.get('/indicator/get_by_name', {
                    params: {
                        name: indictorname,
                        // group: indictorsGroup
                    }
                }).then(res => {
                        console.log(res)
                        let _date = res.data.data;
                        _this.dataSource = [];
                        _date.forEach(function (b) {

                            _this.dataSource.push(b)
                            _this.details.push(b.textValue)

                        })
                    _this.pagination.total = res.data.data.total
                    
                })

                


            },

            selectData1() {
                let _this = this
                console.log('select')
                let indictorname = this.indictorsName
                let indictorsGroup = this.indictorsGroup.tenant_id
                if(indictorsGroup === "全部分组") {
                    indictorsGroup = ''
                }
                console.log(indictorsGroup)
                    
                _this.$axios.get('/indicator/get_by_group', {
                    params: {
                        group: indictorsGroup,

                    }
                }).then(res => {
                        console.log(res)
                        let _date = res.data.data;
                        _this.dataSource = [];
                        _date.forEach(function (b) {

                            _this.dataSource.push(b)
                            _this.details.push(b.textValue)

                        })
                    _this.pagination.total = res.data.data.total
                    
                })

                


            },
            // 新增
            showAdd() {
                let _this = this
                _this.add = true;
            },
            onAdd(e) {
                let _this = this
                _this.add = false;

                _this.$refs.addEdit.onSubmit()
                _this.showData()
            },
            // 修改
            showUpdate(e) {
                let _this = this
                _this.update = true
                console.log("showUpdate")
                console.log(e)
                this.$nextTick( () => {
                    _this.$refs.updateEdit.showUpdateDate(e)
                })
            },
            onUpdate(e) {
                console.log(e)

                let _this = this
                _this.update = false

                _this.$nextTick( () => {
                    _this.$refs.updateEdit.onUpdate()
                })


            },
            // 删除
            onDelete(e) {
                let _this = this
                let _id = e.id
                console.log(e.id)
                console.log('OK');
                _this.$confirm({
                    title: '确认删除',
                    content: h => <div style="color:red;">您确定删除采集数据吗？</div>,
                onOk() {
                    if(e.isUsed != 0) {
                        alert("使用中！不可删除！");
                    }else{
                        console.log('OK');
                        _this.$axios.get('/indicators/delete', {
                            params: {
                                id: _id
                            }
                        }).then(res => {
                            //
                            console.log('deleteOK');
                            // _this.pagination.current = 1;
                            let current = _this.pagination.current
                            console.log(current)
                            _this.showData()
                            console.log("已删除")
                        })
                    }
                },
                onCancel()
                {
                    console.log('Cancel');
                }
            })
            }
        }
    }
</script>

<style scoped>
    .ant-table-tbody > tr > td {
        background: #fff;
        padding: 5px 15px !important;
        background: #fff;
    }

</style>
