<!--<template>-->
<!--  <div>-->
<!--    <div id="photo" style="height: 95%"></div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  data() {-->
<!--    return {-->

<!--    }-->
<!--  },-->
<!--}-->
<!--</script>-->
<template>
  <div>
    <div :style="{ borderBottom: '1px solid #E9E9E9' }">
      <a-checkbox :indeterminate="indeterminate1" :checked="checkAll1" @change="onCheckAllChange1">
        Check all
      </a-checkbox>
    </div>
    <br />
    <a-checkbox-group v-model="checkedList1" :options="plainOptions1" @change="onChange1"/>
<!--    -->
    <div :style="{ borderBottom: '1px solid #E9E9E9' }">
      <a-checkbox :indeterminate="indeterminate2" :checked="checkAll2" @change="onCheckAllChange2">
        Check all
      </a-checkbox>
    </div>
    <br />
    <a-checkbox-group v-model="checkedList2" :options="plainOptions2" @change="onChange2" />

    <a-button @click="fun()">点击</a-button>
  </div>
</template>
<script>
  const plainOptions1 = ['Apple', 'Pear', 'Orange'];
  const defaultCheckedList1 = ['Apple', 'Orange'];

  const plainOptions2 = ['Apple', 'Pear', 'Orange'];
  const defaultCheckedList2 = ['Apple', 'Orange'];
  export default {
    data() {
      return {
        checkedList1:defaultCheckedList1,//多选结果1
        checkedList2:defaultCheckedList2,//多选结果2
        checkedList: [this.checkedList1,this.checkedList2],
        indeterminate1: false,
        indeterminate2: false,
        checkAll1: true,
        checkAll2: true,
        plainOptions1,
        plainOptions2,
      };
    },
    methods: {
      fun()
      {
        var s=[];
        for(var i=0;i<this.checkedList1.length;i++)
        {
          s.push(this.checkedList1[i])
        }
        for(var i=0;i<this.checkedList2.length;i++)
        {
          s.push(this.checkedList2[i])
        }
        console.log(this.checkedList1)
        console.log(s)
       // var s= [this.checkedList1,this.checkedList2]
       //  console.log(this.checkedList1+this.checkedList2)
       //  console.log(s)
       //    this.$alert(s)
      },
      onChange1(checkedList1) {
        console.log(checkedList1)
        this.indeterminate1 = !!checkedList1.length && checkedList1.length < plainOptions1.length;
        this.checkAll1 = checkedList1.length === plainOptions1.length;
      },
      onCheckAllChange1(e) {

        Object.assign(this, {
          checkedList1: e.target.checked ? plainOptions1 : [],
          indeterminate1: false,
          checkAll1: e.target.checked,
        });
        console.log(this.checkedList1)
      },

      onChange2(checkedList2) {
        console.log(checkedList2)
        this.indeterminate2 = !!checkedList2.length && checkedList2.length < plainOptions2.length;
        this.checkAll2 = checkedList2.length === plainOptions2.length;
      },
      onCheckAllChange2(e) {

        Object.assign(this, {
          checkedList2: e.target.checked ? plainOptions1 : [],
          indeterminate2: false,
          checkAll2: e.target.checked,
        });
        console.log(this.checkedList2)
      },
    },
  };
</script>
