<template>
  <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">

    <a-form-model-item label="用户名称">
      <a-input v-model="form.username"/>
    </a-form-model-item>

    <a-form-model-item label="用户密码">
      <a-input v-model="form.password"/>
    </a-form-model-item>

    <a-form-model-item label="邮箱">
      <a-input v-model="form.email"/>
    </a-form-model-item>

    <a-form-model-item label="联系方式">
      <a-input v-model="form.mobile"/>
    </a-form-model-item>

  </a-form-model>
</template>
<script>
export default {
  name: 'edit-user',
  props:{
    getList: Function
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        id: '',
        username: '',
        password:'',
        email: '',
        mobile: '',
        status: 1,
        // createTime: ''
      },
    };
  },
  methods: {
    ShowData(e) {
      let _this = this
      _this.form = e
    },
    onUpdate(e) {
      let _this = this

      // console.log(e);
      // _this.form.createTime = new Date().getTime()
      // console.log(this.form);

      _this.$axios.post('setting/upUser', _this.form).then(res => {
        console.log(res)
        _this.getList()
      })
    },
    onSubmit() {
      let _this = this

      // console.log(this.form);
      // _this.form.createTime = new Date().getTime()

      console.log(this.form);
      _this.$axios.post('setting/addUser', _this.form).then(res => {
        console.log(res)
        _this.getList()
      })

      _this.form.id = ''
      _this.form.username = ''
      _this.form.email = ''
      _this.form.mobile = ''
    },
  },
};
</script>