<template>
  <div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">

      <a-form-model-item label="角色名称">
        <a-input v-model="form.rolename"/>
      </a-form-model-item>

      <a-form-model-item label="权限管理">
        <!--      <a-input v-model="form.remark"/>-->
        <a-select v-model="form.remark" mode="tags" style="width: 100%" placeholder="Tags Mode" @change="handleChange">
          <a-select-option v-for="permission in this.permissions" :key="permission.permission">
            {{ permission.permission }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

    </a-form-model>
  </div>

</template>
<script>
export default {
  name: 'edit-role',
  props:{
    getList: Function
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      permissions: [
        {
          id: '1',
          permission: '查看权限'
        },
        {
          id: '2',
          permission: '添加权限'
        },
        {
          id: '3',
          permission: '修改权限'
        }
        ,
        {
          id: '4',
          permission: '删除权限'
        }
      ],
      form: {
        rolename: '',
        remark: undefined
      },
    };
  },
  methods: {
    handleChange(value) {
      console.log(value)

      if(value.length === 0) {
        this.form.remark = undefined
      } else {
        this.form.remark = value.toString()
      }

    },
    ShowData(e) {
      let _this = this
      _this.form = e
    },
    onUpdate(e) {
      let _this = this

      // console.log(e);
      // _this.form.createTime = new Date().getTime()
      // console.log(this.form);

      _this.$axios.post('setting/upRole', _this.form).then(res => {
        console.log(res)
        _this.getList()
      })
    },
    onSubmit() {
      let _this = this

      // console.log(this.form);
      // _this.form.createTime = new Date().getTime()

      console.log(this.form);
      _this.$axios.post('setting/addRole', _this.form).then(res => {
        console.log(res)
        _this.getList()
      })

      _this.form.rolename = ''
      _this.form.remark = ''
    },
  },
};
</script>