<template>
    <div style="text-align: center; width:100%;">
        <h1>{{this.year}}年叶片微量元素表</h1>
       <div id="indicator-leaf-charts" :style="{height: '500px',width:'100%'}">
                <h1>图表加载失败</h1>
       </div>
    </div>
</template>

<script>
    export default {
        name: "Indicator-leaf-charts",
        props:{//接受传入的参数
            'year': {},
            'showPromptMessage': {},
            'showWarningLine': {},
            collapsed:{},
        },
        data () {
            return {
              optionsYear: [],
              msg:{
                N:{
                  value:'',
                  upperLimit:'',
                  lowerLimit:'',
                },
                P:{
                  value:'',
                  upperLimit:'',
                  lowerLimit:'',
                },
                K:{
                  value:'',
                  upperLimit:'',
                  lowerLimit:'',
                },
                Ca:{
                  value:'',
                  upperLimit:'',
                  lowerLimit:'',
                },
                Mg:{
                  value:'',
                  upperLimit:'',
                  lowerLimit:'',
                }
              },
            };
        },
        methods: {
          getMsg(val){
            this.$axios.post("/indicator/leafAll",val).then((res)=>{
              let N={name:"氮元素",num:0};
              let P={name:"磷元素",num:0};
              let K={name:"钾元素",num:0};
              let Ca={name:"钙元素",num:0};
              let Mg={name:"镁元素",num:0};
              for(let i=0;i<res.data.title.length;i++){
                if(res.data.list306<this.msg.Ca.lowerLimit){ //叶片钙含量
                  Ca.num++;
                }
                if(res.data.list307<this.msg.Mg.lowerLimit){ //叶片钙含量
                  Mg.num++;
                }
                if(res.data.list314<this.msg.N.lowerLimit){ //叶片钙含量
                  N.num++;
                }
                if(res.data.list315<this.msg.P.lowerLimit){ //叶片钙含量
                  P.num++;
                }
                if(res.data.list316<this.msg.K.lowerLimit){ //叶片钙含量
                  K.num++;
                }
              }
              let a=[{name:"叶片微量元素",num:1}]
              a.push(N,P,K,Ca,Mg);
              this.$emit("change",a);
              console.log(res.data)
              let myCharts = this.$echarts.init(document.getElementById("indicator-leaf-charts"));
              // myCharts.clear();
              myCharts.setOption( {
                title:{
                  text:this.year+'年叶片微量元素表',
                  left:'center',
                  show:false
                },
                xAxis: [{
                  nameLocation:'end', //坐标轴名称显示的位置
                  silent:true,  //是否静态无法交互
                  type: 'category',
                  data: res.data.title,
                  axisLine:{
                    show:true,
                  },},],
                legend:{
                  // formatter:'{name}'+(this.showPromptMessage===true?"*":' '),
                  data: [
                    {name:'氮元素', tooltip:
                          {
                            name:'氮元素',
                            trigger:'氮元素',
                            show:this.showPromptMessage,
                            formatter:this.msg.N.value,
                            position:'top',
                          }
                    },{name:'磷元素', tooltip:
                          {
                            name:'磷元素',
                            trigger:'磷元素',
                            show:this.showPromptMessage,
                            formatter:this.msg.P.value,
                            position:'top',
                          }
                    },{name:'钾元素', tooltip:
                          {
                            name:'钾元素',
                            trigger:'钾元素',
                            show:this.showPromptMessage,
                            formatter:this.msg.K.value,
                            position:'top',
                          }
                    },{name:'钙元素', tooltip:
                          {
                            name:'钙元素',
                            trigger:'钙元素',
                            show:this.showPromptMessage,
                            formatter:this.msg.Ca.value,
                            position:'top',
                          }
                    },{name:'镁元素', tooltip:
                          {
                            name:'镁元素',
                            trigger:'镁元素',
                            show:this.showPromptMessage,
                            formatter:this.msg.Mg.value,
                            position:'top',
                          }
                    },
                  ]
                },
                series: [
                  {
                    markLine:{
                      label:{
                        show:this.showWarningLine,
                      },
                      data : [{
                        silent:false, //鼠标悬停事件 true没有，false有
                        lineStyle:{ //警戒线的样式 ，虚实 颜色
                          width: this.showWarningLine===true?1:0   //宽度
                        },
                        yAxis: this.msg.N.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                      }]
                    },
                    name:'氮元素',
                    data: res.data.list314,
                  },
                  {
                    markLine:{
                      label:{
                        show:this.showWarningLine,
                      },
                      data : [{
                        silent:false, //鼠标悬停事件 true没有，false有
                        lineStyle:{ //警戒线的样式 ，虚实 颜色
                          width: this.showWarningLine===true?1:0   //宽度
                        },
                        yAxis: this.msg.P.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                      }]
                    },
                    name:'磷元素',
                    data:res.data.list315,
                    // symbolSize:10
                  },
                  {
                    markLine:{
                      label:{
                        show:this.showWarningLine,
                      },
                      data : [{
                        silent:false, //鼠标悬停事件 true没有，false有
                        lineStyle:{ //警戒线的样式 ，虚实 颜色
                          width: this.showWarningLine===true?1:0   //宽度
                        },
                        yAxis: this.msg.K.lowerLimit // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                      }]
                    },
                    name:'钾元素',
                    data:res.data.list316,
                    // symbolSize:10
                  },
                  {
                    markLine:{
                      label:{
                        show:this.showWarningLine,
                      },
                      data : [{
                        silent:false, //鼠标悬停事件 true没有，false有
                        lineStyle:{ //警戒线的样式 ，虚实 颜色
                          width: this.showWarningLine===true?1:0   //宽度
                        },
                        yAxis: this.msg.Ca.lowerLimit // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                      }]
                    },
                    name:'钙元素',
                    data:res.data.list306,
                    // symbolSize:10
                  },
                  {
                    markLine:{
                      label:{
                        show:this.showWarningLine,
                      },
                      data : [{
                        silent:false, //鼠标悬停事件 true没有，false有
                        lineStyle:{ //警戒线的样式 ，虚实 颜色
                          width: this.showWarningLine===true?1:0   //宽度
                        },
                        yAxis: this.msg.Mg.lowerLimit // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                      }]
                    },
                    name:'镁元素',
                    data:res.data.list307,
                    // symbolSize:10
                  }
                ]
              });
            })
          },
          changeChart(){
                this.getMsg(this.year)
          },
          handleWindowResize() {
            let myCharts = this.$echarts.init(document.getElementById('indicator-leaf-charts'));
            myCharts && myCharts.resize();
          },
          changeChartSize(){
            setTimeout(()=>{
              this.handleWindowResize()
            },500)
          }
        },
        beforeMount() {
            this.$axios.get("/indicators/getDetails/314",).then((rest)=>{
              this.msg.N.value=rest.data.indexDetails
              this.msg.N.upperLimit=rest.data.upperLimit
              this.msg.N.lowerLimit=rest.data.lowerLimit
            })
            this.$axios.get("/indicators/getDetails/315",).then((rest)=>{
              this.msg.P.value=rest.data.indexDetails
              this.msg.P.upperLimit=rest.data.upperLimit
              this.msg.P.lowerLimit=rest.data.lowerLimit
            })
            this.$axios.get("/indicators/getDetails/316",).then((rest)=>{
              this.msg.K.value=rest.data.indexDetails
              this.msg.K.upperLimit=rest.data.upperLimit
              this.msg.K.lowerLimit=rest.data.lowerLimit
            })
            this.$axios.get("/indicators/getDetails/306",).then((rest)=>{
              this.msg.Ca.value=rest.data.indexDetails
              this.msg.Ca.upperLimit=rest.data.upperLimit
              this.msg.Ca.lowerLimit=rest.data.lowerLimit
            })
            this.$axios.get("/indicators/getDetails/307",).then((rest)=>{
              this.msg.Mg.value=rest.data.indexDetails
              this.msg.Mg.upperLimit=rest.data.upperLimit
              this.msg.Mg.lowerLimit=rest.data.lowerLimit
            })
        },
        watch:{
            year:'changeChart',
            showPromptMessage:'changeChart',
            showWarningLine:'changeChart',
            collapsed:"changeChartSize",
        },
        mounted() {
            //初始化echarts
            let myCharts = this.$echarts.init(document.getElementById("indicator-leaf-charts"));
            setTimeout(()=>{
              this.handleWindowResize()
            },100)
            window.addEventListener('resize',this.handleWindowResize)
            myCharts.setOption( {
                title: {
                    show:false,
                    text: '叶片微量元素表',
                },
                xAxis: [{
                    name:'日期',  //坐标轴的名称
                    nameLocation:'end', //坐标轴名称显示的位置
                    silent:false,  //是否静态无法交互
                    type: 'category',
                    data: [],
                    axisLine:{
                        show:true,
                    },
                }],
                yAxis: {
                    name:'ppm',  //坐标轴的名称
                    type: 'value',
                    scale:true,
                    // splitNumber:20,
                    start:30,
                    axisLine:{
                        show:true,
                    },
                },
                toolbox: {
                    show:true,
                    feature: {
                        saveAsImage: {},
                    }
                },
                //控制x轴y轴位置
              grid: {
                left: '27px',
                right: '50px', ///   调整大小
                bottom: '1%',
                top:'7%',
                // show:true, //  边框是否显示
                containLabel: true
              },
                legend: {
                    top:5,
                    textStyle:{
                        color:"#444",
                        fontSize:14,
                        fontWeight: 'bold',
                    },
                    // '氮元素', '磷元素', '钾元素','钙元素','镁元素'
                    data: [
                        {name:'氮元素', tooltip:
                                {
                                // name:'氮元素',
                                // trigger:'氮元素',
                                show:true,
                                formatter:this.N,
                                }
                        },{name:'磷元素', tooltip:
                                {
                                    // name:'磷元素',
                                    // trigger:'磷元素',
                                    show:true,
                                    formatter:this.P,
                                }
                        },{name:'钾元素', tooltip:
                                {
                                    name:'钾元素',
                                    trigger:'钾元素',
                                    show:true,
                                    formatter:this.K,
                                }
                        },{name:'钙元素', tooltip:
                                {
                                    name:'钙元素',
                                    trigger:'钙元素',
                                    show:true,
                                    formatter:this.Ca,
                                }
                        },{name:'镁元素', tooltip:
                                {
                                    name:'镁元素',
                                    trigger:'镁元素',
                                    show:true,
                                    formatter:this.Mg,
                                }
                        },
                    ]
                },
                tooltip:{
                    appendToBody:'true',
                    backgroundColor:'rgba(255,255,255,0.96)',   //背景的颜色
                    textStyle:{
                        fontWeight:'normal',
                        indent:'50px',
                        color:"#555",
                    },
                    extraCssText:'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;white-space:pre-wrap;max-width:300px;',
                    show:true,
                    trigger:'axis',
                },
                series: [
                    {
                        markLine:{
                            symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
                            label:{
                                position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
                                // formatter:'10cmEC警戒指标',
                            },
                            data : [{
                                silent:false, //鼠标悬停事件 true没有，false有
                                lineStyle:{ //警戒线的样式 ，虚实 颜色
                                    type:"dotted", //样式  ‘solid’和'dotted'
                                    // color:"#FA3934",
                                    width: 1   //宽度
                                },
                                yAxis: 220000 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                            }]
                        },
                        name:"氮元素",
                        data: [],
                        type: 'line',
                        connectNulls:true,  //将断点连起来
                    },
                    {
                        markLine:{
                            symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
                            label:{
                                position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
                                // formatter:'20cmEC警戒指标',
                            },
                            data : [{
                                silent:false, //鼠标悬停事件 true没有，false有
                                lineStyle:{ //警戒线的样式 ，虚实 颜色
                                    type:"dotted", //样式  ‘solid’和'dotted'
                                    // color:"#FA3934",
                                    width: 1   //宽度
                                },
                                yAxis: 25000 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                            }]
                        },
                        name:"磷元素",
                        data: [],
                        type: 'line',
                        connectNulls:true,
                    },
                    {
                        markLine:{
                            symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
                            label:{
                                position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
                                // formatter:'30cmEC警戒指标',
                            },
                            data : [{
                                silent:false, //鼠标悬停事件 true没有，false有
                                lineStyle:{ //警戒线的样式 ，虚实 颜色
                                    type:"dotted", //样式  ‘solid’和'dotted'
                                    // color:"#FA3934",
                                    width: 1   //宽度
                                },
                                yAxis: 1000000 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                            }]
                        },
                        connectNulls:true,
                        name:"钾元素",
                        data: [],
                        type:'line',
                    },
                    {
                        markLine:{
                            symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
                            label:{
                                position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
                                // formatter:'30cmEC警戒指标',
                            },
                            data : [{
                                silent:false, //鼠标悬停事件 true没有，false有
                                lineStyle:{ //警戒线的样式 ，虚实 颜色
                                    type:"dotted", //样式  ‘solid’和'dotted'
                                    // color:"#FA3934",
                                    width: 1   //宽度
                                },
                                yAxis: 400000 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                            }]
                        },
                        connectNulls:true,
                        name:"钙元素",
                        data: [],
                        type:'line',
                    },
                    {
                        markLine:{
                            symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
                            label:{
                                position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
                                // formatter:'30cmEC警戒指标',
                            },
                            data : [{
                                silent:false, //鼠标悬停事件 true没有，false有
                                lineStyle:{ //警戒线的样式 ，虚实 颜色
                                    type:"dotted", //样式  ‘solid’和'dotted'
                                    // color:"#FA3934",
                                    width: 1   //宽度
                                },
                                yAxis: 80000 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                            }]
                        },
                        connectNulls:true,
                        name:"镁元素",
                        data: [],
                        type:'line',
                    },
                ],

            });
        },
    }
</script>

<style scoped>
    h1{
        display: inline-block;
        font-weight: bolder;
        font-size: 24px;
    }
</style>