<template>
  <div style="display: flex; justify-content: space-between; align-items: center">
    <div id="closeButton" @click="hideMenu">
      <a-icon type="right" style="font-size: 16px; font-weight: bolder;"/>
    </div>
    <a-collapse v-model="activeKey" :bordered="false" :expand-icon-position="expandIconPosition" style="width: 100%;">
      <a-collapse-panel key="1" header="信息绑定">
        <a-form-model :layout="form.layout" :model="form">
          <a-form-model-item label="选择地块">
            <a-select disabled v-model="form.name" @change="setCentral" placeholder="请选择地块">
              <a-select-option v-for="(item, index) in soilList" :key="index" >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="标注类型">
            <a-select disabled  v-model="form.devType" @change="setDeviceType" placeholder="请选择标注类型">
              <a-select-option v-for="(item, index) in typeList" :key="index" >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="绑定设备">
            <a-select disabled v-model="form.device" @change="setDeviceNum" placeholder="请选择绑定设备">
              <a-select-option  v-for="(item, index) in deviceList" :key="index" >
                {{ item.equipmentName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-item label="地块位置">
            <a-input disabled  placeholder="请选择标注" :value="form.location" ></a-input>
          </a-form-item>
          <a-form-item label="地块说明">
            <a-textarea disabled  v-model="form.introduce" placeholder="请输入地块说明" auto-size />
          </a-form-item>
          <a-form-model-item>
            <a-space>
<!--              <a-button type="primary" @click="showMap">-->
<!--                查询-->
<!--              </a-button>-->
              <a-button type="primary" @click="showPhoto">
                进入地块 
              </a-button>
            </a-space>
          </a-form-model-item>
        </a-form-model>
      </a-collapse-panel>
      <a-collapse-panel key="2" header="详情信息">
        <!--        <a-button type="primary" @click="showTable">-->
        <!--          Open-->
        <!--        </a-button>-->
        <a-card>
          <a-form layout="inline" style="display: flex; justify-content: space-between; align-items: center;">
            <a-form-model-item >
              <a-select
                  style="width: 120px"
                  id="soil"
                  v-model="deviceId"
                  placeholder="请选择地块"
                  @change="getDevices">
                <a-select-option v-for="(item, index) in soilList" :key="index" >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-icon id="spin" @click="renovates" type="sync" style="color: #1879FE"/>
            </a-form-model-item>
          </a-form>
        </a-card>
        <a-table
            :bordered="false"
            :dataSource="dataSource"
            :columns="columns"
            @change="changePage"
            :pagination="pagination"
        >
        </a-table>

      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
const data = [
  {
    deviceId: '1',
    deviceStatus: 'John Brown',
    weatherStationstatus: 32,
    plotName: 'New York No. 1 Lake Park',
  },
  {
    deviceId: '2',
    deviceStatus: 'Jim Green',
    weatherStationstatus: 42,
    plotName: 'London No. 1 Lake Park',
  },
  {
    deviceId: '3',
    deviceStatus: 'Joe Black',
    weatherStationstatus: 32,
    plotName: 'Sidney No. 1 Lake Park',
  }
];
export default {
  name: "side-home",
  props: {
    hideMenu: Function,
    setCenter: Function,
    showBottom: Function
  },
  data() {
    return {
      ismap: 0,
      deviceId: 0,
      pagination: {
        size: "default",
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 6,
        showSizeChanger: false,
        showQuickJumper: true,
        // pageSizeOptions: ['2','10']
      },
      dataSource: null,
      columns: [

        {
          title: '设备名称',
          dataIndex: 'equipmentName',
          align: 'center'
        },
        {
          title: '温度',
          dataIndex: 'temperatureValue',
          align: 'center'
        },
        {
          title: '湿度',
          dataIndex: 'humidityValue',
          align: 'center'
        },
        // {
        //   title: '创建时间',
        //   dataIndex: 'equipmentPutInStorage',
        //   width: '15%',
        //   align: 'center'
        // },
      ],
      expandIconPosition: 'right',
      activeKey: ['1'],
      form: {
        id: '',
        layout: 'vertical',
        name: undefined,
        type: '',
        devTypeNum: null,
        devType: undefined,
        deviceNum: null,
        // device: undefined,
        device: undefined,
        soilId: null,
        location: '',
        introduce: '',
      },
      soilList: [],
      typeList: [
        {
          id: 1,
          name: '气象站'
        }, {
          id: 2,
          name: '土壤仪'
        }, {
          id: 3,
          name: '地块'
        }
      ],
      deviceList: [],
      renovate: 0,
    }
  },
  mounted() {
    this.getSoilList()
    this.getDeviceList()
    window.setTimeout(() => {
      this.showData()
    }, 1000)
  },
  methods: {
    renovates(e) {
      // console.log(this.renovate)
      // anticon-spin
      var spin = document.getElementById("spin")
      var svg = spin.getElementsByTagName("svg")[0]
      // console.log(svg)
      svg.setAttribute("class", "anticon-spin")
      window.setTimeout(() => {
        svg.removeAttribute("class")
        // console.log("remove")
      }, 1000)

      if (this.renovate) {
        this.getDevices(this.renovate)
      }
    },
    setDeviceNum(value) {
      // console.log(value)
      this.form.deviceNum = this.deviceList[value].id
    },
    setDeviceType(value) {
      // console.log(value)

      if(value === 2) {
        this.form.devTypeNum = -1
        this.form.deviceNum = 0
        this.form.device = '无'
      } else {
        this.form.devTypeNum = value
      }

    },
    showData() {
      let _this = this
      // console.log('Ok')

      // _this.$axios
      //     .get("/equipment/listEquipment", {
      //       params: {
      //         page: _this.pagination.current - 1,
      //         offset: _this.pagination.pageSize,
      //         tag: 0,
      //       },
      //     })
      //     .then((res) => {
      //       _this.dataSource = res.data.listEquipment.list;
      //       for(var i =0 ; i < _this.dataSource.length; i++) {
      //         _this.dataSource[i].equipmentName = res.data.listEquipment.list[i].name
      //         _this.dataSource[i].equipmentProvider = res.data.listEquipment.list[i].provide
      //         _this.dataSource[i].equipmentUser =  res.data.listEquipment.list[i].use
      //       }
      //
      //     });
      _this.getDevices(0)
    },
    changePage(page, filters, sorter){
      let _this = this
      _this.pagination.current = page.current
    },
    getDevices(value) {
      // console.log(this.soilList[value])
      var id = this.soilList[value].id
      if (id != null) {
        this.$axios.request({
          method: 'GET',
          url: '/equipment/listEquipmentByPlotId',
          params: {
            id: id
          }
        }).then( res => {
          console.log(res)
          this.dataSource = res.data.listEquipmentByPlotId
          for (var i = 0; i < this.dataSource.length; i++) {
            this.dataSource[i].temperatureValue = this.dataSource[i].temperatureValue/100
            this.dataSource[i].humidityValue = this.dataSource[i].humidityValue/100
          }
        })
      }
    },
    getDeviceList() {
      let _this = this

      _this.$axios.request({
        method: 'GET',
        url: '/equipment/getAllEquipment'
      }).then(res => {
        // console.log(res)
        _this.deviceList = res.data.data
      })
    },
    showTable() {
      this.hideMenu()
      this.showBottom()
    },
    getSoilList() {
      let _this = this

      _this.$axios.request({
        method: 'GET',
        url: '/cropsSumMap/getPlot'
      }).then(res => {
        // console.log(res)
        _this.soilList = res.data.data
      })
    },
    getTypeList() {
      let _this = this

      // _this.$axios.request({
      //   method: 'GET',
      //   url: '/cropsSumMap/getPlot'
      // }).then(res => {
      //   console.log(res)
      //   _this.typeList = res.data.data
      // })
    },
    setCentral(value) {
      // console.log(this.soilList[value])
      var markInfo = this.soilList[value]

      if(markInfo === null) {
        this.$confirm({
          title: '提示信息',
          content: h => <div>无相关的标注信息</div>,
          onOk() {
            // console.log('OK');
          },
          onCancel() {
            // console.log('Cancel');
          }
        })
      } else {
        // console.log(JSON.parse(markInfo.location))

        this.ismap = 1
        let id = this.soilList[value].id
        let name = this.soilList[value].name
        // let point = JSON.parse(markInfo.location)
        // console.log(point)
        // var devType = markInfo.devType
        // var inroduce = markInfo.soilContent

        this.form.soilId = id
        this.form.name = name

        // console.log(this.form.soilId)
        // console.log(point)
        this.setCenter(id, point, inroduce)
        // this.setValue(id, devType, point, inroduce)
      }
    },
    clearValue() {
      var _this = this

      _this.form.id = ''
      _this.form.name = undefined
      _this.form.type = ''
      _this.form.devTypeNum = 0
      _this.form.devType = undefined
      _this.form.deviceNum = 0
      _this.form.device = undefined
      _this.form.soilId = 0
      _this.form.location = ''
      _this.form.introduce = ''
    },
    setValue(id, devType, location, introduce, type) {
      let _this = this

      if(id !== _this.form.id || location !== _this.form.location) {
        _this.clearValue()
      }

      // console.log(id)
      if(id === undefined) {

      } else {
        _this.form.id = id
        _this.$axios.request({
          method: 'GET',
          url: '/cropsSumMap/get/map',
          params: {
            id: id
          }
        }).then(res => {
          // console.log(res)
          _this.form.soilId = res.data.data.id
          _this.form.name = res.data.data.name
          // _this.form.introduce = res.data.data.affiliation
        })

        _this.$axios.request({
          method: 'POST',
          url: '/soil/getMarkerById',
          params: {
            id: id
          }
        }).then( res => {
          // console.log(res)
          _this.form.introduce = res.data.data.soilContent
        })
        _this.$axios.request({
          method: 'GET',
          url: '/equipment/listEquipmentByMarkerId',
          params: {
            id: id
          }
        }).then(res => {
          // console.log(res)
          _this.form.deviceNum = res.data.listEquipmentByMarkerId[0].id
          _this.form.device = res.data.listEquipmentByMarkerId[0].equipmentName
        })
      }

      // _this.form.devType = devType
      // console.log(devType)

      _this.form.type = type[0].toUpperCase() + type.substr(1, type.length)
      // console.log(_this.form.type)
      if(devType !== undefined) {
        _this.form.devTypeNum = devType
        _this.form.devType = _this.typeList[devType].name
      }
      _this.form.location = location
    },
    showMap() {
      let val =  [116.433015,39.909843]
      this.setCenter(val)
      this.hideMenu()
    },
    showPhoto() {

      this.$router.push({ name:'map-message'})


      let _this = this

      if(_this.ismap === 1) {
        _this.ismap = 0
        _this.$axios.request({
          method: 'GET',
          url: '/cropsSumMap/getMap',
          params: {
            id: _this.form.id
          }
        }).then(res => {
          // console.log(res.data.data)

          var data = res.data.data
          var rgb = data[0]
          var green = data[1]
          var nir = data[2]
          var red = data[3]
          var red_edge = data[4]

          if(rgb === undefined) {
            this.$message.error('没有相应的小地块信息');
          } else {
            _this.$router.push({
              name: 'map-soil',
              params: {
                rgb: rgb,
                green: green,
                nir: nir,
                red: red,
                red_edge: red_edge
              }
            })
          }
        })
      } else {
        // console.log(_this.form.id)
        _this.$axios.request({
          method: 'GET',
          url: '/cropsSumMap/get/map',
          params: {
            id: _this.form.id
          }
        }).then( res => {
          // console.log(res)
          var id = res.data.data.id

          // console.log(id)
          _this.$axios.request({
            method: 'GET',
            url: '/cropsSumMap/getMap',
            params: {
              id: id
            }
          }).then(res => {
            // console.log(res.data.data)

            var data = res.data.data
            var rgb = data[0]
            var green = data[1]
            var nir = data[2]
            var red = data[3]
            var red_edge = data[4]

            if(rgb === undefined) {
              this.$message.error('没有相应的小地块信息');
            } else {
              _this.$router.push({
                name: 'map-soil',
                params: {
                  rgb: rgb,
                  green: green,
                  nir: nir,
                  red: red,
                  red_edge: red_edge
                }
              })
            }
          })
        })
      }

      // this.$router.push('/soil/map-soil')
      this.hideMenu()
    },
  }
}
</script>

<style>

</style>