<template>
    <div>
        <a-tabs default-active-key="1" >
            <a-tab-pane key="1" tab="1.创建新计划">
                <create/>
            </a-tab-pane>
            <a-tab-pane key="2" tab="2.计划基本信息" force-render>
                <basic/>
            </a-tab-pane>
            <a-tab-pane key="3" tab="3.计划详细信息">
                <detail/>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
    import basic from './Basic-information'
    import detail from './Detailed-information'
    import create from './Create-template'
    export default {
        name: "collect-show",
        components:{basic,detail,create},
        data() {
            return {};
        },
    }
</script>

<style scoped>

</style>