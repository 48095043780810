<template>
    <div style="text-align: center; width:100%;">
      <h1>{{this.year}}年长势(长度)情况表</h1>
      <div id="length" :style="{width: '100%',height: '535px'}" >
        <h1>图表加载失败</h1>
      </div>
    </div>
</template>
<script>
    export default {
        name: "grow-length",
        props:{//接受传入的参数
            'year': {},
            'showPromptMessage': {},
            'showWarningLine': {},
          collapsed:{},
        },
        data () {
            return {
                optionsYear: [],
                yc:'',ycu:'',lg:'',
                zg:'',cd:'',
              msg:{
                budsLong:{  //芽长
                  value:'',
                  upperLimit:'',
                  lowerLimit:'',
                },
                budsCoarse:{  //芽粗
                  value:'',
                  upperLimit:'',
                  lowerLimit:'',
                },
                highRidge:{ //垄高
                  value:'',
                  upperLimit:'',
                  lowerLimit:'',
                },
                plantHeight:{ //株高
                  value:'',
                  upperLimit:'',
                  lowerLimit:'',
                },
                stolonLength:{  //匍匐茎长度
                  value:'',
                  upperLimit:'',
                  lowerLimit:'',
                }
              }
            };
        },
        methods: {
            getMsg(val){
              this.$axios.post("/indicator/length",val).then((res)=>{
                let budsLong={name:'芽长',num:0}
                let budsCoarse={name:'芽粗',num:0}
                let highRidge={name:'垄高',num:0}
                let plantHeight={name:'株高',num:0}
                let stolonLength={name:'匍匐茎长度',num:0}
                //芽长mm293  芽粗mm10052  垄高cm10053   株高cm297  匍匐茎长度cm  10055
                for(let i=0;i<res.data.title.length;i++){
                  if(res.data.list293<this.msg.budsLong.lowerLimit){
                    budsLong.num++;
                  }
                  if(res.data.list10052<this.msg.budsCoarse.lowerLimit){
                    budsCoarse.num++;
                  }
                  if(res.data.list10053<this.msg.highRidge.lowerLimit){
                    highRidge.num++;
                  }
                  if(res.data.list297<this.msg.plantHeight.lowerLimit){
                    plantHeight.num++;
                  }
                  if(res.data.list10055<this.msg.stolonLength.lowerLimit){
                    stolonLength.num++;
                  }
                }
                let a=[{name:"长势（长度）",num:2}]
                a.push(budsLong,budsCoarse,highRidge,plantHeight,stolonLength);
                this.$emit("change",a)

                let myCharts = this.$echarts.init(document.getElementById("length"));
                myCharts.setOption( {
                  title:{
                    show:false,
                    text:this.year+'年长势(长度)情况',
                    left:'center',
                    textStyle:{
                      fontWeight:'bolder',
                      fontSize:22,
                      color:"black",
                    },
                  },
                  xAxis: [{
                    nameLocation:'end', //坐标轴名称显示的位置
                    silent:true,  //是否静态无法交互
                    type: 'category',
                    data: res.data.title,
                    axisLine:{
                      show:true,
                    },},],

                  legend:{
                    // formatter:'{name}'+(this.showPromptMessage===true?"*":' '),
                    data: [
                      {name:'芽长（mm）', tooltip:
                            {
                              name:'芽长（mm）',
                              trigger:'芽长（mm）',
                              show:this.showPromptMessage,
                              formatter:this.yc,
                              position:'top'
                            }
                      },{name:'芽粗（mm）', tooltip:
                            {
                              name:'芽粗（mm）',
                              trigger:'芽粗（mm）',
                              show:this.showPromptMessage,
                              formatter:this.ycu,
                              position:'top'
                            }
                      },{name:'垄高（cm）', tooltip:
                            {
                              name:'垄高（cm）',
                              trigger:'垄高（cm）',
                              show:this.showPromptMessage,
                              formatter:this.lg,
                            }
                      },{name:'株高（cm）', tooltip:
                            {
                              name:'株高（cm）',
                              trigger:'株高（cm）',
                              show:this.showPromptMessage,
                              formatter:this.zg,
                            }
                      },{name:'匍匐茎长度（cm）', tooltip:
                            {
                              name:'匍匐茎长度（cm）',
                              trigger:'匍匐茎长度（cm）',
                              show:this.showPromptMessage,
                              formatter:this.cd,
                            }
                      },
                    ]
                  },
                  series: [
                    {

                      name:'芽长（mm）',
                      data: res.data.list293,
                      // symbolSize:10
                    },{
                      name:'芽粗（mm）',
                      data:res.data.list10052,
                      // symbolSize:10
                    },{
                      name:'垄高（cm）',
                      data:res.data.list10053,
                      // symbolSize:10
                    },
                    {
                      name:'株高（cm）',
                      data:res.data.list297,
                      // symbolSize:10
                    },
                    {
                      name:'匍匐茎长度（cm）',
                      data:res.data.list10055,
                      // symbolSize:10
                    }
                  ],
                });
              })
            },
          changeChart(){
                this.getMsg(this.year)
            },
          handleWindowResize() {
            let myCharts = this.$echarts.init(document.getElementById('length'));
            myCharts && myCharts.resize();
          },
          changeChartSize(){
            setTimeout(()=>{
              this.handleWindowResize()
              // console.log("在刷新了")
            },500)
          }
        },

        watch:{
            year:'changeChart',
            showPromptMessage:'changeChart',
            showWarningLine:'changeChart',
          collapsed:"changeChartSize",
        },

        mounted() {
          //芽长mm293  芽粗mm10052  垄高cm10053   株高cm297  匍匐茎长度cm  10055
          this.$axios.get("/indicators/getDetails/293",).then((rest)=>{
            this.yc=rest.data.indexDetails
            this.msg.budsLong.value=rest.data.indexDetails
            this.msg.budsLong.upperLimit=rest.data.upperLimit
            this.msg.budsLong.lowerLimit=rest.data.lowerLimit
          })
          this.$axios.get("/indicators/getDetails/10052",).then((rest)=>{
            this.msg.budsCoarse.value=rest.data.indexDetails
            this.msg.budsCoarse.upperLimit=rest.data.upperLimit
            this.msg.budsCoarse.lowerLimit=rest.data.lowerLimit
          })
          this.$axios.get("/indicators/getDetails/10053",).then((rest)=>{
            this.msg.highRidge.value=rest.data.indexDetails
            this.msg.highRidge.upperLimit=rest.data.upperLimit
            this.msg.highRidge.lowerLimit=rest.data.lowerLimit
          })
          this.$axios.get("/indicators/getDetails/297",).then((rest)=>{
            this.msg.plantHeight.value=rest.data.indexDetails
            this.msg.plantHeight.upperLimit=rest.data.upperLimit
            this.msg.plantHeight.lowerLimit=rest.data.lowerLimit
          })
          this.$axios.get("/indicators/getDetails/10055",).then((rest)=>{
            this.msg.stolonLength.value=rest.data.indexDetails
            this.msg.stolonLength.upperLimit=rest.data.upperLimit
            this.msg.stolonLength.lowerLimit=rest.data.lowerLimit
          })
            let _this = this
            //初始化echarts
            let myCharts = this.$echarts.init(document.getElementById("length"));
            setTimeout(()=>{
              this.handleWindowResize()
            },100)
            window.addEventListener('resize',this.handleWindowResize)
            myCharts.setOption( {
                title: {
                    show:false,
                    text: '长势（长度）情况表',
                },
                xAxis: [{
                    name:'日期',  //坐标轴的名称
                    nameLocation:'end', //坐标轴名称显示的位置
                    silent:false,  //是否静态无法交互
                    type: 'category',
                    data: [],
                    axisLine:{
                        show:true,
                    },
                }],
                yAxis: {
                    name:'',  //坐标轴的名称
                    type: 'value',
                    scale:true,
                    // splitNumber:20,
                    start:30,
                    axisLine:{
                        show:true,
                    },
                },
              grid: {
                left: '27px',
                right: '50px', ///   调整大小
                bottom: '1%',
                top:'7%',
                // show:true, //  边框是否显示
                containLabel: true
              },
                toolbox: {
                    show:true,
                    feature: {
                        saveAsImage: {},
                    }
                },
                legend: {
                    top:0,
                    textStyle:{
                        color:"#444",
                        fontSize:14,
                        fontWeight: 'bold',
                    },
                    //芽长mm293  芽粗mm10052  垄高cm10053   株高cm297  匍匐茎长度cm  10055
                    // data: ['芽长（mm）', '芽粗（mm）', '垄高（cm）','株高（cm）','匍匐茎长度（cm）']
                    data: [
                        {name:'芽长（mm）', tooltip:
                                {
                                    name:'芽长（mm）',
                                    trigger:'芽长（mm）',
                                    show:this.showPromptMessage,
                                    formatter:this.yc,
                                }
                        },{name:'芽粗（mm）', tooltip:
                                {
                                    name:'芽粗（mm）',
                                    trigger:'芽粗（mm）',
                                    show:this.showPromptMessage,
                                    formatter:this.ycu,
                                }
                        },{name:'垄高（cm）', tooltip:
                                {
                                    name:'垄高（cm）',
                                    trigger:'垄高（cm）',
                                    show:this.showPromptMessage,
                                    formatter:this.lg,
                                }
                        },{name:'株高（cm）', tooltip:
                                {
                                    name:'株高（cm）',
                                    trigger:'株高（cm）',
                                    show:this.showPromptMessage,
                                    formatter:this.zg,
                                }
                        },{name:'匍匐茎长度（cm）', tooltip:
                                {
                                    name:'匍匐茎长度（cm）',
                                    trigger:'匍匐茎长度（cm）',
                                    show:this.showPromptMessage,
                                    formatter:this.cd,
                                }
                        },
                    ]
                },
                tooltip:{
                    appendToBody:'true',
                    backgroundColor:'rgba(255,255,255,0.96)',   //背景的颜色
                    textStyle:{
                        fontWeight:'normal',
                        indent:'50px',
                        color:"#555",
                    },
                    extraCssText:'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;white-space:pre-wrap;max-width:300px;',
                    show:true,
                    trigger:'axis',
                },
                series: [
                    {
                        // markLine:{
                        //     symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
                        //     label:{
                        //         position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
                        //         // formatter:'10cmEC警戒指标',
                        //     },
                        //     data : [{
                        //         silent:false, //鼠标悬停事件 true没有，false有
                        //         lineStyle:{ //警戒线的样式 ，虚实 颜色
                        //             type:"dotted", //样式  ‘solid’和'dotted'
                        //             // color:"#FA3934",
                        //             width: 3   //宽度
                        //         },
                        //         yAxis: 230000 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                        //     }]
                        // },
                        name:"芽长（mm）",
                        data: [],
                        type: 'line',
                        connectNulls:true,  //将断点连起来
                    },
                    {
                        // markLine:{
                        //     symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
                        //     label:{
                        //         position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
                        //         // formatter:'20cmEC警戒指标',
                        //     },
                        //     data : [{
                        //         silent:false, //鼠标悬停事件 true没有，false有
                        //         lineStyle:{ //警戒线的样式 ，虚实 颜色
                        //             type:"dotted", //样式  ‘solid’和'dotted'
                        //             // color:"#FA3934",
                        //             width: 3   //宽度
                        //         },
                        //         yAxis: 100000 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                        //     }]
                        // },
                        name:"芽粗（mm）",
                        data: [],
                        type: 'line',
                        connectNulls:true,
                    },
                    {
                        // markLine:{
                        //     symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
                        //     label:{
                        //         position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
                        //         // formatter:'30cmEC警戒指标',
                        //     },
                        //     data : [{
                        //         silent:false, //鼠标悬停事件 true没有，false有
                        //         lineStyle:{ //警戒线的样式 ，虚实 颜色
                        //             type:"dotted", //样式  ‘solid’和'dotted'
                        //             // color:"#FA3934",
                        //             width: 3   //宽度
                        //         },
                        //         yAxis: 1000000 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                        //     }]
                        // },
                        connectNulls:true,
                        name:"垄高（cm）",
                        data: [],
                        type:'line',
                    },
                    {
                        // markLine:{
                        //     symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
                        //     label:{
                        //         position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
                        //         // formatter:'30cmEC警戒指标',
                        //     },
                        //     data : [{
                        //         silent:false, //鼠标悬停事件 true没有，false有
                        //         lineStyle:{ //警戒线的样式 ，虚实 颜色
                        //             type:"dotted", //样式  ‘solid’和'dotted'
                        //             // color:"#FA3934",
                        //             width: 3   //宽度
                        //         },
                        //         yAxis: 400000 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                        //     }]
                        // },
                        connectNulls:true,
                        name:"株高（cm）",
                        data: [],
                        type:'line',
                    },
                    {
                        // markLine:{
                        //     symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
                        //     label:{
                        //         position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
                        //         // formatter:'30cmEC警戒指标',
                        //     },
                        //     data : [{
                        //         silent:false, //鼠标悬停事件 true没有，false有
                        //         lineStyle:{ //警戒线的样式 ，虚实 颜色
                        //             type:"dotted", //样式  ‘solid’和'dotted'
                        //             // color:"#FA3934",
                        //             width: 3   //宽度
                        //         },
                        //         yAxis: 11250 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                        //     }]
                        // },
                        connectNulls:true,
                        name:"匍匐茎长度（cm）",
                        data: [],
                        type:'line',
                    },
                ],

            });
        },
    }
</script>

<style scoped>
h1{
  display: inline-block;
  font-weight: bolder;
  font-size: 24px;
}
</style>