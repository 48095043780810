<template>
  <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
    <a-button type="primary" ghost style="margin-bottom: 16px; width: 100%;" @click="toggleCollapsed">
      <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
    </a-button>
    <a-menu theme="dark" mode="inline" :open-keys="openKeys" @openChange="onOpenChange" :inline-collapsed="collapsed"
      :default-selected-keys="['1']" :default-open-keys="['sub1']">
      <a-menu-item>
        <router-link to="/soil/map-editor"></router-link>
        <a-icon type="home" />
        <span>首页</span>
      </a-menu-item>
      <a-sub-menu key="sub1">
        <span slot="title"><a-icon type="windows" /><span>地块管理</span></span>
        <a-menu-item>
          <router-link to="/soil/map-message"></router-link>
          地块基本信息
        </a-menu-item>
        <a-menu-item>
          <router-link to="/soil/map-editor"></router-link>
          地块地图编辑
        </a-menu-item>
        <!-- <a-menu-item>
          <router-link to="/soil/map-detection"></router-link>
          智慧出苗检测
        </a-menu-item> -->
        <a-menu-item>
          <router-link to="/plot/upload"></router-link>
          地块地图上传
        </a-menu-item>
        <a-menu-item>
          <router-link to="/plot/task"></router-link>
          模型任务指定
        </a-menu-item>
        <a-menu-item>
          <router-link to="/plot/process"></router-link>
          任务总览
        </a-menu-item>
        <a-menu-item>
          <router-link to="/plot/test"></router-link>
          小图测试
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="sub2">
        <span slot="title"><a-icon type="area-chart" /><span>生长指标监控</span></span>
        <a-menu-item>
          <router-link to="/grow/show-temperature-humidity"></router-link>
          温湿度实时监控
        </a-menu-item>
        <a-menu-item>
          <router-link to="/grow/show-temperature-humidity1"></router-link>
          温湿度空气实时监控
        </a-menu-item>
        <a-menu-item>
          <router-link to="/grow/show-soil-water"></router-link>
          土壤及水源指标
        </a-menu-item>
        <a-menu-item>
          <router-link to="/grow/show-nutrition-growth"></router-link>
          营养长势指标
        </a-menu-item>
        <a-menu-item>
          <router-link to="/grow/show-product-quality"></router-link>
          产品及品质指标
        </a-menu-item>
        <a-menu-item>
          <router-link to="/collect/collection-data-get1"></router-link>
          获取所有采集数据
        </a-menu-item>

      </a-sub-menu>
      <a-sub-menu key="sub3">
        <span slot="title"><a-icon type="file-done" /><span>采集管理</span></span>
        <a-menu-item>
          <router-link to="/collect/collect-show"></router-link>
          制定采集计划
        </a-menu-item>

        <a-menu-item>
          <router-link to="/collect/collection-plan-track"></router-link>
          采集计划追踪
        </a-menu-item>
        <a-menu-item>
          <router-link to="/collect/collection-plan-history"></router-link>
          历史采集计划
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="sub4">
        <span slot="title"><a-icon type="poweroff" /> <span>设备管理</span>
        </span>
        <a-menu-item>
          <router-link to="/devices/device-running-status"></router-link>
          设备运行状态
        </a-menu-item>
        <a-menu-item>
          <router-link to="/devices/device-message"></router-link>
          设备基本信息
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="sub5">
        <span slot="title"><a-icon type="table" /> <span>基础信息管理</span> </span>
        <a-menu-item>
          <router-link to="/message/manager-indicator"></router-link>
          指标管理
        </a-menu-item>
        <a-menu-item>
          <router-link to="/message/manager-collect"></router-link>
          采集阶段管理
        </a-menu-item>
        <a-menu-item>
          <router-link to="/message/manager-organ"></router-link>
          组织方管理
        </a-menu-item>
      </a-sub-menu>
      <a-menu-item>
        <router-link to="/pool/model-pool"></router-link>
        <a-icon type="tool" />
        <span>模型池管理</span>
      </a-menu-item>
      <a-sub-menu key="sub6">
        <span slot="title"><a-icon type="setting" /> <span>系统管理</span> </span>
        <a-menu-item>
          <router-link to="/setting/manager-user"></router-link>
          用户管理
        </a-menu-item>
        <a-menu-item>
          <router-link to="/setting/manager-role"></router-link>
          角色管理
        </a-menu-item>
        <!--          <a-menu-item>-->
        <!--            <router-link to="/setting/manager-permission"></router-link>-->
        <!--            权限管理-->
        <!--          </a-menu-item>-->
      </a-sub-menu>
      <a-menu-item>
        <router-link to="/"></router-link>
        <a-icon type="home" />
        <span>退出登录</span>
      </a-menu-item>
    </a-menu>
  </a-layout-sider>
</template>

<script>
export default {
  name: "side-bar",
  props: {
    collapsed: false
  },
  data() {
    return {
      rootSubmenuKeys: ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6',],
      openKeys: [],
    }
  },
  methods: {
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
  }
}
</script>

<style scoped>
#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>
