var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin":"20px 0","margin-top":"50px"}},[_c('a-form',_vm._b({attrs:{"form":_vm.form,"model":_vm.formState,"name":"validate_other"},on:{"submit":_vm.handleSubmit}},'a-form',_vm.formItemLayout,false),[_c('a-form-item',{attrs:{"name":"name","label":"任务名称","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'taskName',
        {
          rules: [
            {
              required: true,
              message: '请输入任务名称！',
            },
          ],
        },
      ]),expression:"[\n        'taskName',\n        {\n          rules: [\n            {\n              required: true,\n              message: '请输入任务名称！',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"任务名称"}})],1),_c('a-form-item',{attrs:{"name":"type","label":"任务类型","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'type',
        {
          rules: [
            { required: true, message: '请输入任务类型！' },
          ],
        },
      ]),expression:"[\n        'type',\n        {\n          rules: [\n            { required: true, message: '请输入任务类型！' },\n          ],\n        },\n      ]"}],ref:"select",staticStyle:{"width":"100%"}},[_c('a-select-option',{attrs:{"value":"fenge"}},[_vm._v("分割任务")]),_c('a-select-option',{attrs:{"value":"shibie"}},[_vm._v("图像识别")]),_c('a-select-option',{attrs:{"value":"ScheduledTasks"}},[_vm._v("定时任务")])],1)],1),_c('a-form-item',{attrs:{"name":"aiModelName","label":"AI模型","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'aiModelName',
        {
          rules: [
            { required: true, message: '请选择AI模型' },
          ],
        },
      ]),expression:"[\n        'aiModelName',\n        {\n          rules: [\n            { required: true, message: '请选择AI模型' },\n          ],\n        },\n      ]"}],ref:"select",staticStyle:{"width":"100%"}},[_c('a-select-option',{attrs:{"value":"","select":"select"}},[_vm._v("不绑定")]),_vm._l((_vm.aiModels),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.modelName}},[_vm._v(" "+_vm._s(item.modelName)+" ")])})],2)],1),_c('a-form-item',{attrs:{"name":"modelName","label":"地块地图","has-feedback":""}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'map',
        {
          rules: [
            { required: true, message: '请选择地块地图！' },
          ],
        },
      ]),expression:"[\n        'map',\n        {\n          rules: [\n            { required: true, message: '请选择地块地图！' },\n          ],\n        },\n      ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"placeholder":"请选择地图","allow-clear":"","tree-default-expand-all":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.maps),function(item,index){return _c('a-tree-select-node',{key:item.id,attrs:{"selectable":false,"value":item.name,"title":item.name}},_vm._l((item.innerData),function(innerItem,index){return _c('a-tree-select-node',{key:innerItem.id,attrs:{"value":innerItem.showDataId,"title":innerItem.cropsRotationPlotName+'——————————'+innerItem.formatTime}})}),1)}),1)],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 6 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("提交")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }