<template>
  <div id="components-form-demo-advanced-search">
    <a-card> <!--使用了antd的Data Display的card组件来整体构建-->
      <a-form layout="inline">
        <a-form-item label="地块名">
          <a-input v-model="plotName" style="width: 150px;" placeholder="输入地块名" />
        </a-form-item>
        <a-form-item label="地块归属方">
          <a-input v-model="plotAffiliation" style="width: 150px;" placeholder="输入归属方" />
        </a-form-item>
        <a-form-item label="建立年份">
          <a-select mode="multiple" v-model="plotYears" style="width: 150px;" placeholder="请选择年份" @change="handleChange">
            <a-select-option v-for="year in this.allYears" :key="year">
              {{ year }}
            </a-select-option>
          </a-select>

        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit" @click="search">
            搜索
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit" @click="showPlotAdd"> <!--地块和地图的新增窗口要区分开-->
            新增
          </a-button>
        </a-form-item>
      </a-form>
      <a-modal v-model="addplot" title="新增地块" @ok="onPlotAdd">
        <!--这个似乎和上面的新增按钮联系起来了-->
        <!--没错，这个是Modal对话框组件，用于弹出一个浮窗来处理事务-->
        <!--当add为true,这个窗口就被显示了，否则则不会出现-->
        <Editplot ref="addPlotEdit" :get-list="showData"></Editplot>
      </a-modal>
    </a-card>

    <!--展示数据的表格，使用了Antd-Data Display-Table-嵌套子表格组件-->
    <a-table :columns="columns" :data-source="dataSource" class="components-table-demo-nested" @change="changePage"
      :pagination="pagination">

      <template slot="date" slot-scope="text,record"> <!--日期转换也交给前台吧，不然后来修改地块时，那个date不是原本样式将无法被正常显示-->
        {{ record.date.substring(0, 4) + "年" + record.date.substring(5, 7) + "月" + record.date.substring(8, 10) + "日" }}
      </template>

      <template slot="operation" slot-scope="text,record">
        <!-- <a-button type="link" style="margin-left:-15px" @click="showMapAdd(record.id, record.name)" title="添加地图">
          <a-icon type="plus" />
        </a-button> -->
        <a-button type="link" style="margin-left:-15px" @click="showPlotUpdate(record)" title="修改地块"> <!--修改地块-->
          <a-icon type="edit" />
        </a-button>
        <a-button type="link" style="margin-left:-15px" @click="onPlotDelete(record)" title="删除地块">
          <a-icon type="delete" />
        </a-button>
      </template>

      <a-table slot="expandedRowRender" slot-scope="record" :columns="innerColumns" :data-source="record.innerData"
        :pagination="false">
        <span slot="date_son" slot-scope="text,record">
          {{ record.formatTime.substring(0, 4) + "年" + record.formatTime.substring(5, 7) + "月" +
            record.formatTime.substring(8, 10) + "日" }}
        </span>

        <span slot="nvdi" slot-scope="text,record">
          <a-icon type="check" style="color: #32CD32" v-if="record.isNvdi == 1" />
          <a-icon type="close" style="color: red" v-if="record.isNvdi == 0" />
        </span>

        <span slot="tiles" slot-scope="text,record">
          <a-icon type="check" style="color: #32CD32" v-if="record.isTiles == 1" />
          <a-icon type="close" style="color: red" v-if="record.isTiles == 0" />
        </span>

        <span slot="upload" slot-scope="text,record">
          <a-icon type="check" style="color: #32CD32" v-if="record.isUpload == 1" />
          <a-icon type="close" style="color: red" v-if="record.isUpload == 0" />
        </span>

        <span slot="operation" slot-scope="text, record" class="table-operation"> <!--slot-scope控制上级传入的参数-->
          <!--          <a href="map-home.vue">进入地图</a>-->
          <a-button type="link" style="margin: 0 10px;" @click="intoMap(record)" title="进入地图">
            <a-icon type="enter" />
          </a-button>
          <!--          <router-link to="/soil/map-soil">进入地图</router-link>-->

          <a-button type="link" style="margin-left:-15px" @click="onMapDelete(record)" title="删除地图">
            <a-icon type="delete" />
          </a-button>

          <a-button type="link" style="margin-left:-15px" @click="startTask(record)" title="模型预测">
            <a-icon type="upload" />
          </a-button>
        </span>
      </a-table>
    </a-table>
    <a-modal v-model="addmap" title="新增地图" @ok="onMapAdd">
      <Editmap ref="addMapEdit" :get-list="showData"></Editmap>
    </a-modal>
    <a-modal v-model="updateplot" title="修改地块" @ok="onPlotUpdate">
      <Editplot ref="updatePlotEdit" :get-list="showData"></Editplot>
    </a-modal>

  </div>
</template>

<script>
import Editplot from "../edits/Editplot";
import Editmap from "../edits/Editmap";
import axios from "axios";

const rowSelection = {                             //行选择？3个方法
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};



export default {                  //这个到底相当于什么，一个对于上面整体<template>的Vue实例吗？ 怎么感觉它什么都能放
  name: "map-message",            //
  components: { Editplot, Editmap },     //这个寓意什么? 似乎就是宣告从别的文件导入了组件，在这里就可以直接用了
  data() {
    return {                      //data()中写的是一个数据模板，是前后端交互承接数据用的
      count: 2,                   //条目数量
      addplot: false,             //控制新增地块页面显示
      addmap: false,              //控制新增地图页面显示
      updateplot: false,          //控制修改地块页面显示
      updatemap: false,           //控制修改地图页面显示
      plotName: '',               //绑定搜索框
      plotAffiliation: '',
      plotYears: undefined,       //想开始完全没有就是undefined
      allYears: ['2020', '2021', '2022'],
      update: false,
      rowSelection,
      expandedRowKeys: [],
      pagination: {                   //对Navigation-Pagination分页组件传参用
        size: "default",
        position: "bottom",           //页码显示所在位置   --- 我要用这些参数控制前端显示
        pageSize: 5,                  //每页条目数量      --- 原来如此，这里不只用于承接前端要显示的数据
        current: 1,                   //当前页码         --- 还要对前端一些需要参数的组件提供参数
        total: 3,                     //总计条目数，应该是根据获取数据来变化的，它变了，分页分了多少页才有根据
        showSizeChanger: false,        //每页条目数量可选择下拉框
        showQuickJumper: true,        //快速访问框
        // pageSizeOptions: ['2', '5', '10', '20', '30']    //下拉框可选量
      },
      dataSource: null,               //数据源对象，后台传给前台的JSON被get后的response的data部分
      loading: false,
      iconLoading: false,

      rotation_plot_id: 12,           //用于子表找到其父表信息
      rotation_plot_name: 'ztf',

      columns: [                     //title是我要显示的名字,dataIndex是后台使用时指明属性含义,key必不可少，与dataIndex保持一致就好
        {
          title: '序号',
          dataIndex: 'order',          //只是序号，并非真实地块id，地块id在这里无须显示
          key: 'order'
        },
        {
          title: '地块名称',
          dataIndex: 'name',
          key: 'name'
          // align:'center',
          // width: '30%'
        },
        {
          title: '地块归属',
          dataIndex: 'affiliation',
          key: 'affiliation'
        },
        {
          title: '地图数量',
          dataIndex: 'amount',
          key: 'amount'
        },
        {
          title: '建立日期',
          dataIndex: 'date',
          key: 'date',
          scopedSlots: { customRender: 'date' }
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      innerColumns: [
        { title: '序号', dataIndex: 'order', key: 'order', align: 'center', },
        { title: '地图名称', dataIndex: 'cropsRotationPlotName', key: 'cropsRotationPlotName' },
        { title: '创建时间', dataIndex: 'formatTime', key: 'formatTime', scopedSlots: { customRender: 'date_son' } },
        { title: '已有数据格式', dataIndex: 'dataFormat', key: 'dataFormat' },
        { title: '任务进度', dataIndex: 'progress', key: 'progress', align: 'center', scopedSlots: { customRender: 'progress' } },
        { title: '具有NVDI数据', dataIndex: 'isNvdi', key: 'isNvdi', align: 'center', scopedSlots: { customRender: 'nvdi' } },
        { title: '已切片', dataIndex: 'isTiles', key: 'isTiles', align: 'center', scopedSlots: { customRender: 'tiles' } },
        { title: '已上传', dataIndex: 'isUpload', key: 'isUpload', align: 'center', scopedSlots: { customRender: 'upload' } },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          align: 'center',
          scopedSlots: { customRender: 'operation' },
        },
      ],
    }
  },
  created() {                   //在Vue实例创建完还未挂载前执行的钩子函数，我似乎知道为什么不是其他时刻了
    this.showData()             //看那个"生命周期图"中，下一个可用时刻已经在"把data里数据和value语法声明的模板编译成可读HTML"之后了
    //而初始页就有的数据也应该是HTML的一部分才对
    //毕竟应该刚进到这个页面就先呈现着数据，所以要在编译之前就搞定
    this.getAllYears()
  },


  methods: {
    intoMap(e) {

      console.log("e is: ", e)
      let _this = this
      let id = e.id;

      if (e.dataFormat == "3D") {//进入3D地图
        //通过e.id获取对应的3d地图的name
        const fd = new FormData()
        fd.append("id", e.id)
        axios.post("/map/get3DNameByImageId", fd).then(res => {
          console.log("res is: ", res)
          _this.$router.push('/three/three-show?name=' + res.data.data[0].url)

        })
      } else {
        _this.$axios.request({
          method: 'GET',
          url: '/cropsSumMap/getSpectrum',
          params: {
            id: e.id
          }
        }).then(res => {
          console.log(res.data.data)
          var data = res.data.data
          var rgb = data[0]
          var green = data[1]
          var nir = data[2]
          var red = data[3]
          var red_edge = data[4]

          localStorage.setItem('rgb', JSON.stringify(rgb))

          _this.$router.push({
            name: 'map-detection',
            params: {
              id: id,
              rgb: rgb,
              green: green,
              nir: nir,
              red: red,
              red_edge: red_edge
            }
          })
        })
      }


    },
    //展示所有页面数据
    showData() {
      let _this = this
      let current = _this.pagination.current
      _this.$axios.get('/cropsSumMap/getListPage', {
        params: {
          pageNum: current,
          plotName: '',
          plotAffiliation: '',
          plotYears: ''
        }
      }).then(res => {

        let dateSource1 = res.data.data.data
        let times = []
        let time = ''
        
        let startOrder = (current - 1) * 5              //计算序号开始位置
        for (let i = 0; i < dateSource1.length; i++)       //前端生成序号
        {
          let data1 = dateSource1                   //好像这里一定要有这一步，否则dataSource1便是会传不进来，似乎是作用域的问题
          data1[i].order = startOrder + i + 1

          for (let j = 0; j < data1[i].innerData.length; j++) {
            let data2 = data1[i].innerData
            if(i === 3){
              time =data1[i].innerData[j].formatTime.substring(0,20)
              time = time.replace('T' ,' ')

              times[j] = time
            }
            data2[j].order = j + 1
          }
        }
        console.log(times)
        this.$root.backgroundTaskResTime = times
        _this.dataSource = dateSource1
        _this.pagination.total = res.data.data.total
        console.log("dataSource is:", _this.dataSource)
      });
    },

    //使用右下角点击切换当前页
    //这个似乎还有问题-就是使用了search后，如果我点击这里，其实应该显示带筛选的，但现在似乎是全部的切换，以后处理
    changePage(page, filters, sorter) {
      let _this = this
      _this.pagination.current = page.current         //我理解应该是这个分页组件封装了这种行为，当我点击其他也，就会返回这样一个page对象
      let current = _this.pagination.current

      let plot_name = _this.plotName
      let plot_affiliation = _this.plotAffiliation
      let plot_years = _this.plotYears
      if (typeof plot_years === 'undefined')     //判断类型是undefined
      {
        plot_years = ''
      }
      else {
        plot_years = plot_years.toString()  //原来这个array可以直接这样转换成逗号分割的字符串-使用这个方法有遇到null的可能
      }

      _this.$axios.get('/cropsSumMap/getListPage', {
        params: {
          pageNum: current,
          plotName: plot_name,
          plotAffiliation: plot_affiliation,
          plotYears: plot_years
        }
      }).then(res => {

        let dateSource1 = res.data.data.data
        let startOrder = (current - 1) * 5              //计算序号开始位置
        for (let i = 0; i < dateSource1.length; i++)       //前端生成序号-但是换了页之后，其实不能再从1开始了，需要有一个计算(目前一页5个已经固定)
        {
          let data1 = dateSource1                   //好像这里一定要有这一步，否则dataSource1便是会传不进来，似乎是作用域的问题
          data1[i].order = startOrder + i + 1

          for (let j = 0; j < data1[i].innerData.length; j++) {
            let data2 = data1[i].innerData
            data2[j].order = j + 1
          }
        }
        _this.dataSource = dateSource1
        _this.pagination.total = res.data.data.total
      });
    },

    //初始化建立年份表
    getAllYears() {
      console.log("getAllYears")
      let _this = this
      _this.$axios.get('/cropsSumMap/getAllYears').then(res => {
        _this.allYears = res.data.data

        //初始化年份搜索框
        // _this.plotYears = _this.allYears[0]     //如果最初没有呢? 那这行可能就有越界风险
      })
    },

    // 新增地块
    showPlotAdd() {
      let _this = this
      _this.addplot = true;        //似乎是和后文onAdd一起触发的，表示一个标志状态? 似乎这样之后就出现了弹窗
    },
    onPlotAdd() {
      let _this = this
      _this.addplot = false;

      _this.$refs.addPlotEdit.onSubmit()   //提交弹窗的内容，$refs似乎就是和Edit组件的ref属性进行联系的，先不管 总之调用了那个组件的onSubmit方法
      _this.showData()                     //再次显示所有数据
    },
    enterLoading() {
      this.loading = true;
    },
    enterIconLoading() {
      this.iconLoading = { delay: 1000 }
    },

    // 修改地块 - 还是会使用和新增一样的组件，无非是这次把内容传过来了而已
    showPlotUpdate(e) {
      let _this = this
      _this.updateplot = true
      _this.$nextTick(() => {          //直接cv过来的，暂时不清楚功效
        console.log(e)
        let e1 = {}                     //这里我要传给plotEdit组件内容，但是只能传原本数据的五部分，不能多传
        e1.id = e.id                    //原本的e是一个对象，里面的键值对也有点对象的意思，使用delete会带来很多问题
        e1.name = e.name                //于是干脆开一个新的对象，只向里面放入我想传入的部分，这样就合理了
        e1.affiliation = e.affiliation
        e1.amount = e.amount
        e1.date = e.date
        _this.$refs.updatePlotEdit.showUpdate(e1)
      })
    },
    onPlotUpdate() {
      let _this = this
      _this.updateplot = false

      console.log("提交状态")
      _this.$nextTick(() => {
        _this.$refs.updatePlotEdit.onUpdate()
      })
    },

    //删除地块
    onPlotDelete(e) {
      let _this = this
      let plot_id = e.id
      console.log(plot_id)

      _this.$confirm({
        title: '确认删除',
        content: h => <div style="color:red;">您确定删除地块数据吗？</div>,
        onOk() {
          _this.$axios.get('/cropsSumMap/deleteplot', {
            params: {
              plotId: plot_id
            }
          }).then(res => {
            console.log("删除成功")
            _this.pagination.current = 1
            _this.showData()
          })
        },
        onCancel() {
          console.log('Cancel');
        }
      })
    },

    //地图新增
    showMapAdd(id, name) {
      let _this = this
      _this.addmap = true
      _this.rotation_plot_id = id         //存储当前所在父表信息
      _this.rotation_plot_name = name
    },
    onMapAdd(e) {
      let _this = this
      _this.addmap = false;

      _this.$refs.addMapEdit.onSubmit(_this.rotation_plot_id, _this.rotation_plot_name)     //似乎refs后面的那个都会对应上面自己给的ref名字
      _this.showData()    //再次显示所有数据
    },
    onMapDelete(e) {
      let _this = this
      let map_id = e.id
      console.log(map_id)

      _this.$confirm({
        title: '确认删除',
        content: h => <div style="color:red;">您确定删除地图数据吗？</div>,
        onOk() {
          console.log("正常删除了")
          _this.$axios.get('/cropsSumMap/deletemap', {
            params: {
              mapId: map_id
            }
          }).then(res => {
            console.log("删除成功")
            _this.pagination.current = 1
            _this.showData()
          })
        },
        onCancel() {
          console.log('Cancel');
        }
      })
    },

    //搜索按钮功能
    search() {
      let _this = this
      _this.pagination.current = 1
      let current = _this.pagination.current

      let plot_name = _this.plotName
      let plot_affiliation = _this.plotAffiliation

      let plot_years = _this.plotYears

      console.log("plot_years =  " + plot_years)

      if (typeof plot_years === 'undefined')     //判断类型是undefined
      {
        plot_years = ''
      }
      else {
        plot_years = plot_years.toString()  //原来这个array可以直接这样转换成逗号分割的字符串-使用这个方法有遇到null的可能
      }

      _this.$axios.get('/cropsSumMap/getListPage', {
        params: {
          pageNum: current,
          plotName: plot_name,
          plotAffiliation: plot_affiliation,
          plotYears: plot_years
        }
      }).then(res => {
        console.log("查询成功")

        let dateSource1 = res.data.data.data
        for (let i = 0; i < dateSource1.length; i++)       //前端生成序号
        {
          let data1 = dateSource1           //好像这里一定要有这一步，否则dataSource1便是会传不进来，似乎是作用域的问题
          data1[i].order = i + 1

          for (let j = 0; j < data1[i].innerData.length; j++) {
            let data2 = data1[i].innerData
            data2[j].order = j + 1
          }
        }
        _this.dataSource = dateSource1
        _this.pagination.total = res.data.data.total
      })
    },
    handleChange(value) {                 //年份多选的配置
      // console.log(`selected ${value}`);
      let _this = this
    },
  }
}
</script>

<style scoped>
.ant-table-tbody>tr>td {
  background: #fff;
}

/*/deep/ .ant-select-selection__rendered ul {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 150px;*/
/*  overflow: hidden;*/
/*  white-space: nowrap;*/
/*}*/

/*/deep/ .ant-select-selection--multiple .ant-select-selection__rendered {*/
/*  height: 12px;*/
/*}*/
</style>
