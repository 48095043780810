<template>
    <el-table
            :data="tableData"
            border
            style="width: 100%">
<!--        <el-table-column-->
<!--                prop="id"-->
<!--                label="模板id"-->
<!--                width="80"-->
<!--        >-->
<!--        </el-table-column>-->
        <el-table-column
                prop="name"
                label="计划名称"
        >
        </el-table-column>
        <el-table-column
                prop="createTime"
                label="创建日期"
        >
        </el-table-column>
        <!-- <el-table-column
                prop="cite"
                label="使用情况"
        >
        </el-table-column> -->
        <el-table-column
                label="操作"
        >
            <template slot-scope="scope">
<!--                <el-button @click="edit(scope.row)" type="text" size="small"><a-icon type="edit" /></el-button>-->
                <el-button @click="showData(scope.row)" type="text" size="small"><a-icon type="edit" /></el-button>
                <!--通过点击事件将本行信息发送到信息修改页面-->
                <a-modal v-model="visible" title="信息修改" @ok="handleOk">
                    <!--通过ref，使得父页面可以向子组件传递信息                    -->
                    <edit ref="editPage" />
                </a-modal>

                <el-button @click="deleted(scope.row)" type="text" size="small"><a-icon type="delete" /></el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
    import edit from './edit/Edit'
    export default {
        components:{edit},
        data() {
            return {
                visible: false,
                tableData: []
            }
        },
        created() {
            this.$axios.get("/templateBasicinfo/findAll").then(res=>{//目标接口
                this.tableData=res.data;
                console.log(this.tableData)
            })
        },
        methods:{
            showData(row) {
                this.visible = true;
                // this.$alert(row)
                this.$nextTick( () => {
                    //通过ref向子组件传递信息
                    this.$refs.editPage.showEditDate(row)
                })
            },
            deleted(row){
                this.$axios.post("/templateDetailinfo1/del",row).then(res=>{
                    console.log(res.data)
                    if(res.data==1)
                    {
                        this.$alert("删除成功",{
                            confirmButtonText: '确定',
                            callback: action => {//删除成功后重新加载一下当前页面
                                window.location.reload()
                            }
                        })
                    }
                    if(res.data==0)
                    {
                        this.$alert("此模板当前正被使用，暂不可删除！",{
                            confirmButtonText: '确定',
                            // callback: action => {//删除成功后重新加载一下当前页面
                            //     window.location.reload()
                            // }
                        })
                    }

                })

            },
            handleOk(e) {//点击弹窗中的“确定”按钮触发的事件

                this.$nextTick( () => {
                    //通过ref向子组件传递信息
                    this.$refs.editPage.updata()
                    this.visible=false
                })
            },
        }
    }
</script>