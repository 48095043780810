<template>
  <div style="height: 100%">
    <div>
      <div id="rightMenu" type="primary" @click="showDrawer">
        <a-icon type="left" style="font-size: 16px"/>
      </div>
      <a-drawer
          title="地图查询"
          placement="right"
          :mask="false"
          :keyboard="true"
          :closable="false"
          :visible="visible"
          :width="400"
          :after-visible-change="afterVisibleChange"
          @close="onClose"
      >
        <SideBar ref="SideBar" id="side-bar" :hide-menu="onClose" :show-bottom="showBottom" :set-center="setCenter"></SideBar>
      </a-drawer>

      <!--      <a-drawer-->
      <!--          title="Basic Drawer"-->
      <!--          placement="bottom"-->
      <!--          :closable="false"-->
      <!--          :visible="bottom"-->
      <!--          :mask="false"-->
      <!--          headerStyle="display: none"-->
      <!--          @close="closeBottom"-->
      <!--      >-->
      <!--        <p>Some contents...</p>-->
      <!--        <p>Some contents...</p>-->
      <!--        <p>Some contents...</p>-->
      <!--      </a-drawer>-->

    </div>

    <div id="allBmap" style="height: 118%"></div>
  </div>

</template>

<script>
import SideBar from './side/side-home'
export default {
  name: "map-home",
  components: {
    SideBar
  },
  data() {
    return {
      bottom: false,
      visible: true,
      center: {
        lng: 115.490899,//115.490899,38.856628
        lat: 38.856628
      },
      dateValue: new Date(),
      zoom: 17,
      BMap: null,
      mapObj: null, // 地图
      positionMaker: {
        name: '地块一',
        soilPosition: {
          lng: 115.490899, lat: 38.856628
        }
      },
      styleOptions: {
        strokeColor: '#5E87DB',   // 边线颜色
        fillColor: '#FFFFFF',     // 填充颜色。当参数为空时，圆形没有填充颜色
        strokeWeight: 2,          // 边线宽度，以像素为单位
        strokeOpacity: 1,         // 边线透明度，取值范围0-1
        fillOpacity: 0.2          // 填充透明度，取值范围0-1
      },
      labelOptions: {
        borderRadius: '2px',
        background: '#FFFBCC',
        border: '1px solid #E1E1E1',
        color: '#703A04',
        fontSize: '12px',
        letterSpacing: '0',
        padding: '5px'
      },
      allMap: false,
      drawingManager:null, //鼠标绘图工具
      show:false,
    }
  },
  watch: {
  },
  created() {
    // this.loadBMapScript();
  },
  mounted() {
    this.initMap();

    this.getMarkers()
    this.getPolyline()
    this.getPolygon()

    // this.showData()
  },
  methods: {
    setCenter(id, point, introduce) {
      if(point == null) {
        // console.log("point is null")
      } else {
        let location = point[0] + ',' + point[1]
        // let introduce = '123123123'
        this.$refs.SideBar.setValue(id, location, introduce)

        this.mapObj.setZoom(17)
        this.mapObj.setCenter(new BMap.Point(point[0], point[1]))
      }
    },
    afterVisibleChange(val) {
      // console.log('visible', val);
    },
    showBottom() {
      this.bottom = true;
    },
    closeBottom() {
      this.bottom = false;
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    initMap() {
      let _this = this
      let map = null

      map = new BMap.Map("allBmap", {
        minZoom: 8,
        maxZoom: 17
      })

      // 初始化地图中心和Zoom层级
      // map.centerAndZoom(new BMap.Point(114.9455555555, 41.422), _this.zoom)
      map.centerAndZoom(new BMap.Point(115.490899,38.856628), _this.zoom)


      // 启动滚轮放大缩小
      map.enableScrollWheelZoom(true)

      // 启动连续缩放效果
      map.enableContinuousZoom(true)

      // 设置地图类型
      map.setMapType(BMAP_HYBRID_MAP)

      // 鱼骨图
      map.addControl(new BMap.NavigationControl())

      // 设置地图空间
      map.addControl(new BMap.MapTypeControl({
        mapTypes: [
          BMAP_NORMAL_MAP,
          BMAP_HYBRID_MAP,
          // BMAP_EARTH_MAP
        ]
      }))

      _this.mapObj = map
    },
    showPhoto() {
      this.$router.push('/soil/map-soil')
    },
    showAllMap() {
      if (this.allMap) {
        document.getElementById('side_bar').style.width = 0 + '%'
        this.allMap = !this.allMap
      } else {
        document.getElementById('side_bar').style.width = 30 + '%'
        this.allMap = !this.allMap
      }
    },
    getMarkers() {
      let _this = this

      _this.$axios.request({
        method: 'POST',
        url: '/soil/getMarkers',
        params: {
          type: 'Marker',
          isBaidu: 1
        }
      }).then(res => {
        // console.log(res)

        var markers = res.data.data
        // console.log(markers)
        // 标注点
        for (var i = 0; i< markers.length; i++)
        {
          var location = JSON.parse(markers[i].location)

          var lng = location[0][0]
          var lat = location[0][1]

          var devType = markers[i].devType
          var markerId = markers[i].id
          // console.log(location)

          var point = new BMap.Point(lng, lat);
          var marker = new BMap.Marker(point);        // 创建标注

          // 添加标注信息
          var content = '名称: ' + markers[i].soilName  + '<br/>介绍: ' + markers[i].soilContent
          _this.addClickHandler(content, 'Marker', marker, markerId, devType)      // 添加左键点击事件
          _this.RightClickHandler(content, marker, markerId);//右键单击marker出现右键菜单事件


          // console.log(marker)
          _this.mapObj.addOverlay(marker);            // 将标注添加到地图中
        }
      })
    },
    getPolyline() {
      let _this = this

      _this.$axios.request({
        method: 'POST',
        url: '/soil/getMarkers',
        params: {
          type: 'Polyline',
          isBaidu: 1
        }
      }).then(res => {
        // console.log(res)

        var markers = res.data.data
        for(var i = 0; i < markers.length; i++) {
          var markarArr = []

          let location = JSON.parse(markers[i].location)

          let devType = markers[i].devType
          let markerId = markers[i].id

          // console.log(location)

          for(var j = 0; j < location.length; j++) {
            let lng = location[j][0]
            let lat = location[j][1]

            var point = new BMap.Point(lng, lat)
            markarArr.push(point)
          }

          // console.log(markarArr)

          var polyline = new BMap.Polyline(markarArr)

          // 添加标注信息
          var content = 'polyline'
          _this.addClickHandler(content, 'Polyline', polyline, markerId, devType)      // 添加左键点击事件
          // _this.RightClickHandler(content, polyline, markerId);//右键单击marker出现右键菜单事件


          _this.mapObj.addOverlay(polyline)
        }
      })
    },
    getPolygon() {
      let _this = this

      _this.$axios.request({
        method: 'POST',
        url: '/soil/getMarkers',
        params: {
          type: 'Polygon',
          isBaidu: 1
        }
      }).then(res => {
        // console.log(res)

        var markers = res.data.data
        for(var i = 0; i < markers.length; i++) {
          var markarArr = []

          let location = JSON.parse(markers[i].location)
          let devType = markers[i].devType
          let markerId = markers[i].id

          // console.log(location)

          for(var j = 0; j < location.length; j++) {
            let lng = location[j][0]
            let lat = location[j][1]

            var point = new BMap.Point(lng, lat)
            markarArr.push(point)
          }

          // console.log(markarArr)

          var polygon = new BMap.Polygon(markarArr, {fillColor: "#FFFFFF", strokeColor:"blue", strokeWeight:2, strokeOpacity:0.5})

          // 添加标注信息
          var content = 'polygon'
          _this.addClickHandler(content, 'Polygon', polygon, markerId, devType)      // 添加左键点击事件
          // _this.RightClickHandler(content, polygon, markerId); //右键单击marker出现右键菜单事件

          _this.mapObj.addOverlay(polygon)
        }
      })
    },
    openInfo(content, marker, e) {
      // console.log(e)

      var _this = this

      var point = new BMap.Point(e.point.lng, e.point.lat)
      // 创建信息窗口对象
      var infoWindow = new BMap.InfoWindow(content, _this.opts);

      infoWindow.addEventListener("close", function () {
        _this.$refs.SideBar.clearValue()
        marker.setFillColor('#FFFFFF')
      })
      //开启信息窗口
      _this.mapObj.openInfoWindow(infoWindow, point)
    },
    setContent(type, temperture, humidity) {
      if (type === 0) {
        return '<p>气象站空气温度：' +
            temperture +
            '</p>' +
            '<p>气象站空气湿度：' +
            humidity +
            '</p>'
      } else if (type === 1) {
        return '<p>土壤仪土壤温度：' +
            temperture +
            '</p>' +
            '<p>土壤仪土壤湿度：' +
            humidity +
            '</p>'
      }
    },
    // 添加点击事件,可在循环数据时调用
    addClickHandler(content, type, marker, markerId, devType) {
      

      var _this = this
      let dev = devType

      marker.addEventListener("click", function (e) {
        console.log(e)

        var location = '['

        if (type.toLowerCase() === 'Marker'.toLowerCase()) {
          var lng = marker.point.lng
          var lat = marker.point.lat
          location += ('[' + lng + ',' + lat + ']]')
          console.log(location)
          // var content = '<p>温度：</p>' +
          //     '<p>湿度：</p>'

          var introduce = undefined

          if (markerId === undefined) {
            console.log(dev)
            _this.openInfo(content,marker, e)
            _this.$refs.SideBar.setValue(markerId, dev, location, introduce, type)
          } else {
            console.log(markerId)
            _this.$axios.request({
              method: 'GET',
              url: '/equipment/listEquipmentByMarkerId',
              params: {
                id: markerId
              }
            }).then( res => {
              // console.log(markerId)
              // console.log(res)
              var content
              var item = res.data.listEquipmentByMarkerId[0]


              if (item.equipmentConditionVO === null) {
                content = _this.setContent(item.equipmentType, '无', '无')
              } else {
                content = _this.setContent(
                    item.equipmentType,
                    item.equipmentConditionVO.temperatureValue / 100,
                    item.equipmentConditionVO.humidityValue / 100)
              }

              _this.openInfo(content, marker, e)
              _this.$refs.SideBar.setValue(markerId, dev, location, introduce, type)
            })
          }

        } else {
          var markers = marker.Bo

          // console.log(marker)

          // var content = '<p>地块说明：</p>'

          for (var i = 0; i < markers.length; i++) {
            var lng = markers[i].lng
            var lat = markers[i].lat
            location += ('[' + lng + ',' + lat + '],')
          }
          location = location.substr(0, location.length-1) + ']'

          var devType = undefined
          var introduce = undefined

          if (marker.z.fillColor.toUpperCase() === "#FFFFFF".toUpperCase()){
            marker.setFillColor('#FFFBCC')

            if(markerId === undefined) {
              // console.log(dev)
              _this.$refs.SideBar.setValue(markerId, dev, location, introduce, type)
            } else {
              var content = ''
              _this.$axios.request({
                method: 'POST',
                url: '/soil/getMarkerById',
                params: {
                  id: markerId
                }
              }).then(res => {
                // console.log(res)
                content = '<p>地块名称：' +
                    res.data.data.soilName +
                    '</p>' +
                    '<p>地块描述：' +
                    res.data.data.soilContent +
                    '</p>'

                if(dev == -1) {
                  dev = 2
                }
                _this.openInfo(content, marker, e)
                _this.$refs.SideBar.setValue(markerId, dev, location, introduce, type)
              })
              // _this.$axios.request({
              //   method: 'GET',
              //   url: '/equipment/listEquipmentByMarkerId',
              //   params: {
              //     id: markerId
              //   }
              // }).then( res => {
              //   // console.log("----------------")
              //   // console.log(res)
              //   // console.log(location)
              //
              //   // var content = '<p>提供方：' +
              //   //     res.data.listEquipmentByMarkerId[0].equipmentProvider +
              //   //     '</p>' +
              //   //     '<p>使用方：' +
              //   //     res.data.listEquipmentByMarkerId[0].equipmentUser +
              //   //     '</p>'
              //
              //
              //   // _this.openInfo(content, marker, e)
              //   // devType = res.data.listEquipmentByMarkerId[0].equipmentType
              //
              // }).catch(error => {
              //   // console.log(error)
              //
              //   var content = ''
              //   _this.$axios.request({
              //     method: 'POST',
              //     url: '/soil/getMarkerById',
              //     params: {
              //       id: markerId
              //     }
              //   }).then(res => {
              //     // console.log(res)
              //     content = '<p>地块名称：' +
              //         res.data.data.soilName +
              //         '</p>' +
              //         '<p>地块描述：' +
              //         res.data.data.soilContent +
              //         '</p>'
              //
              //     _this.openInfo(content, marker, e)
              //
              //     // console.log(type, dev, devType)
              //     if(dev == -1) {
              //       dev = 2
              //     }
              //     // console.log(dev)
              //     _this.$refs.SideBar.setValue(markerId, dev, location, introduce, type)
              //   })
              // })
            }

            // _this.openInfo(content, e)
          } else {
            // marker.setFillColor('#FFFFFF')
          }
        }

        // let location = e.point.lng + ',' + e.point.lat
      });
    },
    RightClickHandler(content, overlay, markerId) {
      // map.getView().setCenter([115.490899,38.856628])
      // map.getView().setZoom(11);
      var _this = this

      //给覆盖物添加右键菜单
      var toMapSoil = function (e, ee, marker) {
        _this.$router.push('/soil/map-soil')
      }

      //创建右键菜单
      var markerMenu=new BMap.ContextMenu();
      // markerMenu.addItem(new BMap.MenuItem('跳转',toMapSoil.bind(overlay)))

      // 标注点
      _this.mapObj.addOverlay(overlay);                     // 将标注添加到地图中
      overlay.addContextMenu(markerMenu);
    },
    overlaycomplete(e) {
      let _this = this
      // console.log(e.overlay)
      _this.mapObj.removeOverlay(e.overlay)

      // console.log(e)
      var type = e.drawingMode
      var overlay = e.overlay
      var content = '123'

      _this.addClickHandler(content, type, overlay)      // 添加左键点击事件

      e.overlay.enableEditing()
      _this.mapObj.addOverlay(e.overlay)
    },
  }
}
</script>

<style>
#rightMenu {
  top: 10%;
  color: #000;
  right: 0;
  width: 1.5%;
  height: 15%;
  z-index: 10;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 12px -1px rgb(0 0 0 / 15%);
}
.BMap_cpyCtrl {
  display: none;
}
.anchorBL {
  display: none;
}
::-webkit-scrollbar {
  display: none;
  /*width: 0;*/
}
</style>
