<template>
  <div style="height: 100%">

    <div>
      <div id="rightMenu" type="primary" @click="showDrawer">
        <a-icon type="left" style="font-size: 16px"/>
      </div>
      <a-drawer
        title="地图功能"
        placement="right"
        :mask="false"
        :keyboard="true"
        :closable="true"
        :visible="visible"
        :after-visible-change="afterVisibleChange"
        @close="onClose"
      >
        <SideBar id="side-bar" :savePolygon="savePolygon" :changeType="changeType" :openDraw="openDraw"
                 :hide-menu="onClose" :set-center="setCenter" :backToMap="backToMap" :changeMap="changeMap"></SideBar>
      </a-drawer>
    </div>

    <div id="photo" style="height: 120%"></div>
    <a-modal
      title="提示"
      v-model="showDetailDialog"
      width="30%"
      @ok="saveDetail"
      :before-close="handleCancel">
      <a-form-model :model="form" label-width="120px">
        <a-form-model-item label="坐标描述">
          <a-input auto-complete="off" v-model="form.remark" placeholder="请输入描述"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<!--<script type="text/javascript" src="../../utils/ol-ext.js"></script>-->

<script>
import SideBar from './side/side-soil'
import proj4 from "proj4";
import 'ol/ol.css'
import '../../assets/style/ol-ext.css'

export default {
    name: "map-soil",
    components: {
        SideBar
    },
    data() {
        return {
            url:'',
            form: {
                remark: ''
            },
            visible: false,
            line: [],
            point: [],
            // point:[327337.1997242978,327322.64899327734],
            makerLayer: [],
            coordinates: [],
            map: '',
            allMap: false,
            dialogVisible: false,
            checkList: [],
            isDisableMove: true,
            drawVector: null,
            mainBar: null,
            isDraw: false,
            nowLayers: 0,
            layer: null,
            tId: this.$route.params.rgb.id,
            tProjection: '',
            tUrl: this.$route.params.rgb.url,
            tRes: JSON.parse(this.$route.params.rgb.resolutions),
            // tRes: this.$route.params.rgb.resolutions,

            tOrigin: JSON.parse(this.$route.params.rgb.origin),
            // tOrigin: this.$route.params.rgb.origin,

            tExtent: JSON.parse(this.$route.params.rgb.extent),
            // tExtent: this.$route.params.rgb.extent,

            tCode: JSON.parse(this.$route.params.rgb.proCode),
            // tCode: this.$route.params.rgb.proCode,

            tPro_extent: JSON.parse(this.$route.params.rgb.proExtent),

            // tPro_extent: this.$route.params.rgb.proExtent,

            tProje_defs: this.$route.params.rgb.projeDefs,
            
            tUrl1:this.$route.params.rgb.cosUrl,

            showDetailDialog: false,
            pointLocation: [],
        }
    },
    mounted() {
        setTimeout(() => {
               
            this.testInit();
            
            // this.addClick();
            this.map.on("click", (evt) => {
                // console.log(evt.getCoordinates())
                var feature = this.map.forEachFeatureAtPixel(evt.pixel,
                    function (feature) {
                        console.log(feature)
                        return feature;
                    });
                if (feature) { //这里说明我们点击的是点标记，
                    let lnglat = feature.get("lnglat");//我们可以通过给点标记传不同的值，来判断点击不同的点标记触发的事件。
                    if (lnglat) {
                        console.log(lnglat)
                    }

                }
            });
            this.loadJson();
            // this.loadPolygon();
        }, 100)     
    },
    methods: {

      


        loadJson() {
          let _this = this
          console.log("开始获取数据")
          let url = this.url
        //   console.log(url)

        //   const formData = new FormData();
        //   formData.append("taskName", values.taskName)


          _this.$axios.request({
            method: 'GET',
            url: 'http://43.143.170.49:8468/map/getTaskRes',
            params: {
                mapCosUrl : this.tUrl1
            }
          }).then((rs) => {
            var points = JSON.parse(rs.data.data[2].taskJson)
            console.log(rs.data.data[2].taskTime)
            localStorage.setItem('time',rs.data.data[2].taskTime)
    
            localStorage.setItem('length',points.length)
            
            var vectorSource = new ol.source.Vector()
            var point = new ol.Feature({
                geometry: new ol.geom.MultiPoint(points)
            })
            vectorSource.addFeature(point)
            var vectorLayer = new ol.layer.Vector({
              source: vectorSource
            })
            _this.map.addLayer(vectorLayer)
          })
        },

        savePolygon() {
            console.log("进入边框保存函数")
            let id = this.tId;

            console.log(id)

            let positions = this.drawVector.getSource().getFeatures().map((s) => {
                let coordinates = s.getGeometry().getCoordinates();
                if (coordinates.length > 0) {
                    if (coordinates[0].length > 0) {
                        return coordinates[0];
                    } else {
                        return coordinates;
                    }
                }
                return [];
            });
            console.log(positions)
            // console.log(this.drawVector.getSource().getFeatures())
            // this.drawVector.getSource().removeFeature()

            let positionList = [];
            for (let i = 0; i < positions.length; i++) {
                if (positions[i].length > 2) {
                    let positionString = '[';
                    for (let j = 0; j < positions[i].length; j++) {
                        if (positions[i][j].length > 1) {
                            positionString = positionString + '[' + positions[i][j][0] + ',' + positions[i][j][1] + '], ';
                        }
                    }
                    positionString = positionString.substring(0, positionString.length - 2)
                    positionString += ']';
                    let data = {
                        type: "Polygon",
                        location: positionString,
                        mapId: id,
                    };
                    if (positionString !== "[]") {
                        positionList.push(data)
                    }
                } else {
                    let data = {
                        type: "Point",
                        location: "[" + positions[i][0] + "," + positions[i][1] + "]",
                        mapId: id,
                    };
                    positionList.push(data)
                }
            }
            let _this = this;
            console.log(positionList)

            _this.$confirm({
                title: '确认保存',
                content: h => '<div style = "color:red;" > 您确定保存当前页面标注信息吗？</div>',
                onOk()
            {
                _this.$axios.request({
                    method: 'POST',
                    url: 'makers-photo/addMarker',
                    data: {
                        makers: positionList
                    },
                    params: {
                        mapId: id
                    }
                }).then(res => {
                    // console.log(id)
                    _this.loadPolygon()
                })
                // _this.loadPolygon();   
            }
        ,
            onCancel()
            {
                // console.log('Cancel');
            }
        })
        },
        setCenter(point) {
            console.log("进入设置中心点操作", point)
           
            this.mapObj.setZoom(12)
            this.mapObj.setCenter(new BMap.Point(point[0], point[1]))
            
        },
        afterVisibleChange(val) {
            console.log('visible', val);
        },
        showDrawer() {
            this.visible = true;
        },
        onClose() {
            this.visible = false;
        },
        loadPolygon() {
            let _this = this;

            var id = _this.tId
            localStorage.setItem('id',id)
            // this.drawVector.getSource().removeFeature(this.drawVector.getSource().getFeatures())
            this.$axios.request({
                method: 'POST',
                url: 'makers-photo/getPolygon',
                params: {
                    type: 'Polygon',
                    mapId: id
                }
            }).then((res) => {
                if (res.data.code === 1200) {
                    const result = res.data.data;
                    _this.line = [];
                    _this.point = [];
                    for (let i = 0; i < result.length; i++) {
                        if (result[i].type === 'Polygon') {
                            _this.line.push(result[i].location)
                            
                        } else if (result[i].type === 'Point') {line
                            _this.point.push(result[i].point)
                        }
                    }
                }
                // console.log(this.drawVector.getSource().getFeatures())
                
                
                console.log("进行坐标数据存储到本地")
                // localStorage.setItem('localtions',JSON.stringify(this.line))
                localStorage.setItem('localtionAllNumber',this.line.length)
                localStorage.setItem('localtions',JSON.stringify(this.line[0]))
                // localStorage.setItem('localtionAll',this.line)
   

                this.drawVector.getSource().clear()
                for (let i = 0; i < this.line.length; i++) {
                    // this.drawVector.getSource().removeFeature()
                    const polygon = new ol.geom.Polygon([this.line[i]]);
                    let feature = new ol.Feature(polygon);
                    this.drawVector.getSource().addFeature(feature);
                }
                for (let i = 0; i < this.point.length; i++) {
                    const point = new ol.geom.Point(this.point[i]);
                    let feature = new ol.Feature(point);
                    this.drawVector.getSource().addFeature(feature);
                }
                // console.log(this.drawVector.getSource().getFeatures())
            })
        },
        testInit() {
            // console.log(this.tRes)

            let tUrl =this.tUrl
            let tRes =  this.tRes 
            let tOrigin = this.tOrigin
            let tExtent = this.tExtent 
            let tCode = this.tCode
            let tPro_extent = this.tPro_extent
            let tProje_defs  = this.tProje_defs



                this.tUrl = tUrl
                this.tRes = tRes
                this.tOrigin = tOrigin
                this.tExtent = tExtent
                this.tCode = tCode
                this.tPro_extent = tPro_extent
                this.tProje_defs = tProje_defs

            // }
            //进行交换
            var temp = tExtent[1] 
            tExtent[1]  = tExtent[2]
            tExtent[2] = temp

            // console.log(tUrl)
            const _this = this
            let tileGrid = new ol.tilegrid.TileGrid({
                tileSize: [512,512],
                origin: tOrigin,
                resolutions: tRes,
                extent: tExtent
            });

            let code = 'EPSG:' + tCode
            proj4.defs(code, tProje_defs);
            let tProjection = new ol.proj.Projection({
                code: code,
                extent: tPro_extent,
                units: 'm'
            });
            let layers = new ol.layer.Tile({
                source: new ol.source.XYZ({
                    projection: tProjection,
                    tileGrid: tileGrid,
                    tileUrlFunction: function (coordinate) {
                        let x, y, z;
                        let url = tUrl
                        // x = _this.zeroPad(coordinate[1], 8, 16)
                        // y = _this.zeroPad(coordinate[2], 8, 16)
                        // z = _this.zeroPad(coordinate[0], 2, 10)
                        x = coordinate[1]
                        y = coordinate[2]
                        z = coordinate[0]

                        // console.log(coordinate)
                        // console.log(url + 'L' + z + '/R' + y + '/C' + x + '.png')
                        // console.log("图像url")
                        this.url = `${ url }/${ z }/${ x }/${ y }.png` 
                        // console.log(this.url)
                        // return `https://gengyun-1304940588.cos.ap-nanjing.myqcloud.com/a0714/a0714/_alllayers/L${z}/R${y}/C${x}.png`
                        return `${ url }/${ z }/${ x }/${ y }.png`                    
                        
                    }
                })
            });
            this.loadJson(this.url);

            var centers = ol.extent.getCenter(tExtent)
            this.layer = layers
            console.log(layers)
            _this.map = new ol.Map({
                layers: [layers],
                view: new ol.View({
                    center: centers,
                    resolutions: tRes,
                    resolution: tRes[19],
                    projection: tProjection,
                    // minZoom: 18,
                    extent: tExtent
                }),

                // interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
                // interactions: defaultInteractions({ dragPan: true }).extend([new DragRotateAndZoom()]),
                controls: ol.control.defaults({ "attribution": false }),
                target: 'photo'
            });
            
            this.edit();
            
        },

        
        zeroPad(num, len, radix) {
            let str = num.toString(radix || 10);
            while (str.length < len) {
                str = '0' + str
            }
            return str
        },
        changeType(type) {
            let _this = this

            // console.log(_this.$route.params)
            if (type.toLowerCase() === 'rgb') {
                _this.tId = _this.$route.params.rgb.id
                _this.tUrl = _this.$route.params.rgb.url
                _this.tRes = JSON.parse(_this.$route.params.rgb.resolutions)
                _this.tOrigin = JSON.parse(_this.$route.params.rgb.origin)
                _this.tExtent = JSON.parse(_this.$route.params.rgb.extent)
                _this.tCode = JSON.parse(_this.$route.params.rgb.proCode)
                _this.tPro_extent = JSON.parse(_this.$route.params.rgb.proExtent)
                _this.tProje_defs = _this.$route.params.rgb.projeDefs
            } else if (type.toLowerCase() === 'green') {
                _this.tId = _this.$route.params.green.id
                _this.tUrl = _this.$route.params.green.url
                _this.tRes = JSON.parse(_this.$route.params.green.resolutions)
                _this.tOrigin = JSON.parse(_this.$route.params.green.origin)
                _this.tExtent = JSON.parse(_this.$route.params.green.extent)
                _this.tCode = JSON.parse(_this.$route.params.green.proCode)
                _this.tPro_extent = JSON.parse(_this.$route.params.green.proExtent)
                _this.tProje_defs = _this.$route.params.green.projeDefs
            } else if (type.toLowerCase() === 'nir') {
                _this.tId = _this.$route.params.nir.id
                _this.tUrl = this.$route.params.nir.url
                _this.tRes = JSON.parse(this.$route.params.nir.resolutions)
                _this.tOrigin = JSON.parse(this.$route.params.nir.origin)
                _this.tExtent = JSON.parse(this.$route.params.nir.extent)
                _this.tCode = JSON.parse(this.$route.params.nir.proCode)
                _this.tPro_extent = JSON.parse(this.$route.params.nir.proExtent)
                _this.tProje_defs = this.$route.params.nir.projeDefs
            } else if (type.toLowerCase() === 'red') {
                _this.tId = _this.$route.params.red.id
                _this.tUrl = this.$route.params.red.url
                _this.tRes = JSON.parse(this.$route.params.red.resolutions)
                _this.tOrigin = JSON.parse(this.$route.params.red.origin)
                _this.tExtent = JSON.parse(this.$route.params.red.extent)
                _this.tCode = JSON.parse(this.$route.params.red.proCode)
                _this.tPro_extent = JSON.parse(this.$route.params.red.proExtent)
                _this.tProje_defs = this.$route.params.red.projeDefs
            } else if (type.toLowerCase() === 'red_edge') {
                _this.tId = _this.$route.params.red_edge.id
                _this.tUrl = this.$route.params.red_edge.url
                _this.tRes = JSON.parse(this.$route.params.red_edge.resolutions)
                _this.tOrigin = JSON.parse(this.$route.params.red_edge.origin)
                _this.tExtent = JSON.parse(this.$route.params.red_edge.extent)
                _this.tCode = JSON.parse(this.$route.params.red_edge.proCode)
                _this.tPro_extent = JSON.parse(this.$route.params.red_edge.proExtent)
                _this.tProje_defs = this.$route.params.red_edge.projeDefs
            }

            let tileGrid = new ol.tilegrid.TileGrid({
                tileSize: 256,
                origin: _this.tOrigin,
                resolutions: _this.tRes,
                extent: _this.tExtent
            });

            let code = 'EPSG:' + _this.tCode
            proj4.defs(code, _this.tProje_defs);
            this.tProjection = new ol.proj.Projection({
                code: code,
                extent: _this.tPro_extent,
                units: 'm'
            });

            let layers = new ol.layer.Tile({
                source: new ol.source.XYZ({
                    projection: _this.tProjection,
                    tileGrid: tileGrid,
                    tileUrlFunction: function (tileCoord, pixelRatio, proj) {
                        let x, y, z;

                        let url = _this.tUrl

                        x = _this.zeroPad(tileCoord[1], 8, 16)
                        y = _this.zeroPad(tileCoord[2], 8, 16)
                        z = _this.zeroPad(tileCoord[0], 2, 10)

                        return `${ url }L${ z }/R${ y }/C${ x }.png`
                    }
                })
            });

            var centers = ol.extent.getCenter(_this.tExtent)

            // _this.map.changeLayer(layers)
            // console.log(_this.map.getFeatures())
            _this.map.removeLayer(this.layer);
            _this.map.removeLayer(this.drawVector);
            _this.map.addLayer(layers)
            this.layer = layers

            _this.map.setView(new ol.View({
                center: centers,
                resolutions: _this.tRes,
                resolution: _this.tRes[4],
                extent: _this.tExtent,
                projection: _this.tProjection
            }));
            this.edit();
            this.loadPolygon();
        },

       

        openDraw() {
            this.isDraw = !this.isDraw;
              this.mainBar.setVisible(this.isDraw);
              if (this.isDraw) {
                  this.map.addLayer(this.drawVector);
                  this.map.addControl(this.mainBar);
              } else {
                  this.map.removeLayer(this.drawVector);
                  this.map.removeControl(this.mainBar)
              }
        },
        showDetail() {
            let id = this.tId;
            let location = "[" + this.pointLocation[0] + "," + this.pointLocation[1] + "]";
            let _this = this;
            let dataForm = new FormData();
            dataForm.append('location', location);
            dataForm.append('mapId', id);
            _this.$axios.request({
                method: 'POST',
                url: 'makers-photo/getDetail',
                data: dataForm,
            }).then(res => {
                // console.log(id)
                _this.loadPolygon()
            })
        },
        edit() {
            let vector = new ol.layer.Vector({
                name: 'Vecteur',
                source: new ol.source.Vector()
            });
            let mainBar = new ol.control.Bar();
            this.map.addControl(mainBar);

            let editBar = new ol.control.Bar({
                toggleOne: true,	// one control active at the same time
                group: false			// group controls together
            });
            mainBar.addControl(editBar);

            let _this = this;

            let sonBar = new ol.control.Bar();
            sonBar.addControl(new ol.control.Button({
                html: '<i class="fa fa-times"></i>',
                title: "Delete",
                handleClick: function () {
                    let features = selectCtrl.getInteraction().getFeatures();
                    if (!features.getLength()) console.log("Select an object first...");
                    else console.log(features.getLength() + " object(s) deleted.");
                    for (let i = 0, f; f = features.item(i); i++) {
                        vector.getSource().removeFeature(f);
                    }
                    selectCtrl.getInteraction().getFeatures().clear();
                }
            }));
            sonBar.addControl(new ol.control.Button({
                html: '<i class="fa fa-info"></i>',
                title: "Show informations",
                handleClick: function () {
                    switch (selectCtrl.getInteraction().getFeatures().getLength()) {
                        case 0:
                            console.log("Select an object first...");
                            break;
                        case 1:
                            let f = selectCtrl.getInteraction().getFeatures().item(0);
                            // console.log("Selection is a "+f.getGeometry().getType());
                            // console.log(f.getGeometry().getCoordinates());
                            _this.showDetailDialog = true;
                            _this.pointLocation = f.getGeometry().getCoordinates();
                            _this.showDetail();
                            break;
                        default:
                            console.log(selectCtrl.getInteraction().getFeatures().getLength() + " objects seleted.");
                            break;
                    }
                }
            }));

            let selectCtrl = new ol.control.Toggle({
                html: '<i class="fa fa-hand-pointer-o"></i>',
                title: "Select",
                interaction: new ol.interaction.Select({ hitTolerance: 2 }),
                bar: sonBar,
                autoActivate: true,
                active: true
            });

            editBar.addControl(selectCtrl);

            // Add editing tools
            let pointEdit = new ol.control.Toggle({
                html: '<i class="fa fa-map-marker" ></i>',
                title: 'Point',
                interaction: new ol.interaction.Draw({
                    type: 'Point',
                    source: vector.getSource()
                })
            });
            editBar.addControl(pointEdit);

            let polygonEdit = new ol.control.Toggle({
                html: '<i class="fa fa-bookmark-o fa-rotate-270" ></i>',
                title: 'Polygon',
                interaction: new ol.interaction.Draw({
                    type: 'Polygon',
                    source: vector.getSource(),
                    // Count inserted points
                    geometryFunction: function (coordinates, geometry) {
                        this.nbpts = coordinates[0].length;
                        if (geometry) geometry.setCoordinates([coordinates[0].concat([coordinates[0][0]])]);
                        else geometry = new ol.geom.Polygon(coordinates);
                        return geometry;
                    }
                }),
                bar: new ol.control.Bar({
                    controls: [new ol.control.TextButton({
                        html: '取消',//'<i class="fa fa-mail-reply"></i>',
                        title: "取消",
                        handleClick: function () {
                            if (polygonEdit.getInteraction().nbpts > 1) polygonEdit.getInteraction().removeLastPoint();
                        }
                    }),
                        new ol.control.TextButton({
                            html: '结束',
                            title: "结束",
                            handleClick: function () {
                                // Prevent null objects on finishDrawing
                                if (polygonEdit.getInteraction().nbpts > 3) polygonEdit.getInteraction().finishDrawing();
                            }
                        })
                    ]
                })                   
            });
            editBar.addControl(polygonEdit);

            mainBar.setVisible(this.isDraw);
            this.map.addLayer(vector);
            this.drawVector = vector;
            this.mainBar = mainBar;
        },
        addPoint(lineElement) {
            let _this = this;
            let makerLayer = new ol.layer.Vector({
                source: null,
                style: null,
                zIndex: 11
            });
            makerLayer.setSource(null);
            let feature = new ol.Feature({
                geometry: new ol.geom.Point(lineElement)
            });
            let source = new ol.source.Vector();
            source.addFeatures([feature]);
            makerLayer.setSource(source)
            let style = new ol.style.Style({
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0055ff'
                    })
                }),
                text: new ol.style.Text(({
                    textAlign: 'center',
                    textBaseline: 'bottom',
                    font: 'bold 12px 微软雅黑',
                    text: '点标注' + _this.makerLayer.length,
                    offsetY: 30,
                    fill: new ol.style.Fill({ color: '#587de5' }),
                    stroke: new ol.style.Stroke({ color: '#ffcc33', width: 10 })
                }))
            });
            makerLayer.setStyle(style);
            this.makerLayer.push(makerLayer);
            this.map.addLayer(makerLayer)
        },
        getCenter() {
            const line = this.line;
            const lineLength = line.length;
            let maxLat = -1;
            let minLat = 10000;
            let maxLon = -1;
            let minLon = 10000;
            for (let i = 0; i < lineLength; i++) {
                if (line[i][0] < minLon) {
                    minLon = line[i][0]
                }
                if (line[i][0] > maxLon) {
                    maxLon = line[i][0]
                }
                if (line[i][1] < minLat) {
                    minLat = line[i][1]
                }
                if (line[i][1] > maxLat) {
                    maxLat = line[i][1]
                }
            }
            const middleLat = (maxLat + minLat) / 2;
            const middleLon = (maxLon + minLon) / 2;
            console.log("middleLat = " + middleLat + " middleLon = " + middleLon)
            return { middleLon, middleLat }
        },
        backToMap() {
            this.$router.go(-1)
        },
        showAllMap() {
            if (this.allMap) {
                document.getElementById('side_bar').style.width = 0 + '%'
                this.allMap = !this.allMap
            } else {
                document.getElementById('side_bar').style.width = 30 + '%'
                this.allMap = !this.allMap
            }
        },
        handleCancel() {
            this.showDetailDialog = false;
            this.form.remark = ''
        },
        saveDetail() {
            let _this = this;
            let id = this.tId;
            let formData = new FormData();
            formData.append("type",'Point');
            formData.append("location","[" + this.pointLocation[0] + "," + this.pointLocation[1] + "]");
            formData.append("mapId",id);
            formData.append("remark",_this.form.remark);
            _this.$axios.request({
                method: 'post',
                url: 'makers-photo/saveDetail',
                data: formData
            }).then(res => {
                // console.log(id)
                if (res.data.code === 1200){
                    _this.$message.success('上传成功');
                    _this.showDetailDialog = false;
                    _this.form.remark = ''
                }
                _this.loadPolygon()

            })
        },
        drag() {
            // let document = document.getElementById("photo");
            // console.log(document.getElementById("photo"))
            this.map.addEventListener("dragstart", function (event) {
                console.log(event)
            });
            this.map.addEventListener("drag", function (event) {
                console.log(event)
            });
        },

       
    }
}
</script>

<style scoped>

</style>
