<template>
    <div style="height: 100%">

        <div >
            <div id="rightMenu" type="primary" @click="showDrawer">
                <a-icon type="left" style="font-size: 16px" />
            </div>
            <a-drawer
                title="地图功能"
                placement="right"
                :mask="false"
                :keyboard="true"
                :closable="false"
                :visible="visible"
                :width="400"
                :after-visible-change="afterVisibleChange"
                @close="onClose"
              >
                <SideBar id="side-bar" :savePolygon="savePolygon" :changeType="changeType" :openDraw="openDraw"
                    :hide-menu="onClose" :set-center="setCenter" :backToMap="backToMap" ></SideBar>
            </a-drawer>
            
        </div>
        

        <div id="photo" style="height: 118%;"></div>
        <a-modal title="提示" v-model="showDetailDialog" width="30%" @ok="saveDetail" :before-close="handleCancel">
            <a-form-model :model="form" label-width="120px">
                <a-form-model-item label="坐标描述">
                    <a-input auto-complete="off" v-model="form.remark" placeholder="请输入描述" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
  <!-- <script type="text/javascript" src="../../utils/ol-ext.js"></script> -->
  
<script>
import SideBar from './side/side-soil'
import proj4 from "proj4";
import 'ol/ol.css'
import '../../assets/style/ol-ext.css'
import axios from 'axios'

export default {
    name: "map-soil",
    components: {
        SideBar
    },
    data() {
        return {
            taskId1 :0,
            flag : true,
            form: {
                remark: ''
            },
            visible: false,
            line: [],
            point: [],
            makerLayer: [],
            coordinates: [],
            map: '',
            allMap: false,
            dialogVisible: false,
            checkList: [],
            isDisableMove: true,
            drawVector: null,
            mainBar: null,
            isDraw: false,
            nowLayers: 0,
            layer: null,
            tProjection: '',

            tId: JSON.parse(localStorage.getItem('rgb')).id,
            tProjection: '',
            tUrl: JSON.parse(localStorage.getItem('rgb')).url,
            tRes: JSON.parse(JSON.parse(localStorage.getItem('rgb')).resolutions),
            // tRes: this.$route.params.rgb.resolutions,

            tOrigin: JSON.parse(JSON.parse(localStorage.getItem('rgb')).origin),
            // tOrigin: this.$route.params.rgb.origin,

            tExtent: JSON.parse(JSON.parse(localStorage.getItem('rgb')).extent),
            // tExtent: this.$route.params.rgb.extent,

            tCode: JSON.parse(JSON.parse(localStorage.getItem('rgb')).proCode),
            // tCode: this.$route.params.rgb.proCode,

            tPro_extent: JSON.parse(JSON.parse(localStorage.getItem('rgb')).proExtent),

            // tPro_extent: this.$route.params.rgb.proExtent,

            tProje_defs: JSON.parse(localStorage.getItem('rgb')).projeDefs,

            tUrl1: JSON.parse(localStorage.getItem('rgb')).cosUrl,

            showDetailDialog: false,
            pointLocation: [],
            isSeclect:-1,
            vectorLayer:{},
            regionLayer: {},
            res_json:'1',
            jsonFlag: false
        }
    },
    mounted () {
       
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '/ol-ext.min.js';
        document.body.appendChild(script);

        // console.log('active')
        setTimeout(() => {
            this.testInit();
            this.loadJson(this.tUrl1);
            this.loadPolygon();
            // this.loadErrPolygon();
            this.map.on("click", (evt) => {
                // console.log(evt.getCoordinates())
                var feature = this.map.forEachFeatureAtPixel(evt.pixel,
                    function (feature) {
                        // console.log(feature)
                        return feature;
                    });
                if (feature) { //这里说明我们点击的是点标记，
                    let lnglat = feature.get("lnglat");//我们可以通过给点标记传不同的值，来判断点击不同的点标记触发的事件。
                    if (lnglat) {
                        // console.log(lnglat)
                    }

                }
            });
            var _this = this
            _this.map.getView().on('change:resolution',function (e) {
                var zoom = _this.map.getView().getZoom();
                if ( Math.floor(zoom) === zoom){
                    if (zoom >= 21){
                        
                        if (_this.jsonFlag == false && _this.$root.backgroundJSON == 0) {
                            // if(_this.$root.backgroundJSON == 0){
                                _this.addJsonLayer(_this.vectorLayer)
                                _this.jsonFlag = true
                            // }
                        }
                    } else {
                        console.log('获取到小范围，取消获取图层')
                        if (_this.jsonFlag == true) {
                            _this.removeJsonLayer(_this.vectorLayer)
                            _this.jsonFlag = false
                        }
                    }
                }
            });
            this.loadErrPolygon();
        }, 100)
        
    },
    watch:{
        '$root.backgroundSelect':{
            handler(){
                this.loadPolygon()
            },
            deep: true
        },
        '$root.backgroundJSON':{
            handler(){
                let _this = this
                // console.log(_this.$root.backgroundJSON )
                if(_this.$root.backgroundJSON == 1){//部分数据
                    // this.flag = false
                    console.log("进行新数据的获取")
                    this.loadJson1()//新数据获取
                    
                }else if (_this.$root.backgroundJSON == 0){
                    console.log("全图展示，去除部分数据图层展示")
                    this.map.removeLayer(_this.regionLayer)
                }
               
            },
            deep: true
        },
    },
    methods: {
        async addJsonLayer(layer) {
        　　var _this = this
            _this.map.addLayer(layer)
        },
        async removeJsonLayer(layer) {
            var _this = this
            _this.map.removeLayer(layer)
        },
        //加载苗识别数据
        loadJson(url) {
            // console.log("进入点数据获取阶段，获取url如下")
            // console.log(url)
            let _this = this
            _this.$axios.request({
                method: 'GET',
                url: 'http://43.143.170.49:8468/map/getTaskRes',
                params: {
                    mapCosUrl: url
                }
            }).then((res1) => {
                var len = res1.data.data.length
                // console.log(res1.data.data[len-1].taskJson)

                
                _this.$axios.request({
                        method: 'GET',
                        url: res1.data.data[0].taskJson
                    }).then((rs) =>{
                        // console.log(rs)
                        // console.log(rs.data.length)
                       
                        var points = rs.data
                        // console.log(rs.data)
                        var taskId = res1.data.data[len-1].taskId
                        this.taskId1 = taskId
                        this.$root.backgroundTaskId = this.taskId1
                        // console
                        // if(this.$root.backgroundTaskId.includes(taskId)){//添加任务id
                        //     console.log("已经存在这个任务id，不需要再次添加")
                        // }else{
                        //     this.$root.backgroundTaskId.push(taskId)
                        // }   
                        this.$root.backgroundTaskLen = points.length//获取出苗数
                        var vectorSource = new ol.source.Vector()
                        var point = new ol.Feature({
                            geometry: new ol.geom.MultiPoint(points)
                        })
                        vectorSource.addFeature(point)
                        _this.vectorLayer = new ol.layer.Vector({
                            source: vectorSource
                        })
                        // console.log('-------------------------------')
                        // console.log(_this.vectorLayer)
                        // _this.map.addLayer(this.vectorLayer)
                        // console.log('-------------------------------')
                    })

            })
            

        },
        loadJson1(){//将获取到的数据传递到这里重新进行加载，不进行上面全部数据的加载
            // console.log(JSON.stringify(this.line))
            let _this = this
            const formData = new FormData();
            
            let line1 = []
            for(let i = 0; i< this.line.length; i++){
                line1[i] = [this.line[i]]
            }
            // console.log(JSON.stringify(line1))
            formData.append("points", JSON.stringify(line1))
            formData.append("taskId", this.taskId1)
            axios.post("http://43.143.170.49:8466/api/taskDetection/innerPoint", formData).then(rs => {
                console.log("rs", rs)
                // console.log(rs.data.data.length)
                // console.log(rs.data.data)
                _this.removeJsonLayer(this.regionLayer)//删除现有的坐标图层
                var points = rs.data.data
                
                var vectorSource = new ol.source.Vector()
                var point = new ol.Feature({
                    geometry: new ol.geom.MultiPoint(points)
                })
                vectorSource.addFeature(point)
                _this.regionLayer = new ol.layer.Vector({
                    source: vectorSource
                })
                // console.log('-------------------------------')
                // console.log(_this.regionLayer)
                // _this.map.addLayer(this.vectorLayer)
                // console.log('-------------------------------')
                _this.map.addLayer(_this.regionLayer)


            })



            
        },
        
        //保存方框数组
        savePolygon() {
            let id = this.tId;

            // console.log(id)

            let positions = this.drawVector.getSource().getFeatures().map((s) => {
                let coordinates = s.getGeometry().getCoordinates();
                if (coordinates.length > 0) {
                    if (coordinates[0].length > 0) {
                        return coordinates[0];
                    } else {
                        return coordinates;
                    }
                }
                return [];
            });
            // console.log(this.drawVector.getSource().getFeatures())
            // this.drawVector.getSource().removeFeature()

            let positionList = [];
            for (let i = 0; i < positions.length; i++) {
                if (positions[i].length > 2) {
                    let positionString = '[';
                    for (let j = 0; j < positions[i].length; j++) {
                        if (positions[i][j].length > 1) {
                            positionString = positionString + '[' + positions[i][j][0] + ',' + positions[i][j][1] + '], ';
                        }
                    }
                    positionString = positionString.substring(0, positionString.length - 2)
                    positionString += ']';
                    let data = {
                        type: "Polygon",
                        location: positionString,
                        mapId: 215,
                        // remark: this.form.remark
                        remark:"自定义地块"+i
                    };
                    if (positionString !== "[]") {
                        positionList.push(data)
                    }
                } else {
                    let data = {
                        type: "Point",
                        location: "[" + positions[i][0] + "," + positions[i][1] + "]",
                        mapId: 215,
                    };
                    positionList.push(data)
                }
            }
            let _this = this;
            // console.log(positionList)

            _this.$confirm({
                title: '确认保存',
                content: h => ' 您确定保存当前页面标注信息吗？',
                onOk() {
                    console.log(JSON.stringify(positionList)),
                    _this.$axios.request({
                       
                        method: 'POST',
                        url: 'makers-photo/addMarker',
                        data: {
                            makers: positionList
                        },
                        params: {
                            mapId: 215
                        }
                    }).then(res => {
                        // console.log(id)
                        _this.loadPolygon()
                        location.reload()
                    })
                    // _this.loadPolygon();
                }
                ,
                onCancel() {
                    // console.log('Cancel'); 
                }
            })
        },
        setCenter(point) {
            if (point == null) {
                console.log("point is null")
            } else {
                var _this = this
                _this.map.getView().setCenter(point)
            }
        },
        afterVisibleChange(val) {
            console.log('visible', val);
        },
        showDrawer() {
            this.visible = true;
        },
        onClose() {
            this.visible = false;
        },
        //新需求，异常地块的记录
        loadErrPolygon() {
            var that = this
            var _this = this
            _this.line = [];
            _this.point = [];
            const result =  [{
                "id": 3417,
                "type": "Polygon",
                "location": [
                    [
                        114.9237896295252,
                        41.41921564945931
                    ],
                    [
                        114.9237896295252,
                        41.41921564945931
                    ],
                    [
                        114.9239007032952,
                        41.41917129449747
                    ],
                    [
                        114.92381832979464,
                        41.418967038034516
                    ],
                    [
                        114.92377695667896,
                        41.41897859268845
                    ],
                    [
                        114.9237672656789,
                        41.418958092496
                    ],
                    [
                        114.92373632902483,
                        41.418973374457636
                    ],
                    [
                        114.92374378364028,
                        41.41900095653475
                    ],
                    [
                        114.92370725602464,
                        41.419013956650224
                    ],
                    [
                        114.9237896295252,
                        41.41921564945931
                    ]
                ],
                "point": null,
                "mapId": "215", 
                "remark": "自定义地块3"
            },
            {
            "id": 3421,
            "type": "Polygon",
            "location": [
                [
                    114.9237896295252,
                    41.41921564945931
                ],
                [
                    114.9237896295252,
                    41.41921564945931
                ],
                [
                    114.9239007032952,
                    41.41917129449747
                ],
                [
                    114.92381832979464,
                    41.418967038034516
                ],
                [
                    114.92377695667896,
                    41.41897859268845
                ],
                [
                    114.9237672656789,
                    41.418958092496
                ],
                [
                    114.92373632902483,
                    41.418973374457636
                ],
                [
                    114.92374378364028,
                    41.41900095653475
                ],
                [
                    114.92370725602464,
                    41.419013956650225
                ],
                [
                    114.9237896295252,
                    41.41921564945931
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块0"
        },
        {
            "id": 3422,
            "type": "Polygon",
            "location": [
                [
                    114.9237896295252,
                    41.41921564945931
                ],
                [
                    114.9237896295252,
                    41.41921564945931
                ],
                [
                    114.9239007032952,
                    41.41917129449747
                ],
                [
                    114.92381832979464,
                    41.418967038034516
                ],
                [
                    114.92377695667896,
                    41.41897859268845
                ],
                [
                    114.9237672656789,
                    41.418958092496
                ],
                [
                    114.92373632902483,
                    41.418973374457636
                ],
                [
                    114.92374378364028,
                    41.41900095653475
                ],
                [
                    114.92370725602464,
                    41.419013956650225
                ],
                [
                    114.9237896295252,
                    41.41921564945931
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块1"
        },
        // ,{
        //     "id": 3426,
        //     "type": "Polygon",
        //     "location": [
        //         [
        //             114.92367987057483,
        //             41.41932202392208
        //         ],
        //         [
        //             114.92362488529002,
        //             41.419253627592205
        //         ],
        //         [
        //             114.92374223193443,
        //             41.419237534338116
        //         ],
        //         [
        //             114.92376301905429,
        //             41.41929587238419
        //         ],
        //         [
        //             114.92367987057483,
        //             41.41932202392208
        //         ]
        //     ],
        //     "point": null,
        //     "mapId": "215",
        //     "remark": "自定义地块5"
        // },
        {
            "id": 3427,
            "type": "Polygon",
            "location": [
                [
                    114.92488326903135,
                    41.419926273178746
                ],
                [
                    114.92484806503803,
                    41.41984748328895
                ],
                [
                    114.92486952271015,
                    41.419838598471586
                ],
                [
                    114.92486248191149,
                    41.41982200230331
                ],
                [
                    114.92487119909079,
                    41.419820158284615
                ],
                [
                    114.92488058682234,
                    41.41983926902384
                ],
                [
                    114.92488829817326,
                    41.419837089729015
                ],
                [
                    114.92492132287174,
                    41.41991755599945
                ],
                [
                    114.92488712470681,
                    41.41992845247357
                ],
                [
                    114.92488326903135,
                    41.419926273178746
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块6"
        },
        {
            "id": 3428,
            "type": "Polygon",
            "location": [
                [
                    114.92475990837274,
                    41.42016635511603
                ],
                [
                    114.92472034578975,
                    41.420072477800495
                ],
                [
                    114.92475186174569,
                    41.420060743136055
                ],
                [
                    114.92479008322415,
                    41.42015428517546
                ],
                [
                    114.92477834855971,
                    41.420166019839904
                ],
                [
                    114.92475990837274,
                    41.42016635511603
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块7"
        },
        {
            "id": 3429,
            "type": "Polygon",
            "location": [
                [
                    114.92510661943518,
                    41.42059581923524
                ],
                [
                    114.92507845624053,
                    41.42052390250604
                ],
                [
                    114.92507560639345,
                    41.42051602351706
                ],
                [
                    114.92509438185655,
                    41.42051099437516
                ],
                [
                    114.92511164857709,
                    41.420511832565474
                ],
                [
                    114.92511952756607,
                    41.420516358793186
                ],
                [
                    114.92512807710729,
                    41.42053831937949
                ],
                [
                    114.92514138826434,
                    41.42058016288235
                ],
                [
                    114.92513233580891,
                    41.42058938297584
                ],
                [
                    114.92510661943518,
                    41.42059581923524
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块8"
        },
        {
            "id": 3430,
            "type": "Polygon",
            "location": [
                [
                    114.92400043903386,
                    41.42072397551017
                ],
                [
                    114.92402189670597,
                    41.42069178900199
                ],
                [
                    114.92402994333301,
                    41.42067502519565
                ],
                [
                    114.9240547537664,
                    41.42067703685241
                ],
                [
                    114.92407209508832,
                    41.420705498452705
                ],
                [
                    114.9240997149765,
                    41.42069459632768
                ],
                [
                    114.9241158082306,
                    41.42072074786557
                ],
                [
                    114.92414263032074,
                    41.42073703270602
                ],
                [
                    114.92416274688836,
                    41.42073502104926
                ],
                [
                    114.92420096836682,
                    41.420820181185476
                ],
                [
                    114.9242257788002,
                    41.42089260082888
                ],
                [
                    114.92426802359218,
                    41.42099826070544
                ],
                [
                    114.92431496224994,
                    41.420988872973886
                ],
                [
                    114.92437128863925,
                    41.421121642320124
                ],
                [
                    114.92438537023658,
                    41.42116120490309
                ],
                [
                    114.92417012296313,
                    41.421272516577204
                ],
                [
                    114.92411044381255,
                    41.42117059263464
                ],
                [
                    114.92399779103394,
                    41.42086683246371
                ],
                [
                    114.92402930698987,
                    41.42085275086638
                ],
                [
                    114.92398639164563,
                    41.42072199317691
                ],
                [
                    114.92400043903386,
                    41.42072397551017
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块9"
        },
        {
            "id": 3431,
            "type": "Polygon",
            "location": [
                [
                    114.9244044711043,
                    41.42096626789203
                ],
                [
                    114.92437310702779,
                    41.42090044631417
                ],
                [
                    114.92433756775834,
                    41.42090480490382
                ],
                [
                    114.92430638707853,
                    41.42083305581266
                ],
                [
                    114.92434174988686,
                    41.42082333280497
                ],
                [
                    114.92432431552827,
                    41.420765665311144
                ],
                [
                    114.92439036492526,
                    41.420734149355226
                ],
                [
                    114.92447720144213,
                    41.42095174356158
                ],
                [
                    114.92440880511224,
                    41.42097219540532
                ],
                [
                    114.9244044711043,
                    41.42096626789203
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块10"
        },
        {
            "id": 3432,
            "type": "Polygon",
            "location": [
                [
                    114.92432086586962,
                    41.41862815727915
                ],
                [
                    114.9242888469995,
                    41.41855858748284
                ],
                [
                    114.92429303795109,
                    41.41854836156097
                ],
                [
                    114.92430024638782,
                    41.41854249422875
                ],
                [
                    114.92431298688064,
                    41.41853780036298
                ],
                [
                    114.92432321280249,
                    41.4185418236765
                ],
                [
                    114.92433980897077,
                    41.41858188917365
                ],
                [
                    114.92435254946359,
                    41.41861541678633
                ],
                [
                    114.92435120835908,
                    41.41862648089852
                ],
                [
                    114.92432086586962,
                    41.41862815727915
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块11"
        },
        {
            "id": 3433,
            "type": "Polygon",
            "location": [
                [
                    114.92421680233585,
                    41.41881838709805
                ],
                [
                    114.92418930969345,
                    41.41875434935781
                ],
                [
                    114.92418763331281,
                    41.41874227941724
                ],
                [
                    114.92419836214887,
                    41.41873456806632
                ],
                [
                    114.92421445540296,
                    41.418733897514066
                ],
                [
                    114.92423222503768,
                    41.418757366842954
                ],
                [
                    114.92424630663501,
                    41.418801958567826
                ],
                [
                    114.92424463025438,
                    41.41881939292643
                ],
                [
                    114.92421680233585,
                    41.41881838709805
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块12"
        },{
            "id": 3436,
            "type": "Polygon",
            "location": [
                [
                    114.92448645518772,
                    41.41949506671063
                ],
                [
                    114.92444588677637,
                    41.419403536328
                ],
                [
                    114.9244512511944,
                    41.41938409031263
                ],
                [
                    114.9244750557994,
                    41.41937671423784
                ],
                [
                    114.92448142604582,
                    41.419378390618476
                ],
                [
                    114.9245199828004,
                    41.41948232621782
                ],
                [
                    114.9245099245166,
                    41.41949774891965
                ],
                [
                    114.92449215488188,
                    41.41949841947191
                ],
                [
                    114.92448645518772,
                    41.41949506671063
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块15"
        },
        {
            "id": 3437,
            "type": "Polygon",
            "location": [
                [
                    114.9234816995145,
                    41.41680200748218
                ],
                [
                    114.92342537312518,
                    41.416657168195385
                ],
                [
                    114.92397522597322,
                    41.41637419514432
                ],
                [
                    114.92403557567606,
                    41.41648550681844
                ],
                [
                    114.92403825788507,
                    41.41652708105816
                ],
                [
                    114.92406239776619,
                    41.416563290879864
                ],
                [
                    114.92406239776619,
                    41.416620958373684
                ],
                [
                    114.92351522712718,
                    41.416800666377675
                ],
                [
                    114.9234816995145,
                    41.41680200748218
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块16"
        },
        {
            "id": 3438,
            "type": "Polygon",
            "location": [
                [
                    114.92369619185996,
                    41.41707400124503
                ],
                [
                    114.92368143971038,
                    41.417040305994284
                ],
                [
                    114.92369870643091,
                    41.417039300165904
                ],
                [
                    114.92372053117012,
                    41.41703496700903
                ],
                [
                    114.92372757196878,
                    41.417039157960616
                ],
                [
                    114.92373679206227,
                    41.41706614768882
                ],
                [
                    114.92372635196514,
                    41.41707421758447
                ],
                [
                    114.92369617711374,
                    41.41707606160317
                ],
                [
                    114.92369619185996,
                    41.41707400124503
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块17"
        },
        {
            "id": 3439,
            "type": "Polygon",
            "location": [
                [
                    114.92320257666067,
                    41.418431582011905
                ],
                [
                    114.92311138155416,
                    41.418210299768184
                ],
                [
                    114.92307114841894,
                    41.41814190343831
                ],
                [
                    114.92299604656652,
                    41.4180681426904
                ],
                [
                    114.92287534716087,
                    41.41776237086272
                ],
                [
                    114.92271977903796,
                    41.417377473869074
                ],
                [
                    114.92263394834949,
                    41.41741100148176
                ],
                [
                    114.92298531773046,
                    41.418412806548794
                ],
                [
                    114.92303627970175,
                    41.41845572189303
                ],
                [
                    114.92311540486769,
                    41.41845572189303
                ],
                [
                    114.92316636683897,
                    41.418475838460644
                ],
                [
                    114.9232065999742,
                    41.41848254398318
                ],
                [
                    114.92320257666067,
                    41.418431582011905
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块18"
        },
        {
            "id": 3440,
            "type": "Polygon",
            "location": [
                [
                    114.92394589095699,
                    41.418149162824406
                ],
                [
                    114.92390884294497,
                    41.4180589735463
                ],
                [
                    114.92391906886685,
                    41.418043550844466
                ],
                [
                    114.92394153236734,
                    41.41804036572126
                ],
                [
                    114.92394522040473,
                    41.41803885697869
                ],
                [
                    114.9239874651967,
                    41.41814564242508
                ],
                [
                    114.92397204249487,
                    41.418156035985014
                ],
                [
                    114.923961816573,
                    41.41815989166047
                ],
                [
                    114.92394589095699,
                    41.418149162824406
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块19"
        },
        {
            "id": 3441,
            "type": "Polygon",
            "location": [
                [
                    114.92399288491953,
                    41.41759887418154
                ],
                [
                    114.9239663981055,
                    41.41752925866574
                ],
                [
                    114.92393152938831,
                    41.41753026449411
                ],
                [
                    114.92390135453688,
                    41.417531940874746
                ],
                [
                    114.92389096097695,
                    41.417547698852715
                ],
                [
                    114.92390135453688,
                    41.417586255607304
                ],
                [
                    114.92393085883606,
                    41.417592961129834
                ],
                [
                    114.92396438644874,
                    41.41760536634653
                ],
                [
                    114.92397142724741,
                    41.41761844211548
                ],
                [
                    114.92399087326277,
                    41.41761475407809
                ],
                [
                    114.92399288491953,
                    41.41759887418154
                ]
            ],
            "point": null,
            "mapId": "215",
            "remark": "自定义地块20"
        }
        
        
        ]
            console.log("加载获取到的自定义方框数据1")
            // console.log(result)
            
            for (let i = 0; i < result.length; i++) {
                if (result[i].type === 'Polygon') {
                    console.log("polygon", result[i].type)
                    _this.line.push(result[i].location)
                } 
            }
            

            // that.drawVector.getSource().clear()
            for (let i = 0; i < _this.line.length; i++) {
                // this.drawVector.getSource().removeFeature()
                const polygon = new ol.geom.Polygon([_this.line[i]]);
                let feature = new ol.Feature(polygon);
                feature.setStyle(getStyle());//如果是选中的数据就进行切换，否则就不进行上色切换
                that.drawVector.getSource().addFeature(feature);
            }
                
                // console.log(this.drawVector.getSource().getFeatures())

            function getStyle() {
                var imageSrc = "../../image/map/label.png";
                return new ol.style.Style({
                    fill: new ol.style.Fill({
                        color: 'rgba(255, 255, 255, 0.2)'
                    }),
                    stroke: new ol.style.Stroke({
                        color: '#fd0707',
                        width: 2
                    }),
                    image: new ol.style.Icon({
                        src: imageSrc,
                        imgSize: [10, 22],
                        //scale: 0.5
                        offsetX: 0,
                        offsetY: 11
                    }),
                    text: new ol.style.Text({
                        textAlign: 'center',
                        text: "异常地块",//！
                        offsetX: 15,
                        offsetY: -10
                    })
                })
        
            }
                

        },

        //加载自定义方框数据
        loadPolygon() {
            var that = this
            let _this = this;
            this.isSelect = localStorage.getItem('isSelect')
            // console.log(this.isSeclect)

            var id = _this.tId
            localStorage.setItem('id', id)
            // console.log(id)
            this.$root.backgroundId = id//获取到现在想要查询的自定义数组的id，并保存到本地，监听并进行计算最新自定义数组
            this.$axios.request({
                method: 'POST',
                url: 'makers-photo/getPolygon',
                params: {
                    type: 'Polygon',
                    mapId: 215
                }
            }).then((res) => {
                _this.line = [];
                _this.point = [];
                if (res.data.code === 1200) {
                    const result = res.data.data;
                    // console.log("加载获取到的自定义方框数据")
                    // console.log(result)
                    
                    for (let i = 0; i < result.length; i++) {
                        if (result[i].type === 'Polygon') {
                            _this.line.push(result[i].location)
                        } else if (result[i].type === 'Point') {
                            _this.point.push(result[i].point)
                        }
                    }
                }
                // that.drawVector.getSource().clear()
                for (let i = 0; i < _this.line.length; i++) {
                    // this.drawVector.getSource().removeFeature()
                    const polygon = new ol.geom.Polygon([_this.line[i]]);
                    let feature = new ol.Feature(polygon);
                    
                    if(i == _this.$root.backgroundSelect){
                        // that.drawVector.getSource().clear()
                        feature.setStyle(getStyle(_this.$root.backgroundSelect+1));//如果是选中的数据就进行切换，否则就不进行上色切换
                    }
                    that.drawVector.getSource().addFeature(feature);
                }
                for (let i = 0; i < _this.point.length; i++) {
                    const point = new ol.geom.Point(_this.point[i]);
                    let feature = new ol.Feature(point);
                    feature.setStyle(getStyle1())
                    that.drawVector.getSource().addFeature(feature);
                }
                // console.log(this.drawVector.getSource().getFeatures())
            })
            function getStyle1() {
               
                return new ol.style.Style({
                    fill: new ol.style.Fill({
                        color: '#ffcc33'
                    }),
                    stroke: new ol.style.Stroke({
                        color: '#ffcc33',
                        width: 2
                    }),

                })
            }
            function getStyle(id) {
                var imageSrc = "../../image/map/label.png";
                return new ol.style.Style({
                    fill: new ol.style.Fill({
                        color: 'rgba(255, 255, 255, 0.2)'
                    }),
                    stroke: new ol.style.Stroke({
                        color: '#ffcc33',
                        width: 2
                    }),
                    image: new ol.style.Icon({
                        src: imageSrc,
                        imgSize: [10, 22],
                        //scale: 0.5
                        offsetX: 0,
                        offsetY: 11
                    }),
                    text: new ol.style.Text({
                        textAlign: 'center',
                        // text: "自定义地块"+id,//！
                        offsetX: 15,
                        offsetY: -10
                    })
                })
                
            }

        },
        //初始化图像
        testInit() {


            let tUrl = this.tUrl
            let tRes = this.tRes
            let tOrigin = this.tOrigin
            let tExtent = this.tExtent
            let tCode = this.tCode
            let tPro_extent = this.tPro_extent
            let tProje_defs = this.tProje_defs

            var temp = tExtent[1]
            tExtent[1] = tExtent[2]
            tExtent[2] = temp


            const _this = this
            let tileGrid = new ol.tilegrid.TileGrid({
                tileSize: [512, 512],
                origin: tOrigin,
                resolutions: tRes,
                extent: tExtent
            });

            let code = 'EPSG:' + tCode
            proj4.defs(code, tProje_defs);
            let tProjection = new ol.proj.Projection({
                code: code,
                extent: tPro_extent,
                units: 'm'
            });
            let layers = new ol.layer.Tile({
                source: new ol.source.XYZ({
                    projection: tProjection,
                    tileGrid: tileGrid,
                    tileUrlFunction: function (coordinate) {
                        let x, y, z;
                        let url = tUrl
                        x = coordinate[1]
                        y = coordinate[2]
                        z = coordinate[0]
                        return `${url}/${z}/${x}/${y}.png`
                    }
                })
            });

            // 创建一个交互式选择器
            var selectInteraction = new ol.interaction.Select({
                condition: ol.events.condition.click
            });

            // 监听选中事件
            selectInteraction.on('select', function(event) {
                console.log('----------------')
                if (event.selected.length > 0) {
                    // 多边形被选中
                    var selectedFeature = event.selected[0];
                    var geometry = selectedFeature.getGeometry();
                    // 处理点击事件
                    // console.log("监听获取到数据被点击")
                    // console.log(geometry.l)
                    let tem  = geometry.l
                    // console.log(geometry.l)
                    let tem1 = [1,1]
                    let polygon = [];
                    for(let i = 0; i < tem.length; i++){
                        tem1 = [1,1]
                        // console.log(tem[i])
                        if(i%2 != 0){
                        //   console.log(tem[i]+"奇数")
                          tem1[1] = tem[i]              
                          tem1[0] = tem[i-1]       
                        }else{
                            continue
                        }
                        polygon.push(tem1)
                    }
                    // console.log(polygon)
                    let polygon1 = JSON.stringify(polygon)
                    // console.log(polygon1.slice(0,39))//传递第一个字段
                    const formData = new FormData();
                    formData.append("polygon", polygon1.slice(0,39))
                    // console.log(_this.tId)
                    //接下来去后端获取这个图形的id编号或者名称
                    //然后就去对应修改右侧的数据
                    axios.post("http://43.143.170.49:8468/makers-photo/polygon2Id", formData).then(res => {
                        console.log(res.data.data[0].id)
                        
                        _this.$root.backgroundSelectid = res.data.data[0].id
                        console.log(_this.$root.backgroundSelectid)
                    })


                }
            });

            var centers = ol.extent.getCenter(tExtent)
            this.layer = layers
            // console.log(layers)
            _this.map = new ol.Map({
                layers: [layers],
                interactions: ol.interaction.defaults().extend([selectInteraction]),
                view: new ol.View({
                    center: centers,
                    resolutions: tRes,
                    resolution: tRes[19],
                    projection: tProjection,
                    // minZoom: 18,
                    extent: tExtent
                }),

                controls: ol.control.defaults({ "attribution": false }),
                target: 'photo'
            });

            // var that = this
            //  // 监听singleclick事件
            //  that.map.on('singleclick', function (e) {
            //     console.log("coordinate", e.coordinate)
            //     that.setCenter(e.coordinate)
            // })


            this.edit();
        },
        zeroPad(num, len, radix) {
            let str = num.toString(radix || 10);
            while (str.length < len) {
                str = '0' + str
            }
            return str
        },
        //修改图像类型
        // changeType1(type) {
        //     let _this = this

        //     // console.log(_this.$route.params)
        //     // if (type.toLowerCase() === 'rgb') {
        //     //     _this.tId = _this.$route.params.rgb.id
        //     //     _this.tUrl = _this.$route.params.rgb.url
        //     //     _this.tRes = JSON.parse(_this.$route.params.rgb.resolutions)
        //     //     _this.tOrigin = JSON.parse(_this.$route.params.rgb.origin)
        //     //     _this.tExtent = JSON.parse(_this.$route.params.rgb.extent)
        //     //     _this.tCode = JSON.parse(_this.$route.params.rgb.proCode)
        //     //     _this.tPro_extent = JSON.parse(_this.$route.params.rgb.proExtent)
        //     //     _this.tProje_defs = _this.$route.params.rgb.projeDefs
        //     // } else if (type.toLowerCase() === 'green') {
        //     //     _this.tId = _this.$route.params.green.id
        //     //     _this.tUrl = _this.$route.params.green.url
        //     //     _this.tRes = JSON.parse(_this.$route.params.green.resolutions)
        //     //     _this.tOrigin = JSON.parse(_this.$route.params.green.origin)
        //     //     _this.tExtent = JSON.parse(_this.$route.params.green.extent)
        //     //     _this.tCode = JSON.parse(_this.$route.params.green.proCode)
        //     //     _this.tPro_extent = JSON.parse(_this.$route.params.green.proExtent)
        //     //     _this.tProje_defs = _this.$route.params.green.projeDefs
        //     // } else if (type.toLowerCase() === 'nir') {
        //     //     _this.tId = _this.$route.params.nir.id
        //     //     _this.tUrl = this.$route.params.nir.url
        //     //     _this.tRes = JSON.parse(this.$route.params.nir.resolutions)
        //     //     _this.tOrigin = JSON.parse(this.$route.params.nir.origin)
        //     //     _this.tExtent = JSON.parse(this.$route.params.nir.extent)
        //     //     _this.tCode = JSON.parse(this.$route.params.nir.proCode)
        //     //     _this.tPro_extent = JSON.parse(this.$route.params.nir.proExtent)
        //     //     _this.tProje_defs = this.$route.params.nir.projeDefs
        //     // } else if (type.toLowerCase() === 'red') {
        //     //     _this.tId = _this.$route.params.red.id
        //     //     _this.tUrl = this.$route.params.red.url
        //     //     _this.tRes = JSON.parse(this.$route.params.red.resolutions)
        //     //     _this.tOrigin = JSON.parse(this.$route.params.red.origin)
        //     //     _this.tExtent = JSON.parse(this.$route.params.red.extent)
        //     //     _this.tCode = JSON.parse(this.$route.params.red.proCode)
        //     //     _this.tPro_extent = JSON.parse(this.$route.params.red.proExtent)
        //     //     _this.tProje_defs = this.$route.params.red.projeDefs
        //     // } else if (type.toLowerCase() === 'red_edge') {
        //     //     _this.tId = _this.$route.params.red_edge.id
        //     //     _this.tUrl = this.$route.params.red_edge.url
        //     //     _this.tRes = JSON.parse(this.$route.params.red_edge.resolutions)
        //     //     _this.tOrigin = JSON.parse(this.$route.params.red_edge.origin)
        //     //     _this.tExtent = JSON.parse(this.$route.params.red_edge.extent)
        //     //     _this.tCode = JSON.parse(this.$route.params.red_edge.proCode)
        //     //     _this.tPro_extent = JSON.parse(this.$route.params.red_edge.proExtent)
        //     //     _this.tProje_defs = this.$route.params.red_edge.projeDefs
        //     // }

        //     let tileGrid = new ol.tilegrid.TileGrid({
        //         tileSize: 256,
        //         origin: _this.tOrigin,
        //         resolutions: _this.tRes,
        //         extent: _this.tExtent
        //     });

        //     let code = 'EPSG:' + _this.tCode
        //     proj4.defs(code, _this.tProje_defs);
        //     this.tProjection = new ol.proj.Projection({
        //         code: code,
        //         extent: _this.tPro_extent,
        //         units: 'm'
        //     });

        //     let layers = new ol.layer.Tile({
        //         source: new ol.source.XYZ({
        //             projection: _this.tProjection,
        //             tileGrid: tileGrid,
        //             tileUrlFunction: function (tileCoord, pixelRatio, proj) {
        //                 let x, y, z;

        //                 let url = _this.tUrl

        //                 x = _this.zeroPad(tileCoord[1], 8, 16)
        //                 y = _this.zeroPad(tileCoord[2], 8, 16)
        //                 z = _this.zeroPad(tileCoord[0], 2, 10)
        //                 // console.log(tileCoord)
        //                 // console.log(url + 'L' + z + '/R' + y + '/C' + x + '.png')
        //                 // return `https://gengyun-1304940588.cos.ap-nanjing.myqcloud.com/a0714/a0714/_alllayers/L${z}/R${y}/C${x}.png`
        //                 return `${url}L${z}/R${y}/C${x}.png`
        //             }
        //         })
        //     });

        //     var centers = ol.extent.getCenter(_this.tExtent)

        //     // _this.map.changeLayer(layers)
        //     // console.log(_this.map.getFeatures())
        //     _this.map.removeLayer(this.layer);
        //     _this.map.removeLayer(this.drawVector);
        //     _this.map.addLayer(layers)
        //     this.layer = layers

        //     _this.map.setView(new ol.View({
        //         center: centers,
        //         resolutions: _this.tRes,
        //         resolution: _this.tRes[4],
        //         extent: _this.tExtent,
        //         projection: _this.tProjection
        //     }));
        //     this.edit();
        //     this.loadPolygon();
        // },
        //修改图像的内容，重新刷新
        changeType(newMap){//修改图像的信息，复制的上一个修改地图类型的函数，byd不知道对不对，应该还得改

            // console.log("进入地图转换函数")
            // console.log(newMap)
            let _this = this
            _this.tId = newMap.id
            // console.log(_this.tId)
            _this.tUrl = newMap.url
            _this.tRes = JSON.parse(newMap.resolutions)
            _this.tOrigin = JSON.parse(newMap.origin)
            _this.tExtent = JSON.parse(newMap.extent)
            _this.tCode = JSON.parse(newMap.proCode)
            _this.tPro_extent = JSON.parse(newMap.proExtent)
            this.tUrl1 = newMap.cosUrl,
            // console.log(this.tUrl1 )
            _this.tProje_defs = newMap.projeDefs

            let tUrl =this.tUrl
            // let tUrl = 'https://gis-1300769337.cos.ap-beijing.myqcloud.com/gengyun/2023/129' 
            let tRes =  this.tRes 
            let tOrigin = this.tOrigin
            let tExtent = this.tExtent 
            let tCode = this.tCode
            let tPro_extent = this.tPro_extent
            let tProje_defs  = this.tProje_defs
            
            var temp = tExtent[1] 
            tExtent[1]  = tExtent[2]
            tExtent[2] = temp

            let tileGrid = new ol.tilegrid.TileGrid({
                tileSize: [512, 512],
                origin: tOrigin,
                resolutions: tRes,
                extent: tExtent
            });

            let code = 'EPSG:' + tCode
            proj4.defs(code, tProje_defs);
            let tProjection = new ol.proj.Projection({
                code: code,
                extent: tPro_extent,
                units: 'm'
            });
            let layers = new ol.layer.Tile({
                source: new ol.source.XYZ({
                    projection: tProjection,
                    tileGrid: tileGrid,
                    tileUrlFunction: function (coordinate) {
                        let x, y, z;
                        let url = tUrl
                        x = coordinate[1]
                        y = coordinate[2]
                        z = coordinate[0]


                        return `${url}/${z}/${x}/${y}.png`
                    }
                })
            });

            var centers = ol.extent.getCenter(tExtent)

            _this.map.removeLayer(this.layer);
            _this.map.removeLayer(this.vectorLayer);
            _this.map.removeLayer(this.drawVector);
            
            _this.map.addLayer(layers);
            this.layer = layers
            this.loadJson(newMap.cosUrl);
            this.loadPolygon();
            this.edit();
            

        },
        //打开编辑界面
        openDraw() {
            this.isDraw = !this.isDraw;
            this.mainBar.setVisible(this.isDraw);
            if (this.isDraw) {
                this.map.addLayer(this.drawVector);
                this.map.addControl(this.mainBar);
                
            } else {
                this.map.removeLayer(this.drawVector);
                this.map.removeControl(this.mainBar)
            }
        },
        showDetail() {
            let id = this.tId;
            let location = "[" + this.pointLocation[0] + "," + this.pointLocation[1] + "]";
            let _this = this;
            let dataForm = new FormData();
            dataForm.append('location', location);
            dataForm.append('mapId', id);
            _this.$axios.request({
                method: 'POST',
                url: 'makers-photo/getDetail',
                data: dataForm,
            }).then(res => {
                // console.log(id)
                _this.loadPolygon()
            })
        },
        edit() {
            let vector = new ol.layer.Vector({
                name: 'Vecteur',
                source: new ol.source.Vector()
            });
            const mainBar = new ol.control.Bar();
            this.map.addControl(mainBar);


            let editBar = new ol.control.Bar({
                toggleOne: true,	// one control active at the same time
                group: false			// group controls together
            });
            mainBar.addControl(editBar);

            let _this = this;

            let sonBar = new ol.control.Bar();
            sonBar.addControl(new ol.control.Button({
                html: '<i class="fa fa-times"></i>',
                title: "Delete",
                handleClick: function () {
                    let features = selectCtrl.getInteraction().getFeatures();
                    if (!features.getLength()) console.log("Select an object first...");
                    else console.log(features.getLength() + " object(s) deleted.");
                    for (let i = 0, f; f = features.item(i); i++) {
                        vector.getSource().removeFeature(f);
                    }
                    selectCtrl.getInteraction().getFeatures().clear();
                }
            }));
            sonBar.addControl(new ol.control.Button({
                html: '<i class="fa fa-info"></i>',
                title: "Show informations",
                handleClick: function () {
                    switch (selectCtrl.getInteraction().getFeatures().getLength()) {
                        case 0:
                            // console.log("Select an object first...");
                            break;
                        case 1:
                            let f = selectCtrl.getInteraction().getFeatures().item(0);
                            _this.showDetailDialog = true;
                            _this.pointLocation = f.getGeometry().getCoordinates();
                            _this.showDetail();
                            break;
                        default:
                            console.log(selectCtrl.getInteraction().getFeatures().getLength() + " objects seleted.");
                            break;
                    }
                }
            }));

            let selectCtrl = new ol.control.Toggle({
                html: '<i class="fa fa-hand-pointer-o"></i>',
                title: "Select",
                interaction: new ol.interaction.Select({ hitTolerance: 2 }),
                bar: sonBar,
                autoActivate: true,
                active: true
            });

            editBar.addControl(selectCtrl);

            // Add editing tools
            let pointEdit = new ol.control.Toggle({
                html: '<i class="fa fa-map-marker" ></i>',
                title: 'Point',
                interaction: new ol.interaction.Draw({
                    type: 'Point',
                    source: vector.getSource()
                })
            });
            editBar.addControl(pointEdit);

            let polygonEdit = new ol.control.Toggle({
                html: '<i class="fa fa-bookmark-o fa-rotate-270" ></i>',
                title: 'Polygon',
                interaction: new ol.interaction.Draw({
                    type: 'Polygon',
                    source: vector.getSource(),
                    // Count inserted points
                    geometryFunction: function (coordinates, geometry) {
                        this.nbpts = coordinates[0].length;
                        if (geometry) geometry.setCoordinates([coordinates[0].concat([coordinates[0][0]])]);
                        else geometry = new ol.geom.Polygon(coordinates);
                        return geometry;
                    }
                }),
                bar: new ol.control.Bar({
                    controls: [new ol.control.TextButton({
                        html: '取消',//'<i class="fa fa-mail-reply"></i>',
                        title: "取消",
                        handleClick: function () {
                            if (polygonEdit.getInteraction().nbpts > 1) polygonEdit.getInteraction().removeLastPoint();
                        }
                    }),
                    new ol.control.TextButton({
                        html: '结束',
                        title: "结束",
                        handleClick: function () {
                            // Prevent null objects on finishDrawing
                            if (polygonEdit.getInteraction().nbpts > 3) polygonEdit.getInteraction().finishDrawing();
                        }
                    })
                    ]
                })
            });
            editBar.addControl(polygonEdit);

            mainBar.setVisible(this.isDraw);
            this.map.addLayer(vector);
            this.drawVector = vector;
            this.mainBar = mainBar;
        },
        addPoint(lineElement) {
            let _this = this;
            let makerLayer = new ol.layer.Vector({
                source: null,
                style: null,
                zIndex: 11
            });
            makerLayer.setSource(null);
            let feature = new ol.Feature({
                geometry: new ol.geom.Point(lineElement)
            });
            let source = new ol.source.Vector();
            source.addFeatures([feature]);
            makerLayer.setSource(source)
            let style = new ol.style.Style({
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0055ff'
                    })
                }),
                text: new ol.style.Text(({
                    textAlign: 'center',
                    textBaseline: 'bottom',
                    font: 'bold 12px 微软雅黑',
                    text: '点标注' + _this.makerLayer.length,
                    offsetY: 30,
                    fill: new ol.style.Fill({ color: '#587de5' }),
                    stroke: new ol.style.Stroke({ color: '#ffcc33', width: 10 })
                }))
            });
            makerLayer.setStyle(style);
            this.makerLayer.push(makerLayer);
            this.map.addLayer(makerLayer)
        },
        getCenter() {
            const line = this.line;
            const lineLength = line.length;
            let maxLat = -1;
            let minLat = 10000;
            let maxLon = -1;
            let minLon = 10000;
            for (let i = 0; i < lineLength; i++) {
                if (line[i][0] < minLon) {
                    minLon = line[i][0]
                }
                if (line[i][0] > maxLon) {
                    maxLon = line[i][0]
                }
                if (line[i][1] < minLat) {
                    minLat = line[i][1]
                }
                if (line[i][1] > maxLat) {
                    maxLat = line[i][1]
                }
            }
            const middleLat = (maxLat + minLat) / 2;
            const middleLon = (maxLon + minLon) / 2;
            // console.log("middleLat = " + middleLat + " middleLon = " + middleLon)
            return { middleLon, middleLat }
        },
        backToMap() {
            this.$router.go(-1)
        },
        showAllMap() {
            if (this.allMap) {
                document.getElementById('side_bar').style.width = 0 + '%'
                this.allMap = !this.allMap
            } else {
                document.getElementById('side_bar').style.width = 30 + '%'
                this.allMap = !this.allMap
            }
        },
        handleCancel() {
            this.showDetailDialog = false;
            this.form.remark = ''
        },
        saveDetail() {
            let _this = this;
            let id = this.tId;
            let formData = new FormData();
            formData.append("type", 'Point');
            formData.append("location", "[" + this.pointLocation[0] + "," + this.pointLocation[1] + "]");
            formData.append("mapId", id);
            formData.append("remark", _this.form.remark);
            _this.$axios.request({
                method: 'post',
                url: 'makers-photo/saveDetail',
                data: formData
            }).then(res => {
                // console.log(id)
                if (res.data.code === 1200) {
                    _this.$message.success('上传成功');
                    _this.showDetailDialog = false;
                    _this.form.remark = ''
                }
                _this.loadPolygon()

            })
        },
        drag() {
            this.map.addEventListener("dragstart", function (event) {
                console.log(event)
            });
            this.map.addEventListener("drag", function (event) {
                console.log(event)
            });
        },
    }
}
</script>
  
<style scoped></style>
  