<template>
    <div>
        <div class="headers">
            <div class="header" >
                <a-dropdown :trigger="['click']">
                    <a @click="[e => e.preventDefault()] [selectYear=!selectYear ]" style="color: black;">
                        <div style="font-weight: bolder">
                            <h2>当前显示的年份 </h2>
                            <a-icon :type="selectYear===true?'caret-down':'caret-up'" style="color:#888" />
                        </div>
                    </a>
                    <a-menu slot="overlay" @click="getYear"  >
                        <a-menu-item
                                v-for="item in optionsYear"
                                :key="item">
                            <span style="font-size: 20px; margin-right: 20px; margin-left: 20px;">{{item}}</span>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
                <h1>{{year}}</h1>
                <h2 style="font-weight: bolder;margin: 0;">&nbsp;&nbsp;年</h2>
            </div>
            <div class="header">
                <a-dropdown :trigger="['click']">
                    <a @click="e => e.preventDefault()" style="color: black;">
                        <div style="font-weight: bolder">
                            <h2>{{testWarn}}异常指标</h2>
                            <a-icon type="caret-down" style="color:#888" />
                        </div>
                    </a>
                    <a-menu slot="overlay" @click="handleWarn" >
                      <a-menu-item
                          v-for="item in warn"
                          :key="item.id">
                        <span>{{item.item}}</span>
                      </a-menu-item>
                    </a-menu>
                </a-dropdown>
<!--                <h1 class="anormaly" style="color:blue">{{ anormal }}</h1>-->
<!--                <h2>&nbsp;&nbsp;个</h2>-->
<!--                <h3 class="anormaly">暂无异常</h3>-->
              <h1 class="anormaly">{{ anormalIndex }}</h1>
              <h2>&nbsp;&nbsp;个指标</h2>
              <h1 class="anormaly">{{ anormalNum }}</h1>
              <h2>&nbsp;&nbsp;次异常</h2>
              <h3 class="anormaly">{{ anormalMsg }}</h3>
            </div>
            <div class="header">
                <a-dropdown :trigger="['click']">
                    <a @click="e => e.preventDefault()" style="color: black;">
                        <div style="font-weight: bolder">
                            <h2>{{testComplete}}采集完成度</h2>
                            <a-icon type="caret-down" style="color:#888" />
                        </div>
                    </a>
                    <a-menu slot="overlay" @click="handleComplete" >
                        <!--            <a&#45;&#45;menu-item key="-1">dfs</a&#45;&#45;menu-item>-->
                        <a-menu-item key="总体">总体</a-menu-item>
                        <a-sub-menu key="1" title="叶片微量元素">
                            <a-menu-item key="叶片微量元素总体">叶片微量元素总体</a-menu-item>
                            <a-menu-item key="氮元素">氮元素</a-menu-item>
                            <a-menu-item key="磷元素">磷元素</a-menu-item>
                            <a-menu-item key="钾元素">钾元素</a-menu-item>
                            <a-menu-item key="钙元素">钙元素</a-menu-item>
                            <a-menu-item key="镁元素">镁元素</a-menu-item>
                        </a-sub-menu>
                        <a-sub-menu key="2" title="长势(长度)">
                            <a-menu-item key="长度值总体">长度值总体</a-menu-item>
                            <a-menu-item key="芽长">芽长</a-menu-item>
                            <a-menu-item key="芽粗">芽粗</a-menu-item>
                            <a-menu-item key="垄高">垄高</a-menu-item>
                            <a-menu-item key="株高">株高</a-menu-item>
                            <a-menu-item key="匍匐茎长度">匍匐茎长度</a-menu-item>
                        </a-sub-menu>
                        <a-sub-menu key="3" title="长势(数量)">
                            <a-menu-item key="数量值总体">数量值总体</a-menu-item>
                            <a-menu-item key="出苗数">出苗数</a-menu-item>
                            <a-menu-item key="50%出苗时间">50%出苗时间</a-menu-item>
                            <a-menu-item key="叶片数">叶片数</a-menu-item>
                            <a-menu-item key="匍匐茎数量">匍匐茎数量</a-menu-item>
                            <a-menu-item key="单株芽数">单株芽数</a-menu-item>
                        </a-sub-menu>
                        <!--            <a-menu-item-->
                        <!--                v-for="item in completeValue"-->
                        <!--                :key="item">-->
                        <!--              <span style="font-size: 20px; margin-right: 20px; margin-left: 20px;">{{item}}</span>-->
                        <!--            </a-menu-item>-->
                    </a-menu>
                </a-dropdown>
                <h1>100</h1>
                <h2>&nbsp;&nbsp;%</h2>
                <h3>应采集次数：9 次 </h3>
                <h3>已采集次数：9 次</h3>
            </div>
            <div class="header set">
                <h2>显示设置</h2> <a-icon type="control" theme="filled" style="color:#888" />
                <div style="width: 90%">
                    <div>
                        <h2>警戒线</h2>&nbsp;
                        <a-switch style="margin-bottom: 6px;" checked-children="开" un-checked-children="关" default-unchecked @click="showWarningLine=!showWarningLine"/>

                    </div>
                    <div>
                        <h2>提示信息</h2>&nbsp;
                        <a-switch style="margin-bottom: 6px;" checked-children="开" un-checked-children="关" default-unchecked @click="showPromptMessage=!showPromptMessage"/>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <a-tabs default-active-key="1">
                <a-tab-pane key="1" tab="叶片微量元素">
                        <leaf  :showPromptMessage="showPromptMessage"
                               :showWarningLine="showWarningLine"
                               :year='this.year'
                               @change="getMsg"
                               :collapsed="collapsed"
                               style=" display: inline-block; background-color: #FFF; width: 100%;  padding: 20px;" />

                </a-tab-pane>
                <a-tab-pane key="2" tab="长势情况" force-render >
                  <length :collapsed="collapsed"
                          :showPromptMessage="showPromptMessage"
                          :showWarningLine="showWarningLine"
                          @change="getMsg"
                          :year='this.year'
                          style=" width: 100%;  background-color: #ffffff; padding: 20px;"/>
                  <!-- <count :showPromptMessage="showPromptMessage"
                         :showWarningLine="showWarningLine"
                         @change="getMsg"
                         :collapsed="collapsed"
                         :year='this.year'
                         style="margin-top:20px; margin-bottom: 20px;  width: 100%;  background-color: #ffffff; padding: 20px;"/> -->
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    import leaf from '../charts/Indicator-leaf-charts'
    import count from '../charts/grow-count'
    import length from '../charts/grow-length'

    export default {
      props:{
        collapsed:{},
      },
      name: "show-nutrition-growth",
      components:{leaf,count,length},
      data(){
        return {
          optionsYear:[],
          options:['氮元素','磷元素','钾元素','钙元素','镁元素'],
          year:'',
          selectYear:true,
          showWarningLine:false,
          showPromptMessage:false,
          /**异常指标*/
          warn: [
            {
              id:0, item:'总体', num:0, msg:'',time:"",index:0,
            },
            {
              id:1, item:'叶片微量元素',   num:0,   msg:'',index:0,
            },
            {
              id:2,item:'长势(长度)',  num:0,  msg:'',index:0,
            },
            // {
            //   id:3, item:'长势(数量)', num:0,   msg:'',index:0,
            // },
          ],
          anormal:0, //异常值百分比
          completeness:0.1,
          testWarn:'总体',
          testComplete:'总体',
          anormalNum:0,
          anormalIndex:0,
          anormalMsg:'',
        };
      },

      mounted() {
        //加载年份
        this.$axios.post("/indicator/getYear").then((res)=>{
          this.optionsYear=res.data
          this.year=this.optionsYear[0]
          getColor()
        })
        function getColor(){
          let a = document.getElementsByClassName('anormaly');
          let setColor;
          // console.log("颜色为"+a[0].innerText)
          let val=a[0].innerText
          if(val==="0"){
            setColor='#24ab62'
          } else{
            setColor='#2487ABFF'
          }
          for(let item = 0 ; item<a.length ;item++){
            a[item].style.color=setColor
          }
        }
      },
      methods:{
        getMsg(val){
          console.log("+++++++++")
          console.log(val)
          let sum=0;
          let t=val[0].num;
          this.warn[0].msg="";
          this.warn[0].num=0;
          this.warn[t].msg="";
          this.warn[t].num=0;
          this.warn[0].index=0;
          this.warn[t].index=0;
          for(let i=1;i<val.length;i++){
            if(val[i].num !== 0){
              this.warn[t].index++;
              this.warn[t].num+=val[i].num;
              if(this.warn[t].msg!==""){
                this.warn[t].msg+="，";
              }
              this.warn[t].msg+=val[i].name+"("+val[i].num+"次)";
              sum++;
            }
          }
          //更新菜单选项信息
          for(let i=1;i<this.warn.length;i++){
            this.warn[0].num+=this.warn[i].num;
            if(this.warn[i].num!==0){
              this.warn[0].index++;
              if(this.warn[0].msg!==""){
                this.warn[0].msg+="，";
              }
              this.warn[0].msg+=this.warn[i].item+"("+this.warn[i].num+"次)";
            }
          }
          console.log(this.warn[0].index);
          console.log("sum为"+sum)
          this.handleWarn(undefined)
        },
        getYear(val){
          console.log(val)
          this.year=val.key;
        },
        handleWarn(val){
          let a = {key : 0};
          if(val===undefined){
            val=a;
          }
          this.anormalIndex=this.warn[val.key].index;
          this.testWarn=this.warn[val.key].item;
          this.anormalNum=this.warn[val.key].num;
          this.anormalMsg=this.warn[val.key].msg===""?"暂无异常":(this.warn[val.key].msg+"数据异常");
          this.getColor()
        },
        //更新显示的颜色
        getColor(){
          let color='';
          if(this.anormalNum===0){
            color="#24ab62";
          }else{
            color="#cc0f0f";
          }
          let a=document.getElementsByClassName('anormaly')
          for(let item = 0 ; item<a.length ;item++){
            a[item].style.color=color
          }
        },
        handleComplete(val){
          this.testComplete=val.key
        },
      },


    }
</script>

<style scoped>

    .headers{
        /*user-select:none;*/
        background-color: #FFF;
        min-height: 200px;
        box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.13) 0 0 1px 1px;
    }
    /* headers下的第一个header没有边框 */
    .headers .header:first-child {
        border-width:0;
    }

    .header h1{
        display:inline-block;
        font-size:50px;
        line-height: 50px;
        /*font-weight: bold;*/
        margin-top: 4px;
    }
    .header h2{
        display: inline-block;
        font-size: 16px;
        font-weight: bolder;
    }
    .header h3{
        margin: 0;
        color: #666666;
    }

    .header span{

    }
    .headers .header {
        margin-top: 20px;
        display: inline-block;
        text-align: left;
        /*background-color: #00ff00;*/
        vertical-align: middle;
        padding-left: 20px;
        width: 25%;
        min-height: 160px;
        border-left: 1px solid #ddd;
    }

    .headers .set div h2{
        margin-top: 20px;
        width: 30%;
        line-height: 30px;
    }

</style>