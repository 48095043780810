<template>
  <div style="display: flex; justify-content: space-between; align-items: center">
    <div id="closeButton" @click="hideMenu">
      <a-icon type="right" style="font-size: 16px; font-weight: bolder;"/>
    </div>
    <a-collapse v-model="activeKey" :bordered="false" :expand-icon-position="expandIconPosition" style="width: 100%;">
      <a-collapse-panel key="1" header="信息绑定">
        <a-form-model :layout="form.layout" :model="form">
          <a-form-model-item label="选择地块">
            <a-select v-model="form.name" @change="setCentral" placeholder="请选择地块">
              <a-select-option v-for="(item, index) in soilList" :key="index" >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="标注类型">
            <a-select v-model="form.devType" @change="setDeviceType" placeholder="请选择标注类型">
              <a-select-option v-for="(item, index) in typeList" :key="index" >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="绑定设备">
            <a-select v-model="form.device" @change="setDeviceNum" placeholder="请选择绑定设备">
              <a-select-option v-for="(item, index) in deviceList" :key="index" >
                {{ item.equipmentName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-item label="地块位置">
            <a-input placeholder="请选择标注" :value="form.location" ></a-input>
          </a-form-item>
          <a-form-item label="地块说明">
            <a-textarea v-model="form.introduce" placeholder="请输入地块说明" auto-size />
          </a-form-item>
          <a-form-model-item>
            <a-space>
              <a-button type="primary" @click="showMap">
                保存更改
              </a-button>
              <a-button type="primary" @click="showPhoto">
                进入地块 
              </a-button>
            </a-space>
          </a-form-model-item>
        </a-form-model>
      </a-collapse-panel>
      <a-collapse-panel key="2" header="详情信息">
<!--        <a-button type="primary" @click="showTable">-->
<!--          Open-->
<!--        </a-button>-->
        <a-card>
          <a-form layout="inline" style="display: flex; justify-content: space-between; align-items: center;">
            <a-form-model-item >
              <a-select
                  style="width: 120px"
                  id="soil"
                  v-model="deviceId"
                  placeholder="请选择地块"
                  @change="getDevices">
                <a-select-option v-for="(item, index) in soilList" :key="index" >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-icon id="spin" @click="renovates" type="sync" style="color: #1879FE"/>
            </a-form-model-item>
          </a-form>
        </a-card>
          <a-table
              :bordered="false"
              :dataSource="dataSource"
              :columns="columns"
              @change="changePage"
              :pagination="pagination"
          >
          </a-table>

      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
const data = [
  {
    deviceId: '1',
    deviceStatus: 'John Brown',
    weatherStationstatus: 32,
    plotName: 'New York No. 1 Lake Park',
  },
  {
    deviceId: '2',
    deviceStatus: 'Jim Green',
    weatherStationstatus: 42,
    plotName: 'London No. 1 Lake Park',
  },
  {
    deviceId: '3',
    deviceStatus: 'Joe Black',
    weatherStationstatus: 32,
    plotName: 'Sidney No. 1 Lake Park',
  }
];
export default {
  name: "side-bar",
  props: {
    hideMenu: Function,
    setCenter: Function,
    showBottom: Function,
    setLock: Function
  },
  data() {
    return {
      ismap: 0,
      deviceId: 0,
      pagination: {
        size: "default",
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 6,
        showSizeChanger: false,
        showQuickJumper: true,
        // pageSizeOptions: ['2','10']
      },
      dataSource: null,
      columns: [
        // {
        //   title: '序号',
        //   dataIndex: 'id',
        //   align:'center',
        //   width: '16%'
        //   // scopedSlots: {customRender: 'name'},
        // },
        {
          title: '设备名称',
          dataIndex: 'equipmentName',
          align: 'center'
        },
        {
          title: '温度',
          dataIndex: 'temperatureValue',
          align: 'center'
        },
        {
          title: '湿度',
          dataIndex: 'humidityValue',
          align: 'center'
        },
        // {
        //   title: '创建时间',
        //   dataIndex: 'equipmentPutInStorage',
        //   width: '15%',
        //   align: 'center'
        // },
      ],
      expandIconPosition: 'right',
      activeKey: ['1'],
      form: {
        id: '',
        layout: 'vertical',
        name: undefined,
        type: '',
        devTypeNum: null,
        devType: undefined,
        deviceNum: null,
        // device: undefined,
        device: undefined,
        soilId: null,
        location: '',
        introduce: '',
      },
      soilList: [],
      typeList: [
        {
          id: 1,
          name: '气象站'
        }, {
          id: 2,
          name: '土壤仪'
        }, {
          id: 3,
          name: '地块'
        }
      ],
      deviceList: [],
      renovate: 0,
    }
  },
  mounted() {
    this.getSoilList()
    this.getDeviceList()
    window.setTimeout(() => {
      this.showData()
    }, 1000)
  },
  watch: {
    activeKey(key) {
      // console.log(key);
    },
  },
  methods: {
    renovates(e) {
      // console.log(this.renovate)
      // anticon-spin
      var spin = document.getElementById("spin")
      var svg = spin.getElementsByTagName("svg")[0]
      // console.log(svg)
      svg.setAttribute("class", "anticon-spin")
      window.setTimeout(() => {
        svg.removeAttribute("class")
        // console.log("remove")
      }, 1000)

      if (this.renovate) {
        this.getDevices(this.renovate)
      }
    },
    setDeviceNum(value) {
      // console.log(value)
      this.form.deviceNum = this.deviceList[value].id
    },
    setDeviceType(value) {
      // console.log(value)

      if(value === 2) {
        this.form.devTypeNum = -1
        this.form.deviceNum = 0
        this.form.device = '无'
        this.form.location = 0
      } else {
        this.form.devTypeNum = value
      }

    },
    showData() {
      let _this = this
      // console.log('Ok')

      // _this.$axios
      //     .get("/equipment/listEquipment", {
      //       params: {
      //         page: _this.pagination.current - 1,
      //         offset: _this.pagination.pageSize,
      //         tag: 0,
      //       },
      //     })
      //     .then((res) => {
      //       _this.dataSource = res.data.listEquipment.list;
      //       for(var i =0 ; i < _this.dataSource.length; i++) {
      //         _this.dataSource[i].equipmentName = res.data.listEquipment.list[i].name
      //         _this.dataSource[i].equipmentProvider = res.data.listEquipment.list[i].provide
      //         _this.dataSource[i].equipmentUser =  res.data.listEquipment.list[i].use
      //       }
      //
      //     });
      _this.getDevices(0)
    },
    changePage(page, filters, sorter){
      let _this = this
      _this.pagination.current = page.current
    },
    getDevices(value) {
      this.renovate = value
      // console.log(this.soilList[value])
      var id = this.soilList[value].id
      if (id != null) {
        this.$axios.request({
          method: 'GET',
          url: '/equipment/listEquipmentByPlotId',
          params: {
            id: id
          }
        }).then( res => {
          // console.log(res)
          this.dataSource = res.data.listEquipmentByPlotId
          for (var i = 0; i < this.dataSource.length; i++) {
            this.dataSource[i].temperatureValue = this.dataSource[i].temperatureValue/100
            this.dataSource[i].humidityValue = this.dataSource[i].humidityValue/100
          }
        })
      }
    },
    getDeviceList() {
      let _this = this

      _this.$axios.request({
        method: 'GET',
        url: '/equipment/getAllEquipment'
      }).then(res => {
        // console.log(res)
        _this.deviceList = res.data.data
      })
    },
    showTable() {
      this.hideMenu()
      this.showBottom()
    },
    getSoilList() {
      let _this = this

      _this.$axios.request({
        method: 'GET',
        url: '/cropsSumMap/getPlot'
      }).then(res => {
        // console.log(res)
        _this.soilList = res.data.data
        console(this.soilList)
      })
    },
    getTypeList() {
      let _this = this

      _this.$axios.request({
        method: 'GET',
        url: '/cropsSumMap/getPlot'
      }).then(res => {
        console.log(res)
        _this.typeList = res.data.data
      })
    },
    setCentral(value) {
      // console.log(this.soilList[value])
      var markInfo = this.soilList[value]
      console.log("markInfo", markInfo)
      if(markInfo === null) {
        this.$confirm({
          title: '提示信息',
          content: h => <div>无相关的标注信息</div>,
          onOk() {
            // console.log('OK');
          },
          onCancel() {
            // console.log('Cancel');
          }
        })
      } else {
        // console.log(JSON.parse(markInfo.location))
        let val = [115.490899, 38.856628]
        this.setCenter(val)
        // this.ismap = 1
        // let id = this.soilList[value].id
        // let name = this.soilList[value].name
        // let point = JSON.parse(115.490899, 38.856628)
        // console.log(point)
        // var devType = markInfo.devType
        // var inroduce = markInfo.soilContent

        // this.form.soilId = id
        // this.form.name = name

        // this.$axios.request({
        //   method: 'GET',
        //   url: '/equipment/listEquipmentByPlotId',
        //   params: {
        //     id: id
        //   }
        // }).then(res => {
        //   console.log(res)
        //   this.deviceList = res.data.listEquipmentByPlotId
        // })
        // console.log(this.form.soilId)
        // console.log(point)
        // this.setCenter(point)
        // this.setValue(id, devType, point, inroduce)
      }
    },
    clearValue() {
      var _this = this

      _this.form.id = ''
      _this.form.name = undefined
      _this.form.type = ''
      _this.form.devTypeNum = 0
      _this.form.devType = undefined
      _this.form.deviceNum = 0
      _this.form.device = undefined
      _this.form.soilId = 0
      _this.form.location = ''
      _this.form.introduce = ''
    },
    setValue(id, devType, location, introduce, type) {
      let _this = this

      if(id !== _this.form.id || location !== _this.form.location) {
        _this.clearValue()
      }

      // console.log(id)
      if(id === undefined) {

      } else {
        _this.form.id = id
        _this.$axios.request({
          method: 'GET',
          url: '/cropsSumMap/get/map',
          params: {
            id: id
          }
        }).then(res => {
          console.log(res)
          _this.form.soilId = res.data.id
          _this.form.name = res.data.name
          // _this.form.introduce = res.data.data.affiliation
        })

        // console.log(id)
        _this.$axios.request({
          method: 'POST',
          url: '/soil/getMarkerById',
          params: {
            id: id
          }
        }).then( res => {
          // console.log(res)
          _this.form.introduce = res.data.data.soilContent
        })
        _this.$axios.request({
          method: 'GET',
          url: '/equipment/listEquipmentByMarkerId',
          params: {
            id: id
          }
        }).then(res => {
          // console.log(res)
          _this.form.deviceNum = res.data.listEquipmentByMarkerId[0].id
          _this.form.device = res.data.listEquipmentByMarkerId[0].equipmentName
        })
      }

      // _this.form.devType = devType
      // console.log(devType)

      _this.form.type = type[0].toUpperCase() + type.substr(1, type.length)
      // console.log(devType)
      if(devType !== undefined) {
        _this.form.devTypeNum = devType
        _this.form.devType = _this.typeList[devType].name
        if(devType === 2) {
          _this.form.device = '无'
        }
      }
      _this.form.location = location
    },
    showMap() {
      var _this = this

      var id = _this.form.id

      // console.log(id)
      if (id.length === 0) {
        
          console.log(_this.form.deviceNum)
          _this.$confirm({
            title: '确认保存',
            content: h => <div>您确定保存此标注信息吗？</div>,
            onOk() {
              _this.$axios.request({
                method: 'POST',
                url: '/soil/addMarker',
                data: {
                  id: null,
                  devType: _this.form.devTypeNum,
                  devId: _this.form.deviceNum,
                  type: _this.form.type,
                  isBaidu: 1,
                  location: _this.form.location,
                  soilId: _this.form.soilId,
                  soilName: _this.form.name,
                  soilContent: _this.form.introduce
                }
              }).then(res => {
                // console.log('-----------------')
                console.log(res)
                // console.log('-----------------')
                // _this.$router.go(0);
              })
            },
            onCancel()
            {
              // console.log('Cancel');
            }
          })
       

      } else {
        _this.$confirm({
          title: '确认保存',
          content: h => <div>您确定保存此标注信息吗？</div>,
          onOk() {
            _this.$axios.request({
              method: 'POST',
              url: '/soil/upMarker',
              data: {
                id: _this.form.id,
                devType: _this.form.devTypeNum,
                devId: _this.form.deviceNum,
                type: _this.form.type,
                isBaidu: 1,
                location: _this.form.location,
                soilId: _this.form.soilId,
                soilName: _this.form.name,
                soilContent: _this.form.introduce
              }
            }).then(res => {
              // console.log('-----------------')
              // console.log(res)
              // console.log('-----------------')
              _this.$router.go(0);
            })

            console.log(_this.form.id)
          },
          onCancel()
          {
            // console.log('Cancel');
          }
        })
      }
      // this.hideMenu()
    },
    showPhoto() {
      // this.$router.push({ name:'map-message'})
      console.log("进入showphoto函数")
      let _this = this
      _this.$axios.request({
          method: 'GET',
          url: '/cropsSumMap/getSpectrum',
          params: {
            id: "151"
          }
        }).then(res => {
          console.log(res.data.data)
          var data = res.data.data
          var rgb = data[0]
          var green = data[1]
          var nir = data[2]
          var red = data[3]
          var red_edge = data[4]

        _this.$router.push({
            name: 'map-detection',
            params: {
              id: 151,
              rgb: rgb,
              green: green,
              nir: nir,
              red: red,
              red_edge: red_edge
            }
          })
        })
     

      // this.$router.push('/soil/map-soil')
      this.hideMenu()
    },

  }
}
</script>

<style>
#closeButton {
  position:absolute;
  left: 0;
  top: 0;
  height: 100%;
  padding: 0 5px;
  display: flex;
  align-items: center;
  z-index: 10;
}
#closeButton:hover {
  background: rgba(0,0,0,0.1);
}
.ant-drawer-body {
  padding: 5px;
}
.ant-collapse {
  margin-left: 20px;
  background: #fff;
}
.ant-collapse-header {
  font-size: 15px;
  font-weight: bolder;
}
.ant-collapse-content-box {
  background: #fff;
  /*padding: 5px 15px !important;*/
}
.ant-table-row {
  font-size: 14px !important;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 15px 3px !important;
}
</style>
