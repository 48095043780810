<template>
    <div>
<!--        <div class="shadow"  style=" margin-top:10px;margin-bottom: 4px;width: 50px;" >-->
<!--            <span style="margin-bottom: 10px">年份：</span>-->
<!--            <a-select-->
<!--                    show-search-->
<!--                    option-filter-prop="children"-->
<!--                    :placeholder="year"-->
<!--                    style="width: 120px"-->
<!--                    filterable-->
<!--                    :default-value="new Date().getFullYear()"-->
<!--                    @change="getMsg">-->
<!--                <a-select-option-->
<!--                        v-for="item in optionsYear"-->
<!--                        :key="item"-->
<!--                        :label="item"-->
<!--                        :value="item">{{item}}-->
<!--                </a-select-option>-->
<!--            </a-select>-->
<!--        </div>-->
        <div  style="background-color: #FFFFFF;text-align: center;min-width: 600px; width:50%;display: inline-block; float: left;" >
            <count :showPromptMessage="showPromptMessage"
            :showWarningLine="showWarningLine"
            :year='this.year'
                    class="shadow" />
        </div>
        <div style="background-color: #FFFFFF;text-align: center;min-width: 600px; width:50%;display: inline-block;">
            <weight class="shadow" :showPromptMessage="showPromptMessage"
                    :showWarningLine="showWarningLine"
                    :year='this.year'/>
        </div>
        <div  style="background-color: #FFFFFF;box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px; margin:0; min-width: 600px; width:100%;" >
            <count1 :showPromptMessage="showPromptMessage"
                    :showWarningLine="showWarningLine"
                    :year='this.year'/>
        </div>
        <div  style="margin-top:5px;background-color: #FFFFFF;box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;margin:0;min-width: 600px; width:100%;display: inline-block;">
            <weight2 :showPromptMessage="showPromptMessage"
                     :showWarningLine="showWarningLine"
                     :year='this.year'/>
        </div>
    </div>

</template>

<script>
    import count from '../charts/quantity-count'
    import count1 from '../charts/quantity-count1'
    import count2 from '../charts/quantity-count2'
    import weight from '../charts/quantity-weight'
    import weight2 from '../charts/quantity-weight2'

    export default {
        name: "singular",
        components:{count,count1,count2,weight,weight2,},
        data(){
            return {
                optionsYear:[],
                year:'',
                selectYear:true,
                showWarningLine:false,
                showPromptMessage:false,
                anormal:0, //异常值百分比
                completeness:0.1,
                testWarn:'总体',
                testComplete:'总体',
            };
        },
        beforeMount() {
            //加载年份
            this.$axios.post("/indicator/getYear").then((res)=>{
                this.optionsYear=res.data
                // this.year=res.data[0]
            })
            this.getMsg(new Date().getFullYear())
        },
        methods:{
            getMsg(val){
                this.year=val;
            }
        },
    }
</script>

<style scoped>

</style>