<template>
  <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
    <a-form-model-item label="名称">
      <a-input v-model="form.name"/>
    </a-form-model-item>

    <a-form-model-item label="年份">
      <a-input-number
              id="year"
              v-model="form.year" :min="0"
              @change=""
      />
    </a-form-model-item>
    <!--     使用 dateRender 可以自定义日期单元格的内容和样式。   -->
    <!--        使用 format 属性，可以自定义日期显示格式。-->
<!--    :default-value="[moment('2015/01/01', dateFormat), moment('2015/01/01', dateFormat)]"-->
<!--    :format="dateFormat"-->
<!--    <a-date-picker :default-value="moment('2015/01/01', dateFormat)" :format="dateFormat" />-->
    <a-form-model-item label="起始日期">
      <a-date-picker
                     @change="changeStartTime"
      />
    </a-form-model-item>


    <a-form-model-item label="周数">
      <a-input-number
              id="weeks"
              v-model="form.weeks" :min="0"
              @change=""
      />
    </a-form-model-item>


  </a-form-model>
</template>
<script>
  import moment from 'moment';
  export default {
    name: 'Form',
    props:{
      getList: Function
    },
    data() {
      return {
        dateFormat: 'YYYY-MM-DD',
        // monthFormat: 'YYYY/MM',
        // dateFormatList: ['DD/MM/YYYY', 'DD/MM/YY'],
        dates:[],
        dateStrings: [],
        labelCol: { span: 6 },
        wrapperCol: { span: 15 },
        form: {
          name: '',
          endTime: undefined,
          startTime: undefined,
          weeks: undefined,
          year: undefined,
          id: ''

        },
      };
    },
    created() {

    },

    watch: {
      value(val) {
        console.log(`selected:`, val);
      },
    },
    methods: {
      changeStartTime(date, dateString) {
        // console.log(dateString);
        // console.log("dateString")
        // console.log(date);
        // console.log("date")
        this.form.startTime = dateString;
      },
      // moment,
      // onChange(date, dateString) {
      //   console.log(dateString);
      //   console.log("dateString")
      //   let _this = this
      //   _this.form.startTime = dateString[0];
      //   _this.form.endTime = dateString[1];
      // },
      showUpdateDate(e) {
        console.log("showUpdateDate")
        console.log(e)
        console.log(this)
        let _this = this

        console.log("e.name:"+e.name)

        this.$nextTick( () => {
          console.log(_this.form)
          _this.form = e;
          _this.dates = [e.startTime, e.endTime];
          _this.dateStrings = [_this.dateFormat, _this.dateFormat];
          console.log(_this.dates)
          console.log(_this.dateStrings)
        })

      },
      handleChange(value) {
        console.log(`selected ${value}`);
        // let _this = this
        // _this.indictorsGroup = value
      },

      onUpdate() {
        console.log("edit-->onUpdate")
        let _this = this
        let collectionPhase = _this.form;
        console.log(collectionPhase);
        _this.$axios.request({
          method: 'POST',
          url: '/collectionPhase/update',
          data: collectionPhase
        }).then(res => {
          console.log("res")
          console.log(res)
          _this.getList()
        })


        // _this.$axios.post('/indicators/update',
        //         {indicator}, //对象
        //         {params:{
        //           //属性
        //           }})
      },

      onAdd() {
        let _this = this
        let collectionPhase = _this.form;
        console.log(collectionPhase);

        _this.$axios.request({
          method: 'POST',
          url: '/collectionPhase/add',
          data: collectionPhase
        }).then(res => {
          console.log("res")
          console.log(res)
          _this.getList()
        })
      },

      onSubmit() {
        let _this = this
        console.log('submit!', _this.form);
        _this.onAdd()

      },
    }
  };
</script>
