<template>
    <div >
<!--        <a-layout>-->
<!--            <a-layout-sider height="100%" width="200" style="background: #fff">-->
<!--                <a-menu-->
<!--                        mode="inline"-->
<!--                        :default-selected-keys="['1']"-->
<!--                        :default-open-keys="['sub1']"-->
<!--                        :style="{ height: '100%', borderRight: 0 }"-->
<!--                >-->
<!--                    <a-menu-item >-->
<!--                        模板信息表-->
<!--                        <router-link to="/collect/template"></router-link>-->
<!--                    </a-menu-item>-->

<!--&lt;!&ndash;                    <a-menu-item >&ndash;&gt;-->
<!--&lt;!&ndash;                        模板列表&ndash;&gt;-->
<!--&lt;!&ndash;                        <router-link to="/collect/collection-plan-template"></router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                    </a-menu-item>&ndash;&gt;-->
<!--&lt;!&ndash;                    <a-menu-item >&ndash;&gt;-->
<!--&lt;!&ndash;                        采集执行表&ndash;&gt;-->
<!--&lt;!&ndash;                        <router-link to="/collect/exe"></router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                    </a-menu-item>&ndash;&gt;-->
<!--                    <a-sub-menu key="sub2">-->
<!--                        <span slot="title"><a-icon type="laptop" />模板列表</span>-->
<!--                        <a-menu-item key="5">-->
<!--                            模板名称1-->
<!--                            <router-link to="/collect/collection-plan-template"></router-link>-->
<!--                        </a-menu-item>-->
<!--                        <a-menu-item key="6">-->
<!--                            模板名称2-->
<!--                        </a-menu-item>-->
<!--                        <a-menu-item key="7">-->
<!--                            模板名称3-->
<!--                        </a-menu-item>-->
<!--                        <a-menu-item key="8">-->
<!--                            模板名称4-->
<!--                        </a-menu-item>-->
<!--                    </a-sub-menu>-->
<!--                    <a-sub-menu key="sub3">-->
<!--                        <span slot="title"><a-icon type="notification" />subnav 3</span>-->
<!--                        <a-menu-item key="9">-->
<!--                            option9-->
<!--                        </a-menu-item>-->
<!--                        <a-menu-item key="10">-->
<!--                            option10-->
<!--                        </a-menu-item>-->
<!--                        <a-menu-item key="11">-->
<!--                            option11-->
<!--                        </a-menu-item>-->
<!--                        <a-menu-item key="12">-->
<!--                            option12-->
<!--                        </a-menu-item>-->
<!--                    </a-sub-menu>-->
<!--                </a-menu>-->
<!--            </a-layout-sider>-->

<!--            <a-layout style="padding: 0 24px 24px">-->
<!--                <a-layout-content-->
<!--                        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '960px', }"-->
<!--                >-->
<!--                    <router-view/>-->
<!--                </a-layout-content>-->
<!--            </a-layout>-->
<!--        </a-layout>-->
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: "collect"
    }
</script>

<style scoped>

</style>