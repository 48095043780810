<template>
    <div id="weight2" style="margin: auto;width: 80%;height: 300px">
        <h1>图片未显示</h1>
    </div>
</template>

<script>
    export default {
        name: "quantity-weight2",
        props:{//接受传入的参数
            'year': {},
        },
        data () {
            return {
                optionsYear: [],
            };
        },
        methods:{
            getMsg(val){
                let _this = this
                this.$axios.post('/indicator/getBar21',val).then(function (response) {
                    // console.log(response)
                    let myChart = _this.$echarts.init(document.getElementById('weight2'))
                    myChart.setOption({
                        title: {
                            text: _this.year+'年不同等级结薯重量',
                            left: 'center'
                        },
                        xAxis: {
                            axisLabel: {
                                interval:0,
                                rotate : 50},
                            type: 'category',
                            data:['2两以下','2-3两','3两以上','大薯块平均','中薯块平均','小薯块平均','特小薯块平均','平均重量']
                            // data: ['2两以下', '2-3两', '3两以上', '单株大薯块平均重量', '单株中薯块平均重量', '单株小薯块平均重量', '单株特小薯块平均重量','单株平均结薯重量']
                        },
                        yAxis: {
                            name:'g',
                            type: 'value'
                        },
                        tooltip: {},
                        series: [
                            {
                                data: response.data,
                                type: 'bar',
                                // showBackground: true,
                                backgroundStyle: {
                                    color: 'rgba(180, 180, 180, 0.2)'
                                }
                            }
                        ]
                    })
                })

            },
            changeChart(){
                this.getMsg(this.year)
            }
        },
        beforeMount() {
            this.getMsg(new Date().getFullYear())
        },
        watch:{
            year:'changeChart'
        },
    }
</script>

<style scoped>

</style>