<template>
	<div class="card-box">

  <div class="card"  v-for="item in innerColumns" :key="item.id">

      <div class="card" :class="active(2) || active(3)" >
        <div class="card-header">
          <h1 class="card-fullname">{{item.title}}</h1>
          <h2 class="card-jobtitle">马铃薯生长周期</h2>
        </div>
        
        <div class="card-main">
          
          <!--第一页-->
          <div class="card-section" :class="active(1)">
            <!-- <div class="card-content">
              <div class="card-subtitle">关于</div>
              <p class="card-desc"></p>
            </div> -->
            <!--三个图标-->
            
          </div>
          
          <!--第二页-->
          <div class="card-section" :class="active(2)">
            <div class="card-content">
              <div class="card-subtitle">采集流程</div>
              
              <div class="card-timeline">
                <div class="card-item" :data-year="item.time"  v-for="item in timeline" :key="item.id">
                  <div class="card-item-title">{{item.title}}</div>
                  <div class="card-item-desc">{{item.description}}</div>
                </div>
              </div>
            </div>
          </div>
          
          <!--第三页-->
          <div class="card-section" :class="active(3)">
            <div class="card-content">
              <div class="card-subtitle">指标详情</div>
			  
              <div class="card-contact-wrapper">
                <div class="card-contact" v-for="item1 in contact1[item.priod_id]" :key="item.id">
                  <img :src="item1.image">
                  <span>{{item1.info}}</span>
				  <button class="contact-me" @click="showTrack(item.priod_id,item1.info)">进行追踪</button>
                </div>
				<!-- <h1 class="card-fullname">{{item.priod_id}}</h1> -->
                <!-- <button class="contact-me">进行追踪</button> -->

				<el-dialog title="指标采集详情" :visible.sync="detailDialogVisible" size="small" >
					<div class="card-timeline">
						<div class="card-item" :data-year="item.time"  v-for="item in TrackDetal" :key="item.id">
						  <div class="card-item-title">{{item.title}}</div>
						  <div class="card-item-desc">{{item.description}}</div>
						</div>
					  </div>
				</el-dialog>
				

              </div>
            </div>
          </div>
          
          <!--底部按钮-->
          <div class="card-buttons">
            <button :class="active(1)" @click="selectTab(1)">阶段</button>
            <button :class="active(2)" @click="selectTab(2)">流程</button>
            <button :class="active(3)" @click="selectTab(3)">指标</button>
          </div>
        </div>
      </div>

  </div>
   
	</div>
</template>

<script>
export default {
	name: "fill-plan",
	data(){
		return {
			period:0,
			IndicatorId:0,
			detailDialogVisible:false,
			index: 1,
			imageUrl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg01.e23.cn%2F2020%2F0224%2F20200224041337504.jpg&refer=http%3A%2F%2Fimg01.e23.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1662453154&t=821e7d98bb6796c7da0b1039184239d1#pskt#&imgName=%u70B9%u51FB%u67E5%u770B%u56FE%u7247%u6765%u6E90&imgLink=https%3A%2F%2Fimage.baidu.com%2Fsearch%2Findex%3Ftn%3Dbaiduimage%26ps%3D1%26ct%3D201326592%26lm%3D-1%26cl%3D2%26nc%3D1%26ie%3Dutf-8%26dyTabStr%3DMCwzLDEsMiw2LDQsNSw3LDgsOQ%253D%253D%26word%3D%25E5%2591%25A8%25E6%259D%25B0%25E4%25BC%25A6',
			
			innerColumns : [
				{ title: '播种期',float:'right', children: [{title:'',dataIndex: 'boZhong', key: 'boZhong',},],priod_id: 1},
				{ title: '发芽期',  children: [{title:'',dataIndex:'faYa', key: 'faYa'}],priod_id: 2},
				{ title: '幼苗期', children:[{title:'',dataIndex: 'youMiao', key: 'youMiao' ,}],priod_id: 3},
				{ title: '发棵期', children:[{title:'',dataIndex: 'faKe', key: 'faKe' ,}],priod_id: 4},
				{ title: '结薯期', children:[{title:'',dataIndex: 'jieShu', key: 'jieShu' ,}] ,priod_id: 5},
				{ title: '成熟期',children:[{title:'',dataIndex: 'chengShu', key: 'chengShu',}],priod_id: 6}
			],
			TrackDetal:{},//跟踪详情信息
			timeline:[
				{time:'2023', title:'系统展示', description:'系统进行追踪展示'},
				{time:'2023', title:'数据分析', description:'智能图像识别进行数据检测'},
				{time:'2023', title:'数据上传', description:'使用小程序进行上传'},
				{time:'2023', title:'邮寄检测', description:'元素等需送检'},
				{time:'2023', title:'拍照留证', description:'使用手机拍照'},
				{time:'2023', title:'数据测量', description:'使用卷尺测量'},
			],
				
			contact:[
				
			],
			contact1:[
				[],
				[
				{image:require('../../../assets/images/change_skin.png'), info:'土壤氮含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤磷含量'},
				{image: require('../../../assets/images/change_skin.png'), info: '土壤钾含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤钙含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤镁含量'},
				{image: require('../../../assets/images/change_skin.png'), info: '土壤硼含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤锰含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤锌含量'},
				{image: require('../../../assets/images/change_skin.png'), info: '土壤铁含量'},
				{image: require('../../../assets/images/change_skin.png'), info: '土壤铜含量'}],
				
				[{image:require('../../../assets/images/change_skin.png'), info:'芽长'},
				{image:require('../../../assets/images/change_skin.png'), info:'50%出苗时间'},
				{image: require('../../../assets/images/change_skin.png'), info: '出苗数'}],

				[{image:require('../../../assets/images/change_skin.png'), info:'水源PH'},
				{image:require('../../../assets/images/change_skin.png'), info:'水源EC'},
				{image:require('../../../assets/images/change_skin.png'), info:'水源氮含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'主茎粗度'},
				{image:require('../../../assets/images/change_skin.png'), info:'株高'},
				{image:require('../../../assets/images/change_skin.png'), info:'叶片数'},
				{image:require('../../../assets/images/change_skin.png'), info:'冠层覆盖'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤EC(10cm)'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤EC(20cm)'},
				{image: require('../../../assets/images/change_skin.png'), info: '土壤EC(30cm)'},
				{image: require('../../../assets/images/change_skin.png'), info: '出苗数'}],

				[{image:require('../../../assets/images/change_skin.png'), info:'株高'},
				{image:require('../../../assets/images/change_skin.png'), info:'冠层覆盖'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤EC(10cm)'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤EC(20cm)'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤EC(30cm)'},
				{image:require('../../../assets/images/change_skin.png'), info: '出苗数'},
				{image:require('../../../assets/images/change_skin.png'), info:'根系情况'},
				{image:require('../../../assets/images/change_skin.png'), info:'冠层覆盖'},
				{image: require('../../../assets/images/change_skin.png'), info:'叶柄氮含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'叶柄磷含量'},
				{image: require('../../../assets/images/change_skin.png'), info: '叶柄钾含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'叶柄钙含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'叶柄镁含量'},
				{image: require('../../../assets/images/change_skin.png'), info: '叶柄硼含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'叶柄锰含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'叶柄锌含量'},
				{image: require('../../../assets/images/change_skin.png'), info: '叶柄铁含量'},
				{image: require('../../../assets/images/change_skin.png'), info: '叶柄铜含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'测产2两以下结薯重量'},
				{image:require('../../../assets/images/change_skin.png'), info:'测产2到3两结薯重量'},
				{image: require('../../../assets/images/change_skin.png'), info: '测产3两以上结薯重量'},
				{image: require('../../../assets/images/change_skin.png'), info: '测产单株大薯平均结薯重量'},
				{image: require('../../../assets/images/change_skin.png'), info: '测产单株中薯平均结薯重量'},
				{image:require('../../../assets/images/change_skin.png'), info:'测产2两以下结薯数量'},
				{image:require('../../../assets/images/change_skin.png'), info:'测产2到3两结薯数量'},
				{image: require('../../../assets/images/change_skin.png'), info: '测产3两以上结薯数量'},
			],

			[{image:require('../../../assets/images/change_skin.png'), info:'株高'},
				{image:require('../../../assets/images/change_skin.png'), info:'冠层覆盖'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤EC(10cm)'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤EC(20cm)'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤EC(30cm)'},
				{image:require('../../../assets/images/change_skin.png'), info: '出苗数'},
				{image:require('../../../assets/images/change_skin.png'), info:'根系情况'},
				{image:require('../../../assets/images/change_skin.png'), info:'冠层覆盖'},
				{image: require('../../../assets/images/change_skin.png'), info:'叶柄氮含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'叶柄磷含量'},
				{image: require('../../../assets/images/change_skin.png'), info: '叶柄钾含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'叶柄钙含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'叶柄镁含量'},
				{image: require('../../../assets/images/change_skin.png'), info: '叶柄硼含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'叶柄锰含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'叶柄锌含量'},
				{image: require('../../../assets/images/change_skin.png'), info: '叶柄铁含量'},
				{image: require('../../../assets/images/change_skin.png'), info: '叶柄铜含量'},
				{image:require('../../../assets/images/change_skin.png'), info:'测产2两以下结薯重量'},
				{image:require('../../../assets/images/change_skin.png'), info:'测产2到3两结薯重量'},
				{image: require('../../../assets/images/change_skin.png'), info: '测产3两以上结薯重量'},
				{image: require('../../../assets/images/change_skin.png'), info: '测产单株大薯平均结薯重量'},
				{image: require('../../../assets/images/change_skin.png'), info: '测产单株中薯平均结薯重量'},
				{image:require('../../../assets/images/change_skin.png'), info:'测产2两以下结薯数量'},
				{image:require('../../../assets/images/change_skin.png'), info:'测产2到3两结薯数量'},
				{image: require('../../../assets/images/change_skin.png'), info: '测产3两以上结薯数量'},
				{image: require('../../../assets/images/change_skin.png'), info: '经济系数'}
			],

				[{image:require('../../../assets/images/change_skin.png'), info:'株高'},
				{image:require('../../../assets/images/change_skin.png'), info:'冠层覆盖'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤EC(10cm)'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤EC(20cm)'},
				{image:require('../../../assets/images/change_skin.png'), info:'土壤EC(30cm)'},
				{image:require('../../../assets/images/change_skin.png'), info:'冠层覆盖'},
				{image:require('../../../assets/images/change_skin.png'), info:'测产2两以下结薯数量'},
				{image:require('../../../assets/images/change_skin.png'), info:'测产2到3两结薯数量'},
				{image: require('../../../assets/images/change_skin.png'), info: '测产3两以上结薯数量'},
				{image:require('../../../assets/images/change_skin.png'), info:'匍匐茎长度'},
				{image: require('../../../assets/images/change_skin.png'), info: '匍匐茎数量'},
			]
				//获取指定的指标值
			]   ,
			
		}
		
	},
	methods:{
		showTrack( param1, param2){
			let _this = this
			console.log("进行弹窗显示")
			this.detailDialogVisible = true;
			console.log("阶段"+param1)
			console.log("指标"+param2)
			
			this.$axios.get('/templateDetailinfo1/get_all_by_type',{
				params: {
					period: param1,
					IndicatorName: param2
				}
			}).then(res => {
				console.log(res)
				let _date = res.data.data;
				_this.TrackDetal = res.data.data;
				console.log(_this.TrackDetal)

			})

		},
		selectTab(index){
			this.index = index;
		},
		active(index){
			return index === this.index ? 'is-active' : ''
		}
	},
	computed:{
	
	}
}
</script>

<style lang="scss" scoped>

@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500|Jost:400,500,600&display=swap");


.card-box {
	font-family: "Jost", sans-serif;
	display: flex;
	flex-direction: column;
	//flex-wrap: wrap;
	background-color: rgba(16, 18, 27, 0.1);
	align-items: center;
	overflow: auto;
	width: 100%;
	
}

.card {
	width: 100%;
	background-color: rgba(255, 255, 255, 1);
	display: flex;
	flex-direction: column;
	transition: 0.5s;
	border-radius: 10px;
	box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
	margin: 10px 0;
}

.card-header {
	position: relative;
	display: flex;
	height: 200px;
	flex-shrink: 0;
	width: 100%;
	transition: 0.5s;
	
	*{
		transition: 0.5s;
	}
	.card-avatar {
		width: 100px;
		height: 100px;
		box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
		border-radius: 50%;
		object-position: center;
		object-fit: cover;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%) translateY(-64px);
	}
	.card-fullname {
		position: absolute;
		bottom: 0;
		font-size: 22px;
		font-weight: 700;
		text-align: center;
		white-space: nowrap;
		transform: translateY(-10px) translateX(-50%);
		left: 50%;
	}
	
	.card-jobtitle {
		position: absolute;
		bottom: 0;
		font-size: 11px;
		white-space: nowrap;
		font-weight: 500;
		opacity: 0.7;
		text-transform: uppercase;
		letter-spacing: 1.5px;
		margin: 0;
		left: 50%;
		transform: translateX(-50%) translateY(-7px);
	}
}

.card.is-active {
	.card-header {
		height: 80px;
	}
	
	.card-avatar {
		transform: none;
		left: 20px;
		width: 50px;
		height: 50px;
		bottom: 10px;
	}
	
	.card-fullname,
	.card-jobtitle {
		left: 86px;
		transform: none;
	}
	
	.card-fullname {
		bottom: 18px;
		font-size: 19px;
	}
	
	.card-jobtitle {
		bottom: 16px;
		letter-spacing: 1px;
		font-size: 10px;
	}
}

.card-main {
	position: relative;
	flex: 1;
	display: flex;
	align-items: center;
	padding-top: 10px;
	flex-direction: column;
	
	.card-section {
		display: none;
		
		&.is-active {
			display: block;
			animation: fadeIn 0.6s both;
		}
		
		.card-content {
			padding: 20px;
			
			.card-subtitle {
				font-weight: 700;
				font-size: 13px;
				margin-bottom: 8px;
			}
			
			//第一块
			.card-desc {
				line-height: 1.6;
				color: #636b6f;
				font-size: 14px;
				margin: 0;
				font-weight: 400;
				font-family: "DM Sans", sans-serif;
			}
			
			//第二块
			.card-timeline {
				margin-top: 30px;
				position: relative;
				
				&:after {
					background: linear-gradient(
							to top,
							rgba(134, 214, 243, 0) 0%,
							rgba(81, 106, 204, 1) 100%
					);
					content: "";
					left: 42px;
					width: 2px;
					top: 0;
					height: 100%;
					position: absolute;
				}
				
				.card-item {
					position: relative;
					padding-left: 60px;
					padding-right: 20px;
					padding-bottom: 30px;
					z-index: 1;
					
					&:last-child {
						padding-bottom: 5px;
					}
					
					&:after {
						content: attr(data-year);
						width: 10px;
						position: absolute;
						top: 0;
						left: 37px;
						width: 8px;
						height: 8px;
						line-height: 0.6;
						border: 2px solid #fff;
						font-size: 11px;
						text-indent: -35px;
						border-radius: 50%;
						color: rgba(#868686, 0.7);
						background: linear-gradient(
								to bottom,
								lighten(#516acc, 20%) 0%,
								#516acc 100%
						);
					}
					
					.card-item-title {
						font-weight: 500;
						font-size: 14px;
						margin-bottom: 5px;
					}
					.card-item-desc {
						font-size: 13px;
						color: #6f6f7b;
						line-height: 1.5;
						font-family: "DM Sans", sans-serif;
					}
				}
				
			}
			
			//第三块
			.card-contact-wrapper {
				margin-top: 20px;
				
				.card-contact {
					display: flex;
					align-items: center;
					font-size: 13px;
					color: #6f6f7b;
					font-family: "DM Sans", sans-serif;
					line-height: 1.6;
					cursor: pointer;
					margin-bottom: 10px;
					
					& + & {
						margin-top: 16px;
					}
					
					img {
						flex-shrink: 0;
						width: 25px;
						height: 25px;
						transition: 0.3s;
						margin-right: 10px;
					}
					span{
						border-left: 1px solid #dfe2ec;
						padding-left: 12px;
					}
				}
				.contact-me {
					border: 0;
					outline: none;
					background: linear-gradient(
							to right,
							rgba(83, 200, 239, 0.8) 0%,
							rgba(81, 106, 204, 0.8) 96%
					);
					box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
					color: #fff;
					padding: 12px 16px;
					width: 100%;
					border-radius: 5px;
					margin-top: 25px;
					cursor: pointer;
					font-size: 14px;
					font-weight: 500;
					font-family: "Jost", sans-serif;
					transition: 0.5s;
				}
			}
			
		}
		
		//第一块
		.card-social {
			display: flex;
			align-items: center;
			padding: 0 20px;
			margin-bottom: 30px;
			
			svg {
				fill: rgb(165, 181, 206);
				width: 22px;
				display: block;
				transition: 0.5s;
			}
			
			a {
				color: #8797a1;
				height: 32px;
				width: 32px;
				border-radius: 50%;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				transition: 0.5s;
				background-color: rgba(93, 133, 193, 0.05);
				margin-right: 10px;
				
				&:hover {
					svg {
						fill: darken(rgb(165, 181, 206), 20%);
					}
				}
				
				&:last-child {
					margin-right: 0;
				}
			}
			
			
		}
	}
	
	//底部按钮
	.card-buttons {
		display: flex;
		background-color: #fff;
		margin-top: auto;
		width: 98%;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		
		button {
			flex: 1 1 auto;
			user-select: none;
			background: 0;
			font-size: 13px;
			border: 0;
			padding: 15px 5px;
			cursor: pointer;
			color: #5c5c6d;
			transition: 0.5s;
			font-family: "Jost", sans-serif;
			font-weight: 500;
			outline: 0;
			border-bottom: 3px solid transparent;
			
			&.is-active,
			&:hover {
				color: #2b2c48;
				border-bottom: 3px solid #8a84ff;
				background: linear-gradient(
						to bottom,
						rgba(127, 199, 231, 0) 0%,
						rgba(207, 204, 255, 0.2) 44%,
						rgba(211, 226, 255, 0.4) 100%
				);
			}
		}
	}
}


</style>