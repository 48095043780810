var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"white","height":"100vh"}},[_c('a-radio-group',{staticStyle:{"position":"relative","top":"40px","left":"40px"},model:{value:(_vm.switch2D),callback:function ($$v) {_vm.switch2D=$$v},expression:"switch2D"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("上传遥感图像")]),_c('br'),_c('a-radio',{attrs:{"value":0}},[_vm._v("上传点云模型")])],1),(_vm.switch2D==1)?_c('a-form',_vm._b({staticStyle:{"background-color":"white","padding":"20px","padding-top":"50px"},attrs:{"id":"components-form-demo-validate-other","form":_vm.form},on:{"submit":_vm.handleSubmit}},'a-form',_vm.formItemLayout,false),[_c('a-form-model-item',{attrs:{"label":"地图名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name',
        { rules: [{ required: true, message: '地图名称' }] },
      ]),expression:"[\n        'name',\n        { rules: [{ required: true, message: '地图名称' }] },\n      ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"地图名称"}})],1),_c('a-form-model-item',{attrs:{"label":"创建时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'formatTime',
        { rules: [{ required: true, message: '创建时间' }] },
      ]),expression:"[\n        'formatTime',\n        { rules: [{ required: true, message: '创建时间' }] },\n      ]"}],staticStyle:{"width":"100%"},attrs:{"show-time":"","type":"date","placeholder":"选择日期"}})],1),_c('a-form-model-item',{attrs:{"label":"是否具有NVDI数据"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'isNvdi',
        { rules: [{ required: true, message: '是否具有NVDI数据' }] },
      ]),expression:"[\n        'isNvdi',\n        { rules: [{ required: true, message: '是否具有NVDI数据' }] },\n      ]"}]},[_c('a-radio',{attrs:{"value":"1"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"0"}},[_vm._v("否")])],1)],1),_c('a-form-model-item',{attrs:{"label":"是否切片","prop":"isTiles"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'isTiles',
        { rules: [{ required: true, message: '是否切片' }] },
      ]),expression:"[\n        'isTiles',\n        { rules: [{ required: true, message: '是否切片' }] },\n      ]"}]},[_c('a-radio',{attrs:{"value":"1"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"0"}},[_vm._v("否")])],1)],1),_c('a-form-model-item',{attrs:{"label":"是否上传","prop":"isUpload"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'isUpload',
        { rules: [{ required: true, message: '是否上传' }] },
      ]),expression:"[\n        'isUpload',\n        { rules: [{ required: true, message: '是否上传' }] },\n      ]"}]},[_c('a-radio',{attrs:{"value":"1"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"0"}},[_vm._v("否")])],1)],1),_c('a-form-item',{attrs:{"label":"所属地图"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'map',
        { rules: [{ required: true, message: '请选择所属地图' }] },
      ]),expression:"[\n        'map',\n        { rules: [{ required: true, message: '请选择所属地图' }] },\n      ]"}],attrs:{"placeholder":"所属地图"}},_vm._l((_vm.plotData),function(item,index){return _c('a-select-option',{attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.affiliation)+" ："+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"是否需要切分"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['switch', { valuePropName: 'checked' }]),expression:"['switch', { valuePropName: 'checked' }]"}]})],1),_c('a-form-item',{attrs:{"label":"地图类型","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'type',
        { rules: [{ required: true, message: '请选择地图类型' }] },
      ]),expression:"[\n        'type',\n        { rules: [{ required: true, message: '请选择地图类型' }] },\n      ]"}],attrs:{"placeholder":"地图类型"}},[_c('a-select-option',{attrs:{"value":"rgb"}},[_vm._v(" rgb ")]),_c('a-select-option',{attrs:{"value":"red"}},[_vm._v(" red ")]),_c('a-select-option',{attrs:{"value":"green"}},[_vm._v(" green ")]),_c('a-select-option',{attrs:{"value":"red_edge"}},[_vm._v(" red_edge ")]),_c('a-select-option',{attrs:{"value":"nir"}},[_vm._v(" nir ")])],1)],1),_c('a-form-item',{attrs:{"label":"上传地块文件"}},[_c('div',{staticClass:"dropbox"},[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'dragger',
          {
            valuePropName: 'fileList',
            getValueFromEvent: _vm.normFile,
          },
        ]),expression:"[\n          'dragger',\n          {\n            valuePropName: 'fileList',\n            getValueFromEvent: normFile,\n          },\n        ]"}],attrs:{"customRequest":_vm.customRequest}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(" 点击区域上传 / 拖拽到本区域上传 ")]),_c('p',{staticClass:"ant-upload-hint"},[_vm._v(" 支持单个或多个上传 ")])])],1)]),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 6 }}},[_c('a-button',{staticStyle:{"margin-top":"20px"},attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 确认 ")])],1)],1):_vm._e(),(_vm.switch2D==0)?_c('Upload3D'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }