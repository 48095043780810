<template>
  <div style="display: flex; justify-content: space-between; align-items: center">
    <div id="closeButton" @click="hideMenu">
      <a-icon type="right" style="font-size: 16px; font-weight: bolder;"/>
    </div>
    <div style="width: 100%;">
      <div class="soil-funcs">

        <div class="soil-func" @click="backToMap">
          <div class="soil-func-icon">
            <a-icon type="left-square" />
          </div>
          <div class="soil-func-title">
            <div>重选</div>
          </div>
        </div>
  
        <div class="soil-func" @click="openDraws">
          <div class="soil-func-icon">
            <a-icon type="plus-square" />
          </div>
          <div class="soil-func-title">
            <div>{{ isDraw ? '打开绘制' : '关闭绘制' }}</div>
          </div>
        </div>
  
        <div class="soil-func" @click="showChangeJSON1">
          <div class="soil-func-icon">
            <a-icon type="pic-left" />
          </div>
          <div class="soil-func-title">
            <div>切换出苗显示</div>
          </div>
        </div>
  
        <div class="soil-func" @click="savePolygon" v-show="!isDraw">
          <div class="soil-func-icon">
            <a-icon type="plus-square" />
          </div>
          <div class="soil-func-title">
            <div>保存</div>
          </div>
        </div>
  
  
      </div>
  
      <a-modal title="切换图层" :visible="visible1" @ok="changeLayer" @cancel="handleCancel">
        <span>请选择图层类型: </span>
        <a-select :default-value="layerType" style="width: 200px" @change="setLayerType">
          <a-select-option value="RGB">RGB</a-select-option>
          <a-select-option value="GREEN">GREEN</a-select-option>
          <a-select-option value="NIR">NIR</a-select-option>
          <a-select-option value="RED">RED</a-select-option>
          <a-select-option value="RED_EDGE">RED_EDGE</a-select-option>
        </a-select>
      </a-modal>

      <a-modal title="切换" :visible="visible" @ok="showChangeJSON" @cancel="handleCancel">
        <span>请选择出苗展示类型: </span>
        <a-select :default-value="1" style="width: 200px" @change="showChangeJSON">
          <a-select-option value="0">全部数据缩放展示</a-select-option>
          <a-select-option value="1">仅自定义数据展示</a-select-option>
        </a-select>
      </a-modal>
  
      <a-collapse v-model="activeKey" :bordered="false" :expand-icon-position="expandIconPosition" style="width: 100%;">
  
  
  
  
        <a-collapse-panel key="2" header="总体出苗结果">
  
          <a-card>
            <a-form layout="inline" style="display: flex; justify-content: space-between; align-items: center;">
  
              <a-form-model-item>
                选择地块
                <a-select style="width: 120px" id="soil" v-model="deviceId" placeholder="请选择地图" >
                  <a-select-option v-for="(item, index) in soilList" :key="index" >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form>
  
            <a-form layout="inline" style="display: flex; justify-content: space-between; align-items: center;">
              <a-form-model-item>
                选择年份
                <a-select style="width: 120px" id="years" v-model="year" placeholder="请选择年份" @change="getDevices1">
                  <a-select-option v-for="(item, index1) in allYears" :key="index1">
                    {{ item }}年
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form>
  
            <a-form layout="inline" style="display: flex; justify-content: space-between; align-items: center;">
              <a-form-model-item>
                选择时间
                <a-select style="width: 120px" id="time" v-model="time" placeholder="请选择具体时间" @change="getValue">
                  <a-select-option v-for="(item, index2) in alltime" :key="index2">
                    {{ item.formatTime }} 
                  </a-select-option>
                </a-select>
              </a-form-model-item>
  
            </a-form>
            <a-button type="primary" @click="getDevices2">
              进入地块 
            </a-button>
          </a-card>

          <span>出苗数：{{ this.length }}</span>
          <a-card title="出苗详情" style="width: 100%;">
            <template #extra><a href="#"></a></template>
            <p>地块名：雪川哈九</p>
            <p>出苗数：{{ this.length }}</p>
            <p>亩出苗数：{{ this.length/200 }}（个/亩）</p>
            <p>测算时间：{{ this.time }}</p>
          </a-card>
         
  
        </a-collapse-panel>
        <a-collapse-panel key="3" header="自定义范围出苗结果">
  
          <a-card>
            <a-form layout="inline" style="display: flex; justify-content: space-between; align-items: center;">
              <a-form-model-item>
                <a-select style="width: 120px" id="soil" v-model="deviceId1" placeholder="请选择自定义板块"
                  @change="getPolygonListNumber">
                  <a-select-option v-for="(item, index1) in localtionAll" :key="index1">
                    {{ item.Id }} : {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-icon id="spin" @click="renovates" type="sync" style="color: #1879FE" />
              </a-form-model-item>
            </a-form>
          </a-card>
          <!-- <a-button type="primary" @click="changeFlag1">
            修改自定义地块名称 
          </a-button> -->
          <a-card title="出苗详情" style="width: 100%;">
            <template #extra><a href="#"></a></template>
            <p>地块名：{{ this.p_name }}</p>
            <p>出苗数：{{ this.encloseNumber }}</p>
            <p>亩出苗数：{{ this.encloseNumber1 }}</p>
            <p>测算时间：{{ this.time }}</p>
          </a-card>
          
          
          <a-button type="primary" @click="changeFlag">
            进行自定义出苗分析 
          </a-button>
          <br><br>
          
        </a-collapse-panel>

        <a-collapse-panel key="4" header="异常值列表">
  
          <a-card>
            <a-table
              :bordered="false"
              :dataSource="dataSource2"
              :columns="columns"
              :pagination="pagination"
            >
            </a-table>
          </a-card>
         
          
        </a-collapse-panel>
        <a-collapse-panel key="5" header="AI问答" >
          <a-card style="height: 560px;width: 100%;"> 
              <div   style="height:480px;width:100%;overflow:auto;">

                <div v-for="(record, index) in chatRecords" :key="index" class="scrollbar-demo-item">
                  <div
                    v-if="record.isUser !== undefined && record.content !== undefined"
                    :class="record.isUser ? 'user-message-container' : 'server-message-container'"
                  >
                    <div :class="record.isUser ? 'user-message' : 'server-message'">
                      {{ record.content }}
                    </div>
                  </div>
                </div>
                
              </div>

              <div class="input-wrapper">
                <input type="text" class="custom-input" v-model="inputValue" />
                <span class="icon2">
                  <a-icon type="check"  @click="uploadText" style="font-size: 20px; color: #535353"/>
                </span>
              </div>
           
          </a-card>
        </a-collapse-panel>
  </a-collapse>
</div>
    


    <el-dialog title="修改自定义地块名称" :visible.sync="flag1" size="big" append-to-body>
      <div>
        <a-form-item label="自定义名称">
          <a-textarea v-model="name1" placeholder="请输入自定义名称" auto-size />
        </a-form-item>
        <a-button type="primary" @click="submit">
          保存名称 
        </a-button>
      </div>
    </el-dialog>
  
    <el-dialog title="自定义地块出苗详情" :visible.sync="flag" size="big" append-to-body>
        <div>
          <a-tabs default-active-key="1" >
            <a-tab-pane key='1' tab="2023自定义出苗数时间监控" style="background-color: #fff" forceRender>
              <div style="display: inline-block; text-align: center;  width:100%; "  >
                <sprout 
                    style="display: inline-block; background-color: #FFF; width: 100%; padding: 20px;"/>
              </div>
            </a-tab-pane>
            <a-tab-pane key='2' tab="2022自定义出苗数时间监控" style="background-color: #fff" forceRender>
              <div style="display: inline-block; text-align: center;  width:100%; "  >
                <sprout1
                    style="display: inline-block; background-color: #FFF; width: 100%; padding: 20px;"/>
              </div>
            </a-tab-pane>

             <!-- <a-tab-pane key='3' tab="出苗数列表监控" style="background-color: #fff" forceRender>
              <div style="display: inline-block; text-align: center;  width:100%; "  >
                <a-table
                :bordered="false"
                :dataSource="dataSource"
                :columns="columns"
                >
                </a-table>
              </div>
            </a-tab-pane> -->
          
            
          </a-tabs>
          
        </div>
    </el-dialog>
    <!-- <el-dialog title="异常地块检测列表" :visible.sync="flag2" size="big" append-to-body>
      <div>
        <a-tabs default-active-key="1" >
          <a-tab-pane key='1' tab="自定义出苗数时间监控" style="background-color: #fff" forceRender>
            <div style="display: inline-block; text-align: center;  width:100%; "  >
              <sprout 
                  style="display: inline-block; background-color: #FFF; width: 100%; padding: 20px;"/>
            </div>
          </a-tab-pane>

          
        </a-tabs>
        
      </div>
  </el-dialog> -->
  </div>
</template>

<script>
import axios from 'axios'

import sprout from '../../charts/Indicator-sprout-charts'
import sprout1 from '../../charts/Indicator-sprout-charts1'
import { nextTick } from 'vue';

export default {
  length: 0,
  components:{sprout,sprout1,},
  name: "side-soil",
  props: {
    savePolygon: Function,
    hideMenu: Function,
    setCenter: Function,
    backToMap: Function,
    openDraw: Function,
    changeType: Function,
    // changeMap: Function,
    // loadPolygon: Function
  },
  data() {
    return {
      inputValue: '',
      chatRecords: [],
      history: [],

      p_name:"",
      visible1:false,
      name1:"",
      flag1:false,
      flag:false,
      flag2:false,
      myValue:156355,
      myValue1:156355,
      deviceId: "雪川哈9",
      year:"2023年",
      time:"2023-06-19",
      line: [],
      newMap: {},
      newMap1: {},
      localtions: [],
      localtionAll: [],
      localtionNumber: 0,
      encloseNumber: 0,
      encloseNumber1:0,
      formState: {
        'modelName': "",
        'aiModelName': "",
      },
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 14,
        },
      },
      maps: [],
      layerType: 'RGB',
      visible: false,
      isDraw: true,

      ismap: 0,
      
      deviceId1: 0,

      pagination: {
        size: "default",
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 6,
        showSizeChanger: false,
        showQuickJumper: true,
        // pageSizeOptions: ['2','10']
      },
      dataSource: [],
      dataSource1: [],
      dataSource2: [

        {
          
                "id": 3417,
                "type": "Polygon",
                "mapId": "215", 
                "remark": "异常地块3"
            },
            {
            "id": 3421,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块0"
        },
        {
            "id": 3422,
            "type": "Polygon",
            
            "mapId": "215",
            "remark": "异常地块1"
        },
        
        {
            "id": 3427,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块6"
        },
        {
            "id": 3428,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块7"
        },
        {
            "id": 3429,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块8"
        },
        {
            "id": 3430,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块9"
        },
        {
            "id": 3431,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块10"
        },
        {
            "id": 3432,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块11"
        },
        {
            "id": 3433,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块12"
        },{
            "id": 3436,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块15"
        },
        {
            "id": 3437,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块16"
        },
        {
            "id": 3438,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块17"
        },
        {
            "id": 3439,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块18"
        },
        {
            "id": 3440,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块19"
        },
        {
            "id": 3441,
            "type": "Polygon",
            "mapId": "215",
            "remark": "异常地块20"
        }
      ],

      columns: [

        {
          title: '地块名称',
          dataIndex: 'remark',
          align: 'center'
        },
        {
          title: '地块id',
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: '类型',
          dataIndex: 'type',
          align: 'center'
        }
      ],
      
      expandIconPosition: 'right',
      activeKey: ['1'],
      form: {
        id: '',
        layout: 'vertical',
        name: undefined,
        type: '',
        devTypeNum: null,
        devType: undefined,
        deviceNum: null,
        // device: undefined,
        device: undefined,
        soilId: null,
        location: '',
        introduce: '',
      },
      soilList: [
        
      ],
      typeList: [
        {
          id: 1,
          name: '气象站'
        }, {
          id: 2,
          name: '土壤仪'
        }, {
          id: 3,
          name: '地块'
        }
      ],
      deviceList: [],
      renovate: 0,
      id: 0,
      time: "",
      allYears:[],
      alltime:[],
      id1: [1],
      AllTime1:[]



    }
  },

  watch:{

        '$root.backgroundId':{
            handler(){
                this.getPolygonList()
            },
            deep: true
        },
        '$root.backgroundSelectid':{
            handler(){
               console.log("监听成功")
                this.getPolygonListNumber1()
            },
            deep: true
        },
        '$root.backgroundTaskLen':{
            handler(){
                this.length = this.$root.backgroundTaskLen
            },
            deep: true
        },
        '$root.backgroundTaskId':{
            handler(){
              console.log("监听到任务id变化，进行数据列表的重新替换以及重建"+this.$root.backgroundTaskId)
                // this.myId = this.$root.backgroundTaskLen
                this.getPolygonListNumberList()
            },
            deep: true
        },
    },
  mounted() {
    
    this.showData()
    this.getAllYears()
    
    this.getSoilList()
    this.getDeviceList()
    
    this.getPolygonList()
    this.getPolygonListNumber()
    this.getDevices2()
   
    
    window.setTimeout(() => {
      this.showData()
    }, 1000)
    // this.length = JSON.parse(localStorage.getItem('length'))
    this.length = this.$root.backgroundTaskLen
    //动态获取AI模型名称
    axios.get("http://43.143.170.49:8466/api/aimodel/list").then(res => {
      this.aiModels = res.data.data;
    })
    //动态获取地图信息
    axios.get("http://43.143.170.49:8468/map/list").then(res => {
      this.maps = res.data.data;
      // console.log(this.maps) 
    })
    this.getAllEchars()
    this.chatRecords.push({ content: '您好，请问有什么可以帮您', isUser: false });
  },

  methods: {
    uploadText: async function() {
      this.chatRecords.push({ content: this.inputValue, isUser: true });
      try {
        // json
        const postData = {
          query: this.inputValue, // 用户输入的问题
          knowledge_base_name: 'samples',
          top_k: 20,
          score_threshold: 1,
          history: this.history,
          stream: false,
          model_name: 'chatglm3-6b',
          temperature: 0.7,
          max_tokens: 0,
          prompt_name: 'default',
        };
        const response = await axios.post('https://u210137-be19-fb10e36e.westb.seetacloud.com:8443/chat/knowledge_base_chat', postData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const dataString = response.data.replace(/^data: /, '').trim();
        const jsonData = JSON.parse(dataString);
        const answer = jsonData.answer;
        console.log('answer:', answer);
        console.log(response);

        this.history.push({ role: 'user', content: this.inputValue });
        if (answer) {
          this.chatRecords.push({ content: answer, isUser: false });
          this.history.push({ role: 'assistant', content: answer });
        }
        this.inputValue = '';
        await nextTick();
      } catch (error) {
        this.chatRecords.push({ content: '网络错误', isUser: false });
        console.error('上传失败:', error);
      }
    },
    getAllEchars(){
      let _this = this
      // let _this = this 
      console.log("进入获取所有echars数据函数")
      const formData = new FormData();
      formData.append("id", 111)
      axios.post("http://43.143.170.49:8468/map/pointChart",  formData).then(res => {
        console.log("获取到的数据")
        console.log(res.data.data)
        var data = res
        console.log(data.data.data.backgroundTaskRes[0])
        console.log(data.data.data.backgroundTaskResTime)
        console.log(data.data.data.backgroungAllTaskRes)
        // _this.$root.backgroundTaskAllRes = data.data.data.backgroundTaskRes//自定义的数据
        // _this.$root.backgroundTaskResTime = data.data.data.backgroundTaskResTime//时间
        // _this.$root.backgroungAllTaskRes = data.data.data.backgroungAllTaskRes//总体地块
      })
    },
    showChangeJSON1(){
      this.visible = true
    },
    showChangeJSON(value){
      // console.log("进入出苗修改函数"+value)
      //修改出苗级别，只显示用户画框选取之内的
      this.$root.backgroundJSON = value // 0 是全部，1是修改
      this.visible = false
    },
    submit(){//名称修改
      let _this = this
      // console.log(_this.myValue1)
      
      let ChangedPolygon =  _this.localtionAll[_this.myValue1]
      console.log(_this.name1)
      const formData = new FormData();
      formData.append("id", ChangedPolygon.Id)
      formData.append("makersName", _this.name1)
      axios.post('http://43.143.170.49:8468/makers-photo/addName',   formData   ).then(res => {
          this.flag1 = false
          console.log("修改成功")
      })

    },
    changeFlag(){
      this.flag = true
    },
    changeFlag1(){
      this.flag1 = true
    },
    getValue(value){
      this.myValue = value
      // this.time = this.$root.backgroundTaskResTime[value]
      this.time = this.alltime[value].formatTime.substring(0,20)
      this.time = this.time.replace('T' ,' ')
      // console.log(this.time)
    },
    getNewMap(value){
      // console.log(value)
      let _this = this
      _this.$axios.request({
        method: 'GET',
        url: '/cropsSumMap/getSpectrum',
        params: {
          id: value
        }
      }).then(res => {
        // console.log(res.data.data)
        var data = res.data.data
        var rgb = data[0]
        var green = data[1]
        var nir = data[2]
        var red = data[3]
        var red_edge = data[4]
        this.changeType(data[0])
      })

      
    },
    getDevices1(value) {
      console.log(value)
      let _this = this
      
      _this.$axios.get('/cropsSumMap/getListPage', {
        params: {
          pageNum: 1,
          plotName: '',
          plotAffiliation: '',
          plotYears: this.allYears[value]
        }
      }).then(res => {

        let dateSource1 = res.data.data.data
        // console.log(dateSource1)
        this.alltime = dateSource1[0].innerData
        // console.log(this.alltime)
        this.time = this.alltime[value-1].formatTime.substring(0,20)
        this.time = this.time.replace('T' ,' ')
        // console.log(this.time)
        this.Alltime1 = []
        for(var i = 0; i < this.alltime.length ;i++){
          // console.log(dateSource1[0].innerData[i].id)
          this.id1[i] = this.alltime[i].id
          this.Alltime1.push(this.alltime[i].formatTime.substring(0,20).replace('T' ,' '))
        }
        // console.log("id列表")
        console.log(this.Alltime1)
      });
    },
    getDevices2() {
      let _this = this
      
      _this.$axios.get('/cropsSumMap/getListPage', {
        params: {
          pageNum: 1,
          plotName: '',
          plotAffiliation: '',
          plotYears: 2023
        }
      }).then(res => {

        let dateSource1 = res.data.data.data
        // console.log(dateSource1)
        this.alltime = dateSource1[0].innerData
        // console.log(this.alltime)
        this.time = this.alltime[value-1].formatTime.substring(0,20)
        this.time = this.time.replace('T' ,' ')
        // console.log(this.time)
        this.Alltime1 = []
        for(var i = 0; i < this.alltime.length ;i++){
          // console.log(dateSource1[0].innerData[i].id)
          this.id1[i] = this.alltime[i].id
          this.Alltime1.push(this.alltime[i].formatTime.substring(0,20).replace('T' ,' '))
        }
        // console.log("id列表")
        console.log(this.Alltime1)
      });
    },
    getAllYears() {
      // console.log("getAllYears")
      let _this = this
      _this.$axios.get('/cropsSumMap/getAllYears').then(res => {
        _this.allYears = res.data.data
      })
      // _this.getDevices1(2)
      
    },
    getSoilList() {
      let _this = this

      _this.$axios.request({
        method: 'GET',
        url: '/cropsSumMap/getPlot'
      }).then(res => {
        // console.log(res)
        _this.soilList = res.data.data
      })
    },
    getPolygonListNumber1(){//更新本地保存数据
      let _this = this
      let localtionAll_id = 0
      for(let i = 0; i < _this.localtionAll.length; i++){
        if(_this.$root.backgroundSelectid === _this.localtionAll[i].Id){
          localtionAll_id = i
          _this.p_name = _this.localtionAll[i].name
        }

      }
      // console.log(localtionAll_id)
      let format_line = [[[]]]
      let taskId  =  _this.$root.backgroundTaskId
      // console.log(taskId)
      format_line[0] = _this.line[localtionAll_id]
      const formData = new FormData();
      formData.append("points", JSON.stringify(format_line))
      formData.append("taskId", taskId)
        axios.post("http://43.143.170.49:8466/api/taskDetection/enclose", formData).then(res => {

          _this.encloseNumber = res.data.data.length
          console.log(_this.encloseNumber)
        })

    },
    getPolygonListNumber(value) {
      this.encloseNumber1 = 0
      console.log("进入自定义数据采集阶段，开始改变数据")
      let format_line = [[[]]]
      this.localtionNumber = localStorage.getItem('localtionAllNumber')
      localStorage.getItem('localtionAllNumber')
      localStorage.setItem('isSelect',value)
      this.$root.backgroundSelect = value
      this.myValue1 = value
      // console.log("已经保存当前选择地块"+this.$root.backgroundSelect)
      
      format_line[0] = this.line[value]
      
      let taskId  =  this.$root.backgroundTaskId
      console.log(taskId)

      const formData = new FormData();
      formData.append("points", JSON.stringify(format_line))
      formData.append("taskId", taskId)
        axios.post("http://43.143.170.49:8466/api/taskDetection/enclose", formData).then(res => {
          this.encloseNumber = res.data.data.length
          this.p_name = this.localtionAll[value].name
        })

        axios.post("http://43.143.170.49:8468/map/calculateArea", formData).then(res => {
          this.encloseNumber1 = res.data.data.number
          
        })
        console.log("进行亩数的计算")
        console.log(this.encloseNumber1)
        this.getPolygonListNumberList()//调用查询所有列表数据

    },
    getPolygonListNumberList(){//获取所有出苗信息的列表，并且在图标右边的列表进行展
      console.log("进行当前页面所有自定义数据的查询")
      let encloseNumber1 = 0
      // this.dataSource =[]
      let taskId  =  this.$root.backgroundTaskId
      console.log(taskId)
      // this.dataSource = []//清空数据，重新获取
      for(let i = 0;  i < this.localtionAll.length; i++){
        const formData = new FormData();
        formData.append("points", JSON.stringify([this.line[i]]))
        formData.append("taskId", taskId)
          axios.post("http://43.143.170.49:8466/api/taskDetection/enclose", formData).then(res => {
            encloseNumber1 = res.data.data.length
            let obj1 = {
              soilId: this.localtionAll[i].name,
              number: encloseNumber1,
              time: this.time
            }
            this.dataSource[i] = obj1
          })
      }
      console.log(this.dataSource)
    },
    loadPolygon1(){
      this.loadPolygon()
    },
    getPolygonList() {  
      this.line = []
      let _this = this
      this.id = localStorage.getItem("id")
      // console.log(this.id)
      this.$axios.request({
        method: 'POST',
        url: 'makers-photo/getPolygon', 
        params: {
          type: 'Polygon',
          mapId: 215
        }
      }).then((res) => {
        this.line = []
        let name1 = []
        let myids = []
        this.localtionAll = []
        if (res.data.code === 1200) {
          const result = res.data.data;//获取到的结果里面会有一个id以及名称，把这两个获取到，就可以了
          // console.log(result)
          
          for (let i = 0; i < result.length; i++) {
            if (result[i].type === 'Polygon') {
              _this.line.push(result[i].location)
              name1.push(result[i].remark)
              myids.push(result[i].id)
            }
          }
          for (let i = 0; i <= _this.line.length-1; i++) {
            let obj = {
              Id: myids[i],
              name: name1[i]
            }
            this.localtionAll.push(obj)

          }
        }
      }
      )
    },
    getDevices2() {
      this.ids = this.id1[this.myValue]
      this.getNewMap(this.ids)
    },
    setLayerType(value) {
      this.layerType = value
    },
    showMap() {
      let val = [116.433015, 39.909843]
      this.setCenter(val)
      // this.hideMenu()
    },
    openDraws() {
      this.isDraw = !this.isDraw
      // console.log("开始进行画框选取")
      this.openDraw()
      // this.hideMenu()
    },
    showChangeType() {
      this.visible = true;
    },
    changeLayer() {
      // this.changeLayers()
      this.visible = false;
      this.getPolygonListNumberList()
      // console.log(this.layerType)
      this.changeType(this.layerType)//可以传递值，这里是传递了修改地图的类型，上一个就是传递修改之后地图的所有相关信息，直接传递到上一个界面
      this.hideMenu()
    },
    handleCancel(e) {
      this.visible = false;
    },
    renovates(e) {
      // console.log(this.renovate)
      // anticon-spin
      var spin = document.getElementById("spin")
      var svg = spin.getElementsByTagName("svg")[0]
      // console.log(svg)
      svg.setAttribute("class", "anticon-spin")
      window.setTimeout(() => {
        svg.removeAttribute("class")
        // console.log("remove")
      }, 1000)

      if (this.renovate) {
        this.getDevices(this.renovate)
      }
    },
    setDeviceNum(value) {
      // console.log(value)
      this.form.deviceNum = this.deviceList[value].id
    },
    setDeviceType(value) {
      // console.log(value)

      if (value === 2) {
        this.form.devTypeNum = -1
        this.form.deviceNum = 0
        this.form.device = '无'
      } else {
        this.form.devTypeNum = value
      }

    },
    showData() {
      let _this = this

      _this.getDevices2(0)
    },
    changePage(page, filters, sorter) {
      let _this = this
      _this.pagination.current = page.current
    },
    getDeviceList() {
      let _this = this

      _this.$axios.request({
        method: 'GET',
        url: '/equipment/getAllEquipment'
      }).then(res => {
        // console.log(res)
        _this.deviceList = res.data.data
      })
    },
    showTable() {
      // this.hideMenu()
      this.showBottom()
    },
    getSoilList() {
      let _this = this

      _this.$axios.request({
        method: 'GET',
        url: '/cropsSumMap/getPlot'
      }).then(res => {
        // console.log(res)
        _this.soilList = res.data.data
      })
    },
  }


  // },



}
</script>

<style scoped>



.user-message-container,
.server-message-container {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.user-message-container {
  justify-content: flex-end; /* 用户消息靠右对齐 */

}

.server-message-container {
  justify-content: flex-start; /* 服务器消息靠左对齐 */
}

.user-message,
.server-message {
  display: inline-block; /* 自适应宽度 */
  border-radius: 7px;
  padding: 5px 4px;
  margin-bottom: 2px;
  white-space: pre-wrap;
  word-wrap: break-word; /* 到达边界时自动换行 */
  hyphens: auto;
  font-size: 20px;
}

.user-message {
  background-color: #87e044; /* 用户消息的背景颜色 */
  max-width: 75%;
  margin-left: auto;
}

.server-message {
  background-color: #f8f9fa; /* 服务器消息的背景颜色 */
  max-width: 85%;
}

.input-wrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  
  
  height: 40px;
  width: 300px;
  display: flex;
  align-items: center;
  border: 3px solid rgb(53, 169, 235);
  border-radius: 9px;
  padding-left: 5px;
}

.custom-input {
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 20px;
}

.icon2 {
  margin-right: 9px;
  margin-left:3px;
  margin-top:2px;
}








.soil-funcs {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.soil-func {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}

.soil-func-icon {
  font-size: 20px;
  font-weight: lighter;
}

.soil-func-title {
  font-size: 16px;
  font-weight: normal;
}

.soil-func:hover {
  color: #1890ff;
}
</style>
