<template>
  <div id="width" style=" margin:0 auto; ">
    <div class="headers">
      <div class="header" >
        <a-dropdown :trigger="['click']">
          <a @click="[e => e.preventDefault()];" style="color: black;">
            <div style="font-weight: bolder">
              <h2>年份 </h2>
<!--              <a-icon :type="selectYear===true?'caret-down':'caret-up'" style="color:#888" />-->
              <a-icon type="caret-down" style="color:#888" />
            </div>
          </a>
          <a-menu slot="overlay" @click="getYear"  >
            <a-menu-item
                v-for="item in optionsYear"
                :key="item">
              <span style="font-size: 20px; margin-right: 20px; margin-left: 20px;">{{item}}</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <h1>{{year}}</h1>
        <h2 style="font-weight: bolder;margin: 0;">&nbsp;&nbsp;年</h2>
        <h3>当前生长阶段：{{agriculturalStage}}</h3>
      </div>
      <div class="header">
        <a-dropdown :trigger="['click']">
          <a @click="e => e.preventDefault()" style="color: black;">
            <div style="font-weight: bolder">
              <h2>{{testWarn}}异常指标</h2>
              <a-icon type="caret-down" style="color:#888" />
            </div>
          </a>
          <a-menu slot="overlay" @click="handleWarn" >
            <a-menu-item
                v-for="item in warn"
                :key="item.id">
              <span >{{item.item}}</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <h1 class="anormaly">{{ anormalIndex }}</h1>
        <h2>&nbsp;&nbsp;个指标</h2>
        <h1 class="anormaly">{{ anormalNum }}</h1>
        <h2>&nbsp;&nbsp;次异常</h2>
        <h3 class="anormaly">{{ anormalMsg }}</h3>
      </div>
      <div class="header">
        <a-dropdown :trigger="['click']">
          <a @click="e => e.preventDefault()" style="color: black;">
            <div style="font-weight: bolder">
              <h2>{{testComplete}}采集完成度</h2>
              <a-icon type="caret-down" style="color:#888" />
            </div>
          </a>
          <a-menu slot="overlay" @click="handleComplete" >
<!--            <a&#45;&#45;menu-item key="-1">dfs</a&#45;&#45;menu-item>-->
            <a-menu-item key="总体">总体</a-menu-item>
            <a-sub-menu key="1" title="土壤微量元素">
              <a-menu-item key="微量元素总体">微量元素总体</a-menu-item>
              <a-menu-item key="氮元素">氮元素</a-menu-item>
              <a-menu-item key="磷元素">磷元素</a-menu-item>
              <a-menu-item key="钾元素">钾元素</a-menu-item>
            </a-sub-menu>
            <a-sub-menu key="2" title="EC值">
              <a-menu-item key="EC值总体">EC值总体</a-menu-item>
              <a-menu-item key="10cmEC">10cmEC</a-menu-item>
              <a-menu-item key="20cmEC">20cmEC</a-menu-item>
              <a-menu-item key="30cmEC">30cmEC</a-menu-item>
            </a-sub-menu>
            <a-sub-menu key="3" title="PH值">
              <a-menu-item key="PH值总体">PH值总体</a-menu-item>
              <a-menu-item key="土壤PH">土壤PH</a-menu-item>
              <a-menu-item key="水源PH">水源PH</a-menu-item>
            </a-sub-menu>
<!--            <a-menu-item-->
<!--                v-for="item in completeValue"-->
<!--                :key="item">-->
<!--              <span style="font-size: 20px; margin-right: 20px; margin-left: 20px;">{{item}}</span>-->
<!--            </a-menu-item>-->
          </a-menu>
        </a-dropdown>
        <h1>100</h1>
        <h2>&nbsp;&nbsp;%</h2>
        <h3>应采集次数：7 次 </h3>
        <h3>已采集次数：7 次</h3>
      </div>
      <div class="header set">
        <h2>显示设置</h2> <a-icon type="control" theme="filled" style="color:#888" />
        <div style="width: 90%">
          <div>
            <h2>警戒线</h2>&nbsp;
            <a-switch style="margin-bottom: 6px;" checked-children="开" un-checked-children="关" @click="showWarningLine=!showWarningLine"/>
<!--            {{showWarningLine}}-->
          </div>
          <div>
            <h2>提示信息</h2>&nbsp;
            <a-switch style="margin-bottom: 6px;" checked-children="开" un-checked-children="关" @click="showPromptMessage=!showPromptMessage"/>
<!--            {{showPromptMessage}}-->
          </div>
        </div>
      </div>
    </div>

    <div>
      <a-tabs default-active-key="1"  >
        <a-tab-pane key='1' tab="土壤微量元素" style="background-color: #fff" forceRender>
<!--          <div style="max-width: 1600px; background-color: #99ccff">-->
            <soil-trace-elements-charts
                @change="getMsg"
                :showPromptMessage="showPromptMessage"
                :showWarningLine="showWarningLine"
                :collapsed="collapsed"
                :year='this.year'
                style=" display: inline-block; background-color: #FFF; width: 100%;  padding: 20px;"/>
<!--          </div>-->
        </a-tab-pane>
        <a-tab-pane key='2' tab="土壤EC值" style="background-color: #fff" forceRender>
          <div style="display: inline-block; text-align: center;  width:100%; "  >
            <EC @change="getMsg"
                :collapsed="collapsed"
                :showPromptMessage="showPromptMessage"
                :showWarningLine="showWarningLine"
                :year="this.year"
                style="display: inline-block; background-color: #FFF; width: 100%; padding: 20px;"/>
          </div>
        </a-tab-pane>
        <a-tab-pane key='3' tab="土壤及水源PH值" style="background-color: #83bcc5" forceRender>
          <div style="display: inline-block; text-align: center;   width:100%;  background-color: #993366">
            <soil-water-ph-charts
                @change="getMsg"
                :collapsed="collapsed"
                :showPromptMessage="showPromptMessage"
                :showWarningLine="showWarningLine"
                :year="this.year" style=" width: 100%;  background-color: #ffffff; padding: 20px;"/>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>

  </div>
</template>

<script>
import EC from '../charts/Indicator-EC-charts'
import soilTraceElementsCharts from '../charts/soil-trace-elements-charts'
import soilWaterPhCharts from '../charts/soil-water-Ph-charts'
export default {
  components:{EC,soilTraceElementsCharts,soilWaterPhCharts},
  name: "soil-water-indicators",
  props:{collapsed:{}},
  data(){
    return {
      optionsYear:[],
      year:'',
      selectYear:true,
      showWarningLine:false,
      warn: [
        {
          id:0, item:'总体', num:0, msg:'',time:"",index:0,
        },
        {
          id:1, item:'土壤微量元素',   num:0,   msg:'',index:0,
        },
        {
          id:2,item:'土壤EC值',  num:0,  msg:'',index:0,
        },
        {
          id:3, item:'土壤及水源PH值', num:0,   msg:'',index:0,
        },
      ],
      complete:[
        ['氮元素','磷元素','钾元素'],
        ['10cmEC','20cmEC','30cmEC'],
        ['土壤','水源'],
      ],
      showPromptMessage:false,
      completeness:0.1,
      style:{
        color:''
      },
      anormalNum:0, //异常值百分比
      agriculturalStage:"成熟期后",
      testComplete:'总体',
      anormalMsg:'加载中...',
      anormalIndex:0,
      testWarn:"",
    };
  },

  mounted() {
    //加载年份
    this.$axios.post("/indicator/getYear").then((res)=>{
      this.optionsYear=res.data
      this.year=this.optionsYear[0]
      this.getColor()
    })

  },
  methods:{
    /**采集完成度相关函数
     */
    handleComplete(val){
      // console.log(val)
      this.testComplete=val.key;
    },
    /**
     * 异常指标相关函数
     */
    //更新显示
    handleWarn(val){
      let a = {key : 0};
      if(val===undefined){
        val=a;
      }
      this.anormalIndex=this.warn[val.key].index;
      this.testWarn=this.warn[val.key].item;
      this.anormalNum=this.warn[val.key].num;
      this.anormalMsg=this.warn[val.key].msg===""?"暂无异常":(this.warn[val.key].msg+"数据异常");
      this.getColor()
    },
    //更新菜单的内容
    getMsg(val){
      let sum=0;
      let t=val[0].num;
      this.warn[0].msg="";
      this.warn[0].num=0;
      this.warn[t].msg="";
      this.warn[t].num=0;
      this.warn[0].index=0;
      this.warn[t].index=0;
      for(let i=1;i<val.length;i++){
        if(val[i].num !== 0){
          this.warn[t].index++;
          this.warn[t].num+=val[i].num;
          if(this.warn[t].msg!==""){
            this.warn[t].msg+="，";
          }
          this.warn[t].msg+=val[i].name+"("+val[i].num+"次)";
          sum++;
        }
      }
      //更新菜单选项信息
      for(let i=1;i<this.warn.length;i++){
        this.warn[0].num+=this.warn[i].num;
        if(this.warn[i].num!==0){
          this.warn[0].index++;
          if(this.warn[0].msg!==""){
            this.warn[0].msg+="，";
          }
          this.warn[0].msg+=this.warn[i].item+"("+this.warn[i].num+"次)";
        }
      }
      console.log(this.warn[0].index);
      console.log("sum为"+sum)
      this.handleWarn(undefined)
    },
    //更新显示的颜色
    getColor(){
      let color='';
      if(this.anormalNum===0){
        color="#24ab62";
      }else{
        color="#cc0f0f";
      }
      let a=document.getElementsByClassName('anormaly')
      for(let item = 0 ; item<a.length ;item++){
        a[item].style.color=color
      }
    },
    /**
     * 异常指标和采集完成度共有的相关函数
     */
    //更新年份信息
    getYear(val){
      this.year=val.key;
      this.handleWarn(undefined)
    },
  },
}
</script>

<style scoped>

.headers{
  user-select:none;
  background-color: #FFF;
  min-height: 200px;
  box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.13) 0 0 1px 1px;
}
/* headers下的第一个header没有边框 */
.headers .header:first-child {
  border-width:0;
}

.header h1{
  display:inline-block;
  font-size:50px;
  line-height: 50px;
  /*font-weight: bold;*/
  margin-top: 4px;
}
.header h2{
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
}
.header h3{
  margin: 0;
  color: #666666;
}

.header span{

}
.headers .header {
  margin-top: 20px;
  display: inline-block;
  text-align: left;
  /*background-color: #00ff00;*/
  vertical-align: middle;
  padding-left: 20px;
  width: 25%;
  min-height: 160px;
  border-left: 1px solid #ddd;
}

.headers .set div h2{
  margin-top: 20px;
  width: 30%;
  line-height: 30px;
}

</style>