<template>
  <div>
    <div class="headers">
      <div class="header">
        <div style="font-weight: bolder">
          <h2>当前气压</h2>
        </div>
        <h1>1029.08</h1>

        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;pa</h2>
        <h3>采集时间：{{ getCurrentTimeFormatted() }}</h3>
        <h3 style="color: red" v-show="airTemColor == 1">暂无数据</h3>
        <h3 style="color: green" v-show="airTemColor == 0">暂无异常</h3>
      </div>
    
      <div class="header">
        <div style="font-weight: bolder">
          <h2>当前风向</h2>
        </div>
        <h1>320.0</h1>

        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;度</h2>
        <h3>采集时间：{{ getCurrentTimeFormatted() }}</h3>
        <h3 style="color: red" v-show="soilHumColor == 1">暂无数据</h3>
        <h3 style="color: green" v-show="soilHumColor == 0">暂无异常</h3>
      </div>
      <div class="header">
        <div style="font-weight: bolder">
          <h2>当前风速</h2>
        </div>
        <h1>2.6</h1>

        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;m/s</h2>
        <h3>采集时间：{{ getCurrentTimeFormatted() }}</h3>
        <h3 style="color: red" v-show="soilHumColor == 1">暂无数据</h3>
        <h3 style="color: green" v-show="soilHumColor == 0">暂无异常</h3>
      </div>
      <div class="header">
        <div style="font-weight: bolder">
          <h2>当前降水</h2>
        </div>
        <h1>2.3</h1>

        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;mm</h2>
        <h3>采集时间：{{ getCurrentTimeFormatted() }}</h3>
        <h3 style="color: red" v-show="soilHumColor == 1">暂无数据</h3>
        <h3 style="color: green" v-show="soilHumColor == 0">暂无异常</h3>
      </div>


      <div class="header">
       
      </div>

      <div class="header">
        <div style="font-weight: bolder">
          <h2>当前空气温度</h2>
        </div>
        <h1>-4</h1>

        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;℃</h2>
        <h3>采集时间：{{ getCurrentTimeFormatted() }}</h3>
        <h3 style="color: red" v-show="airHumColor == 1">暂无数据</h3>
        <h3 style="color: green" v-show="airHumColor == 0">暂无异常</h3>
      </div>

       <div class="header">
        <div style="font-weight: bolder">
          <h2>当前空气湿度</h2>
        </div>
        <h1>93</h1>

        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;%RH</h2>
        <h3>采集时间：{{ getCurrentTimeFormatted() }}</h3>
        <h3 style="color: red" v-show="airHumColor == 1">暂无数据</h3>
        <h3 style="color: green" v-show="airHumColor == 0">暂无异常</h3>
      </div>
      <div class="header">
        <div style="font-weight: bolder">
          <h2>当前土壤温度</h2>
        </div>
        <h1>-2</h1>

        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;℃</h2>
        <h3>采集时间：{{ getCurrentTimeFormatted() }}</h3>
        <h3 style="color: red" v-show="soilTemColor == 1">暂无数据</h3>
        <h3 style="color: green" v-show="soilTemColor == 0">暂无异常</h3>
      </div>
      <div class="header">
        <div style="font-weight: bolder">
          <h2>当前土壤湿度</h2>
        </div>
        <h1>95</h1>

        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;%RH</h2>
        <h3>采集时间：{{ getCurrentTimeFormatted() }}</h3>
        <h3 style="color: red" v-show="soilHumColor == 1">暂无数据</h3>
        <h3 style="color: green" v-show="soilHumColor == 0">暂无异常</h3>
      </div>

      <div class="header set">
        <h2>显示设置</h2>
        <a-icon type="control" theme="filled" style="color: #888" />
        <div style="width: 90%">
          <div>
            <h2>操作</h2>
            &nbsp;
            <el-button type="primary" size="small" @click="refNowData"
              >刷新</el-button
            >
          </div>
          <div>
            <h2>警戒线</h2>
            &nbsp;
            <a-switch
              style="margin-bottom: 6px"
              checked-children="开"
              un-checked-children="关"
              @click="getLine"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <a-tabs default-active-key="1">
        <a-tab-pane
          key="1"
          tab="气压"
          style="background-color: #fff"
          forceRender
        >
          <div>
            <el-row>
              <el-col :offset="1" :span="22">
                <el-divider></el-divider>
                <div
                style="width: 100%; overflow-x: auto; padding-bottom: 5px"
                class="text-oneLine"
              >

                <div
                  style="
                    width: 200px;
                    display: inline;
                    font-weight: bolder;
                    margin-left: 40px;
                    font-size: 16px;
                  "
                >
                  查询方式：&nbsp;
                </div>
                <div
                  style="
                    width: 400px;
                    display: inline;
                    margin-left: 40px;
                    font-size: 16px;
                  "
                >
                  <el-radio-group v-model="radio">
                    <el-radio :label="3">
                      <span style="font-size: 16px">时间段</span>
                    </el-radio>
                    <el-radio :label="6">
                      <span style="font-size: 16px">天</span></el-radio
                    >
                    <el-radio :label="9"
                      ><span style="font-size: 16px">月份</span></el-radio
                    >
                    <el-radio :label="12"
                      ><span style="font-size: 16px">年份</span></el-radio
                    >
                  </el-radio-group>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 3"
                >
                  <el-date-picker
                    v-model="selectTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    align="right"
                    size="small"
                    @change="getChartsData"
                  />
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 6"
                >
                  <el-date-picker
                    v-model="selectDay"
                    type="date"
                    placeholder="选择日期"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 9"
                >
                  <el-date-picker
                    v-model="selectMonth"
                    type="month"
                    placeholder="选择月"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 12"
                >
                  <el-date-picker
                    v-model="selectYear"
                    type="year"
                    placeholder="选择年"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div style="width: 200px; display: inline; margin-left: 50px">
                  <el-button @click="getChartsData" type="primary" size="small"
                    >查询</el-button
                  >
                </div>
              </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :offset="1" :span="22">
                <div style="margin-bottom: 8px; text-align: center">
                  <strong style="font-size: 24px; color: black"
                    >平均气压图表</strong
                  >
                </div>
                <div id="temChart" class="charts"></div> </el-col
            ></el-row>
          </div>
        </a-tab-pane>

        <a-tab-pane
          key="2"
          tab="风向"
          style="background-color: #fff"
          forceRender
        >
          <el-row>
            <el-col :offset="1" :span="22">
              <el-divider></el-divider>
              <div
                style="width: 100%; overflow-x: auto; padding-bottom: 5px"
                class="text-oneLine"
              >

                <div
                  style="
                    width: 200px;
                    display: inline;
                    font-weight: bolder;
                    margin-left: 40px;
                    font-size: 16px;
                  "
                >
                  查询方式：&nbsp;
                </div>
                <div
                  style="
                    width: 400px;
                    display: inline;
                    margin-left: 40px;
                    font-size: 16px;
                  "
                >
                  <el-radio-group v-model="radio">
                    <el-radio :label="3">
                      <span style="font-size: 16px">时间段</span>
                    </el-radio>
                    <el-radio :label="6">
                      <span style="font-size: 16px">天</span></el-radio
                    >
                    <el-radio :label="9"
                      ><span style="font-size: 16px">月份</span></el-radio
                    >
                    <el-radio :label="12"
                      ><span style="font-size: 16px">年份</span></el-radio
                    >
                  </el-radio-group>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 3"
                >
                  <el-date-picker
                    v-model="selectTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    align="right"
                    size="small"
                    @change="getChartsData"
                  />
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 6"
                >
                  <el-date-picker
                    v-model="selectDay"
                    type="date"
                    placeholder="选择日期"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 9"
                >
                  <el-date-picker
                    v-model="selectMonth"
                    type="month"
                    placeholder="选择月"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 12"
                >
                  <el-date-picker
                    v-model="selectYear"
                    type="year"
                    placeholder="选择年"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div style="width: 200px; display: inline; margin-left: 50px">
                  <el-button @click="getChartsData" type="primary" size="small"
                    >查询</el-button
                  >
                </div>
              </div>
              <!-- <el-divider></el-divider> -->
            </el-col>
          </el-row>
          <el-row>
            <el-col :offset="1" :span="22">
              <div style="margin-bottom: 8px; text-align: center">
                <strong style="font-size: 24px; color: black"
                  >平均风向图表</strong
                >
              </div>
              <div id="humChart" class="charts"></div> </el-col
          ></el-row>
        </a-tab-pane>

        <a-tab-pane
          key="3"
          tab="风速"
          style="background-color: #fff"
          forceRender
        >
        <el-divider></el-divider>
          <el-row>
            <el-col :offset="1" :span="22">
              <el-divider></el-divider>
              <div
                style="width: 100%; overflow-x: auto; padding-bottom: 5px"
                class="text-oneLine"
              >

                <div
                  style="
                    width: 200px;
                    display: inline;
                    font-weight: bolder;
                    margin-left: 40px;
                    font-size: 16px;
                  "
                >
                  查询方式：&nbsp;
                </div>
                <div
                  style="
                    width: 400px;
                    display: inline;
                    margin-left: 40px;
                    font-size: 16px;
                  "
                >
                  <el-radio-group v-model="radio">
                    <el-radio :label="3">
                      <span style="font-size: 16px">时间段</span>
                    </el-radio>
                    <el-radio :label="6">
                      <span style="font-size: 16px">天</span></el-radio
                    >
                    <el-radio :label="9"
                      ><span style="font-size: 16px">月份</span></el-radio
                    >
                    <el-radio :label="12"
                      ><span style="font-size: 16px">年份</span></el-radio
                    >
                  </el-radio-group>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 3"
                >
                  <el-date-picker
                    v-model="selectTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    align="right"
                    size="small"
                    @change="getChartsData"
                  />
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 6"
                >
                  <el-date-picker
                    v-model="selectDay"
                    type="date"
                    placeholder="选择日期"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 9"
                >
                  <el-date-picker
                    v-model="selectMonth"
                    type="month"
                    placeholder="选择月"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 12"
                >
                  <el-date-picker
                    v-model="selectYear"
                    type="year"
                    placeholder="选择年"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div style="width: 200px; display: inline; margin-left: 50px">
                  <el-button @click="getChartsData" type="primary" size="small"
                    >查询</el-button
                  >
                </div>
              </div>
              <el-divider></el-divider>
            </el-col>
          </el-row>
          <el-row>
            <el-col :offset="1" :span="22">
              <div style="margin-bottom: 8px; text-align: center">
                <strong style="font-size: 24px; color: black"
                  >平均风速图表</strong
                >
              </div>
              <div id="rainChart" class="charts"></div> </el-col
          ></el-row>
        </a-tab-pane>

        <a-tab-pane key="4" tab="多土壤仪显示" forceRender>
          <div
            style="
              background-color: #ffffff;
              box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
                rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;

              min-width: 600px;
              width: 100%;
            "
          >
          <el-divider></el-divider>
            <el-row>
              <el-col :offset="1" :span="22">
                
                <div
                  style="width: 100%; overflow-x: auto; padding-bottom: 5px"
                  class="text-oneLine"
                >

                  <div
                    style="
                      width: 200px;
                      display: inline;
                      font-weight: bolder;
                      margin-left: 40px;
                      font-size: 16px;
                    "
                  >
                    查询方式：&nbsp;
                  </div>
                  <div
                    style="
                      width: 400px;
                      display: inline;
                      margin-left: 40px;
                      font-size: 16px;
                    "
                  >
                    <el-radio-group v-model="radio">
                      <el-radio :label="3">
                        <span style="font-size: 16px">时间段</span>
                      </el-radio>
                      <el-radio :label="6">
                        <span style="font-size: 16px">天</span></el-radio
                      >
                      <el-radio :label="9"
                        ><span style="font-size: 16px">月份</span></el-radio
                      >
                      <el-radio :label="12"
                        ><span style="font-size: 16px">年份</span></el-radio
                      >
                    </el-radio-group>
                  </div>

                  <div
                    style="width: 400px; display: inline; margin-left: 50px"
                    v-show="radio == 3"
                  >
                    <el-date-picker
                      v-model="selectTime"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      align="right"
                      size="small"
                      @change="getChartsData"
                    />
                  </div>

                  <div
                    style="width: 400px; display: inline; margin-left: 50px"
                    v-show="radio == 6"
                  >
                    <el-date-picker
                      v-model="selectDay"
                      type="date"
                      placeholder="选择日期"
                      size="small"
                      @change="getChartsData"
                    >
                    </el-date-picker>
                  </div>

                  <div
                    style="width: 400px; display: inline; margin-left: 50px"
                    v-show="radio == 9"
                  >
                    <el-date-picker
                      v-model="selectMonth"
                      type="month"
                      placeholder="选择月"
                      size="small"
                      @change="getChartsData"
                    >
                    </el-date-picker>
                  </div>

                  <div
                    style="width: 400px; display: inline; margin-left: 50px"
                    v-show="radio == 12"
                  >
                    <el-date-picker
                      v-model="selectYear"
                      type="year"
                      placeholder="选择年"
                      size="small"
                      @change="getChartsData"
                    >
                    </el-date-picker>
                  </div>

                  <div style="width: 200px; display: inline; margin-left: 50px">
                    <el-button
                      @click="getChartsData"
                      type="primary"
                      size="small"
                      >查询</el-button
                    >
                  </div>
                </div>
                <el-divider></el-divider>
              </el-col>
            </el-row>
            <div style="margin-bottom: 8px; text-align: center">
              <strong style="font-size: 24px; color: black"
                >土壤仪温度图表</strong
              >
            </div>

            <el-row>
              <el-col :offset="1" :span="22">
                <div id="soilTemChart" class="charts"></div>
                <br />
              </el-col>
            </el-row>
          </div>
          <div
            style="
              margin-top: 20px;
              background-color: #ffffff;
              box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
                rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
              text-align: center;
              min-width: 600px;
              width: 100%;
            "
          >
            <el-row>
              <el-col :offset="1" :span="22">
                <div style="margin-bottom: 8px">
                  <strong style="font-size: 24px; color: black"
                    >土壤仪湿度图表</strong
                  >
                </div>
                <div id="soilHumChart" class="charts"></div>
              </el-col>
            </el-row>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "allLot",
  mounted() {
    // this.selectTime.push(new Date(2023, 4, 23, 0, 0, 0));
    // this.selectTime.push(new Date(2023, 5, 4, 0, 0, 0));
    // this.getSoilOption();
    // this.getiot();
    // this.getCurrentTimeFormatted();
    this.initChart("temChart")
    this.initChart("humChart")
    this.initChart("rainChart")
    this.initChart("soilTemChart")
    this.initChart("soilHumChart")
  },
  data() {
    return {
      Time: new Date(),
      radio: 3,
      selectWay: 2,
      airTem: "",
      soilUpTime: "",
      airUpTime: "",
      soilHum: "",
      soilTem: "",
      airHum: "",
      soilHumColor: 0,
      airTemColor: 0,
      soilTemColor: 0,
      airHumColor: 0,
      xAxisTime: [],
      xSoilAxisTime: [],
      activeTab: "soilTemChart",
      soilName: "",
      soilOption: [],
      selectTime: [],
      selectDay: "",
      selectMonth: "",
      selectYear: "",
      charts: [],
      optionTem: {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          type: "scroll",
          data: [],
          textStyle: {
            color: "black",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          name: "日期 ",
          data: [],
          axisLabel: {
            textStyle: {
              color: "black",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "black",
            },
            formatter: "{value} (°C)",
          },
        },
        series: [],
      },
      optionHum: {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          type: "scroll",
          data: [],
          textStyle: {
            color: "black",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          name: "日期 ",
          data: [],
          axisLabel: {
            textStyle: {
              color: "black",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "black",
            },
            formatter: "{value} (%RH)",
          },
        },
        series: [],
      },
      optionRain: {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          type: "scroll",
          data: [],
          textStyle: {
            color: "black",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          name: "日期 ",
          data: [],
          axisLabel: {
            textStyle: {
              color: "black",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "black",
            },
            formatter: "{value} (mm)",
          },
        },
        series: [],
      },
      soilOptionTem: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          type: "scroll",
          data: [],
          textStyle: {
            color: "black",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,

          name: "日期 ",
          data: [],
          axisLabel: {
            textStyle: {
              color: "black",
            },
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          axisLabel: {
            textStyle: {
              color: "black",
            },
            formatter: "{value} (°C)",
          },
        },
        series: [],
      },
      soilOptionHum: {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          type: "scroll",
          data: [],
          textStyle: {
            color: "black",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          name: "日期 ",
          data: [],
          axisLabel: {
            textStyle: {
              color: "black",
            },
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          axisLabel: {
            textStyle: {
              color: "black",
            },
            formatter: "{value} (%RH)",
          },
        },
        series: [],
      },
      showWarningLine: false,
      lowerTemLimit: 20,
      upperTemLimit: "",
      lowerAirTemLimit: "",
      upperAirTemLimit: "",
      lowerHumLimit: "",
      upperHumLimit: "",
      lowerAirHumLimit: "",
      upperAirHumLimit: "",
      day:["11/21","11/22","11/23","11/24","11/25","11/26","11/27","11/28","11/29","11/30","12/01","12/02","12/03","12/04",

      "12/05","12/06","12/07","12/08","12/09","12/10","12/11","12/12","12/13","12/14"     
     ],
      temChartData:[1009.08, 1000.56, 1030.89, 1012.45, 980.79,993.29,1090.79,1009.08, 1050.56, 900.89, 990.45, 1100.79, 993.29,980.79,
      1029.08, 1000.56, 1030.89, 1012.45, 980.79,983.29,1060.79,1029.08
    ],
      humChartData:[35.0, 15.6, 20.9, 20.9, 35.6, 25.8,2.9,21.0, 15.6, 29.9, 20.9, 5.6, 6.8,4.9,
      3.6, 8,4.9,5.0, 1.6, 2.9, 2.9, 1.6, 5.8,2.9,5.8,2.9
    ],
      rainChartData:[200, 260, 170, 150, 290, 100, 150,270, 240, 190, 200, 190, 100,180,
      180, 160,220, 210, 180, 260, 190, 200,350 ,170, 150,
    ],
      soilTemChartData:[1.0, 4.0, 5.0, 3.5, 2.6, 3.0,0.5,2.0, 6.0, 5.0, 2.5, 1.6, 2.0,0.9,
      -0.1,-0.5,-2.0, -4.0, -5.0, -2.5, -1.6, -2.0,-3,-2.5, -1.6,
    ],
      soilTemChartData1:[1.0, 4.0, 3.9, 3.5, 2.5, 3.0,0.5,2.3, 6.0, 5.0, 2.7, 1.2, 2.0,0.8,
      -1.0,-0.5,-2.3, -6.0, -5.0, -2.7, -1.2, -2.0,-0.8, -2.7, -1.2
     ],
      soilHumChartData:[32, 29, 24, 34, 16, 24,14,30, 25, 26, 35, 15, 24,19,
      24,14,30, 25, 26, 35, 15, 24,19, 35, 15,19, 35,],
      soilHumChartData1:[31, 20, 25, 28, 20, 28,18,25, 25, 27, 34, 16, 24,20,
      31, 20, 25, 28, 20, 28,18,25, 25, 20, 28,, 28,18]
    };
  },
  methods: {

    allDate(){

      //所有数据进行筛选

    },








    getCurrentTimeFormatted() {
        const now = new Date();
        const formattedTime = "2023" + '-' + 
                              ("12").toString().padStart(2, '0') + '-' + 
                              "14".toString().padStart(2, '0') + ' ' + 
                              "07".toString().padStart(2, '0') + ':' + 
                              "00".toString().padStart(2, '0') + ':' + 
                              "00".toString().padStart(2, '0');
                              console.log(formattedTime)
                              // formattedTime = "Sat Jan 01 2022 00:00:00 GMT+0800 (GMT+08:00)"      
        return formattedTime;
    },
    // getiot(){
    //   this.$axios.get('http://120.26.55.192:18081/raw/iot_indicator', {
    //       params: {
    //           startTimestamp: 1700636400000,
    //           endTimestamp: 1700639400000
    //       },
    //       headers: {
    //           'PDS-ESP-APP-TOKEN': '6a3d382c08f04f47b4303592c9ddb483',
    //           'Postman-Token': '4eccecef-f531-4b4f-8408-df2addfabeba',
    //           'cache-control': 'no-cache'
    //       }
    //   })
    //   .then(res => {
    //       console.log(res);
    //       // this.dataSource = res.data.data;
    //       // this.pagination = res.data.pagination;
    //       this.soilUpTime = "1"
    //   })
    //   .catch(error => {
    //       console.error("There was an error!", error);
    //   });
    // },
    onTabClick() {
      this.initChart(this.activeTab);
    },
    refreshChart(id) {
      switch (id) {
        case "temChart":
          this.initChart("temChart");
          break;
        case "humChart":
          this.initChart("humChart");
          break;
        case "rainChart":
          this.initChart("rainChart");
          break;
        case "soilHumChart":
          this.initChart("soilHumChart");
          break;
        case "soilTemChart":
          this.initChart("soilTemChart");
          break;
      }
    },
    initChart(id) {
      switch (id) {
        case "temChart":
          console.log("1")
          this.charts[0] = echarts.init(document.getElementById("temChart"));
          setTimeout(() => {
            this.charts[0].resize();
          }, 100);
          window.addEventListener("resize", () => {
            this.charts[0].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = ["11/28","11/29","11/30","12/01","12/02","12/03","12/04"];
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均气压值',
              type: 'line',
              data: [1009.08, 1000.56, 1030.89, 1012.45, 980.79, 993.29,1090.79]
          }]
          this.charts[0].clear();
          this.charts[0].setOption(this.optionTem, false, true);
          break;
        case "humChart":
          console.log("2")
          this.charts[1] = echarts.init(document.getElementById("humChart"));
          setTimeout(() => {
            this.charts[1].resize();
          }, 100);
          window.addEventListener("resize", () => {
            this.charts[1].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = ["11/28","11/29","11/30","12/01","12/02","12/03","12/04"];
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均风向值',
              type: 'line',
              data: [315.0, 150.6, 200.9, 20.9, 355.6, 256.8,200.9]
          }]
          this.charts[1].clear();
          this.charts[1].setOption(this.optionTem, false, true);
          break;
        case "rainChart":
        console.log("3")
          this.charts[2] = echarts.init(document.getElementById("rainChart"));
          setTimeout(() => {
            this.charts[2].resize();
          }, 100);
          window.addEventListener("resize", () => {
            this.charts[2].resize({
              with: 10,
              height: 400
            });
          });
          this.optionTem.xAxis.data = ["11/28","11/29","11/30","12/01","12/02","12/03","12/04"];
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均风速值',
              type: 'line',
              data: [200, 260, 170, 150, 290, 100,150]
          }]
          this.charts[2].clear();
          this.charts[2].setOption(this.optionTem, false, true);
          break;
        case "soilTemChart":
          this.charts[3] = echarts.init(
            document.getElementById("soilTemChart")
          );
          setTimeout(() => {
            this.charts[3].resize();
          }, 100);
          window.addEventListener("resize", () => {
            this.charts[3].resize({
              with: 10,
              height: 400
            });
          });
          this.optionTem.xAxis.data = ["11/28","11/29","11/30","12/01","12/02","12/03","12/04"];
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
                name: '一号土壤仪温度值',
                type: 'line',
                data: [2.0, 6.0, 8.0, 2.5, 1.6, 2.0,0.9]
            },{
                name: '二号土壤仪温度值',
                type: 'line',
                data: [1.0, 4.0, 7.0, 3.5, 2.6, 3.0,0.5]
            },
          ]
          this.charts[3].clear();
          this.charts[3].setOption(this.optionTem, false, true);
          break;
        case "soilHumChart":
          this.charts[4] = echarts.init(
            document.getElementById("soilHumChart")
          );
          setTimeout(() => {
            this.charts[4].resize();
          }, 100);
          window.addEventListener("resize", () => {
            this.charts[4].resize({
              with: 10,
              height: 400
            });
          });
          this.optionTem.xAxis.data = ["11/28","11/29","11/30","12/01","12/02","12/03","12/04"];
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '一号土壤仪湿度值',
              type: 'line',
              data: [30, 25, 26, 35, 15, 24,19]
          },{
                name: '二号土壤仪湿度值',
                type: 'line',
                data: [32, 29, 24, 34, 16, 24,14][30, 25, 26, 35, 15, 24,19]
            }]
          this.charts[4].clear();
          this.charts[4].setOption(this.optionTem, false, true);
          break;
      }
    },
    refNowData() {
      let refForm = new FormData();
      refForm.append("cropId", this.soilName),
        this.$axios.post("/LOT/latestTime", refForm).then((res) => {
          if (res.data.code == 0) {
            let result = res.data.getLOT;
            for (let i = 0; i < result.length; i++) {
              switch (result[i].targetName) {
                case "土壤湿度":
                  this.soilHum = result[i].targetValue[0];
                  if (!result[i].targetValue[0]) {
                    this.soilHumColor = 1;
                  } else {
                    this.soilHumColor = 0;
                  }
                  break;
                case "土壤温度":
                  this.soilTem = result[i].targetValue[0];
                  if (!result[i].targetValue[0]) {
                    this.soilTemColor = 1;
                  } else {
                    this.soilTemColor = 0;
                  }
                  break;
                case "空气温度":
                  this.airTem = result[i].targetValue[0];
                  if (!result[i].targetValue[0]) {
                    this.airTemColor = 1;
                  } else {
                    this.airTemColor = 0;
                  }
                  break;
                case "空气湿度":
                  this.airHum = result[i].targetValue[0];
                  if (!result[i].targetValue[0]) {
                    this.airHumColor = 1;
                  } else {
                    this.airHumColor = 0;
                  }
                  break;
                case "土壤时间":
                  this.soilUpTime = result[i].targetValue[0];
                  break;
                case "空气时间":
                  this.airUpTime = result[i].targetValue[0];
                  break;
              }
            }
          }
        });
    },
    getSoilOption() {
      this.$axios
        .post("/LOT/getCropRotationId")
        .then((res) => {
          if (res.data.code == 0) {
            let result = res.data.stringListMap;
            for (let i = 0; i < result.length; i++) {
              this.soilOption.push({
                label: result[i].id,
                value: result[i].id,
              });
            }
            this.soilName = this.soilOption[10].value;
            this.getChartsData();
            this.refNowData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getChartsData() {
      let that = this;
      let begin_index = 0;//开始下表 
      let end_index = 0;//结束下表

      function compareDates(dateStr1, dateStr2) {
      // 转换第一个日期
      const date1 = new Date(dateStr1);

      // 解析第二个日期
      const [month, day] = dateStr2.split('/').map(Number);
      const date2 = new Date(date1.getFullYear(), month - 1, day);

      // 比较年、月、日
      return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
      }

      // 示例使用
      const result = compareDates('Sun Dec 10 2023 00:00:00 GMT+0800', '12/10');
      console.log(result); // 输出：true 或 false
      console.log(this.radio)
      switch (this.radio) {
        case 3:
          console.log("进行时间段查询")  
          console.log("selectTime", this.selectTime[0])
          

            
          for(let i = 0; i < this.day.length; i++){
            if(compareDates(  this.selectTime[0],this.day[i] )){
              console.log(this.day[i] )
              console.log(this.selectTime[0] )
              begin_index = i;
              end_index = begin_index + 7
              if(end_index >  this.day.length){//如果最后一个大于数组最大下表，则更新为下表
                end_index =  this.day.length-1;
              }
              console.log(begin_index)
              console.log(end_index)
              break
            }
          }

          this.charts[0] = echarts.init(document.getElementById("temChart"));
          this.charts[1] = echarts.init(document.getElementById("humChart"));
          // this.charts[2] = echarts.init(document.getElementById("rainChart"));
          // this.charts[3] = echarts.init(document.getElementById("soiltemChart"));
          // this.charts[4] = echarts.init(document.getElementById("soilhumChart"));
          setTimeout(() => {
            this.charts[0].resize();
            this.charts[1].resize();
            this.charts[2].resize();
            this.charts[3].resize();
            this.charts[4].resize();
          }, 100);
          console.log("进行气压数据的调整")
          window.addEventListener("resize", () => {
            this.charts[0].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均气压值',
              type: 'line',
              data: that.temChartData.slice(begin_index, end_index)
          }]
          this.charts[0].clear();
          this.charts[0].setOption(this.optionTem, false, true);

          console.log("进行风向数据的调整")
          window.addEventListener("resize", () => {
            this.charts[1].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均风向值',
              type: 'line',
              data: that.humChartData.slice(begin_index, end_index)
          }]
          this.charts[1].clear();
          this.charts[1].setOption(this.optionTem, false, true);

          console.log("进行风速数据的调整")
          window.addEventListener("resize", () => {
            this.charts[2].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均风速值',
              type: 'line',
              data: that.rainChartData.slice(begin_index, end_index)
          }]
          this.charts[2].clear();
          this.charts[2].setOption(this.optionTem, false, true);
          console.log("进行多土壤仪数据的调整")
          window.addEventListener("resize", () => {
            this.charts[3].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '土壤一号平均温度',
              type: 'line',
              data: that.soilTemChartData.slice(begin_index, end_index)
          },{
              name: '土壤二号平均温度',
              type: 'line',
              data: that.soilTemChartData1.slice(begin_index, end_index)
          }
        ]
          this.charts[3].clear();
          this.charts[3].setOption(this.optionTem, false, true);

          window.addEventListener("resize", () => {
            this.charts[4].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '土壤一号仪器平均温度',
              type: 'line',
              data: that.soilHumChartData.slice(begin_index, end_index)
          },{
              name: '土壤二号平均温度',
              type: 'line',
              data: that.soilHumChartData1.slice(begin_index, end_index)
          }]
          this.charts[4].clear();
          this.charts[4].setOption(this.optionTem, false, true);



          break
        case 6:
          console.log("进行天查询")
          console.log("selectDay", this.selectDay)
          
            
          for(let i = 0; i < this.day.length; i++){
            if(compareDates(  this.selectDay,this.day[i] )){
              console.log(this.day[i] )
              console.log(this.selectDay )
              begin_index = i;
              end_index = begin_index + 7
              if(end_index >  this.day.length){//如果最后一个大于数组最大下表，则更新为下表
                end_index =  this.day.length-1;
              }
              console.log(begin_index)
              console.log(end_index)
              break
            }
          }

          this.charts[0] = echarts.init(document.getElementById("temChart"));
          this.charts[1] = echarts.init(document.getElementById("humChart"));
          // this.charts[2] = echarts.init(document.getElementById("rainChart"));
          // this.charts[3] = echarts.init(document.getElementById("soiltemChart"));
          // this.charts[4] = echarts.init(document.getElementById("soilhumChart"));
          setTimeout(() => {
            this.charts[0].resize();
            this.charts[1].resize();
            this.charts[2].resize();
            this.charts[3].resize();
            this.charts[4].resize();
          }, 100);
          console.log("进行气压数据的调整")
          window.addEventListener("resize", () => {
            this.charts[0].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均气压值',
              type: 'line',
              data: that.temChartData.slice(begin_index, end_index)
          }]
          this.charts[0].clear();
          this.charts[0].setOption(this.optionTem, false, true);

          console.log("进行风向数据的调整")
          window.addEventListener("resize", () => {
            this.charts[1].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均风向值',
              type: 'line',
              data: that.humChartData.slice(begin_index, end_index)
          }]
          this.charts[1].clear();
          this.charts[1].setOption(this.optionTem, false, true);

          console.log("进行风速数据的调整")
          window.addEventListener("resize", () => {
            this.charts[2].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均风速值',
              type: 'line',
              data: that.rainChartData.slice(begin_index, end_index)
          }]
          this.charts[2].clear();
          this.charts[2].setOption(this.optionTem, false, true);
          console.log("进行多土壤仪数据的调整")
          window.addEventListener("resize", () => {
            this.charts[3].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '土壤一号平均温度',
              type: 'line',
              data: that.soilTemChartData.slice(begin_index, end_index)
          },{
              name: '土壤二号平均温度',
              type: 'line',
              data: that.soilTemChartData1.slice(begin_index, end_index)
          }
        ]
          this.charts[3].clear();
          this.charts[3].setOption(this.optionTem, false, true);

          window.addEventListener("resize", () => {
            this.charts[4].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '土壤一号仪器平均温度',
              type: 'line',
              data: that.soilHumChartData.slice(begin_index, end_index)
          },{
              name: '土壤二号平均温度',
              type: 'line',
              data: that.soilHumChartData1.slice(begin_index, end_index)
          }]
          this.charts[4].clear();
          this.charts[4].setOption(this.optionTem, false, true);

          break
        case 9:
          console.log("进行月份查询")
          console.log("selectMonth", this.selectMonth)
          let date = new Date(this.selectMonth)
          let month = date.getMonth();
          if(month == 10){
            begin_index = 0,end_index = 9
          }else if(month ==11){
            begin_index = 10, end_index = this.day.length-1
          }
          this.charts[0] = echarts.init(document.getElementById("temChart"));
          this.charts[1] = echarts.init(document.getElementById("humChart"));
          // this.charts[2] = echarts.init(document.getElementById("rainChart"));
          // this.charts[3] = echarts.init(document.getElementById("soiltemChart"));
          // this.charts[4] = echarts.init(document.getElementById("soilhumChart"));
          setTimeout(() => {
            this.charts[0].resize();
            this.charts[1].resize();
            this.charts[2].resize();
            this.charts[3].resize();
            this.charts[4].resize();
          }, 100);
          console.log("进行气压数据的调整")
          window.addEventListener("resize", () => {
            this.charts[0].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均气压值',
              type: 'line',
              data: that.temChartData.slice(begin_index, end_index)
          }]
          this.charts[0].clear();
          this.charts[0].setOption(this.optionTem, false, true);

          console.log("进行风向数据的调整")
          window.addEventListener("resize", () => {
            this.charts[1].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均风向值',
              type: 'line',
              data: that.humChartData.slice(begin_index, end_index)
          }]
          this.charts[1].clear();
          this.charts[1].setOption(this.optionTem, false, true);

          console.log("进行风速数据的调整")
          window.addEventListener("resize", () => {
            this.charts[2].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均风速值',
              type: 'line',
              data: that.rainChartData.slice(begin_index, end_index)
          }]
          this.charts[2].clear();
          this.charts[2].setOption(this.optionTem, false, true);
          console.log("进行多土壤仪数据的调整")
          window.addEventListener("resize", () => {
            this.charts[3].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '土壤一号平均温度',
              type: 'line',
              data: that.soilTemChartData.slice(begin_index, end_index)
          },{
              name: '土壤二号平均温度',
              type: 'line',
              data: that.soilTemChartData1.slice(begin_index, end_index)
          }
        ]
          this.charts[3].clear();
          this.charts[3].setOption(this.optionTem, false, true);

          window.addEventListener("resize", () => {
            this.charts[4].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '土壤一号仪器平均温度',
              type: 'line',
              data: that.soilHumChartData.slice(begin_index, end_index)
          },{
              name: '土壤二号平均温度',
              type: 'line',
              data: that.soilHumChartData1.slice(begin_index, end_index)
          }]
          this.charts[4].clear();
          this.charts[4].setOption(this.optionTem, false, true);

          break
        case 12:
          console.log("进行年查询")
          console.log("selectYear", this.selectYear)


          begin_index = 0, end_index = this.day.length
          this.charts[0] = echarts.init(document.getElementById("temChart"));
          this.charts[1] = echarts.init(document.getElementById("humChart"));
          // this.charts[2] = echarts.init(document.getElementById("rainChart"));
          // this.charts[3] = echarts.init(document.getElementById("soiltemChart"));
          // this.charts[4] = echarts.init(document.getElementById("soilhumChart"));
          setTimeout(() => {
            this.charts[0].resize();
            this.charts[1].resize();
            this.charts[2].resize();
            this.charts[3].resize();
            this.charts[4].resize();
          }, 100);
          console.log("进行气压数据的调整")
          window.addEventListener("resize", () => {
            this.charts[0].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均气压值',
              type: 'line',
              data: that.temChartData.slice(begin_index, end_index)
          }]
          this.charts[0].clear();
          this.charts[0].setOption(this.optionTem, false, true);

          console.log("进行风向数据的调整")
          window.addEventListener("resize", () => {
            this.charts[1].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均风向值',
              type: 'line',
              data: that.humChartData.slice(begin_index, end_index)
          }]
          this.charts[1].clear();
          this.charts[1].setOption(this.optionTem, false, true);

          console.log("进行风速数据的调整")
          window.addEventListener("resize", () => {
            this.charts[2].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '平均风速值',
              type: 'line',
              data: that.rainChartData.slice(begin_index, end_index)
          }]
          this.charts[2].clear();
          this.charts[2].setOption(this.optionTem, false, true);
          console.log("进行多土壤仪数据的调整")
          window.addEventListener("resize", () => {
            this.charts[3].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '土壤一号平均温度',
              type: 'line',
              data: that.soilTemChartData.slice(begin_index, end_index)
          },{
              name: '土壤二号平均温度',
              type: 'line',
              data: that.soilTemChartData1.slice(begin_index, end_index)
          }
        ]
          this.charts[3].clear();
          this.charts[3].setOption(this.optionTem, false, true);

          window.addEventListener("resize", () => {
            this.charts[4].resize({
              // width: document.getElementById("temChart").clientWidth,
              // height: document.getElementById("temChart").clientHeight,
              with: 10,
              height: 400
              // with: [11,11,22,266,66,44,4],
              // height: [11,11,22,266,66,44,4]
            });
          });
          this.optionTem.xAxis.data = that.day.slice(begin_index, end_index);
          this.optionTem.yAxis.data = [1,1,1,1];
          this.optionTem.series =  [{
              name: '土壤一号仪器平均湿度',
              type: 'line',
              data: that.soilHumChartData.slice(begin_index, end_index)
          },{
              name: '土壤二号仪器平均湿度',
              type: 'line',
              data: that.soilHumChartData1.slice(begin_index, end_index)
          }]
          this.charts[4].clear();
          this.charts[4].setOption(this.optionTem, false, true);

          break
      }

    },
    proTime(t) {
      return t < 10 ? "0" + t : t;
    },
    emptyData() {
      this.optionTem.legend.data = [];
      this.optionTem.series = [];
      this.optionHum.legend.data = [];
      this.optionHum.series = [];
      this.optionRain.legend.data = [];
      this.optionRain.series = [];
      this.soilOptionTem.legend.data = [];
      this.soilOptionTem.series = [];
      this.soilOptionHum.legend.data = [];
      this.soilOptionHum.series = [];
    },
    getLine() {
      //土壤温度
      this.$axios.get("indicators/getDetails/100").then((res) => {
        this.lowerTemLimit = res.data.lowerLimit;
        this.upperTemLimit = res.data.upperLimit;
      });
      //土壤湿度
      this.$axios.get("indicators/getDetails/101").then((res) => {
        this.lowerHumLimit = res.data.lowerLimit;
        this.upperHumLimit = res.data.upperLimit;
      });
      //空气温度
      this.$axios.get("indicators/getDetails/102").then((res) => {
        this.lowerAirTemLimit = res.data.lowerLimit;
        this.upperAirTemLimit = res.data.upperLimit;
      });
      //空气湿度
      this.$axios.get("indicators/getDetails/103").then((res) => {
        this.lowerAirHumLimit = res.data.lowerLimit;
        this.upperAirHumLimit = res.data.upperLimit;
      });
      this.showWarningLine = !this.showWarningLine;
      this.getChartsData();
    },
  },
};
</script>

<style scoped>
/* .container {
  background-color: white;
} */
.charts {
  height: 500px;
  width: 100%;
}
.title_middle {
  font-size: 24px;
  font-weight: 700;
  color: black;
}
.title_small {
  font-size: 18px;
  font-size: 500;
}
.text-oneLine {
  display: block; /*内联对象需加*/
  word-break: keep-all; /* 不换行 */
  white-space: nowrap; /* 不换行 */
}

.headers {
  /*user-select:none;*/
  background-color: #fff;
  height: 400px;
  box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.13) 0 0 1px 1px;
}
/* headers下的第一个header没有边框 */
.headers .header:first-child {
  border-width: 0;
}

.header h1 {
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
  /*font-weight: bold;*/
  margin-top: 4px;
}
.header h2 {
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
}
.header h3 {
  margin: 0;
  color: #666666;
}

.header span {
}
.headers .header {
  margin-top: 20px;
  display: inline-block;
  text-align: left;
  /*background-color: #00ff00;*/
  vertical-align: middle;
  padding-left: 20px;
  width: 20%;
  min-height: 160px;
  border-left: 1px solid #ddd;
}

.headers .set div h2 {
  margin-top: 20px;
  width: 30%;
  line-height: 30px;
}
</style>