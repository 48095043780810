<template>
  <div style="text-align: center; width:100%;">
    <h1>{{this.year}}年土壤及水源PH对比表</h1>
    <div id='soil-water-Ph-charts' style="margin: auto" :style="{ minWidth:'300px', width:'100%', height: '500px'}">
      <h1>图表加载失败</h1>
    </div>
  </div>
</template>

<!--suppress JSUnresolvedVariable -->
<script>
export default {
  name: "soil-water-Ph-charts",
  props:{
    'year': {},
    'showPromptMessage': {},
    'showWarningLine':{},
    collapsed:{},
  },
  //初值和变量
  data () {
    return {
      // year:"",
      msg:{
        soilPh: {value:"",upperLimit:"",lowerLimit:""},
        waterPh:{value:"",upperLimit:"",lowerLimit:""},
      },
      optionsYear: [],
    };
  },
  //方法
  methods: {

    getMsg(val){
      this.$axios.post("/indicator/getSoilWaterPhCharts",val).then((res)=>{
        let soilPh={name:"土壤PH",num:0};
        let waterPh={name:"土壤PH",num:0};
        for(let i=0;i<res.data.title.length;i++){
          if(res.data.soilPh[i]<this.msg.soilPh.lowerLimit
          ||res.data.soilPh[i]>this.msg.soilPh.upperLimit){
            soilPh.num++;
          }
        }
        let a=[{name:"土壤及水源PH",num:3}]
        a.push(soilPh,waterPh)
        this.$emit("change",a)

        for(let i in  res.data.soilPh){
          res.data.soilPh[i]= res.data.soilPh[i]/10;
          res.data.waterPh[i]= res.data.waterPh[i]/10;
        }

        let myCharts = this.$echarts.init(document.getElementById('soil-water-Ph-charts'));

        myCharts.setOption( {
          title:{
            text:this.year+'年土壤微量元素表'
          },
          xAxis: [{
            nameLocation:'end', //坐标轴名称显示的位置
            silent:true,  //是否静态无法交互
            type: 'category',
            data: res.data.title,
            axisLine:{
              show:true,
            },},],
          legend: {
            // formatter:'{name}'+(this.showPromptMessage===true?"*":' '),
            data: [{
              name:'土壤',
              tooltip:{
                trigger:'item',
                show:this.showPromptMessage,
                formatter:this.msg.soilPh.value,
              }
            }, {
              name:'水源',
              tooltip: {
                trigger:'item',
                show:this.showPromptMessage,
                formatter:this.msg.waterPh.value,
              }
            }],
          },
          series: [
            {
              type: 'bar',
              name:'水源',
              data: res.data.soilPh,
            },{
              markLine:{
                label:{
                  show:this.showWarningLine
                },
                data : [
                  {    lineStyle:
                        { //警戒线的样式 ，虚实 颜色
                          width: this.showWarningLine===true?1:0   //宽度
                        },
                    yAxis: this.msg.soilPh.upperLimit/10 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                  },
                  {
                    lineStyle:{ //警戒线的样式 ，虚实 颜色
                      width: this.showWarningLine===true?1:0   //宽度
                    },
                    yAxis: this.msg.soilPh.lowerLimit/10 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                  }]
              },
              type: 'bar',
              name:'土壤',
              data:res.data.waterPh,
            },
          ]
        });
      }).catch((err)=>{
        console.log(err)
      })
    },
    changeChart(){
      this.getMsg(this.year)
    },
    handleWindowResize() {
      let myCharts = this.$echarts.init(document.getElementById('soil-water-Ph-charts'));
      myCharts && myCharts.resize();
    },
    changeChartSize(){
      setTimeout(()=>{
        this.handleWindowResize()
      },200)
    }
  },
  //在页面加载前执行
  mounted() {
    this.$axios.get('/indicators/getDetails/273').then((res)=>{
      // console.log(res)
      this.msg.soilPh.value=res.data.indexDetails;
      this.msg.soilPh.lowerLimit=res.data.lowerLimit;
      this.msg.soilPh.upperLimit=res.data.upperLimit;
    })
    //获取水源Ph信息
    /**
     * to do
     * */

        // this.getMsg(this.year)
        //初始化echarts
    let myCharts = this.$echarts.init(document.getElementById('soil-water-Ph-charts'));
    setTimeout(()=>{
      this.handleWindowResize()
    },100)
    window.addEventListener('resize',this.handleWindowResize)
    myCharts.setOption( {

      title: {
        show:false,
        text: '土壤微量元素表',
      },
      xAxis: [{
        name:'日期',  //坐标轴的名称
        nameLocation:'end', //坐标轴名称显示的位置
        silent:false,  //是否静态无法交互
        type: 'category',
        data: [],
        axisLine:{
          show:true,
        },
      }],
      yAxis: {
        // name:'ppm',  //坐标轴的名称
        type: 'value',
        max:9.5,
        scale:true,
        min:4.5,
        // splitNumber:10,
        minInterval: 0.1,
        axisLine:{
          show:true,
        },
      },
      toolbox: {
        show:true,
        feature: {
          saveAsImage: {},
        }
      },
      grid: {
        left: '0px',
        right: '50px', ///   调整大小
        bottom: '1%',
        top:'8%',
        show:false, //  边框是否显示
        containLabel: true
      },
      legend: {
        itemGap:30,
        textStyle:{
          color:"#444",
          fontSize:14,
          fontWeight: 'bold',
        },
        // formatter:"{name}*",
        // right:0,
        data: [{
          name:'土壤',
          tooltip:{
            trigger:'item',
            show:true,
            formatter:this.msg.soilPh.value,
            position:'bottom',
          }
        }, {
          name:'水源',
          tooltip: {
            trigger:'item',
            show:true,
            formatter:this.msg.waterPh.value,
            position:'bottom',
          }
        }],
      },
      tooltip:{
        // confine:'true',
        appendToBody:'true',
        backgroundColor:'rgba(255,255,255,0.96)',   //背景的颜色
        textStyle:{
          fontWeight:'normal',
          indent:'50px',
          color:"#555",
        },
        //给浮层一个阴影，并且让浮层内文字自动换行
        extraCssText:'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;white-space:pre-wrap;max-width:300px;',
        show:true,
        trigger:'axis', //显示指示器
        axisPointer: {  //配置指示器
          type:'shadow',  //指示器样式 line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器 'cross' 十字准星指示器。
        }
      },

      series: [
        {
          markLine:{
            symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
            label:{
              position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
              // formatter:'10cmEC警戒指标',
            },
            data : [
              {
                silent:false, //鼠标悬停事件 true没有，false有
                lineStyle:{ //警戒线的样式 ，虚实 颜色
                  type:"dotted", //样式  ‘solid’和'dotted'
                  // color:"#FA3934",
                  width: 1   //宽度
                },
                yAxis: 7400 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
              },
              {
                silent:false, //鼠标悬停事件 true没有，false有
                lineStyle:{ //警戒线的样式 ，虚实 颜色
                  type:"dotted", //样式  ‘solid’和'dotted'
                  // color:"#FA3934",
                  width: 1   //宽度
                },
                yAxis: 6800 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
              }]
          },
          name:"水源",
          data: [],
          type: 'bar',
          connectNulls:true,  //将断点连起来
        },
        {
          markLine:{
            symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
            label:{
              position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
              // formatter:'20cmEC警戒指标',
            },
            data : [
              {
                silent:false, //鼠标悬停事件 true没有，false有
                lineStyle:
                    { //警戒线的样式 ，虚实 颜色
                      type:"dotted", //样式  ‘solid’和'dotted'
                      // color:"#FA3934",
                      width: 1   //宽度
                    },
                yAxis: 6 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
              },
              {
                silent:false, //鼠标悬停事件 true没有，false有
                lineStyle:{ //警戒线的样式 ，虚实 颜色
                  type:"dotted", //样式  ‘solid’和'dotted'
                  // color:"#FA3934",
                  width: 1   //宽度
                },
                yAxis: 5 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
              }]
          },
          name:"土壤",
          data: [],
          type: 'bar',
          connectNulls:true,
        },
      ],

    });
  },
  watch:{//监听year变量的变化，在year变量的数值改变时，调用changeChart方法
    year:'changeChart',
    showPromptMessage:'changeChart',
    showWarningLine:'changeChart',
    collapsed:"changeChartSize"
  }

}
</script>

<style scoped>
h2 {
  display: inline-block;
  font-weight: normal;
}
h1{
  display: inline-block;
  font-weight: bolder;
  font-size: 24px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>