<template>
    <div style="background-color: white;height: 100vh;padding: 20px;padding-top: 50px;">

        <div>
            <a-form name="validate_other" @finishFailed="onFinishFailed" :form="form" v-bind="formItemLayout"
                @submit="handleSubmit">


                <a-form-item label="模型名称" has-feedback accept=".ply">
                    <a-input v-decorator="[
                        'name',
                        { rules: [{ required: true, message: '请填写模型名称' }] },
                    ]" placeholder="模型名称"></a-input>
                </a-form-item>


                <a-form-model-item label="创建时间">
                    <a-date-picker v-decorator="[
                        'formatTime',
                        { rules: [{ required: true, message: '创建时间' }] },
                    ]" show-time type="date" placeholder="选择日期" style="width: 100%;" />
                </a-form-model-item>


                <!-- TODO 需要后端给出可选地图 -->
                <a-form-item label="所属地图">
                    <a-select v-decorator="[
                        'map',
                        { rules: [{ required: true, message: '请选择所属地图' }] },
                    ]" placeholder="所属地图">
                        <a-select-option v-for="(item, index) in plotData" :value="item.id">
                            {{ item.affiliation }} ：{{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>


                <a-form-item name="location" label="地区" has-feedback>
                    <a-input v-decorator="[
                        'location',
                        { rules: [{ required: true, message: '请选择地区' }] },
                    ]" placeholder="雄安新区"></a-input>
                </a-form-item>



                <a-form-item name="plyfile" label="上传点云文件(.ply)">
                    <a-upload v-decorator="[
                        'plyfile',
                        { rules: [{ required: true, message: '请上传点云文件ply' }] },
                    ]" accept=".ply" action="" list-type="text">
                        <a-button>
                            <a-icon type="upload"></a-icon>

                            点击上传
                        </a-button>
                    </a-upload>
                </a-form-item>

                <a-form-item name="jsonfile" label="上传标注文件(.json)">
                    <a-upload v-decorator="[
                        'jsonfile',
                        { rules: [{ required: true, message: '请上传标注文件json' }] },
                    ]" accept=".json" name="jsonfile" action="" list-type="text">
                        <a-button>
                            <a-icon type="upload"></a-icon>

                            点击上传
                        </a-button>
                    </a-upload>
                </a-form-item>



                <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                    <a-button type="primary" html-type="submit">提交</a-button>
                </a-form-item>
            </a-form>
        </div>
    </div>
</template>
<script>

import axios from "axios";
import { message } from 'ant-design-vue';
export default ({
    data() {
        return {
            plotData: [],//已经创建的地块
            mapShowData: [],
            formState: {},
            formItemLayout: {
                labelCol: {
                    span: 6,
                },
                wrapperCol: {
                    span: 14,
                },
            },
        };
    },

    beforeCreate() {
        const _this = this;
        this.form = this.$form.createForm(this, { name: 'validate_other' });


        _this.$axios.get('http://43.143.170.49:8468/cropsSumMap/listMap').then(res => {
            _this.mapShowData = res.data.data;
            console.log(_this.mapShowData);
        })

        _this.$axios.get('/cropsSumMap/getListPage', {
            params: {
                pageNum: 1,
                plotName: '',
                plotAffiliation: '',
                plotYears: ''
            }
        }).then(res => {

            this.plotData = res.data.data.data
            console.log("dataSource is:", this.plotData)
        });


    },
    methods: {



        /**
         * forest3d后端对点云模型的处理全都基于modelName，那么只需要取得modelName，其他就可以解决
         * 上传的时候需要给forest3d发送消息
         * 发送完成之后，需要给gengyun发送消息，因为模型展示的时候只需要name，那么
         * @param {*} values 
         */
        handleSubmit(e) {
            e.preventDefault();

            this.form.validateFields((err, values) => {
                console.log(err);
                console.log(values);
                if (values == null) { return }
                //先创建一个记录，然后再添加map_show_data
                const addMapForm = {
                    cropsRotationPlotId: values.map,
                    cropsRotationPlotName: values.name,
                    //TODO: 这个字段应该是从上传的文件中统计获得，而不是现在手动填写
                    dataFormat: "3D",//固定为3d
                    isNvdi: 0,
                    isTiles: 0,
                    isUpload: 1,
                    formatTime: values.formatTime,
                }
                //先新增地块，然后给地块添加地图
                this.$axios.request({
                    method: "POST",
                    url: '/cropsSumMap/addmap',
                    data: addMapForm
                }).then(res => {
                    const formData = new FormData();
                    //这里判断data是否有值，如果没有则不能提交
                    formData.append("name", values.name)
                    formData.append("mapId", res.data.data.id)
                    axios.post("http://43.143.170.49:8468/map/save3D", formData).then(res => {
                        console.log(res)
                    })

                    //向forest3d上传3d模型
                    let plyfile = (values.plyfile).fileList[0].originFileObj;
                    let jsonfile = (values.jsonfile).fileList[0].originFileObj;
                    let data = new FormData();
                    data.append("name", values.name);
                    data.append("location", values.location);
                    data.append("json", jsonfile);
                    data.append("plyfile", plyfile);
                    axios({
                        url: "http://43.143.170.49:8466/api/model/uploadWithOutImg",
                        method: 'post',
                        data: data,
                        onUploadProgress: function (progressEvent) { //原生获取上传进度的事件
                            if (progressEvent.lengthComputable) {
                                //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                                //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
                                const upLoadProgress = progressEvent.loaded / progressEvent.total * 100 //实时获取上传进度
                                console.log(upLoadProgress)
                            }
                        }
                    }).then(res => {
                        if (res.data.code == 1) {
                            message.success("上传成功((￣︶￣*))", 10)
                        } else {
                            message.error("上传失败＞︿＜：" + res.data.msg);
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                });




                console.log('验证成功Success:', values);
            })
        },
        onFinishFailed(errorInfo) {
            console.log('Failed:', errorInfo);
        }
    }
});
</script>
  
