
<template>
    <div  style="background-color: #fff;" >
        <el-form style="width: 60%;margin:0 auto" :model="form" :rules="rules" ref="form"  class="demo-ruleForm">

            <el-form-item label="输入计划名称"  prop="name">
                <el-input v-model="form.name" />
            </el-form-item>

<!--            <el-form-item label="选择创建日期" prop="createTime">-->
<!--                &lt;!&ndash;                <a-input v-model="form.createTime" />&ndash;&gt;-->
<!--                <a-date-picker-->
<!--                        v-model="form.createTime"-->
<!--                        show-time-->
<!--                        type="date"-->
<!--                        placeholder="选择日期"-->
<!--                        style="width: 100%;"-->
<!--                />-->
<!--            </el-form-item>-->

            <el-form-item label="选择阶段期年份" prop="collector">
                <a-select v-model="form.year">
                    <a-select-option
                            v-for="item in years" :key="item"
                            :value="item">
                        {{item}}
                    </a-select-option>
                </a-select>
            </el-form-item>

            <el-form-item label="选择数据采集方" prop="collector">
<!--                <a-select v-model="form.collector" placeholder="数据采集方">-->
<!--                    <a-select-option-->
<!--                            v-for="item in organs"-->
<!--                            :value="item">-->
<!--                        {{item}}-->
<!--                    </a-select-option>-->
<!--                </a-select>-->
                <el-checkbox-group v-model="form.collector">
                    <el-checkbox
                            v-for="item in organs" :key="item"
                            :label="item" name="collector"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item>
                <h1 style="">选择计划指标</h1>
            </el-form-item>
            <el-form-item label="1. 产量指标-结薯数量分组 :">
                <div  :style="{ borderBottom: '1px solid #E9E9E9' }">
                    <a-checkbox :indeterminate="indeterminate1" :checked="checkAll1" @change="onCheckAllChange1">
                    </a-checkbox>
                </div>
                <br />
                <a-checkbox-group style="background-color: #fff"   id="a" v-model="checkedList1" :options="plainOptions1"  @change="onChange1" />
            </el-form-item>

            <el-form-item label="2. 土壤指标-土壤元素含量分组 :">
                <div  :style="{ borderBottom: '1px solid #E9E9E9' }">
                    <a-checkbox :indeterminate="indeterminate2" :checked="checkAll2" @change="onCheckAllChange2">
                    </a-checkbox>
                </div>
                <br />
                <a-checkbox-group style="background-color: #fff"   id="b" v-model="checkedList2" :options="plainOptions2"  @change="onChange2" />
            </el-form-item>

            <el-form-item label="3. 环境指标（实时IOT）分组 :">
                <div  :style="{ borderBottom: '1px solid #E9E9E9' }">
                    <a-checkbox :indeterminate="indeterminate3" :checked="checkAll3" @change="onCheckAllChange3">
                    </a-checkbox>
                </div>
                <br />
                <a-checkbox-group style="background-color: #fff"    v-model="checkedList3" :options="plainOptions3"  @change="onChange3" />
            </el-form-item>

            <el-form-item label="4. 叶片元素含量分组 :">
                <div  :style="{ borderBottom: '1px solid #E9E9E9' }">
                    <a-checkbox :indeterminate="indeterminate4" :checked="checkAll4" @change="onCheckAllChange4">
                    </a-checkbox>
                </div>
                <br />
                <a-checkbox-group style="background-color: #fff"    v-model="checkedList4" :options="plainOptions4"  @change="onChange4" />
            </el-form-item>

            <el-form-item label="5. 产量指标-结薯重量分组 :">
                <div  :style="{ borderBottom: '1px solid #E9E9E9' }">
                    <a-checkbox :indeterminate="indeterminate5" :checked="checkAll5" @change="onCheckAllChange5">
                    </a-checkbox>
                </div>
                <br />
                <a-checkbox-group style="background-color: #fff"    v-model="checkedList5" :options="plainOptions5"  @change="onChange5" />
            </el-form-item>

            <el-form-item label="6. 水源指标分组 :">
                <div  :style="{ borderBottom: '1px solid #E9E9E9' }">
                    <a-checkbox :indeterminate="indeterminate6" :checked="checkAll6" @change="onCheckAllChange6">
                    </a-checkbox>
                </div>
                <br />
                <a-checkbox-group style="background-color: #fff"    v-model="checkedList6" :options="plainOptions6"  @change="onChange6" />
            </el-form-item>

            <el-form-item label="7. 长势指标分组 :">
                <div  :style="{ borderBottom: '1px solid #E9E9E9' }">
                    <a-checkbox :indeterminate="indeterminate7" :checked="checkAll7" @change="onCheckAllChange7">
                    </a-checkbox>
                </div>
                <br />
                <a-checkbox-group style="background-color: #fff"    v-model="checkedList7" :options="plainOptions7"  @change="onChange7" />
            </el-form-item>

            <el-form-item label="8. 土壤指标-EC值分组 :">
                <div  :style="{ borderBottom: '1px solid #E9E9E9' }">
                    <a-checkbox :indeterminate="indeterminate8" :checked="checkAll8" @change="onCheckAllChange8">
                    </a-checkbox>
                </div>
                <br />
                <a-checkbox-group style="background-color: #fff"    v-model="checkedList8" :options="plainOptions8"  @change="onChange8" />
            </el-form-item>

            <el-form-item style="text-align: center">
                <el-button  type="primary" @click="fun()">提交</el-button>
            </el-form-item>

        </el-form>
<strong></strong>
    </div>
</template>
<script>
    var date = new Date();
    date=date .getFullYear()+'年'+(date .getMonth()+1)+'月'+date .getDate()+'日'; //获取当前日期

    import detail from "./Detailed-information"
    var plainOptions1 = [],plainOptions2 = [],plainOptions3 = [],plainOptions4 = [],plainOptions5 = [],plainOptions6 = [],plainOptions7 = [],plainOptions8 = [];
    var defaultCheckedList1 = plainOptions1,defaultCheckedList2 = plainOptions2,defaultCheckedList3 = plainOptions3,
        defaultCheckedList4 = plainOptions4,defaultCheckedList5 = plainOptions5,defaultCheckedList6 = plainOptions6,
        defaultCheckedList7 = plainOptions7,defaultCheckedList8 = plainOptions8;
    export default {
        components:{detail},
        data() {
            return {
                checkedList1: defaultCheckedList1, checkedList2: defaultCheckedList2, checkedList3: defaultCheckedList3,checkedList4: defaultCheckedList4,
                checkedList5: defaultCheckedList5, checkedList6: defaultCheckedList6, checkedList7: defaultCheckedList7,checkedList8: defaultCheckedList8,
                indeterminate1: false, checkAll1: true, indeterminate2: false, checkAll2: true,
                indeterminate3: false, checkAll3: true,indeterminate4: false, checkAll4: true,
                indeterminate5: false, checkAll5: true,indeterminate6: false, checkAll6: true,
                indeterminate7: false, checkAll7: true,indeterminate8: false, checkAll8: true,
                plainOptions1, plainOptions2,plainOptions3,plainOptions4,plainOptions5,plainOptions6,plainOptions7,plainOptions8,
                year:'',
                test:['1','2'],
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                form:{
                    name:'',
                    createTime:'',
                    collector:[],
                    year:'',
                },
                years:'',
                organs:'',
                rules: {
                    name: [
                        //trigger: 'blur'  触发事件，在失去焦点时触发事件
                        { required: true, message: '模板名称不能为空', trigger: 'blur' },
                    ],
                    createTime:[
                        { required: true, message: '请选择创建日期', trigger: 'blur' }
                    ],
                    collector:[
                        { required: true, message: '请选择数据采集方', trigger: 'blur' }
                    ],
                }
            };
        },
        methods: {
            fun(){
                console.log(this.form)
                this.form.createTime=date;

                var checkedList=[]
                for(var i=0;i<this.checkedList1.length;i++) {checkedList.push(this.checkedList1[i])}for(var i=0;i<this.checkedList2.length;i++) {checkedList.push(this.checkedList2[i])}for(var i=0;i<this.checkedList3.length;i++) {checkedList.push(this.checkedList3[i])}for(var i=0;i<this.checkedList4.length;i++) {checkedList.push(this.checkedList4[i])}
                for(var i=0;i<this.checkedList5.length;i++) {checkedList.push(this.checkedList5[i])}for(var i=0;i<this.checkedList6.length;i++) {checkedList.push(this.checkedList6[i])}for(var i=0;i<this.checkedList7.length;i++) {checkedList.push(this.checkedList7[i])}for(var i=0;i<this.checkedList8.length;i++) {checkedList.push(this.checkedList8[i])}
               if(this.form.name==""||this.form.year==""||this.form.collector==""||checkedList=="")
               {
                   this.$alert("请将信息填写完整")
               }
               else
               {
                   console.log(checkedList)
                   this.$axios.post("/templateDetailinfo2/save1",this.form).then(res=>{
                   })
                   this.$axios.post("/templateDetailinfo2/save2",checkedList).then(res=>{
                   })
                   this.$alert('添加成功！', '消息', {
                       confirmButtonText: '确定',//点击确定会触发一个回调函数
                       callback: action => {
                           window.location.reload()
                       }
                   })
               }

            },
            onChange1(checkedList1) {
                this.indeterminate1 = !!checkedList1.length && checkedList1.length < plainOptions1.length;
                this.checkAll1 = checkedList1.length === plainOptions1.length;
            }, onCheckAllChange1(e) {
                Object.assign(this, {
                    checkedList1: e.target.checked ? plainOptions1 : [],
                    indeterminate1: false,
                    checkAll1: e.target.checked,
                });
            },
            onChange2(checkedList2) {
                this.indeterminate2 = !!checkedList2.length && checkedList2.length < plainOptions2.length;
                this.checkAll2 = checkedList2.length === plainOptions2.length;
            }, onCheckAllChange2(e) {
                Object.assign(this, {
                    checkedList2: e.target.checked ? plainOptions2 : [],
                    indeterminate2: false,
                    checkAll2: e.target.checked,
                });
            },
            onChange3(checkedList3) {
                this.indeterminate3 = !!checkedList3.length && checkedList3.length < plainOptions3.length;
                this.checkAll3 = checkedList3.length === plainOptions3.length;
            }, onCheckAllChange3(e) {
                Object.assign(this, {
                    checkedList3: e.target.checked ? plainOptions3 : [],
                    indeterminate3: false,
                    checkAll3: e.target.checked,
                });
            },
            onChange4(checkedList4) {
                this.indeterminate4 = !!checkedList4.length && checkedList4.length < plainOptions4.length;
                this.checkAll4 = checkedList4.length === plainOptions4.length;
            }, onCheckAllChange4(e) {
                Object.assign(this, {
                    checkedList4: e.target.checked ? plainOptions4 : [],
                    indeterminate4: false,
                    checkAll4: e.target.checked,
                });
            },
            onChange5(checkedList5) {
                this.indeterminate5 = !!checkedList5.length && checkedList5.length < plainOptions5.length;
                this.checkAll5 = checkedList5.length === plainOptions5.length;
            }, onCheckAllChange5(e) {
                Object.assign(this, {
                    checkedList5: e.target.checked ? plainOptions5 : [],
                    indeterminate5: false,
                    checkAll5: e.target.checked,
                });
            },
            onChange6(checkedList6) {
                this.indeterminate6 = !!checkedList6.length && checkedList6.length < plainOptions6.length;
                this.checkAll6 = checkedList6.length === plainOptions6.length;
            }, onCheckAllChange6(e) {
                Object.assign(this, {
                    checkedList6: e.target.checked ? plainOptions6 : [],
                    indeterminate6: false,
                    checkAll6: e.target.checked,
                });
            },
            onChange7(checkedList7) {
                this.indeterminate7 = !!checkedList7.length && checkedList7.length < plainOptions7.length;
                this.checkAll7 = checkedList7.length === plainOptions7.length;
            }, onCheckAllChange7(e) {
                Object.assign(this, {
                    checkedList7: e.target.checked ? plainOptions7 : [],
                    indeterminate7: false,
                    checkAll7: e.target.checked,
                });
            },
            onChange8(checkedList8) {
                this.indeterminate8 = !!checkedList8.length && checkedList8.length < plainOptions8.length;
                this.checkAll8 = checkedList8.length === plainOptions8.length;
            }, onCheckAllChange8(e) {
                Object.assign(this, {
                    checkedList8: e.target.checked ? plainOptions8 : [],
                    indeterminate8: false,
                    checkAll8: e.target.checked,
                });
            },
        },
        created() {
            // 获取分组指标
            this.$axios.get("templateDetailinfo1/list").then((res)=>{
                plainOptions1.splice(0,plainOptions1.length),plainOptions2.splice(0,plainOptions2.length)
                plainOptions3.splice(0,plainOptions3.length),plainOptions4.splice(0,plainOptions4.length)
                plainOptions5.splice(0,plainOptions5.length),plainOptions6.splice(0,plainOptions6.length)
                plainOptions7.splice(0,plainOptions7.length),plainOptions8.splice(0,plainOptions8.length)
                for(var i=0;i<res.data.list1.length;i++) {plainOptions1.push(res.data.list1[i])}for(var i=0;i<res.data.list2.length;i++) {plainOptions2.push(res.data.list2[i])}
                for(var i=0;i<res.data.list3.length;i++) {plainOptions3.push(res.data.list3[i])}for(var i=0;i<res.data.list4.length;i++) {plainOptions4.push(res.data.list4[i])}
                for(var i=0;i<res.data.list5.length;i++) {plainOptions5.push(res.data.list5[i])}for(var i=0;i<res.data.list6.length;i++) {plainOptions6.push(res.data.list6[i])}
                for(var i=0;i<res.data.list7.length;i++) {plainOptions7.push(res.data.list7[i])}for(var i=0;i<res.data.list8.length;i++) {plainOptions8.push(res.data.list8[i])}
            })
            //获取阶段表中的年份
            this.$axios.get("templateDetailinfo2/getYears").then((res)=>{
                this.years=res.data
            })
            //获取后台的全部组织方信息
            this.$axios.get("organization/getAllOrgan").then((res)=>{
                this.organs=res.data
            })
        }
    };
</script>
<style>
</style>




