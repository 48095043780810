<template>
    <el-form style="width: 60%" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

        <el-form-item label="指标分组" prop="indicatorGroup">
            <!--readOnly：只读，不可修改(或者disable)-->
            <el-input v-model="ruleForm.indicatorGroup"  readOnly></el-input>
        </el-form-item>

        <el-form-item label="指标个数" prop="indicatorNum">
            <el-input v-model="ruleForm.indicatorNum" readOnly></el-input>
        </el-form-item>

        <el-form-item label="指标完成度" prop="indicatorFinish">
            <el-input v-model="ruleForm.indicatorFinish" readOnly></el-input>
        </el-form-item>
        <el-form-item label="采集方" prop="collector">
            <el-input v-model="ruleForm.collector"></el-input>
        </el-form-item>

        <!--        <el-form-item>-->
        <!--            <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>-->
        <!--        </el-form-item>-->

    </el-form>
</template>

<script>
    export default {
        name: "Edit1",
        data() {
            return {
                ruleForm: {
                    indicatorGroup: '',
                    indicatorNum: '',
                    indicatorFinish: '',
                    collector:''
                },
                rules: {
                    collector: [
                        { required: true, message: '采集方不能为空', trigger: 'blur' }
                    ],
                }
            };
        },
        methods:{
            showEditDate(row){
                this.ruleForm=row;
            },
            updata()
            {
                if(this.ruleForm.collector=="")
                {
                    this.$alert("请输入采集方名称")
                    // window.location.reload()
                }
                else
                {
                    this.$axios.post("/templateDetailinfo1/updata1",this.ruleForm).then(res=>{
                    })
                }

                // window.location.reload()
            }
        },
    }
</script>

<style scoped>

</style>