<template>
  <div>
    <div class="headers">
      <div class="header">
        <div style="font-weight: bolder">
          <h2>当前空气温度</h2>
        </div>
        <h1>{{ airTem }}</h1>

        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;℃</h2>
        <h3>采集时间：{{ airUpTime }}</h3>
        <h3 style="color: red" v-show="airTemColor == 1">暂无数据</h3>
        <h3 style="color: green" v-show="airTemColor == 0">暂无异常</h3>
      </div>
      <div class="header">
        <div style="font-weight: bolder">
          <h2>当前空气湿度</h2>
        </div>
        <h1>{{ airHum }}</h1>

        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;%RH</h2>
        <h3>采集时间：{{ airUpTime }}</h3>
        <h3 style="color: red" v-show="airHumColor == 1">暂无数据</h3>
        <h3 style="color: green" v-show="airHumColor == 0">暂无异常</h3>
      </div>
      <div class="header">
        <div style="font-weight: bolder">
          <h2>当前土壤温度</h2>
        </div>
        <h1>{{ soilTem }}</h1>

        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;℃</h2>
        <h3>采集时间：{{ soilUpTime }}</h3>
        <h3 style="color: red" v-show="soilTemColor == 1">暂无数据</h3>
        <h3 style="color: green" v-show="soilTemColor == 0">暂无异常</h3>
      </div>
      <div class="header">
        <div style="font-weight: bolder">
          <h2>当前土壤湿度</h2>
        </div>
        <h1>{{ soilHum }}</h1>

        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;%RH</h2>
        <h3>采集时间：{{ soilUpTime }}</h3>
        <h3 style="color: red" v-show="soilHumColor == 1">暂无数据</h3>
        <h3 style="color: green" v-show="soilHumColor == 0">暂无异常</h3>
      </div>
      <div class="header set">
        <h2>显示设置</h2>
        <a-icon type="control" theme="filled" style="color: #888" />
        <div style="width: 90%">
          <div>
            <h2>操作</h2>
            &nbsp;
            <el-button type="primary" size="small" @click="refNowData"
              >刷新</el-button
            >
          </div>
          <div>
            <h2>警戒线</h2>
            &nbsp;
            <a-switch
              style="margin-bottom: 6px"
              checked-children="开"
              un-checked-children="关"
              @click="getLine"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <a-tabs default-active-key="1">
        <a-tab-pane
          key="1"
          tab="温度"
          style="background-color: #fff"
          forceRender
        >
          <div>
            <el-row>
              <el-col :offset="1" :span="22">
                <el-divider></el-divider>
                <div
                  style="width: 100%; overflow-x: auto; padding-bottom: 5px"
                  class="text-oneLine"
                >
                  <!-- <div
                    style="width: 200px; display: inline; font-weight: bolder"
                  >
                    地块名：&nbsp;
                  </div>
                  <div style="width: 200px; display: inline">
                    <el-select v-model="soilName">
                      <el-option
                        v-for="item in soilOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        size="mini"
                      >
                      </el-option>
                    </el-select>
                  </div> -->
                  <div
                    style="
                      width: 200px;
                      display: inline;
                      font-weight: bolder;
                      margin-left: 40px;
                      font-size: 16px;
                    "
                  >
                    查询方式：&nbsp;
                  </div>
                  <div
                    style="
                      width: 400px;
                      display: inline;
                      margin-left: 40px;
                      font-size: 16px;
                    "
                  >
                    <el-radio-group v-model="radio">
                      <el-radio :label="3">
                        <span style="font-size: 16px">时间段</span>
                      </el-radio>
                      <el-radio :label="6">
                        <span style="font-size: 16px">天</span></el-radio
                      >
                      <el-radio :label="9"
                        ><span style="font-size: 16px">月份</span></el-radio
                      >
                      <el-radio :label="12"
                        ><span style="font-size: 16px">年份</span></el-radio
                      >
                    </el-radio-group>
                  </div>

                  <div
                    style="width: 400px; display: inline; margin-left: 50px"
                    v-show="radio == 3"
                  >
                    <el-date-picker
                      v-model="selectTime"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      align="right"
                      size="small"
                      @change="getChartsData"
                    />
                  </div>

                  <div
                    style="width: 400px; display: inline; margin-left: 50px"
                    v-show="radio == 6"
                  >
                    <el-date-picker
                      v-model="selectDay"
                      type="date"
                      placeholder="选择日期"
                      size="small"
                      @change="getChartsData"
                    >
                    </el-date-picker>
                  </div>

                  <div
                    style="width: 400px; display: inline; margin-left: 50px"
                    v-show="radio == 9"
                  >
                    <el-date-picker
                      v-model="selectMonth"
                      type="month"
                      placeholder="选择月"
                      size="small"
                      @change="getChartsData"
                    >
                    </el-date-picker>
                  </div>

                  <div
                    style="width: 400px; display: inline; margin-left: 50px"
                    v-show="radio == 12"
                  >
                    <el-date-picker
                      v-model="selectYear"
                      type="year"
                      placeholder="选择年"
                      size="small"
                      @change="getChartsData"
                    >
                    </el-date-picker>
                  </div>

                  <div style="width: 200px; display: inline; margin-left: 50px">
                    <el-button
                      @click="getChartsData"
                      type="primary"
                      size="small"
                      >查询</el-button
                    >
                  </div>
                </div>
                <el-divider></el-divider>
              </el-col>
            </el-row>
            <el-row>
              <el-col :offset="1" :span="22">
                <div style="margin-bottom: 8px; text-align: center">
                  <strong style="font-size: 24px; color: black"
                    >平均温度图表</strong
                  >
                </div>
                <div id="temChart" class="charts"></div> </el-col
            ></el-row>
          </div>
        </a-tab-pane>

        <a-tab-pane
          key="2"
          tab="湿度"
          style="background-color: #fff"
          forceRender
        >
          <el-row>
            <el-col :offset="1" :span="22">
              <el-divider></el-divider>
              <div
                style="width: 100%; overflow-x: auto; padding-bottom: 5px"
                class="text-oneLine"
              >
                <!-- <div
                    style="width: 200px; display: inline; font-weight: bolder"
                  >
                    地块名：&nbsp;
                  </div>
                  <div style="width: 200px; display: inline">
                    <el-select v-model="soilName">
                      <el-option
                        v-for="item in soilOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        size="mini"
                      >
                      </el-option>
                    </el-select>
                  </div> -->
                <div
                  style="
                    width: 200px;
                    display: inline;
                    font-weight: bolder;
                    margin-left: 40px;
                    font-size: 16px;
                  "
                >
                  查询方式：&nbsp;
                </div>
                <div
                  style="
                    width: 400px;
                    display: inline;
                    margin-left: 40px;
                    font-size: 16px;
                  "
                >
                  <el-radio-group v-model="radio">
                    <el-radio :label="3">
                      <span style="font-size: 16px">时间段</span>
                    </el-radio>
                    <el-radio :label="6">
                      <span style="font-size: 16px">天</span></el-radio
                    >
                    <el-radio :label="9"
                      ><span style="font-size: 16px">月份</span></el-radio
                    >
                    <el-radio :label="12"
                      ><span style="font-size: 16px">年份</span></el-radio
                    >
                  </el-radio-group>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 3"
                >
                  <el-date-picker
                    v-model="selectTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    align="right"
                    size="small"
                    @change="getChartsData"
                  />
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 6"
                >
                  <el-date-picker
                    v-model="selectDay"
                    type="date"
                    placeholder="选择日期"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 9"
                >
                  <el-date-picker
                    v-model="selectMonth"
                    type="month"
                    placeholder="选择月"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 12"
                >
                  <el-date-picker
                    v-model="selectYear"
                    type="year"
                    placeholder="选择年"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div style="width: 200px; display: inline; margin-left: 50px">
                  <el-button @click="getChartsData" type="primary" size="small"
                    >查询</el-button
                  >
                </div>
              </div>
              <el-divider></el-divider>
            </el-col>
          </el-row>
          <el-row>
            <el-col :offset="1" :span="22">
              <div style="margin-bottom: 8px; text-align: center">
                <strong style="font-size: 24px; color: black"
                  >平均湿度图表</strong
                >
              </div>
              <div id="humChart" class="charts"></div> </el-col
          ></el-row>
        </a-tab-pane>

        <a-tab-pane
          key="3"
          tab="降水量"
          style="background-color: #fff"
          forceRender
        >
          <el-row>
            <el-col :offset="1" :span="22">
              <el-divider></el-divider>
              <div
                style="width: 100%; overflow-x: auto; padding-bottom: 5px"
                class="text-oneLine"
              >
                <!-- <div
                    style="width: 200px; display: inline; font-weight: bolder"
                  >
                    地块名：&nbsp;
                  </div>
                  <div style="width: 200px; display: inline">
                    <el-select v-model="soilName">
                      <el-option
                        v-for="item in soilOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        size="mini"
                      >
                      </el-option>
                    </el-select>
                  </div> -->
                <div
                  style="
                    width: 200px;
                    display: inline;
                    font-weight: bolder;
                    margin-left: 40px;
                    font-size: 16px;
                  "
                >
                  查询方式：&nbsp;
                </div>
                <div
                  style="
                    width: 400px;
                    display: inline;
                    margin-left: 40px;
                    font-size: 16px;
                  "
                >
                  <el-radio-group v-model="radio">
                    <el-radio :label="3">
                      <span style="font-size: 16px">时间段</span>
                    </el-radio>
                    <el-radio :label="6">
                      <span style="font-size: 16px">天</span></el-radio
                    >
                    <el-radio :label="9"
                      ><span style="font-size: 16px">月份</span></el-radio
                    >
                    <el-radio :label="12"
                      ><span style="font-size: 16px">年份</span></el-radio
                    >
                  </el-radio-group>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 3"
                >
                  <el-date-picker
                    v-model="selectTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    align="right"
                    size="small"
                    @change="getChartsData"
                  />
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 6"
                >
                  <el-date-picker
                    v-model="selectDay"
                    type="date"
                    placeholder="选择日期"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 9"
                >
                  <el-date-picker
                    v-model="selectMonth"
                    type="month"
                    placeholder="选择月"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div
                  style="width: 400px; display: inline; margin-left: 50px"
                  v-show="radio == 12"
                >
                  <el-date-picker
                    v-model="selectYear"
                    type="year"
                    placeholder="选择年"
                    size="small"
                    @change="getChartsData"
                  >
                  </el-date-picker>
                </div>

                <div style="width: 200px; display: inline; margin-left: 50px">
                  <el-button @click="getChartsData" type="primary" size="small"
                    >查询</el-button
                  >
                </div>
              </div>
              <el-divider></el-divider>
            </el-col>
          </el-row>
          <el-row>
            <el-col :offset="1" :span="22">
              <div style="margin-bottom: 8px; text-align: center">
                <strong style="font-size: 24px; color: black"
                  >降雨量图表</strong
                >
              </div>
              <div id="rainChart" class="charts"></div> </el-col
          ></el-row>
        </a-tab-pane>

        <a-tab-pane key="4" tab="多土壤仪显示" forceRender>
          <div
            style="
              background-color: #ffffff;
              box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
                rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;

              min-width: 600px;
              width: 100%;
            "
          >
            <el-row>
              <el-col :offset="1" :span="22">
                <el-divider></el-divider>
                <div
                  style="width: 100%; overflow-x: auto; padding-bottom: 5px"
                  class="text-oneLine"
                >
                  <!-- <div
                    style="width: 200px; display: inline; font-weight: bolder"
                  >
                    地块名：&nbsp;
                  </div>
                  <div style="width: 200px; display: inline">
                    <el-select v-model="soilName">
                      <el-option
                        v-for="item in soilOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        size="mini"
                      >
                      </el-option>
                    </el-select>
                  </div> -->
                  <div
                    style="
                      width: 200px;
                      display: inline;
                      font-weight: bolder;
                      margin-left: 40px;
                      font-size: 16px;
                    "
                  >
                    查询方式：&nbsp;
                  </div>
                  <div
                    style="
                      width: 400px;
                      display: inline;
                      margin-left: 40px;
                      font-size: 16px;
                    "
                  >
                    <el-radio-group v-model="radio">
                      <el-radio :label="3">
                        <span style="font-size: 16px">时间段</span>
                      </el-radio>
                      <el-radio :label="6">
                        <span style="font-size: 16px">天</span></el-radio
                      >
                      <el-radio :label="9"
                        ><span style="font-size: 16px">月份</span></el-radio
                      >
                      <!-- <el-radio :label="12"
                        ><span style="font-size: 16px">年份</span></el-radio
                      > -->
                    </el-radio-group>
                  </div>

                  <div
                    style="width: 400px; display: inline; margin-left: 50px"
                    v-show="radio == 3"
                  >
                    <el-date-picker
                      v-model="selectTime"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      align="right"
                      size="small"
                      @change="getChartsData"
                    />
                  </div>

                  <div
                    style="width: 400px; display: inline; margin-left: 50px"
                    v-show="radio == 6"
                  >
                    <el-date-picker
                      v-model="selectDay"
                      type="date"
                      placeholder="选择日期"
                      size="small"
                      @change="getChartsData"
                    >
                    </el-date-picker>
                  </div>

                  <div
                    style="width: 400px; display: inline; margin-left: 50px"
                    v-show="radio == 9"
                  >
                    <el-date-picker
                      v-model="selectMonth"
                      type="month"
                      placeholder="选择月"
                      size="small"
                      @change="getChartsData"
                    >
                    </el-date-picker>
                  </div>

                  <!-- <div
                    style="width: 400px; display: inline; margin-left: 50px"
                    v-show="radio == 12"
                  >
                    <el-date-picker
                      v-model="selectYear"
                      type="year"
                      placeholder="选择年"
                      size="small"
                      @change="getChartsData"
                    >
                    </el-date-picker>
                  </div> -->

                  <div style="width: 200px; display: inline; margin-left: 50px">
                    <el-button
                      @click="getChartsData"
                      type="primary"
                      size="small"
                      >查询</el-button
                    >
                  </div>
                </div>
                <el-divider></el-divider>
              </el-col>
            </el-row>
            <div style="margin-bottom: 8px; text-align: center">
              <strong style="font-size: 24px; color: black"
                >土壤仪温度图表</strong
              >
            </div>

            <el-row>
              <el-col :offset="1" :span="22">
                <div id="soilTemChart" class="charts"></div>
                <br />
              </el-col>
            </el-row>
          </div>
          <div
            style="
              margin-top: 20px;
              background-color: #ffffff;
              box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
                rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
              text-align: center;
              min-width: 600px;
              width: 100%;
            "
          >
            <el-row>
              <el-col :offset="1" :span="22">
                <div style="margin-bottom: 8px">
                  <strong style="font-size: 24px; color: black"
                    >土壤仪湿度图表</strong
                  >
                </div>
                <div id="soilHumChart" class="charts"></div>
              </el-col>
            </el-row>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "allLot",
  mounted() {
    this.selectTime.push(new Date(2023, 4, 23, 0, 0, 0));
    this.selectTime.push(new Date(2023, 5, 4, 0, 0, 0));
    this.getSoilOption();
  },
  data() {
    return {
      radio: 3,
      selectWay: 2,
      airTem: "",
      soilUpTime: "",
      airUpTime: "",
      soilHum: "",
      soilTem: "",
      airHum: "",
      soilHumColor: 0,
      airTemColor: 0,
      soilTemColor: 0,
      airHumColor: 0,
      xAxisTime: [],
      xSoilAxisTime: [],
      activeTab: "soilTemChart",
      soilName: "",
      soilOption: [],
      selectTime: [],
      selectDay: "",
      selectMonth: "",
      selectYear: "",
      charts: [],
      optionTem: {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          type: "scroll",
          data: [],
          textStyle: {
            color: "black",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          name: "日期 ",
          data: [],
          axisLabel: {
            textStyle: {
              color: "black",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "black",
            },
            formatter: "{value} (°C)",
          },
        },
        series: [],
      },
      optionHum: {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          type: "scroll",
          data: [],
          textStyle: {
            color: "black",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          name: "日期 ",
          data: [],
          axisLabel: {
            textStyle: {
              color: "black",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "black",
            },
            formatter: "{value} (%RH)",
          },
        },
        series: [],
      },
      optionRain: {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          type: "scroll",
          data: [],
          textStyle: {
            color: "black",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          name: "日期 ",
          data: [],
          axisLabel: {
            textStyle: {
              color: "black",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "black",
            },
            formatter: "{value} (mm)",
          },
        },
        series: [],
      },
      soilOptionTem: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          type: "scroll",
          data: [],
          textStyle: {
            color: "black",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,

          name: "日期 ",
          data: [],
          axisLabel: {
            textStyle: {
              color: "black",
            },
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          axisLabel: {
            textStyle: {
              color: "black",
            },
            formatter: "{value} (°C)",
          },
        },
        series: [],
      },
      soilOptionHum: {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          type: "scroll",
          data: [],
          textStyle: {
            color: "black",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          name: "日期 ",
          data: [],
          axisLabel: {
            textStyle: {
              color: "black",
            },
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          axisLabel: {
            textStyle: {
              color: "black",
            },
            formatter: "{value} (%RH)",
          },
        },
        series: [],
      },
      showWarningLine: false,
      lowerTemLimit: 20,
      upperTemLimit: "",
      lowerAirTemLimit: "",
      upperAirTemLimit: "",
      lowerHumLimit: "",
      upperHumLimit: "",
      lowerAirHumLimit: "",
      upperAirHumLimit: "",
    };
  },
  methods: {
    onTabClick() {
      this.initChart(this.activeTab);
    },
    refreshChart(id) {
      switch (id) {
        case "temChart":
          this.initChart("temChart");
          break;
        case "humChart":
          this.initChart("humChart");
          break;
        case "rainChart":
          this.initChart("rainChart");
          break;
        case "soilHumChart":
          this.initChart("soilHumChart");
          break;
        case "soilTemChart":
          this.initChart("soilTemChart");
          break;
      }
    },
    initChart(id) {
      switch (id) {
        case "temChart":
          this.charts[0] = echarts.init(document.getElementById("temChart"));
          setTimeout(() => {
            this.charts[0].resize();
          }, 100);
          window.addEventListener("resize", () => {
            this.charts[0].resize({
              width: document.getElementById("temChart").clientWidth,
              height: document.getElementById("temChart").clientHeight,
            });
          });
          this.optionTem.xAxis.data = this.xAxisTime;
          this.charts[0].clear();
          this.charts[0].setOption(this.optionTem, false, true);
          break;
        case "humChart":
          this.charts[1] = echarts.init(document.getElementById("humChart"));
          setTimeout(() => {
            this.charts[1].resize();
          }, 100);
          window.addEventListener("resize", () => {
            this.charts[1].resize({
              width: document.getElementById("humChart").clientWidth,
              height: document.getElementById("humChart").clientHeight,
            });
          });
          this.optionHum.xAxis.data = this.xAxisTime;
          this.charts[1].clear();
          this.charts[1].setOption(this.optionHum, false, true);
          break;
        case "rainChart":
          this.charts[2] = echarts.init(document.getElementById("rainChart"));
          setTimeout(() => {
            this.charts[2].resize();
          }, 100);
          window.addEventListener("resize", () => {
            this.charts[2].resize({
              width: document.getElementById("rainChart").clientWidth,
              height: document.getElementById("rainChart").clientHeight,
            });
          });
          this.optionRain.xAxis.data = this.xAxisTime;
          this.charts[2].clear();
          this.charts[2].setOption(this.optionRain, false, true);
          break;
        case "soilTemChart":
          this.charts[3] = echarts.init(
            document.getElementById("soilTemChart")
          );
          setTimeout(() => {
            this.charts[3].resize();
          }, 100);
          window.addEventListener("resize", () => {
            this.charts[3].resize({
              width: document.getElementById("soilTemChart").clientWidth,
              height: document.getElementById("soilTemChart").clientHeight,
            });
          });
          this.soilOptionTem.xAxis.data = this.xSoilAxisTime;
          this.charts[3].clear();
          this.charts[3].setOption(this.soilOptionTem, false, true);
          break;
        case "soilHumChart":
          this.charts[4] = echarts.init(
            document.getElementById("soilHumChart")
          );
          setTimeout(() => {
            this.charts[4].resize();
          }, 100);
          window.addEventListener("resize", () => {
            this.charts[4].resize({
              width: document.getElementById("soilHumChart").clientWidth,
              height: document.getElementById("soilHumChart").clientHeight,
            });
          });
          this.soilOptionHum.xAxis.data = this.xSoilAxisTime;
          this.charts[4].clear();
          this.charts[4].setOption(this.soilOptionHum, false, true);
          break;
      }
    },
    refNowData() {
      let refForm = new FormData();
      refForm.append("cropId", this.soilName),
        this.$axios.post("/LOT/latestTime", refForm).then((res) => {
          if (res.data.code == 0) {
            let result = res.data.getLOT;
            for (let i = 0; i < result.length; i++) {
              switch (result[i].targetName) {
                case "土壤湿度":
                  this.soilHum = result[i].targetValue[0];
                  if (!result[i].targetValue[0]) {
                    this.soilHumColor = 1;
                  } else {
                    this.soilHumColor = 0;
                  }
                  break;
                case "土壤温度":
                  this.soilTem = result[i].targetValue[0];
                  if (!result[i].targetValue[0]) {
                    this.soilTemColor = 1;
                  } else {
                    this.soilTemColor = 0;
                  }
                  break;
                case "空气温度":
                  this.airTem = result[i].targetValue[0];
                  if (!result[i].targetValue[0]) {
                    this.airTemColor = 1;
                  } else {
                    this.airTemColor = 0;
                  }
                  break;
                case "空气湿度":
                  this.airHum = result[i].targetValue[0];
                  if (!result[i].targetValue[0]) {
                    this.airHumColor = 1;
                  } else {
                    this.airHumColor = 0;
                  }
                  break;
                case "土壤时间":
                  this.soilUpTime = result[i].targetValue[0];
                  break;
                case "空气时间":
                  this.airUpTime = result[i].targetValue[0];
                  break;
              }
            }
          }
        });
    },
    getSoilOption() {
      this.$axios
        .post("/LOT/getCropRotationId")
        .then((res) => {
          if (res.data.code == 0) {
            let result = res.data.stringListMap;
            for (let i = 0; i < result.length; i++) {
              this.soilOption.push({
                label: result[i].id,
                value: result[i].id,
              });
            }
            this.soilName = this.soilOption[10].value;
            this.getChartsData();
            this.refNowData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getChartsData() {
      let that = this;
      switch (this.radio) {
        case 3:
          let form = new FormData();
          form.append("cropId", this.soilName);
          form.append(
            "date",
            new Date(2023, 5, 23, 0, 0, 0).toLocaleDateString()
          );
          form.append(
            "time",
            new Date(2023, 6, 4, 0, 0, 0).getHours().toString()
          );
          form.append("indicatorType", "环境指标");
          if (this.selectTime) {
            form.append("beginTime", this.selectTime[0].getTime());
            form.append("endTime", this.selectTime[1].getTime());
          }
          this.$axios
            .post("/LOT/timePeriod", form)
            .then((res) => {
              if (res.data.code == 0) {
                that.emptyData();
                let result = res.data.LotList;

                for (let i = 0; i < result.length; i++) {
                  switch (result[i].targetName) {
                    case "土壤温度":
                      that.optionTem.series.push({
                        name: "土壤温度",
                        type: "line",
                        data: result[i].targetValue,
                        markLine: {
                          symbol: "none", //去掉箭头

                          data: [
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.upperTemLimit,
                            },
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.lowerTemLimit,
                            },
                          ],
                        },
                      });
                      that.optionTem.legend.data.push(result[i].targetName);
                      break;

                    case "空气温度":
                      that.optionTem.series.push({
                        name: "空气温度",
                        type: "line",
                        data: result[i].targetValue,
                        markLine: {
                          symbol: "none", //去掉箭头

                          data: [
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.upperAirTemLimit,
                            },
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.lowerAirTemLimit,
                            },
                          ],
                        },
                      });
                      that.optionTem.legend.data.push(result[i].targetName);
                      break;

                    case "土壤湿度":
                      that.optionHum.series.push({
                        name: "土壤湿度",
                        type: "line",
                        data: result[i].targetValue,
                        markLine: {
                          symbol: "none", //去掉箭头

                          data: [
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.upperHumLimit,
                            },
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.lowerHumLimit,
                            },
                          ],
                        },
                      });
                      that.optionHum.legend.data.push(result[i].targetName);
                      break;

                    case "空气湿度":
                      that.optionHum.series.push({
                        name: "空气湿度",
                        type: "line",
                        data: result[i].targetValue,
                        markLine: {
                          symbol: "none", //去掉箭头

                          data: [
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.upperAirHumLimit,
                            },
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.lowerAirHumLimit,
                            },
                          ],
                        },
                      });
                      that.optionHum.legend.data.push(result[i].targetName);
                      break;
                    case "降雨量":
                      that.optionRain.series.push({
                        name: "降雨量",
                        type: "line",
                        data: result[i].targetValue,
                      });
                      that.optionRain.legend.data.push(result[i].targetName);
                      break;
                    case "土壤时间":
                      that.xAxisTime = result[i].targetValue;
                      break;
                  }
                }
                that.refreshChart("temChart");
                that.refreshChart("humChart");
                that.refreshChart("rainChart");
              }
              if (res.data.LotList[0].targetValue == null) {
                console.log(111);
                return this.$message.warning("警告，数据库未查询到数据！");
              }
            })
            .catch((error) => {});
          this.$axios
            .post("/LOT/InstTimePeriod", form)
            .then((res) => {
              if (res.data.code == 0) {
                that.emptyData();
                let inResult = res.data.humidityList;

                for (let i = 0; i < inResult.length; i++) {
                  if (inResult[i].targetName.includes("温度")) {
                    that.soilOptionTem.series.push({
                      name: inResult[i].targetName,
                      type: "line",
                      data: inResult[i].targetValue,
                      markLine: {
                        symbol: "none", //去掉箭头

                        data: [
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "red",
                            },
                            // xAxis: 4
                            yAxis: that.upperTemLimit,
                          },
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "blue",
                            },
                            // xAxis: 4
                            yAxis: that.lowerTemLimit,
                          },
                        ],
                      },
                    });
                    that.soilOptionTem.legend.data.push(inResult[i].targetName);
                  }
                  if (inResult[i].targetName.includes("湿度")) {
                    that.soilOptionHum.series.push({
                      name: inResult[i].targetName,
                      type: "line",
                      data: inResult[i].targetValue,
                      markLine: {
                        symbol: "none", //去掉箭头

                        data: [
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "red",
                            },
                            // xAxis: 4
                            yAxis: that.upperHumLimit,
                          },
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "blue",
                            },
                            // xAxis: 4
                            yAxis: that.lowerHumLimit,
                          },
                        ],
                      },
                    });

                    that.soilOptionHum.legend.data.push(inResult[i].targetName);
                  }
                  if (inResult[i].targetName.includes("时间")) {
                    that.xSoilAxisTime = inResult[i].targetValue;
                  }
                }
                this.refreshChart("soilTemChart");
                this.refreshChart("soilHumChart");
              }
            })
            .catch((error) => {});
          break;
        case 6:
          let dayForm = new FormData();
          let year = this.selectDay.getFullYear();
          let month = this.selectDay.getMonth() + 1;
          let day = this.selectDay.getDate();
          dayForm.append("cropId", this.soilName);
          dayForm.append(
            "time",
            year +
              "-" +
              this.$options.methods.proTime(month) +
              "-" +
              this.$options.methods.proTime(day)
          );
          this.$axios
            .post("/LOT/daily", dayForm)
            .then((res) => {
              if (res.data.code == 0) {
                that.emptyData();
                let result = res.data.LotList;
                for (let i = 0; i < result.length; i++) {
                  switch (result[i].targetName) {
                    case "土壤温度":
                      that.optionTem.series.push({
                        name: "土壤温度",
                        type: "line",
                        data: result[i].targetValue,
                        markLine: {
                          symbol: "none", //去掉箭头

                          data: [
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.upperTemLimit,
                            },
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.lowerTemLimit,
                            },
                          ],
                        },
                      });
                      that.optionTem.legend.data.push(result[i].targetName);
                      break;

                    case "空气温度":
                      that.optionTem.series.push({
                        name: "空气温度",
                        type: "line",
                        data: result[i].targetValue,
                        markLine: {
                          symbol: "none", //去掉箭头

                          data: [
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.upperAirTemLimit,
                            },
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.lowerAirTemLimit,
                            },
                          ],
                        },
                      });
                      that.optionTem.legend.data.push(result[i].targetName);
                      break;

                    case "土壤湿度":
                      that.optionHum.series.push({
                        name: "土壤湿度",
                        type: "line",
                        data: result[i].targetValue,
                        markLine: {
                          symbol: "none", //去掉箭头

                          data: [
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.upperHumLimit,
                            },
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.lowerHumLimit,
                            },
                          ],
                        },
                      });
                      that.optionHum.legend.data.push(result[i].targetName);
                      break;

                    case "空气湿度":
                      that.optionHum.series.push({
                        name: "空气湿度",
                        type: "line",
                        data: result[i].targetValue,
                        markLine: {
                          symbol: "none", //去掉箭头

                          data: [
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.upperAirHumLimit,
                            },
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.lowerAirHumLimit,
                            },
                          ],
                        },
                      });
                      that.optionHum.legend.data.push(result[i].targetName);
                      break;
                    case "降雨量":
                      that.optionRain.series.push({
                        name: "降雨量",
                        type: "line",
                        data: result[i].targetValue,
                      });
                      that.optionRain.legend.data.push(result[i].targetName);
                      break;
                    case "土壤时间":
                      that.xAxisTime = result[i].targetValue;
                      break;
                  }
                }
                that.refreshChart("temChart");
                that.refreshChart("humChart");
                that.refreshChart("rainChart");
              }
              if (res.data.LotList[0].targetValue == null) {
                console.log(111);
                return this.$message.warning("警告，数据库未查询到数据！");
              }
            })
            .catch((error) => {});
          this.$axios
            .post("/LOT/InstDaily", dayForm)
            .then((res) => {
              if (res.data.code == 0) {
                that.emptyData();
                let inResult = res.data.humidityList;
                for (let i = 0; i < inResult.length; i++) {
                  if (inResult[i].targetName.includes("温度")) {
                    that.soilOptionTem.series.push({
                      name: inResult[i].targetName,
                      type: "line",
                      data: inResult[i].targetValue,
                      markLine: {
                        symbol: "none", //去掉箭头

                        data: [
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "red",
                            },
                            // xAxis: 4
                            yAxis: that.upperTemLimit,
                          },
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "blue",
                            },
                            // xAxis: 4
                            yAxis: that.lowerTemLimit,
                          },
                        ],
                      },
                    });
                    that.soilOptionTem.legend.data.push(inResult[i].targetName);
                  }
                  if (inResult[i].targetName.includes("湿度")) {
                    that.soilOptionHum.series.push({
                      name: inResult[i].targetName,
                      type: "line",
                      data: inResult[i].targetValue,
                      markLine: {
                        symbol: "none", //去掉箭头

                        data: [
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "red",
                            },
                            // xAxis: 4
                            yAxis: that.upperHumLimit,
                          },
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "blue",
                            },
                            // xAxis: 4
                            yAxis: that.lowerHumLimit,
                          },
                        ],
                      },
                    });

                    that.soilOptionHum.legend.data.push(inResult[i].targetName);
                  }
                  if (inResult[i].targetName.includes("时间")) {
                    that.xSoilAxisTime = inResult[i].targetValue;
                  }
                }
                this.refreshChart("soilTemChart");
                this.refreshChart("soilHumChart");
              }
            })
            .catch((error) => {});
          break;
        case 9:
          let mouForm = new FormData();
          mouForm.append("cropId", this.soilName);
          mouForm.append(
            "time",
            this.selectMonth.getFullYear() +
              "-" +
              this.$options.methods.proTime(this.selectMonth.getMonth() + 1)
          );
          this.$axios.post("/LOT/month", mouForm).then((res) => {
            if (res.data.code == 0) {
              this.emptyData();
              let result = res.data.LOTList;
              for (let i = 0; i < result.length; i++) {
                switch (result[i].targetName) {
                  case "土壤温度":
                    that.optionTem.series.push({
                      name: "土壤温度",
                      type: "line",
                      data: result[i].targetValue,
                      markLine: {
                        symbol: "none", //去掉箭头

                        data: [
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                            },

                            yAxis: that.upperTemLimit,
                          },
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                            },

                            yAxis: that.lowerTemLimit,
                          },
                        ],
                      },
                    });
                    that.optionTem.legend.data.push(result[i].targetName);
                    break;

                  case "空气温度":
                    that.optionTem.series.push({
                      name: "空气温度",
                      type: "line",
                      data: result[i].targetValue,
                      markLine: {
                        symbol: "none", //去掉箭头

                        data: [
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                            },

                            yAxis: that.upperAirTemLimit,
                          },
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                            },

                            yAxis: that.lowerAirTemLimit,
                          },
                        ],
                      },
                    });
                    that.optionTem.legend.data.push(result[i].targetName);
                    break;

                  case "土壤湿度":
                    that.optionHum.series.push({
                      name: "土壤湿度",
                      type: "line",
                      data: result[i].targetValue,
                      markLine: {
                        symbol: "none", //去掉箭头

                        data: [
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                            },

                            yAxis: that.upperHumLimit,
                          },
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                            },

                            yAxis: that.lowerHumLimit,
                          },
                        ],
                      },
                    });
                    that.optionHum.legend.data.push(result[i].targetName);
                    break;

                  case "空气湿度":
                    that.optionHum.series.push({
                      name: "空气湿度",
                      type: "line",
                      data: result[i].targetValue,
                      markLine: {
                        symbol: "none", //去掉箭头

                        data: [
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                            },

                            yAxis: that.upperAirHumLimit,
                          },
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                            },

                            yAxis: that.lowerAirHumLimit,
                          },
                        ],
                      },
                    });
                    that.optionHum.legend.data.push(result[i].targetName);
                    break;
                  case "降雨量":
                    that.optionRain.series.push({
                      name: "降雨量",
                      type: "line",
                      data: result[i].targetValue,
                    });
                    that.optionRain.legend.data.push(result[i].targetName);
                    break;
                  case "土壤时间":
                    that.xAxisTime = result[i].targetValue;
                    break;
                }
              }
              this.refreshChart("temChart");
              this.refreshChart("humChart");
              this.refreshChart("rainChart");
            }
            if (res.data.LOTList[0].targetValue == null) {
              console.log(111);
              return this.$message.warning("警告，数据库未查询到数据！");
            }
          });
          this.$axios
            .post("/LOT/InstMonth", mouForm)
            .then((res) => {
              if (res.data.code == 0) {
                that.emptyData();
                let inResult = res.data.humidityList;
                for (let i = 0; i < inResult.length; i++) {
                  if (inResult[i].targetName.includes("温度")) {
                    that.soilOptionTem.series.push({
                      name: inResult[i].targetName,
                      type: "line",
                      data: inResult[i].targetValue,
                      markLine: {
                        symbol: "none", //去掉箭头

                        data: [
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "red",
                            },
                            // xAxis: 4
                            yAxis: that.upperTemLimit,
                          },
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "blue",
                            },
                            // xAxis: 4
                            yAxis: that.lowerTemLimit,
                          },
                        ],
                      },
                    });
                    that.soilOptionTem.legend.data.push(inResult[i].targetName);
                  }
                  if (inResult[i].targetName.includes("湿度")) {
                    that.soilOptionHum.series.push({
                      name: inResult[i].targetName,
                      type: "line",
                      data: inResult[i].targetValue,
                      markLine: {
                        symbol: "none", //去掉箭头

                        data: [
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "red",
                            },
                            // xAxis: 4
                            yAxis: that.upperHumLimit,
                          },
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "blue",
                            },
                            // xAxis: 4
                            yAxis: that.lowerHumLimit,
                          },
                        ],
                      },
                    });

                    that.soilOptionHum.legend.data.push(inResult[i].targetName);
                  }
                  if (inResult[i].targetName.includes("时间")) {
                    that.xSoilAxisTime = inResult[i].targetValue;
                  }
                }
                this.refreshChart("soilTemChart");
                this.refreshChart("soilHumChart");
              }
              if (res.data.LotList[0].targetValue == null) {
                console.log(111);
                return this.$message.warning("警告，数据库未查询到数据！");
              }
            })
            .catch((error) => {});
          break;
        case 12:
          let yearsForm = new FormData();
          yearsForm.append("cropId", this.soilName),
            yearsForm.append("time", this.selectYear.getFullYear()),
            this.$axios.post("/LOT/years", yearsForm).then((res) => {
              if (res.data.code == 0) {
                this.emptyData();
                let result = res.data.LOTList;
                for (let i = 0; i < result.length; i++) {
                  switch (result[i].targetName) {
                    case "土壤温度":
                      that.optionTem.series.push({
                        name: "土壤温度",
                        type: "line",
                        data: result[i].targetValue,
                        markLine: {
                          symbol: "none", //去掉箭头

                          data: [
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.upperTemLimit,
                            },
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.lowerTemLimit,
                            },
                          ],
                        },
                      });
                      that.optionTem.legend.data.push(result[i].targetName);
                      break;

                    case "空气温度":
                      that.optionTem.series.push({
                        name: "空气温度",
                        type: "line",
                        data: result[i].targetValue,
                        markLine: {
                          symbol: "none", //去掉箭头

                          data: [
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.upperAirTemLimit,
                            },
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.lowerAirTemLimit,
                            },
                          ],
                        },
                      });
                      that.optionTem.legend.data.push(result[i].targetName);
                      break;

                    case "土壤湿度":
                      that.optionHum.series.push({
                        name: "土壤湿度",
                        type: "line",
                        data: result[i].targetValue,
                        markLine: {
                          symbol: "none", //去掉箭头

                          data: [
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.upperHumLimit,
                            },
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.lowerHumLimit,
                            },
                          ],
                        },
                      });
                      that.optionHum.legend.data.push(result[i].targetName);
                      break;

                    case "空气湿度":
                      that.optionHum.series.push({
                        name: "空气湿度",
                        type: "line",
                        data: result[i].targetValue,
                        markLine: {
                          symbol: "none", //去掉箭头

                          data: [
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.upperAirHumLimit,
                            },
                            {
                              label: {
                                show: that.showWarningLine,
                              },
                              lineStyle: {
                                width: that.showWarningLine === true ? 2 : 0,
                              },

                              yAxis: that.lowerAirHumLimit,
                            },
                          ],
                        },
                      });
                      that.optionHum.legend.data.push(result[i].targetName);
                      break;
                    case "降雨量":
                      that.optionRain.series.push({
                        name: "降雨量",
                        type: "line",
                        data: result[i].targetValue,
                      });
                      that.optionRain.legend.data.push(result[i].targetName);
                      break;
                    case "土壤时间":
                      that.xAxisTime = result[i].targetValue;
                      break;
                  }
                }
                this.refreshChart("temChart");
                this.refreshChart("humChart");
                this.refreshChart("rainChart");
              }
              if (res.data.LOTList[0].targetValue == null) {
                console.log(111);
                return this.$message.warning("警告，数据库未查询到数据！");
              }
            });
          this.$axios
            .post("/LOT/InstYears", yearsForm)
            .then((res) => {
              if (res.data.code == 0) {
                that.emptyData();
                let inResult = res.data.humidityList;
                for (let i = 0; i < inResult.length; i++) {
                  if (inResult[i].targetName.includes("温度")) {
                    that.soilOptionTem.series.push({
                      name: inResult[i].targetName,
                      type: "line",
                      data: inResult[i].targetValue,
                      markLine: {
                        symbol: "none", //去掉箭头

                        data: [
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "red",
                            },
                            // xAxis: 4
                            yAxis: that.upperTemLimit,
                          },
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "blue",
                            },
                            // xAxis: 4
                            yAxis: that.lowerTemLimit,
                          },
                        ],
                      },
                    });
                    that.soilOptionTem.legend.data.push(inResult[i].targetName);
                  }
                  if (inResult[i].targetName.includes("湿度")) {
                    that.soilOptionHum.series.push({
                      name: inResult[i].targetName,
                      type: "line",
                      data: inResult[i].targetValue,
                      markLine: {
                        symbol: "none", //去掉箭头

                        data: [
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "red",
                            },
                            // xAxis: 4
                            yAxis: that.upperHumLimit,
                          },
                          {
                            label: {
                              show: that.showWarningLine,
                            },
                            lineStyle: {
                              width: that.showWarningLine === true ? 2 : 0,
                              color: "blue",
                            },
                            // xAxis: 4
                            yAxis: that.lowerHumLimit,
                          },
                        ],
                      },
                    });

                    that.soilOptionHum.legend.data.push(inResult[i].targetName);
                  }
                  if (inResult[i].targetName.includes("时间")) {
                    that.xSoilAxisTime = inResult[i].targetValue;
                  }
                }

                this.refreshChart("soilTemChart");
                this.refreshChart("soilHumChart");
              }
            })
            .catch((error) => {});
          break;
      }
    },
    proTime(t) {
      return t < 10 ? "0" + t : t;
    },
    emptyData() {
      this.optionTem.legend.data = [];
      this.optionTem.series = [];
      this.optionHum.legend.data = [];
      this.optionHum.series = [];
      this.optionRain.legend.data = [];
      this.optionRain.series = [];
      this.soilOptionTem.legend.data = [];
      this.soilOptionTem.series = [];
      this.soilOptionHum.legend.data = [];
      this.soilOptionHum.series = [];
    },
    getLine() {
      //土壤温度
      this.$axios.get("indicators/getDetails/100").then((res) => {
        this.lowerTemLimit = res.data.lowerLimit;
        this.upperTemLimit = res.data.upperLimit;
      });
      //土壤湿度
      this.$axios.get("indicators/getDetails/101").then((res) => {
        this.lowerHumLimit = res.data.lowerLimit;
        this.upperHumLimit = res.data.upperLimit;
      });
      //空气温度
      this.$axios.get("indicators/getDetails/102").then((res) => {
        this.lowerAirTemLimit = res.data.lowerLimit;
        this.upperAirTemLimit = res.data.upperLimit;
      });
      //空气湿度
      this.$axios.get("indicators/getDetails/103").then((res) => {
        this.lowerAirHumLimit = res.data.lowerLimit;
        this.upperAirHumLimit = res.data.upperLimit;
      });
      this.showWarningLine = !this.showWarningLine;
      this.getChartsData();
    },
  },
};
</script>

<style scoped>
/* .container {
  background-color: white;
} */
.charts {
  height: 500px;
  width: 100%;
}
.title_middle {
  font-size: 24px;
  font-weight: 700;
  color: black;
}
.title_small {
  font-size: 18px;
  font-size: 500;
}
.text-oneLine {
  display: block; /*内联对象需加*/
  word-break: keep-all; /* 不换行 */
  white-space: nowrap; /* 不换行 */
}

.headers {
  /*user-select:none;*/
  background-color: #fff;
  height: 200px;
  box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.13) 0 0 1px 1px;
}
/* headers下的第一个header没有边框 */
.headers .header:first-child {
  border-width: 0;
}

.header h1 {
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
  /*font-weight: bold;*/
  margin-top: 4px;
}
.header h2 {
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
}
.header h3 {
  margin: 0;
  color: #666666;
}

.header span {
}
.headers .header {
  margin-top: 20px;
  display: inline-block;
  text-align: left;
  /*background-color: #00ff00;*/
  vertical-align: middle;
  padding-left: 20px;
  width: 20%;
  min-height: 160px;
  border-left: 1px solid #ddd;
}

.headers .set div h2 {
  margin-top: 20px;
  width: 30%;
  line-height: 30px;
}
</style>