<template>
  <div id="components-form-demo-advanced-search">
    <a-card>
      <a-form-model :model="form" layout="inline">
        <a-form-model-item label="用户名称">
          <a-input v-model="form.username" placeholder="请输入用户名称"/>
        </a-form-model-item>
<!--        <a-form-item label="角色名称">-->
<!--          <a-input placeholder="请输入角色名称"/>-->
<!--        </a-form-item>-->
        <a-form-model-item label="联系方式">
          <a-input v-model="form.mobile" placeholder="请输入联系方式"/>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" @click="getUser">
            搜索
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="showAdd">
            新增
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <a-modal v-model="add" title="Add" @ok="onAdd">
        <EditUser ref="addUser" :get-list="showData"></EditUser>
      </a-modal>
    </a-card>

    <a-config-provider :locale="locale">
      <a-table bordered :data-source="dataSource" :columns="columns" @change="changePage" :pagination="pagination" size="middle">
        <template slot="operation" slot-scope="text, record">
          <a href="javascript:">
            <a-button type="link" style="margin: 0 10px;" @click="showUpdate(record)">
              <a-icon type="edit" />
            </a-button>
          </a>
          <a href="javascript:">
            <a-button type="link" @click="onDelete(record)">
              <a-icon type="delete" />
            </a-button>
          </a>
        </template>
      </a-table>
    </a-config-provider>
    <a-modal v-model="update" title="Update" @ok="onUpdate">
      <EditUser ref="upUser" :get-list="showData"></EditUser>
    </a-modal>
  </div>
</template>

<script>
import EditUser from './edit/edit-user'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'


export default {
  name: "manager-user",
  components: {EditUser},
  data() {
    return {
      form: {
        username: '',
        mobile: ''
      },
      locale: zhCN,
      count: 2,
      add: false,
      update: false,
      pagination: {
        size: "default",
        position: "bottom",
        pageSize: 5,
        current: 1,
        total: 6,
        showSizeChanger: false,
        showQuickJumper: true,
        pageSizeOptions: ['2', '5', '10', '20', '30']
      },
      dataSource: null,
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          align: 'center'
          // width: '30%'
          // scopedSlots: {customRender: 'name'},
        },
        {
          title: '用户名称',
          dataIndex: 'username',
          align: 'center'
          // width: '30%'
          // scopedSlots: {customRender: 'name'},
        },
        {
          title: '角色名称',
          dataIndex: 'roleName',
          align: 'center'
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          align: 'center'
        },
        {
          title: '联系方式',
          dataIndex: 'mobile',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '16%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        },
      ],
    }
  },
  created() {
    this.showData()
  },
  methods: {
    getUser() {
      // console.log(this.form)

      let _this = this

      if(_this.form.username === '' && _this.form.mobile === '') {
        _this.showData()
      } else {
        _this.$axios.request({
          method: 'POST',
          url: '/setting/getUser',
          params: {
            username: _this.form.username,
            mobile: _this.form.mobile
          }
        }).then( res => {
          // console.log(res)
          if(res.data.data[0] == null) {
            this.$message.error("查询信息是否有误");
          } else {
            _this.dataSource = res.data.data
            _this.pagination.total = 1
          }
        })
      }

      _this.form.username = ''
      _this.form.mobile = ''
    },
    showData() {
      let _this = this
      _this.$axios.post('setting/getAll', {}, {
        // params: {
        //   currentPage: _this.pagination.current
        // }
      }).then(res => {
        // console.log(res)
        _this.dataSource = res.data.data
        _this.pagination.total = res.data.data.count
        // _this.pagination = res.data.pagination
      })
    },
    changePage(page, filters, sorter){
      let _this = this
      _this.pagination.current = page.current

      _this.showData()
    },
    // 新增
    showAdd() {
      let _this = this
      _this.add = true;

      // _this.$nextTick( () => {
      //   _this.$refs.addUser.ShowData()
      // })
    },
    onAdd(e) {
      let _this = this
      _this.add = false;

      _this.$refs.addUser.onSubmit()
      _this.showData()
    },
    // 修改
    showUpdate(e) {
      let _this = this
      _this.update = true


      _this.$nextTick( () => {
        _this.$refs.upUser.ShowData(e)
      })
    },
    onUpdate() {
      let _this = this
      _this.update = false

      _this.$refs.upUser.onUpdate(this.form)
      _this.showData()
    },
    // 删除
    onDelete(e) {
      let _this = this

      // console.log(e)
      _this.$confirm({
        title: '确认删除',
        content: h => <div style="color:red;">您确定删除此用户吗？</div>,
        onOk() {
          // console.log('OK');
          _this.$axios.delete('setting/delUser', {
            params: {
              id: e.id
            }
          }).then(res => {
            console.log("已删除")
            _this.showData()
          })
        },
        onCancel()
        {
          console.log('Cancel');
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-table-tbody > tr > td {
  padding: 5px 15px !important;
  background: #fff;
}
</style>