<template>
  <div id="width" style="margin: 0 auto">
    <!-- 头部 -->
    <div class="headers">
      <div class="header">
        <div style="font-weight: bolder">
          <a-dropdown :trigger="['click']">
            <a
              @click="[(e) => e.preventDefault()][(selectYear = !selectYear)]"
              style="color: black"
            >
              <div style="font-weight: bolder">
                <h2>当前显示的年份</h2>
                <a-icon
                  :type="selectYear === true ? 'caret-down' : 'caret-down'"
                  style="color: #888"
                />
              </div>
            </a>
            <a-menu slot="overlay" @click="getYear">
              <a-menu-item v-for="item in optionsYear" :key="item">
                <span
                  style="font-size: 20px; margin-right: 20px; margin-left: 20px"
                  >{{ item }}</span
                >
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <h1>{{ year }}</h1>
        <h2 style="font-weight: bolder; margin: 0">&nbsp;&nbsp;年</h2>
      </div>

      <div class="header">
        <div style="font-weight: bolder">
          <h2>气象站运行状态</h2>
        </div>
        <br />
        <h1 style="color: green; margin-left: 5px">正常</h1>
      </div>

      <div class="header">
        <div style="font-weight: bolder">
          <h2>土壤仪运行状态</h2>
        </div>
        <h1 style="color: green; margin-left: 5px; margin-top: 22px">正常</h1>
      </div>
    </div>

    <a-tabs default-active-key="1">
      <a-tab-pane
        key="1"
        tab="气象站"
        style="background-color: #fff"
        forceRender
      >
        <div>
          <!--展示数据的表格，使用了Antd-Data Display-Table-嵌套子表格组件-->
          <a-table
            :columns="columns"
            :data-source="dataSource"
            class="components-table-demo-nested"
            @change="changePage"
            :pagination="pagination"
          >
            <!-- <template slot="operation1" slot-scope="item">
              <el-switch v-model="item" :size="mini"> </el-switch>
            </template> -->
            <span slot="operation1" slot-scope="text, record">
              <a-icon
                type="check"
                style="color: lawngreen"
                v-if="record.state == true"
              />
              <a-icon
                type="close"
                style="color: red"
                v-if="record.state == false"
              />
            </span>
            <a-button
              slot="operation"
              type="link"
              style="margin-left: -15px"
              @click="refresh"
            >
              <a-icon type="redo" />
            </a-button>
          </a-table>
        </div>
      </a-tab-pane>

      <a-tab-pane
        key="2"
        tab="土壤仪"
        style="background-color: #fff"
        forceRender
      >
        <div>
          <!--展示数据的表格，使用了Antd-Data Display-Table-嵌套子表格组件-->
          <a-table
            :columns="columns1"
            :data-source="dataSource1"
            class="components-table-demo-nested"
            @change="changePage1"
            :pagination="pagination1"
          >
            <span slot="operation1" slot-scope="text, record">
              <a-icon
                type="check"
                style="color: lawngreen"
                v-if="record.state == true"
              />
              <a-icon
                type="close"
                style="color: red"
                v-if="record.state == false"
              />
            </span>
            <a-button
              slot="operation"
              type="link"
              style="margin-left: -15px"
              @click="refresh"
            >
              <a-icon type="redo" />
            </a-button>
          </a-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import Editplot from "../edits/Editplot"; //鹏远提供的编辑组件，在什么地方用了?
import Editmap from "../edits/Editmap";

const rowSelection = {
  //行选择？3个方法
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};

export default {
  //这个到底相当于什么，一个对于上面整体<template>的Vue实例吗？ 怎么感觉它什么都能放
  name: "map-message", //
  components: { Editplot, Editmap }, //这个寓意什么?
  data() {
    return {
      //添加设备的表单数据
      addForm: {
        devicename: "",
        deviceprovider: "",
        deviceuser: "",
        inDate: "",
      },
      switchvalue: true,
      selectYear: true,
      optionsYear: [],
      year: "",
      addDialogVisible: false,
      //data()中写的是一个数据模板，是前后端交互承接数据用的
      count: 2, //条目数量

      expandedRowKeys: [],
      pagination: {
        //对Navigation-Pagination分页组件传参用
        size: "default", //？
        position: "bottom", //页码显示所在位置   --- 我要用这些参数控制前端显示
        pageSize: 10, //每页条目数量      --- 原来如此，这里不只用于承接前端要显示的数据
        current: 1, //当前页码         --- 还要对前端一些需要参数的组件提供参数
        total: 3, //总计条目数，应该是根据获取数据来变化的，它变了，分页分了多少页才有根据
        showSizeChanger: false, //每页条目数量可选择下拉框
        showQuickJumper: true, //快速访问框
        // pageSizeOptions: ['2', '5', '10', '20', '30']    //下拉框可选量
      },
      pagination1: {
        //对Navigation-Pagination分页组件传参用
        size: "default", //？
        position: "bottom", //页码显示所在位置   --- 我要用这些参数控制前端显示
        pageSize: 10, //每页条目数量      --- 原来如此，这里不只用于承接前端要显示的数据
        current: 1, //当前页码         --- 还要对前端一些需要参数的组件提供参数
        total: 3, //总计条目数，应该是根据获取数据来变化的，它变了，分页分了多少页才有根据
        showSizeChanger: false, //每页条目数量可选择下拉框
        showQuickJumper: true, //快速访问框
        // pageSizeOptions: ['2', '5', '10', '20', '30']    //下拉框可选量
      },
      dataSource: null, //数据源对象，后台传给前台的JSON被get后的response的data部分
      dataSource1: null,
      loading: false,
      iconLoading: false,

      rotation_plot_id: 12, //用于子表找到其父表信息
      rotation_plot_name: "ztf",

      // data,
      columns: [
        //title是我要显示的名字,dataIndex是后台使用时指明属性含义,key必不可少，与dataIndex保持一致就好
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          align: "center",

          width: 100,
          customRender: (text, record, index) => `${index + 1}`,
        },
        // {
        //   title: "气象站ID",
        //   dataIndex: "id",
        //   key: "id",
        // },
        {
          title: "气象站名称",
          dataIndex: "name",
          key: "name",
          // align:'center',
          // width: '30%'
        },
        {
          title: "工作是否正常",
          key: "operation1",
          dataIndex: "state",
          scopedSlots: { customRender: "operation1" },
          align: "center",
        },
        {
          title: "持续工作时间",
          dataIndex: "workingTime",
          key: "workingTime",
        },
        {
          title: "当前空气温度",
          dataIndex: "temperatureValue",
          key: "temperatureValue",
          // align: 'center',
        },
        {
          title: "当前空气湿度",
          dataIndex: "humidityValue",
          key: "humidityValue",
          // align: 'center',
        },
        {
          title: "最后一次更新时间",
          dataIndex: "formatTime",
          key: "formatTime",
          // align: 'center',
        },
        {
          title: "所属地块名称",
          dataIndex: "plot",
          key: "plot",
          // align: 'center',
        },

        {
          title: "操作",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      columns1: [
        //title是我要显示的名字,dataIndex是后台使用时指明属性含义,key必不可少，与dataIndex保持一致就好
        // {
        //   title: "土壤仪ID",
        //   dataIndex: "id",
        //   key: "id",
        // },
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          align: "center",

          // width: 100,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "土壤仪名称",
          dataIndex: "name",
          key: "name",
          // align:'center',
          // width: '30%'
        },
        {
          title: "工作是否正常",
          key: "operation1",
          dataIndex: "state",
          scopedSlots: { customRender: "operation1" },
          align: "center",
        },
        {
          title: "持续工作时间",
          dataIndex: "workingTime",
          key: "workingTime",
        },
        {
          title: "当前空气温度",
          dataIndex: "temperatureValue",
          key: "temperatureValue",
          // align: 'center',
        },
        {
          title: "当前空气湿度",
          dataIndex: "humidityValue",
          key: "humidityValue",
          // align: 'center',
        },
        {
          title: "最后一次更新时间",
          dataIndex: "formatTime",
          key: "formatTime",
          // align: 'center',
        },
        {
          title: "所属地块名称",
          dataIndex: "plot",
          key: "plot",
          // align: 'center',
        },

        {
          title: "操作",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
    };
  },
  created() {
    this.getYear();
  },
  mounted() {
    //加载年份
    this.$axios.get("/equipment/listTime").then((res) => {
      this.optionsYear = res.data.listTime;
      console.log(res.data.listTime);
      this.year = this.optionsYear[0];

      this.showData();
    });
  },
  methods: {
    //展示页面数据
    showData() {
      let _this = this;
      _this.$axios
        .get("/equipment/listEquipmentState", {
          params: {
            year: _this.year,
            page: _this.pagination.current - 1,
            offset: _this.pagination.pageSize,
            tag: 0,
          },
        })
        .then((res) => {
          let result = res.data.listEquipmentState.list;
          for (let i = 0; i < result.length; i++) {
            if (result[i].state == 1) {
              result[i].state = true;
            } else {
              result[i].state = false;
            }
          }
          _this.dataSource = result;
          _this.pagination.total = res.data.listEquipmentState.count;
          console.log(res.data.listEquipmentState.air);
        });
      _this.$axios
        .get("/equipment/listEquipmentState", {
          params: {
            year: _this.year,
            page: _this.pagination.current - 1,
            offset: _this.pagination.pageSize,
            tag: 1,
          },
        })
        .then((res) => {
          _this.dataSource1 = res.data.listEquipmentState.list;
          _this.pagination1.total = res.data.listEquipmentState.count;
          console.log(res.data.listEquipmentState.air);
        });
    },
    //获取年份
    getYear(val) {
      this.year = val.key;
      this.showData();
    },

    //使用右下角点击切换当前页
    changePage(page, filters, sorter) {
      let _this = this;
      _this.pagination.current = page.current; //我理解应该是这个分页组件封装了这种行为，当我点击其他也，就会返回这样一个page对象
      let current = _this.pagination.current;
      console.log(current);
      _this.$axios
        .get("/equipment/listEquipmentState", {
          params: {
            year: _this.year,
            page: _this.pagination.current - 1,
            offset: _this.pagination.pageSize,
            tag: 0,
          },
        })
        .then((res) => {
          console.log(res);
          _this.dataSource = res.data.listEquipmentState.list;
          _this.pagination.total = res.data.listEquipmentState.count;
        });
    },
    changePage1(page, filters, sorter) {
      let _this = this;
      _this.pagination1.current = page.current; //我理解应该是这个分页组件封装了这种行为，当我点击其他也，就会返回这样一个page对象
      let current = _this.pagination1.current;
      console.log(current);
      _this.$axios
        .get("/equipment/listEquipmentState", {
          params: {
            year: _this.year,
            page: _this.pagination1.current - 1,
            offset: _this.pagination1.pageSize,
            tag: 1,
          },
        })
        .then((res) => {
          console.log(res);
          _this.dataSource1 = res.data.listEquipmentState.list;
          _this.pagination1.total = res.data.listEquipmentState.count;
        });
    },
    //刷新按钮
    refresh() {
      this.showData();
    },
    //监听添加对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },

    //添加设备
    addDevice() {
      this.$axios.post("user", this.addForm);
    },

    // test(){
    //   let _this = this
    //   console.log("返回数据:")
    //   _this.$axios.get('/map/getByIdType',{
    //     params: {
    //       mapId: 3,
    //       mapType: 'nir'
    //     }
    //   }).then(res => {
    //     console.log(res)
    //   })
    // }
  },
};
</script>



<style scoped>
.headers {
  user-select: none;
  background-color: #fff;
  height: 200px;
  box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.13) 0 0 1px 1px;
}
/* headers下的第一个header没有边框 */
.headers .header:first-child {
  border-width: 0;
}

.header h1 {
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
  /*font-weight: bold;*/
  margin-top: 4px;
}
.header h2 {
  display: inline-block;
  font-size: 16px;
  font-weight: bolder;
}
.header h3 {
  margin: 0;
  color: #666666;
}

.header span {
}
.headers .header {
  margin-top: 20px;
  display: inline-block;
  text-align: left;
  /*background-color: #00ff00;*/
  vertical-align: middle;
  padding-left: 20px;
  width: 33%;
  min-height: 160px;
  border-left: 1px solid #ddd;
}

.headers .set div h2 {
  margin-top: 20px;
  width: 30%;
  line-height: 30px;
}
</style>
