<template>
  <div>
    <router-view :collapsed="collapsed" />
  </div>

</template>

<script>
export default {
  name: "grow",
  props:{collapsed:{}},
  methods:{
  },

}
</script>

<style scoped>

</style>