<template>
  <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
    <a-form-model-item label="创建时间" prop="formatTime">
      <a-date-picker
          v-model="form.formatTime"
          show-time
          type="date"
          placeholder="选择日期"
          style="width: 100%;"
      />
    </a-form-model-item>
    <a-form-model-item label="已有数据格式" prop="dataFormat">
      <a-select
          mode="multiple"
          v-model="form.dataFormat"
          style="width: 100%"
          placeholder="选择已具有的数据格式（多选）"
          @change="handleChange"
      >
        <a-select-option v-for="dataFormat in this.allFormats" :key="dataFormat">
          {{dataFormat}}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item label="是否具有NVDI数据" prop="isNvdi">
      <a-radio-group v-model="form.isNvdi">
        <a-radio value="1">是</a-radio>
        <a-radio value="0">否</a-radio>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item label="是否切片" prop="isTiles">
      <a-radio-group v-model="form.isTiles">
        <a-radio value="1">是</a-radio>
        <a-radio value="0">否</a-radio>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item label="是否上传" prop="isUpload">
      <a-radio-group v-model="form.isUpload" >
        <a-radio value="1">是</a-radio>
        <a-radio value="0">否</a-radio>
      </a-radio-group>
    </a-form-model-item>

  </a-form-model>
</template>
<script>
export default {
  name: 'Form',
  props:{
    getList: Function,
  },
  data() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
      allFormats: ['RGB','绿色光谱','红色光谱','近红外光谱','红外光谱','3D'],
      form: {
        id: '',
        cropsRotationPlotId: "",              //plot_id和plot_name应该从当前位置取得，看record的发挥了
        cropsRotationPlotName: "",
        dataFormat: undefined,
        isNvdi: '',
        isTiles: '',
        isUpload: '',
        formatTime: undefined,
      },
      rules:{
        formatTime: [
          { required: true, message: '请选择一个创建时间', trigger: 'change' }
        ],
        dataFormat: [
          { required: true, message: '请选择已有数据格式', trigger: 'change' }
        ],
        isNvdi: [
          { required: true, message: '请选择是否具有NVDI数据', trigger: 'change' }
        ],
        isTiles: [
          { required: true, message: '请选择是否切片', trigger: 'change' }
        ],
        isUpload: [
          { required: true, message: '请选择是否上传', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    onSubmit(plotId,plotName) {       //只用于地图增加操作
      let _this = this
      console.log('submit!', _this.form);
      _this.form.cropsRotationPlotId = plotId
      _this.form.cropsRotationPlotName = plotName
      console.log(_this.form.cropsRotationPlotId)
      console.log(_this.form.cropsRotationPlotName)
      let map = _this.form

      //可以发现多选框得到的是一个值的数组 - 这里有一个思考：就是用这样的字符串真的好吗，如果客户顺序有问题，那么结果可能出现这样的状态: dataFormat:A,B / B,A 。但其实二者含义一样，但是显示起来很不好看!
      //不过我至少也要在前端就把这个数组转换成字符串，毕竟量不大
      let formats = map.dataFormat
      map.dataFormat = ""
      for(let i=0;i<formats.length;i++)
      {
        if(i==0)  map.dataFormat += formats[i]
        else      map.dataFormat += ","+formats[i]
      }

      console.log("转换成功")
      console.log(map)

      _this.$axios.request({
        method: "POST",
        url: '/cropsSumMap/addmap',
        data: map           //要传的数据
      }).then(res => {
        console.log("地图信息传输完成!")
        console.log(res)
        _this.form = {
          id: '',
          cropsRotationPlotId: _this.now_plot_id,              //plot_id和plot_name应该从当前位置取得，看record的发挥了
          cropsRotationPlotName: _this.now_plot_name,
          dataFormat: undefined,
          isNvdi: '',
          isTiles: '',
          isUpload: '',
          formatTime: undefined,
        }
        _this.getList()
      });
    },

    handleChange(value) {
      console.log(`selected ${value}`);
      //也许是这样：这个多选组件的值是很难确定的，所以这里连例子中都没有了v-model绑定，让人根本不知道怎么用这个值
      //但是它比平常的不太一样的地方就是有这样一个方法
      //所以也许是这样：方法确实会收集到一个value值，这里你就可以根据value解析然后给自己的dataFormat赋上想要的状态了
    },
  },
};
</script>
