<template>
  <div id="mainCharts" style="text-align: center">
    <h1>{{this.year}}年土壤</h1>
    <!--    <a-popover placement="top">-->
    <!--      <template slot="content">-->
    <!--          &lt;!&ndash; 提示内容 &ndash;&gt;-->
    <!--        <p>土壤中各种形态的氮磷钾元素含量</p>-->
    <!--      </template>-->
    <!--      <template slot="title">-->
    <!--          &lt;!&ndash; 提示标题 &ndash;&gt;-->
    <!--        <span>微量元素</span>-->
    <!--      </template>-->
    <!--      <a-badge>-->
    <h1>微量元素</h1>
    <!--      </a-badge>-->
    <!--    </a-popover>-->
    <h1>表</h1>
    <div id='soil-trace-element-charts' style="margin: auto; height: 100%;"  :style="{width: '100%',height: '500px',minHeight:'10px'}">
      <h1>图表加载失败</h1>
    </div>
  </div>
</template>

<script >

export default {
  name: 'soil-trace-elements-charts',
  props:{
    'year': {},
    'showPromptMessage':{},
    'showWarningLine':{},
    collapsed:{},
  },
  //初值和变量
  data () {
    return {
      // year:"",
      msg:{
        soilN: {value:'',upperLimit:'',lowerLimit:''},
        soilP: {value:'',upperLimit:'',lowerLimit:''},
        soilK: {value:'',upperLimit:'',lowerLimit:''},
      },
      optionsYear: [],
    };
  },
  //方法
  methods: {

    getMsg(val){

      this.$axios.post("/indicator/stec",val).then((res)=>{
        let soilN={name:"氮",num:0};
        let soilP={name:"磷",num:0};
        let soilK={name:"钾",num:0};
        //氮磷钾元素异常值（在警戒线上下界之外为异常数据）

        for(let i=0;i<res.data.title.length;i++){
          if(res.data.list278[i]<this.msg.soilN.lowerLimit
              ||res.data.list278[i]>this.msg.soilN.upperLimit){
            soilN.num++;
          }
          if(res.data.list280[i]<this.msg.soilP.lowerLimit
              ||res.data.list280[i]>this.msg.soilP.upperLimit){
            soilP.num++;
          }
          if(res.data.list279[i]<this.msg.soilK.lowerLimit
              ||res.data.list279[i]>this.msg.soilK.upperLimit){
            soilK.num++;
          }
        }
        let a=[{name:"土壤元素含量",num:1}];
        a.push(soilN,soilP,soilK);
        this.$emit("change",a)


        let myCharts = this.$echarts.init(document.getElementById('soil-trace-element-charts'));

        myCharts.setOption( {
          title:{
            text:this.year+'年土壤微量元素表'
          },
          xAxis: [{
            nameLocation:'end', //坐标轴名称显示的位置
            silent:true,  //是否静态无法交互
            type: 'category',
            data: res.data.title,
            axisLine:{
              show:true,
            },},],
          legend: {
            // right:0,
            // formatter:'{name}'+(this.showPromptMessage===true?"*":' '),
            // formatter:function (name,showPromptMessage) {
            //
            //   return name+'*'+showPromptMessage;
            // },
            data: [{
              name:'氮元素',
              tooltip:{
                name:'氮元素',
                trigger:'item',
                show:this.showPromptMessage,
                formatter:this.msg.soilN.value,
                position:'right',
              },
            },
              {
              name:'磷元素',
              tooltip:{
                name:'氮元素',
                trigger:'item',
                show:this.showPromptMessage,
                formatter:this.msg.soilP.value,
                position:'top',
              },
            },
              {
              name:'钾元素',
              tooltip:{
                name:'氮元素',
                trigger:'item',
                show:this.showPromptMessage,
                formatter:this.msg.soilK.value,
                position:'top',
              },
            }],
          },
          series: [
                  {
              markLine:{
                label:{
                  show:this.showWarningLine,
                  // formatter:'10cmEC警戒指标',
                },
                data : [
                  {
                    lineStyle:{ //警戒线的样式 ，虚实 颜色
                      width: this.showWarningLine===true?1:0   //宽度
                    },
                    yAxis: this.msg.soilN.upperLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                  },
                  {
                    lineStyle:{ //警戒线的样式 ，虚实 颜色
                      width: this.showWarningLine===true?1:0   //宽度
                    },
                    yAxis: this.msg.soilN.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                  }]
              },
              name:'氮元素',
              data: res.data.list278,
            },
            {
            markLine:{
              label:{
                show:this.showWarningLine,
                position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
              },
              data : [
                {
                  lineStyle:
                      { //警戒线的样式 ，虚实 颜色
                        width: this.showWarningLine===true?1:0   //宽度
                      },
                  yAxis: this.msg.soilP.upperLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                },
                {
                  lineStyle:{ //警戒线的样式 ，虚实 颜色
                    width: this.showWarningLine===true?1:0   //宽度
                  },
                  yAxis: this.msg.soilP.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                }]
            },
              name:'磷元素',
              data:res.data.list280,
            },
            {
            markLine:{
              symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
              label:{
                show:this.showWarningLine,
                position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
              },
              data : [{
                lineStyle:{ //警戒线的样式 ，虚实 颜色
                  width: this.showWarningLine===true?1:0   //宽度
                },
                yAxis: this.msg.soilK.upperLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
              },{
                lineStyle:{ //警戒线的样式 ，虚实 颜色
                  width: this.showWarningLine===true?1:0   //宽度
                },
                yAxis: this.msg.soilK.lowerLimit, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
                z:0,
              }]
            },
              name:'钾元素',
              data:res.data.list279,
            }
          ]
        });
      })
    },
    changeChart(){
      this.getMsg(this.year)
      // console.log(this.showWarningLine+" "+this.showPromptMessage)

    },
    handleWindowResize() {
      let myCharts = this.$echarts.init(document.getElementById('soil-trace-element-charts'));
      myCharts && myCharts.resize();
    },
    changeChartSize(){
      setTimeout(()=>{
        this.handleWindowResize()
        // console.log("在刷新了")
      },200)
    }
  },
  //在页面加载前执行
  mounted() {

    //获取土壤氮元素信息

    this.$axios.get('/indicators/getDetails/278').then((res)=>{
      // console.log(res)
      // noinspection JSUnresolvedVariable
      this.msg.soilN.value=res.data.indexDetails;
      this.msg.soilN.upperLimit=res.data.upperLimit;
      this.msg.soilN.lowerLimit=res.data.lowerLimit;
    })
    //获取土壤磷元素信息
    this.$axios.get('/indicators/getDetails/280').then((res)=>{
      // console.log(res)
      // noinspection JSUnresolvedVariable
      this.msg.soilP.value=res.data.indexDetails;
      this.msg.soilP.upperLimit=res.data.upperLimit;
      this.msg.soilP.lowerLimit=res.data.lowerLimit;
    })
    //获取土壤钾元素信息
    this.$axios.get('/indicators/getDetails/279').then((res)=>{
      // console.log(res)
      // noinspection JSUnresolvedVariable
      this.msg.soilK.value=res.data.indexDetails;
      this.msg.soilK.upperLimit=res.data.upperLimit;
      this.msg.soilK.lowerLimit=res.data.lowerLimit;
    })
    if(this.year===undefined){
      this.year=''}
    // //在页面加载后加载有EC的所有年份
    // this.$axios.post("/indicator/getYear").then((res)=>{
    //   console.log(res)
    //   console.log(res.data)
    //   this.optionsYear=res.data
    // })

    //初始化echarts
    let myCharts = this.$echarts.init(document.getElementById('soil-trace-element-charts'));
    setTimeout(()=>{
      this.handleWindowResize()
      // console.log("在刷新了")
    },1)
    window.addEventListener("resize",this.handleWindowResize)
    myCharts.setOption( {

      title: {
        tooltip:{
          name:'氮元素',
          trigger:'氮元素',
          show:true,
          formatter:this.msg.soilN,
        },
        show:false,
        text: '土壤微量元素表',
      },
      xAxis: [{
        name:'日期',  //坐标轴的名称
        nameLocation:'end', //坐标轴名称显示的位置
        silent:false,  //是否静态无法交互
        type: 'category',
        data: [],
        axisLine:{
          show:true,
        },
      }],
      yAxis: {
        name:'ppm',  //坐标轴的名称
        type: 'value',
        scale:true,
        // splitNumber:20,
        start:30,
        axisLine:{
          show:true,
        },
      },
      toolbox: {
        show:true,
        feature: {
          saveAsImage: {},
        }
      },
      // dataZoom: [
      //   {
      //     height:15,
      //     type:'slider',
      //     // show: true,     //显示缩放的滑条
      //     dataBackground:{
      //       showDataShadow:'false',
      //       lineStyle:{
      //         opacity:0,
      //       },
      //       areaStyle:{
      //         opacity:0,
      //       },
      //     }
      //   },
      //   {
      //     type: 'inside', //鼠标滚轮控制缩放
      //     height:15,
      //   }
      //   ],
      grid: {
        left: '12px',
        right: '50px', ///   调整大小
        bottom: '1%',
        top:'8%',
        // show:true, //  边框是否显示
        containLabel: true
      },
      legend: {
        itemGap:30, //图例每项的间隔
        textStyle:{
          color:"#444",
          fontSize:14,
          fontWeight: 600,
        },
        // right:0,
        // bottom:0,
        tooltip:{
          renderMode:'html',
          appendToBody:true,
          // backgroundColor:'rgba(50,50,50,1)',
        },
        // formatter:function (name) {
        //   return name+'-';
        // },
        data: [{name:'氮元素',
          tooltip:{
            name:'氮元素',
            trigger:'氮元素',
            show:this.showPromptMessage,
            formatter:this.msg.soilN.value,
          },
        }, {
          name:'磷元素',
          data:'磷元素',
          tooltip:{
            name:'磷元素',
            trigger:'磷元素',
            show:this.showPromptMessage,
            formatter:this.msg.soilP.value,
          },
        }, {
          name:'钾元素',
          tooltip:{
            name:'钾元素',
            trigger:'钾元素',
            show:this.showPromptMessage,
            formatter:this.msg.soilK.value,
          },
        }],
      },
      tooltip:{
        // confine:'true',
        appendToBody:'true',
        backgroundColor:'rgba(255,255,255,0.96)',   //背景的颜色
        textStyle:{
          fontWeight:'normal',
          indent:'50px',
          color:"#555",
        },
        //给浮层一个阴影，并且让浮层内文字自动换行
        extraCssText:'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;white-space:pre-wrap;max-width:300px;',
        show:true,
        trigger:'axis', //显示指示器
        axisPointer: {  //配置指示器
          type:'shadow',  //指示器样式 line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器 'cross' 十字准星指示器。
        }
      },
      series: [
        {
          markLine:{
            symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
            label:{
              position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
              // formatter:'10cmEC警戒指标',
            },
            data : [
              {
                silent:false, //鼠标悬停事件 true没有，false有
                lineStyle:{ //警戒线的样式 ，虚实 颜色
                  type:"dotted", //样式  ‘solid’和'dotted'
                  // color:"#FA3934",
                  width: 1   //宽度
                },
                yAxis: 7400 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
              },
              {
                silent:false, //鼠标悬停事件 true没有，false有
                lineStyle:{ //警戒线的样式 ，虚实 颜色
                  type:"dotted", //样式  ‘solid’和'dotted'
                  // color:"#FA3934",
                  width: 1   //宽度
                },
                yAxis: 6800 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
              }]
          },
          name:"氮元素",
          data: [],
          type: 'bar',
          connectNulls:true,  //将断点连起来
        },
        {
          markLine:{
            show:false,
            symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
            label:{
              position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
              // formatter:'20cmEC警戒指标',
            },
            data : [
              {
                silent:false, //鼠标悬停事件 true没有，false有
                lineStyle:
                    { //警戒线的样式 ，虚实 颜色
                      type:"dotted", //样式  ‘solid’和'dotted'
                      // color:"#FA3934",
                      width: 1   //宽度
                    },
                yAxis: 1200 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
              },
              {
                silent:false, //鼠标悬停事件 true没有，false有
                lineStyle:{ //警戒线的样式 ，虚实 颜色
                  type:"dotted", //样式  ‘solid’和'dotted'
                  // color:"#FA3934",
                  width: 1   //宽度
                },
                yAxis: 500 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
              }]
          },
          name:"磷元素",
          data: [],
          type: 'bar',
          connectNulls:true,
        },
        {
          markLine:{
            symbol: ['none','none'], //['none']表示是一条横线；['arrow', 'none']表示线的左边是箭头，右边没右箭头；['none','arrow']表示线的左边没有箭头，右边有箭头
            label:{
              position:"end", //将警示值放在哪个位置，三个值“start”,"middle","end" 开始 中点 结束
              // formatter:'30cmEC警戒指标',
            },
            data : [{
              silent:false, //鼠标悬停事件 true没有，false有
              lineStyle:{ //警戒线的样式 ，虚实 颜色
                type:"dotted", //样式  ‘solid’和'dotted'
                // color:"#FA3934",
                width: 1   //宽度
              },
              yAxis: 2000 // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
            },{
              silent:false, //鼠标悬停事件 true没有，false有
              lineStyle:{ //警戒线的样式 ，虚实 颜色
                type:"dotted", //样式  ‘solid’和'dotted'
                // color:"#FA3934",
                width: 1   //宽度
              },
              yAxis: 1000, // 警戒线的标注值，可以有多个yAxis,多条警示线 或者采用 {type : 'average', name: '平均值'}，type值有 max min average，分为最大，最小，平均值
              z:0,
            }]
          },
          connectNulls:true,
          name: "钾元素",
          data: [],
          type:'bar',
        },
      ],

    });
  },
  watch:{//监听year变量的变化，在year变量的数值改变时，调用changeChart方法
    year:'changeChart',
    showPromptMessage:'changeChart',
    showWarningLine:'changeChart',
    collapsed:"changeChartSize"
  }



}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  display: inline-block;
  font-weight: normal;
}
h1{
  display: inline-block;
  font-weight: bolder;
  font-size: 24px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
