<template>
    <div style="height: 800px">
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: "machine-status"
    }
</script>

<style scoped>

</style>