<template>
    <div>
        <test style="background-color: #FFFFFF;box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;" />
        <weight3 style="margin-top:5px;background-color: #FFFFFF;box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;"/>
    </div>
</template>

<script>
    import test from "../charts/test";
    import weight3 from '../charts/quantity-weight3'
    export default {
        name: "multiple",
        components:{weight3,test},
        data(){
            return {
                optionsYear:[],
                year:'',
            };
        },
        beforeMount() {
            //加载年份
            this.$axios.post("/indicator/getYear").then((res)=>{
                this.optionsYear=res.data
            })
            this.getMsg(new Date().getFullYear())
        },
        methods:{
            getMsg(val){
                this.year=val;
            }
        },
    }
</script>

<style scoped>

</style>