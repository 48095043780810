<template>
  <div style="text-align: center; width:100%; height:100%;">
    <h1 style="display: inline-block">2022自定义出苗</h1>
    <h1 style="display: inline-block;">值</h1>
    <div id="indicator-EC-charts1" style="height:60vh; width:45vw;">
      <h1>图表加载失败</h1>
    </div>
  </div>
</template> 

<script >

// noinspection JSUnresolvedVariable
export default {
  name: 'Indicator-sprout-charts1',
  props:{
  },
  //初值和变量
  data () { 
    return {

      msg:{
        EC10cm:{
          value:[15002,20939,30494,129839],
        },
      }
    };
  },
  //方法
  methods: {
    
  },
  //在页面加载前执行
  mounted() {
    
    console.log("进入总体出苗数据加载函数")
    this.msg.EC10cm.value=[15002,20939,30494,129839];
    
    //初始化echarts
    let myCharts = this.$echarts.init(document.getElementById('indicator-EC-charts1'));
    //延迟渲染，
    setTimeout(()=>{
      this.handleWindowResize()
    },100)
    // window.addEventListener('resize',this.handleWindowResize)
    // myCharts.setOption( {
     
      
      // myCharts.setOption(option);
      var option =   {
      title: {
        show:false,
        text: '总体出苗数',
      },
      xAxis: [{
        name:'日期',  //坐标轴的名称
        nameLocation:'end', //坐标轴名称显示的位置
        silent:false,  //是否静态无法交互
        type: 'category',
        data: this.$root.backgroundTaskResTime1,//时间轴
        axisLine:{
          show:true,
        },
      }],
      yAxis: {
        name:'个数/地块',  //坐标轴的名称
        type: 'value',
        scale:true,
        start:30,
        axisLine:{
          show:true,
        },
      },
      toolbox: {
        show:true,
        feature: {
          saveAsImage: {},
        }
      },
      grid: {
        left: '27px',
        right: '50px', ///   调整大小
        bottom: '1%',
        top:'7%',
        // show:true, //  边框是否显示
        width: "100%",
        height: "280px",
        containLabel: true
      },

      legend: {
        itemGap:30, //图例每项的间隔
        textStyle:{
          color:"#444",
          fontSize:14,
          fontWeight: 'bold',
        },
        tooltip: {
          show: true
        },
        data: [{
          tooltip:{
            show:this.showPromptMessage,
          },
          name:'出苗数'
        }]
      },
      tooltip:{
        appendToBody:'true',
        backgroundColor:'rgba(255,255,255,0.96)',   //背景的颜色
        textStyle:{
          fontWeight:'normal',
          indent:'50px',
          color:"#555",
        },
        extraCssText:'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;white-space:pre-wrap;max-width:300px;',
        show:true,
        trigger:'axis',
      },
      series: [
        // {
          
        //   name:"出苗数1",
        //   data: this.$root.backgroundTaskRes,
        //   type: 'line',
        //   connectNulls:true,  //将断点连起来
        // },
        // {
          
        //   name:"出苗数2",
        //   data: this.$root.backgroundTaskRes,
        //   type: 'line',
        //   connectNulls:true,  //将断点连起来
        // },
        console.log("自定义出苗数据加载函数结束")
        
      ],
      
      
    };let data1 = this.$root.backgroundTaskAllRes1
    let data2 = this.$root.backgroundTaskResName
      console.log(data1)
      for (var i = 0; i < data1.length; i++) {
          option.series.push({
              name:data2[i],
              type: 'line',
              data: data1[i]
          });
      }
    // let data1 = this.$root.backgroundTaskAllRes
    // let data2 = this.$root.backgroundTaskResName
      // console.log(data1)
      // for (var i = 0; i < data1.length; i++) {
      //     option.series.push({
      //         name:data2[i],
      //         type: 'line',
      //         data: data1[i]
      //     });
      // }
    myCharts.setOption(option);
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
h1{
  display: inline-block;
  font-weight: bolder;
  font-size: 24px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
