<template>
    <div id="count1" style=" margin: auto" :style="{width: '80%',height: '300px',}">
        count1
    </div>
</template>

<script>
    export default {
        name: "quantity-count1",
        props:{//接受传入的参数
            'year': {},
        },
        data () {
            return {
                optionsYear: [],
            };
        },
        methods:{
            getMsg(val){
                let _this = this
                this.$axios.post('/indicator/getBar1',val).then(function (response) {
                    // console.log(response)
                    let myChart = _this.$echarts.init(document.getElementById('count1'))
                    myChart.setOption({
                        title: {
                            text: _this.year+'年不同等级结薯数量',
                            left: 'center'
                        },
                        xAxis: {
                            axisLabel: {
                                interval:0,
                                rotate : 50},

                            type: 'category',
                            data: ['2两以下', '2-3两', '3两以上', '单株大薯块', '单株中薯块', '单株小薯块', '单株特小薯块','结薯平均']
                        },
                        yAxis: {
                            type: 'value'
                        },
                        tooltip: {},
                        series: [
                            {
                                data: response.data,
                                type: 'bar',
                                // showBackground: true,去掉背景色
                                backgroundStyle: {
                                    color: 'rgba(180, 180, 180, 0.2)'
                                }
                            }
                        ]
                    })
                })

            },
            changeChart(){
                this.getMsg(this.year)
            }
        },
        beforeMount() {
            this.getMsg(new Date().getFullYear())
        },
        watch:{
            year:'changeChart'
        },
        mounted() {
            let _this = this
            this.$axios.post('/indicator/getBar1',new Date().getFullYear()).then(function (response) {
                console.log(response)
                let myChart = _this.$echarts.init(document.getElementById('count1'))
                myChart.setOption({
                    title: {
                        text: '不同等级结薯数量'
                    },
                    xAxis: {
                        type: 'category',
                        data: ['2两以下', '2-3两', '3两以上', '单株大薯块', '单株中薯块', '单株小薯块', '单株特小薯块','结薯平均']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    tooltip: {},
                    series: [
                        {
                            data: response.data,
                            type: 'bar',
                            // showBackground: true,
                            backgroundStyle: {
                                color: 'rgba(180, 180, 180, 0.2)'
                            }
                        }
                    ]
                })
            })
        }
    }
</script>

<style scoped>

</style>