<template>
  <div class="login-container">
    <div class="loginDiv">
      <div class="left">
        <img src="../../assets/loginleft.jpg" class="logoimg" />
        <div class="message chn">耕耘遥感天地空一体化平台</div>
        <div class="message eng">
          Remote sensing sky sky space integration platform
        </div>
      </div>
      <div class="right">
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
          auto-complete="on"
          label-position="left"
        >
          <div class="title-container">
            <h3 class="title">登录</h3>
          </div>

          <el-form-item prop="username">
            <span class="svg-container">
              <!-- <svg-icon icon-class="user" /> -->
            </span>
            <el-input
              ref="username"
              v-model="loginForm.username"
              placeholder="用户名"
              name="username"
              type="text"
              tabindex="1"
              auto-complete="on"
            />
          </el-form-item>

          <el-form-item prop="password">
            <span class="svg-container">
              <!-- <svg-icon icon-class="password" /> -->
            </span>
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="密码"
              name="password"
              tabindex="2"
              auto-complete="on"
              @keyup.enter.native="handleLogin"
            />
            <span class="show-pwd" @click="showPwd">
              <!-- <svg-icon
                :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
              /> -->
            </span>
          </el-form-item>

          <el-button
            :loading="loading"
            class="btn-login"
            @click.native.prevent="handleLogin"
            >登录</el-button
          >
          
      <!-- <div class="tips">
        <span>欢迎登陆农业服务共享平台！</span>

      </div> -->
        </el-form>
        <router-link class="forget-password" to="/reset">已有账号，忘记密码？</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不能少于六位"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: "admin",
        password: "111111",
      },
      loginRules: {
        username: [{ required: true, trigger: "blur" }],
        password: [
          { whitespace:true, required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      loading: false,
      passwordType: "password",
      redirect: "/home",
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  methods: {
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },

    handleLogin() {
      console.log("进入登陆模块")
      console.log(this.loginForm.password)
      console.log(this.loginForm.username)
      this.$axios.request({
        method: 'POST',
        url: '/setting/getUsers',
        data: this.loginForm
      }).then((res) => {

          if(res.code = 200){
            console.log("登陆成功")
              this.$router.push("/soil/map-editor");
          }
      })
    }
    // handleLogin() {
    //   this.$refs.loginForm.validate((valid) => {
    //     if (valid) {
    //       this.loading = true;
    //       this.$store
    //         .dispatch("setting/getUsers", this.loginForm)
    //         .then(() => {
    //           this.$router.push("/home");
    //           this.loading = false;
    //         })
    //         .catch(() => {
    //           this.loading = false;
    //         });
    //     } else {
    //       console.log("error submit!!");
    //       return false;
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #666;
$cursor: #666;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: url("../../assets/3.jpg");
$dark_gray: #889aa4;
$light_gray: #666;
.chn {
  font-size: 20px;
}

.btn-login {
  width: 100%;
  margin-bottom: 20px;
  background: #06cb88;
  color: #eee;
}
.message {
  color: #eee;
  text-align: center;
  &:first-child {
    margin-top: 30px;
  }
}
.chn {
  margin-top: 30px;
}
.logoimg {
  display: block;
  width: 110px;
  height: auto;
  margin:  auto;
  margin-top: 70px;
}
.loginDiv {
  width: 750px;
  height: 350px;
  background: #fff;
  box-shadow: 1px 1px 3px #ddd;
  border-radius: 15px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 13%;
}
.left {
  width: 300px;
  height: 350px;
  background: #06cb88;
  float: left;
  clear: left;
  display: block;
}
.right {
  width: 450px;
  height: 350px;
  background: #fff;
  float: right;
  clear: right;
}
.login-container {
  min-height: 100%;
  width: 100%;
  background-image: $bg;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 30px 30px 0px 30px;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    margin-left: 90px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
.forget-password{
  color: rgb(102, 102, 102);
  text-align: center !important;
  font-size: 16px;
  display: block;
}
</style>
