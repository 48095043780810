<template>
  <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
    <a-form-model-item label="名称">
      <a-input v-model="form.name"/>
    </a-form-model-item>

    <a-form-model-item label="指标分组">
      <a-select
              v-model="form.indicatorGroup"
              style="width: 100%"
              placeholder="请选择指标分组"
              @change="handleChange"
      >
        <a-select-option v-for="group in this.allGroup" :key="group">
          {{group}}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item label="计量单位">
      <a-input v-model="form.unit"/>
    </a-form-model-item>

<!--    <a-form-model-item label="预计次数">-->
<!--      <a-input-number-->
<!--              id="inputNumber"-->
<!--              v-model="form.numOfCollections" :min="0"-->
<!--              @change="onChange"-->
<!--              />-->
<!--    </a-form-model-item>-->


    <a-form-model-item label="警戒线下上限">
      <a-input-number :min="0" :max="10"
                      :step="0.1"
                      v-model="form.upperLimit"
                      @change="onChange"

      />
    </a-form-model-item>
    <a-form-model-item label="警戒线下限">
      <a-input-number :min="0" :max="10"
                      :step="0.1"
                      v-model="form.lowerLimit"
                      @change="onChange"
      />
    </a-form-model-item>


    <a-form-model-item label="采集方">
      <a-select
              mode="tags"
              v-model="form.gatherer"
              style="width: 100%"
              placeholder="请选择采集方（多选）"
              @change="handleChange"
      >
        <a-select-option v-for="gatherer in this.allGatherer" :key="gatherer">
          {{gatherer}}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item label="说明">
<!--      <a-input type="textarea" />-->
      <a-textarea
              v-model="form.indexDetails"
              placeholder="Basic usage" :rows="5"/>
    </a-form-model-item>

  </a-form-model>
</template>
<script>
  import qs from 'qs'
export default {
  name: 'Form',
  props:{
    getList: Function
  },
  data() {
    return {
      allGroup: [],
      allGatherer: [],
      value: ['china'],
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
      form: {
        name: '',
        indicatorGroup: undefined,
        unit: undefined,
        upperLimit: undefined,
        lowerLimit: undefined,
        gatherer: undefined,
        indexDetails: undefined,
        indicatorId: undefined,
        id: ''

      },
    };
  },
  created() {
    this.getAllgroup()
    this.getAllGatherer()
  },

  watch: {
    value(val) {
      console.log(`selected:`, val);
    },
  },
  methods: {
    getAllGatherer() {
      console.log("getAllGatherer")
      let _this = this
      _this.$axios.get('/organization/getAllOrgan') .then(res => {
        console.log(res)
        _this.allGatherer = res.data
      })
    },
    getAllgroup() {
      console.log("getAllgroup")
      let _this = this
      _this.$axios.get('/IndicatorsGroups/getAllGroup') .then(res => {
        console.log(res)
        _this.allGroup = res.data
      })

    },

    showUpdateDate(e) {
      console.log("showUpdateDate")
      console.log(e)
      console.log(this)
      let _this = this

      console.log("e.name:"+e.name)

      this.$nextTick( () => {
        console.log(_this.form)
        _this.form = e
      })

    },
    handleChange(value) {
      console.log(`selected ${value}`);
      // let _this = this
      // _this.indictorsGroup = value
    },

    onUpdate() {
      console.log("edit-->onUpdate")
      let _this = this
      let indicator = _this.form;
      console.log(indicator);
      indicator.gatherer = indicator.gatherer.toString();

      _this.$axios.request({
        method: 'POST',
        url: '/indicators/update',
        data: indicator
      }).then(res => {
        console.log("res")
        console.log(res)
        _this.getList()
      })


      // _this.$axios.post('/indicators/update',
      //         {indicator}, //对象
      //         {params:{
      //           //属性
      //           }})
  },

    onAdd() {
      let _this = this
      let indicator = _this.form;
      console.log(indicator);
      indicator.gatherer = indicator.gatherer.toString();

      _this.$axios.request({
        method: 'POST',
        url: '/indicators/add',
        data: indicator
      }).then(res => {
        console.log("res")
        console.log(res)
        _this.getList()
      })
    },

    onSubmit() {
      let _this = this
      console.log('submit!', this.form);
      _this.onAdd()

    },
  }
};
</script>
