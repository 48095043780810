<template>
    <div >
        <div id="1" class="headers">
            <div class="header" >
                <a-dropdown :trigger="['click']">
                    <a @click="[e => e.preventDefault()] [selectYear=!selectYear ]" style="color: black;">
                        <div style="font-weight: bolder">
                            <h2>当前显示的年份 </h2>
                            <a-icon :type="selectYear===true?'caret-down':'caret-up'" style="color:#888" />
                        </div>
                    </a>
                    <a-menu slot="overlay" @click="getYear"  >
                        <a-menu-item
                                v-for="item in optionsYear"
                                :key="item">
                            <span style="font-size: 20px; margin-right: 20px; margin-left: 20px;">{{item}}</span>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
                <h1>{{year}}</h1>
                <h2 style="font-weight: bolder;margin: 0;">&nbsp;&nbsp;年</h2>
            </div>
            <div class="header">
                <a-dropdown :trigger="['click']">
                    <a @click="e => e.preventDefault()" style="color: black;">
                        <div style="font-weight: bolder">
                            <h2>{{testWarn}}异常指标</h2>
                            <a-icon type="caret-down" style="color:#888" />
                        </div>
                    </a>
                    <a-menu slot="overlay" @click="handleWarn" >
                        <a-menu-item key="总体">总体</a-menu-item>
                        <a-sub-menu key="1" title="结薯数量">
                            <a-menu-item key="结薯数量总体">结薯数量总体</a-menu-item>
                            <a-menu-item key="2两以下">2两以下</a-menu-item>
                            <a-menu-item key="2-3两">2-3两</a-menu-item>
                            <a-menu-item key="3两以上">3两以上</a-menu-item>
                            <a-menu-item key="单株大薯块">单株大薯块</a-menu-item>
                            <a-menu-item key="单株中薯块">单株中薯块</a-menu-item>
                            <a-menu-item key="单株小薯块">单株小薯块</a-menu-item>
                            <a-menu-item key="单株特小薯块">单株特小薯块</a-menu-item>
                            <a-menu-item key="结薯平均">结薯平均</a-menu-item>
                        </a-sub-menu>
                        <a-sub-menu key="2" title="结薯重量">
                            <a-menu-item key="结薯重量总体">结薯重量总体</a-menu-item>
                            <a-menu-item key="2两以下">2两以下</a-menu-item>
                            <a-menu-item key="2-3两">2-3两</a-menu-item>
                            <a-menu-item key="3两以上">3两以上</a-menu-item>
                            <a-menu-item key="大薯块平均">大薯块平均</a-menu-item>
                            <a-menu-item key="中薯块平均">中薯块平均</a-menu-item>
                            <a-menu-item key="小薯块平均">小薯块平均</a-menu-item>
                            <a-menu-item key="单株特小薯块">单株特小薯块</a-menu-item>
                            <a-menu-item key="结薯平均">结薯平均</a-menu-item>
                        </a-sub-menu>
                        <!--            <a-menu-item-->
                        <!--                v-for="item in warningValue"-->
                        <!--                :key="item">-->
                        <!--              <span style="font-size: 20px; margin-right: 20px; margin-left: 20px;">{{item}}</span>-->
                        <!--            </a-menu-item>-->
                    </a-menu>
                </a-dropdown>
                <h1 class="anormaly" style="color:blue">{{ anormal }}</h1>
                <h2>&nbsp;&nbsp;个</h2>
                <h3 class="anormaly">暂无异常</h3>
            </div>
            <div class="header">
                <a-dropdown :trigger="['click']">
                    <a @click="e => e.preventDefault()" style="color: black;">
                        <div style="font-weight: bolder">
                            <h2>{{testComplete}}采集完成度</h2>
                            <a-icon type="caret-down" style="color:#888" />
                        </div>
                    </a>
                    <a-menu slot="overlay" @click="handleComplete" >
                        <!--            <a&#45;&#45;menu-item key="-1">dfs</a&#45;&#45;menu-item>-->
                        <a-menu-item key="总体">总体</a-menu-item>
                        <a-sub-menu key="1" title="结薯数量">
                            <a-menu-item key="结薯数量总体">结薯数量总体</a-menu-item>
                            <a-menu-item key="2两以下">2两以下</a-menu-item>
                            <a-menu-item key="2-3两">2-3两</a-menu-item>
                            <a-menu-item key="3两以上">3两以上</a-menu-item>
                            <a-menu-item key="单株大薯块">单株大薯块</a-menu-item>
                            <a-menu-item key="单株中薯块">单株中薯块</a-menu-item>
                            <a-menu-item key="单株小薯块">单株小薯块</a-menu-item>
                            <a-menu-item key="单株特小薯块">单株特小薯块</a-menu-item>
                            <a-menu-item key="结薯平均">结薯平均</a-menu-item>
                        </a-sub-menu>
                        <a-sub-menu key="2" title="结薯重量">
                            <a-menu-item key="结薯重量总体">结薯重量总体</a-menu-item>
                            <a-menu-item key="2两以下">2两以下</a-menu-item>
                            <a-menu-item key="2-3两">2-3两</a-menu-item>
                            <a-menu-item key="3两以上">3两以上</a-menu-item>
                            <a-menu-item key="大薯块平均">大薯块平均</a-menu-item>
                            <a-menu-item key="中薯块平均">中薯块平均</a-menu-item>
                            <a-menu-item key="小薯块平均">小薯块平均</a-menu-item>
                            <a-menu-item key="单株特小薯块">单株特小薯块</a-menu-item>
                            <a-menu-item key="结薯平均">结薯平均</a-menu-item>
                        </a-sub-menu>
                        <!--            <a-menu-item-->
                        <!--                v-for="item in completeValue"-->
                        <!--                :key="item">-->
                        <!--              <span style="font-size: 20px; margin-right: 20px; margin-left: 20px;">{{item}}</span>-->
                        <!--            </a-menu-item>-->
                    </a-menu>
                </a-dropdown>
                <h1>100</h1>
                <h2>&nbsp;&nbsp;%</h2>
                <h3>应采集次数：1 次 </h3>
                <h3>已采集次数：1 次</h3>
            </div>
            <div class="header set">
                <h2>显示设置</h2> <a-icon type="control" theme="filled" style="color:#888" />
                <div style="width: 90%">
                    <div>
                        <h2>警戒线</h2>&nbsp;
                        <a-switch style="margin-bottom: 6px;" checked-children="开" un-checked-children="关" default-unchecked @click="showWarningLine=!showWarningLine"/>

                    </div>
                    <div>
                        <h2>提示信息</h2>&nbsp;
                        <a-switch style="margin-bottom: 6px;" checked-children="开" un-checked-children="关" default-unchecked @click="showPromptMessage=!showPromptMessage"/>
                    </div>
                </div>
            </div>
        </div>
        <div id="2">
            <div>
                <a-tabs default-active-key="1" @change="callback">
                    <a-tab-pane key="1" tab="单年数据显示" >
                        <div style="margin-right: 23px; display: inline-block; text-align: center; width:48%; ">
                            <count  :showPromptMessage="showPromptMessage"
                                   :showWarningLine="showWarningLine"
                                   :year='this.year'
                                   style=" display: inline-block; background-color: #FFF; width: 100%; padding: 10px;"  />
                        </div>
                        <div style="display: inline-block; text-align: center; width:48%; ">
                            <weight  :showPromptMessage="showPromptMessage"
                                    :showWarningLine="showWarningLine"
                                    :year='this.year'
                                    style=" display: inline-block; background-color: #FFF; width: 100%; padding: 10px;"  />
                        </div>
                        <div style="margin-top: 10px; display: inline-block; text-align: center; width:100%; ">
                            <count1  :showPromptMessage="showPromptMessage"
                                    :showWarningLine="showWarningLine"
                                    :year='this.year'
                                    style=" display: inline-block; background-color: #FFF; width: 100%; padding: 10px;"  />
                        </div>
                        <div style="margin-top: 10px; display: inline-block; text-align: center; width:100%; ">
                            <weight2  :showPromptMessage="showPromptMessage"
                                    :showWarningLine="showWarningLine"
                                    :year='this.year'
                                    style=" display: inline-block; background-color: #FFF; width: 100%; padding: 10px;"  />
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="多年份对比" style="" force-render>
                        <div style="margin-top: 10px; display: inline-block; text-align: center; width:100%; ">
                            <test  :showPromptMessage="showPromptMessage"
                                      :showWarningLine="showWarningLine"
                                      :year='this.year'
                                      style=" display: inline-block; background-color: #FFF; width: 100%; padding: 10px;"  />
                        </div>
                        <div style="margin-top: 10px; display: inline-block; text-align: center; width:100%; ">
                            <weight3  :showPromptMessage="showPromptMessage"
                                      :showWarningLine="showWarningLine"
                                      :year='this.year'
                                      style=" display: inline-block; background-color: #FFF; width: 100%; padding: 10px;"  />
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </div>

<!--        <div id="3">-->
<!--            <router-link :showPromptMessage="showPromptMessage"-->
<!--                         :showWarningLine="showWarningLine"-->
<!--                         :year='this.year'-->
<!--                         to="/grow/show-product-quality/singular" style="margin-right: 10px">单年数据显示</router-link>-->

<!--            <router-link :showPromptMessage="showPromptMessage"-->
<!--                         :showWarningLine="showWarningLine"-->
<!--                         :year='this.year'-->
<!--                         to="/grow/show-product-quality/multiple">多年份对比</router-link>-->
<!--            <router-view/>-->
<!--        </div>-->


<!--        <a-tabs default-active-key="1" @change="callback">-->
<!--            <a-tab-pane key="1" tab="Tab 1">-->
<!--                Content of Tab Pane 1-->
<!--            </a-tab-pane>-->
<!--            <a-tab-pane key="2" tab="Tab 2" force-render>-->
<!--                Content of Tab Pane 2-->
<!--            </a-tab-pane>-->
<!--            <a-tab-pane key="3" tab="Tab 3">-->
<!--                Content of Tab Pane 3-->
<!--            </a-tab-pane>-->
<!--        </a-tabs>-->
<!--        <div class="shadow"  style="margin-bottom: 4px;width: 50px;" >-->
<!--            <span>选择年份：</span>-->
<!--            <a-select-->
<!--                    show-search-->
<!--                    option-filter-prop="children"-->
<!--                    placeholder="请选择年份"-->
<!--                    style="width: 120px"-->
<!--                    filterable-->
<!--                    :default-value="new Date().getFullYear()"-->
<!--                    @change="getMsg">-->
<!--                <a-select-option-->
<!--                        v-for="item in optionsYear"-->
<!--                        :key="item"-->
<!--                        :label="item"-->
<!--                        :value="item">{{item}}-->
<!--                </a-select-option>-->
<!--            </a-select>-->
<!--        </div>-->
<!--        <div  style="text-align: center;min-width: 600px; width:50%;display: inline-block; float: left;" >-->
<!--            <count class="shadow" :year='this.year'/>-->
<!--        </div>-->
<!--        <div style="text-align: center;min-width: 600px; width:50%;display: inline-block;">-->
<!--            <weight class="shadow" :year='this.year'/>-->
<!--        </div>-->
<!--        <div class="shadow"  style="margin:0; min-width: 600px; width:100%;" >-->
<!--            <count1 :year='this.year'/>-->
<!--            <test />-->
<!--        </div>-->
<!--        <div class="shadow" style="min-width: 600px; width:100%;display: inline-block;">-->
<!--            <weight2 :year='this.year'/>-->
<!--            <weight3/>-->
<!--        </div>-->
    </div>
</template>

<script>
    import count from '../charts/quantity-count'
    import count1 from '../charts/quantity-count1'
    import count2 from '../charts/quantity-count2'
    import weight from '../charts/quantity-weight'
    import weight3 from '../charts/quantity-weight3'
    import weight2 from '../charts/quantity-weight2'
    import test from "../charts/test";
    export default {
        name: "show-product-quality",
        components:{count,count1,count2,weight,weight3,weight2,test},
        data(){
            return {
                optionsYear:[],
                year:'',
                selectYear:true,
                showWarningLine:false,
                showPromptMessage:false,
                anormal:0, //异常值百分比
                completeness:0.1,
                testWarn:'总体',
                testComplete:'总体',
            };
        },
        beforeMount() {
            //加载年份
            this.$axios.post("/indicator/getYear").then((res)=>{
                this.optionsYear=res.data
                this.year=res.data[0]
                getColor()
            })
            this.getMsg(this.optionsYear[0])
            function getColor(){
                let a = document.getElementsByClassName('anormaly');
                let setColor;
                // console.log("颜色为"+a[0].innerText)
                let val=a[0].innerText
                if(val==="0"){
                    setColor='#24ab62'
                } else{
                    setColor='#2487ABFF'
                }
                for(let item = 0 ; item<a.length ;item++){
                    a[item].style.color=setColor
                }
            }
        },
        methods:{
            getYear(val){
                this.year=val.key;
            },
            getMsg(val){
                this.year=val;
            },
            handleWarn(val){
                this.testWarn=val.key;
            },
            handleComplete(val){
                this.testComplete=val.key;
            },

        },
    }
</script>

<style scoped>
    .shadow {
        margin-top: 20px;

        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
        /*position: relative;*/
        min-width: 400px;
        /*box-shadow: 0px 1px 4px rgba(0,0,0,0.3);*/
        /*0px 0px 20px rgba(0,0,0,0.1) inset;*/
    }
    .headers{
        /*user-select:none;*/
        background-color: #FFF;
        height: 200px;
        box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.13) 0 0 1px 1px;
    }
    /* headers下的第一个header没有边框 */
    .headers .header:first-child {
        border-width:0;
    }

    .header h1{
        display:inline-block;
        font-size:50px;
        line-height: 50px;
        /*font-weight: bold;*/
        margin-top: 4px;
    }
    .header h2{
        display: inline-block;
        font-size: 16px;
        font-weight: bolder;
    }
    .header h3{
        margin: 0;
        color: #666666;
    }

    .header span{

    }
    .headers .header {
        margin-top: 20px;
        display: inline-block;
        text-align: left;
        /*background-color: #00ff00;*/
        vertical-align: middle;
        padding-left: 20px;
        width: 25%;
        min-height: 160px;
        border-left: 1px solid #ddd;
    }

    .headers .set div h2{
        margin-top: 20px;
        width: 30%;
        line-height: 30px;
    }
</style>